import { Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "../../../../hooks/redux-hook";
import { postApiCategoryCreate, putApiUpdateCategory } from "../../../../services/category";
import { showSnackbar } from "../../../../redux/snackbar";
import { getUpdatedFieldsValues } from "../../../../tools/react-from";
import { Category } from "../../../../models/Category";
import isEmpty from "lodash/isEmpty";
import { loadCategory } from "../../../../redux/category";

interface CategoryFormData {
  id: string;
  name: string;
  description: string;
}


const mockCategoryCreateData = (data: any) => {
  const mockCategoryRecord = {
    id: data.id,
    name: data.name,
    description: data.description,
  };
  return mockCategoryRecord;
};

const AddCategoryForm: React.FC = (props: any) => {
  const { hideModal, onRefersh, defaultData } = props;
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CategoryFormData>();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (defaultData) {
      Object.keys(defaultData).forEach((key) => {
        setValue(key as keyof CategoryFormData, defaultData[key]);
      });
    }
  }, [defaultData, setValue]);

  const onSubmit = async (data: CategoryFormData) => {
    try {
      setLoading(true);
      const payload = mockCategoryCreateData(data);
      delete payload.id;
      if (defaultData) {
        const updatedPayload = getUpdatedFieldsValues(payload, defaultData) as Category;
        if (isEmpty(updatedPayload)) {
          throw new Error("No changes found")
        }
        const updatedCategory = await putApiUpdateCategory(defaultData.id, updatedPayload);
        dispatch(loadCategory(updatedCategory));
        dispatch(showSnackbar({ message: "Category Updated", type: "info" }));
      } else {
        const newCategory = await postApiCategoryCreate(payload)
        dispatch(loadCategory(newCategory));
        dispatch(showSnackbar({ message: "Category Created", type: "info" }));
      }
      // onRefersh();
      hideModal();
    } catch (err) {
      // @ts-ignore
      dispatch(showSnackbar({ message: err.message, type: "error" }));

    } finally {
      setLoading(false);

    }

  };
  const handleClose = () => {
    hideModal();
  };

  return (
    <div style={{ width: "1000px" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ paddingLeft: "20px", paddingRight: "20px", marginTop: "15px" }}
      >
        <h2 style={{ margin: 0 }}>
          {defaultData ? `Edit Category` : `Add Category`}
        </h2>
      </Box>

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          padding: "20px",
        }}
      >
        <TextField
          label="Category Name *"
          variant="outlined"
          {...register("name", { required: "Category Name is required" })}
          error={!!errors.name}
          helperText={errors.name?.message}
        />


        <TextField
          label="Description"
          variant="outlined"
          multiline
          rows={2}
          {...register("description")}
          error={!!errors.description}
          helperText={errors.description?.message}
        />


        <Box display="flex" justifyContent="flex-end" gap={2}>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Close
          </Button>
          {
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {defaultData ? "Update" : "Add"}
            </Button>
          }
        </Box>
      </form>
    </div>
  );
};

export default AddCategoryForm;
