import { ArrowBack } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import TabsWithNavigation from '../../../../components/common/Tabs/tabsWithNavigation';
import { CIMRoleKey } from '../../../../constants';
import { useAuthService } from '../../../../contexts/auth-context';
import { ViewCIList } from './viewCI/viewCIList';

const tabs = [
  {
    label: 'Manage CI',
    route: 'manage-ci',
    component: () => <ViewCIList activeRole='CIM_MANAGER' />,
    componentProps: {
      roles: ["CIM_MANAGER", "SUPER", "ADMIN"] as CIMRoleKey[],
    }
  },
  {
    label: 'Review CI',
    route: 'review-ci',
    component: () => <ViewCIList activeRole='CIM_REVIEWER' />,
    componentProps: {
      roles: ["CIM_REVIEWER"] as CIMRoleKey[],
    }
  },
  {
    label: 'Approve CI',
    route: 'approve-ci',
    component: () => <ViewCIList activeRole='CIM_APPROVER' />,
    componentProps: {
      roles: ["CIM_APPROVER"] as CIMRoleKey[],
    }
  },
  {
    label: 'View CI',
    route: 'view-ci',
    component: () => <ViewCIList activeRole='VIEW' />,
    componentProps: {
      roles: ["VIEW"] as ("VIEW" | CIMRoleKey)[],
    }
  },
];

export const CIManagement = React.memo(() => {
  const nav = useNavigate();
  const auth = useAuthService();
  const roles = auth?.loginInfo?.tenant?.user?.roles;

  const filteredTabs = useMemo(() => {
    const accessibleTabs = tabs.filter((tab) =>
      tab.componentProps.roles?.some((role) => roles?.includes(role))
    );
    if (accessibleTabs.length === 0) {
      accessibleTabs.push({
        label: 'View CI',
        route: 'view-ci',
        component: () => <ViewCIList activeRole="VIEW" />,
        componentProps: { roles: ["VIEW"] },
      });
    }
    return accessibleTabs;
  }, [roles]);

  const MemoTabs = useMemo(() => {
    return <TabsWithNavigation
      tabs={filteredTabs}
      tabPanelStyle={{ padding: 0, paddingTop: '2px' }}
      path={'/app/user/cim/ci-workflow/:tabName'}
    />
  }, [filteredTabs]);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'hidden',
        flex: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px',
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#fff',
          zIndex: 1000,
          position: 'sticky',
          top: 0,
          paddingBottom: '0px',
        }}

      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <ArrowBack
            className="arrow-back"
            onClick={() => nav(-1)}
            style={{ cursor: 'pointer' }}
          />
          <Typography variant="h6" sx={{ fontSize: '32px' }}>
            {'CI Workflow'}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ borderColor: 'divider', flex: 1, display: 'flex' }}>
        {MemoTabs}
      </Box>
    </Box>
  );
});
