import React, { useCallback, useMemo } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  value: string;
  index: number;
  selectedValue: string;
  sx?: React.CSSProperties;
}

interface TabsWithNavigationProps {
  selectedTab?: string;
  iconPosition?: 'top' | 'bottom' | 'end' | 'start';
  tabClasses?: {};
  isSubTabs?: boolean;
  tabPanelStyle?: React.CSSProperties;
  path: string;
  tabs: {
    route: string;
    label: string;
    icon?: React.ReactNode;
    component: React.ComponentType<any>;
    componentProps?: any;
  }[];
}

const TabPanel = ({ children, value, index, selectedValue, ...other }: TabPanelProps) => {
  return (
    value === selectedValue ? (
      <Box
        role="tabpanel"
        hidden={value !== selectedValue}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        <Box
          className="custom-tabPanel"
          sx={{
            display: 'flex',
            flex: 1,
            overflowY: 'auto',
          }}
        >
          {children}
        </Box>
      </Box>
    ) : null
  );
};

const TabsWithNavigation = ({
  tabs,
  iconPosition,
  tabClasses,
  tabPanelStyle,
  path,
  selectedTab,
  isSubTabs = false,
}: TabsWithNavigationProps) => {
  const params = useParams();
  const navigate = useNavigate();

  const selectedValue = useMemo(
    () =>
      selectedTab ||
      (isSubTabs ? params?.subTabName : params?.tabName) ||
      tabs?.[0]?.route,
    [tabs, isSubTabs, params?.subTabName, params?.tabName, selectedTab]
  );

  const handleChange = useCallback(
    (event, newValue) => {
      const tabParams = isSubTabs
        ? {
          tabName: params?.tabName,
          subTabName: newValue,
        }
        : { tabName: newValue, subTabName: undefined };
      const newPath = generatePath(path, {
        ...params,
        ...tabParams,
      });
      navigate(newPath, { replace: true });
    },
    [isSubTabs, navigate, params, path]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      {/* Tabs Bar */}
      <Tabs
        value={selectedValue}
        onChange={handleChange}
        sx={{
          backgroundColor: '#fff',
          padding: '12px',
          borderBottom: '1px solid #ddd',
          zIndex: 1000,
          position: 'sticky',
          top: 0,
          display: 'flex',
        }}
      >
        {tabs.map((item, index) => (
          <Tab
            sx={{ display: 'flex' }}
            classes={{
              ...tabClasses,
            }}
            key={index}
            label={item.label}
            value={item.route}
            icon={item.icon as React.ReactElement}
            iconPosition={iconPosition}
          />
        ))}
      </Tabs>

      {/* Tab Panels */}
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          overflow: 'hidden',
        }}
      >
        {tabs.map((item, index) => {
          const Component = item?.component;
          return (
            <TabPanel
              key={index}
              index={index}
              value={item.route}
              selectedValue={selectedValue}
              sx={{ ...tabPanelStyle, flexGrow: 1, overflow: 'hidden', display: 'flex', flex: 1 }}
            >
              <Component {...item.componentProps} />
            </TabPanel>
          );
        })}
      </Box>
    </Box>
  );
};

export default TabsWithNavigation;