import React, {useRef} from 'react';
import {useCustomModal} from "./custom-message-modal";
import UndoIcon from '@mui/icons-material/Undo';

const ConfirmUndoTransformModal = (props : {continue:()=>void }) => {
    const { hideModal } = useCustomModal();
    const modalRef = useRef<HTMLDivElement>(null);

    return <div ref={modalRef} style={{
        position: "relative",
        width: 400, display: "flex", alignItems: 'center',
        flexDirection: "column", justifyContent: "center", padding: "20px 30px"
    }}>
        <UndoIcon  style={{fontSize:"50px",color:"#e86122"}}/>
        <p>Do you want to undo the most recent transformation?</p>

        <div style={{marginTop: "10px"}}>
            <button onClick={() => {
                hideModal();
            }} className={"sanitize-first-modal"} style={{margin: "0px 10px", backgroundColor: '#e60024'}}>No
            </button>
            <button onClick={() => {
                hideModal();
                props.continue()
            }} className={"sanitize-first-modal"} style={{backgroundColor: "#e86122"}}>Yes
            </button>
        </div>

    </div>


};

export default ConfirmUndoTransformModal;
