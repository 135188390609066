import React from 'react';
import { Box, Skeleton, Grid, Divider, Typography } from '@mui/material';

const ViewCISkeleton: React.FC = () => {
  return (
    <Box>
      <Skeleton variant="text" width="30%" height={40} sx={{ mb: 4 }} />
      <Box sx={{ mb: 4 }}>
        <Skeleton variant="rectangular" height={50} sx={{ mb: 2, borderRadius: 1 }} />
      </Box>
      <Divider sx={{ mb: 4 }} />
      <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
        <Skeleton width="40%" />
      </Typography>
      <Grid container spacing={2}>
        {Array.from({ length: 8 }).map((_, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Skeleton variant="text" width="50%" />
            <Skeleton variant="text" width="80%" />
          </Grid>
        ))}
      </Grid>

      <Divider sx={{ my: 4 }} />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="text" width="40%" sx={{ mb: 1 }} />
          <Box display="flex" gap={1} flexWrap="wrap">
            {Array.from({ length: 4 }).map((_, index) => (
              <Skeleton key={index} variant="circular" width={32} height={32} />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="text" width="40%" sx={{ mb: 1 }} />
          <Box display="flex" gap={1} flexWrap="wrap">
            {Array.from({ length: 4 }).map((_, index) => (
              <Skeleton key={index} variant="circular" width={32} height={32} />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ViewCISkeleton;
