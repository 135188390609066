import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { MenuItem } from '@mui/material';
import React from 'react';
import { useCimUserRoles } from '../../../../../hooks/useCimUserRoles';
import { CI } from '../../../../../models/CI';
import { ciWorkFlowStatusObj } from '../../../../../models/ciWorkFlowStatus';


type PutOnHoldMenuItemProps = {
  onClick: () => void;
  ciData: CI;
  loading: boolean;
};

export const PutOnHoldMenuItem: React.FC<PutOnHoldMenuItemProps> = ({
  onClick,
  ciData,
  loading
}) => {

  const { isManager } = useCimUserRoles();

  if (!isManager) {
    return null
  }
  if (ciData.status === ciWorkFlowStatusObj.ON_HOLD) {
    return null
  }
  return <MenuItem
    onClick={onClick}
    disabled={loading}
    sx={{
      '&:hover': {
        backgroundColor: '#555',
        color: '#fff',
        transition: 'all 0.3s ease',
      },
    }}
  >
    <PauseCircleIcon sx={{ mr: 1 }} />
    Put on Hold
  </MenuItem>
}