import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { DataGridPro, GridColDef, GridToolbar } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { UsersAvatarList } from "../../../../components/users-avatar-list";
import { useAppSelector } from "../../../../hooks/redux-hook";
import { CiWorkFlowActionKeys, ciWorkFlowActionObj } from "../../../../models/ciWorkFlowAction";
import { ciWorkFlowStatus } from "../../../../models/ciWorkFlowStatus";
import { InfoTenantUser } from "../../../../models/InfoTenantUser";
import { usersSelector } from "../../../../redux/users";
import { userSettingsSelector } from "../../../../redux/userSettings";
import "./statusHistoryTable.css";
import { orderBy } from "lodash";
import { RejectionCategory } from "../../../../models/RejectionCategory";
import { convertDateTimeFromUTCToTimeZoneIncludeTime } from "../../../../services/utils";

type StatusHistoryProps = {
  history: {
    action: string;
    previousStatus: number | null;
    newStatus: number;
    actionReason: string | null;
    createdAt: string;
    createdByObj?: { name: string; email: string };
    createdBy: number;
    cycle: number;
    id: number;
    rejectionCategory: RejectionCategory | null;
    rejectionCategoryId: number | null;
  }[];
};

export const StatusHistory: React.FC<StatusHistoryProps> = ({ history }) => {
  const [open, setOpen] = useState(false);

  const userSettings = useAppSelector((state) => userSettingsSelector(state));
  const users = useAppSelector((state) => usersSelector(state, false))

  const dateFormat = userSettings?.regional?.dateFormat || "MM/DD/YYYY";
  const timeFormat = userSettings?.regional?.timeFormat || 24;
  const timezone  = userSettings?.regional?.timezone || "UTC";

  const statusHisotryTableHeaders = [
    "Action",
    "Previous Status",
    "New Status",
    "Reason",
    "Created At",
    "Created By",
  ];

  const filteredUsers = (selectedUsers: InfoTenantUser[], users: InfoTenantUser[]) => {
    const filteredUsers = users.filter(u => selectedUsers.some(su => su.id === u.id));
    return filteredUsers
  }

  const sortedHistory = orderBy(history, ["createdAt"], ["desc"]);

  function getColumns() {
    let columnsObject: GridColDef[] = [];
    statusHisotryTableHeaders.forEach((header, index) => {
      if (index === 5) {
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 100,
          flex: 1,
          headerClassName: "custom-header",
          cellClassName: "cell-left-border table-left-padding",
          renderCell: (params) => {
            const createdBy = params.value?.createdByObj;
            return (
              <div style={{ display: 'flex', justifyContent: 'center' }}>


                <UsersAvatarList users={filteredUsers(createdBy ? [createdBy] : [], users || [])} shortView={false} />


              </div>
            );
          },
        });
      } else {
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 180,
          flex: 1,
          headerClassName: "custom-header",
          cellClassName: "cell-left-border table-left-padding",
        });
      }
    });
    return columnsObject;
  }

  function getProjectsRows() {
    return sortedHistory.map((historyItem) => ({
      id: historyItem.id,
      [statusHisotryTableHeaders[0]]: ciWorkFlowActionObj[historyItem.action as CiWorkFlowActionKeys] || "N/A",
      [statusHisotryTableHeaders[1]]: historyItem.previousStatus
        ? ciWorkFlowStatus[historyItem.previousStatus].label
        : "N/A",
      [statusHisotryTableHeaders[2]]: ciWorkFlowStatus[historyItem.newStatus].label,
      [statusHisotryTableHeaders[3]]: historyItem.rejectionCategory
        ? `${historyItem.actionReason ? historyItem.actionReason + " - " : ""}${historyItem.rejectionCategory.name}`
        : historyItem.actionReason,
      [statusHisotryTableHeaders[4]]: convertDateTimeFromUTCToTimeZoneIncludeTime(historyItem.createdAt,timezone,dateFormat,timeFormat),
      [statusHisotryTableHeaders[5]]: historyItem,
    }));
  }

  const toggleModal = () => setOpen((prev) => !prev);

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <div className="body-containerx">
          <div className="page-wrapperz" style={{ padding: 0, flexGrow: 1 }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
              <div><Typography style={{ fontSize: 24, fontWeight: 600 }}>Status History</Typography></div>
              <IconButton onClick={toggleModal}>
                <ZoomInIcon fontSize="large" />
              </IconButton>
            </div>
            {sortedHistory.length === 0 ? (
              <div
                style={{
                  margin: 32,
                  backgroundColor: "var(--lightGrayishBlue)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: 205,
                }}
              >
                <div
                  style={{
                    marginTop: 32,
                    marginBottom: 40,
                    textAlign: "center",
                    fontSize: 21,
                    fontWeight: 600,
                    letterSpacing: 0.15,
                  }}
                >
                  There is no history yet.
                </div>
              </div>
            ) : (
              <div style={{ paddingTop: 16, overflow: "hidden" }}>
                <Box>
                  <DataGridPro
                    autoPageSize={false}
                    autoHeight={true}
                    rowSpacingType={"border"}
                    rows={getProjectsRows()}
                    sx={{
                      "& .MuiDataGrid-cell": {
                        padding: 0,
                      },
                      "& .custom-header": {
                        backgroundColor: "#374c72",
                        color: "#fff",
                        fontWeight: "bold",
                        borderBottom: "2px solid #d3d3d3",
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        color: "#fff !important",
                      },
                      "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
                      {
                        outline: "none",
                      },
                      "& .MuiDataGrid-sortIcon, & .MuiDataGrid-menuIconButton": {
                        color: "#fff !important",
                      },
                    }}
                    disableDensitySelector
                    disableColumnSelector
                    columns={getColumns()}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                    pagination
                    initialState={{
                      pagination: { paginationModel: { pageSize: 5 } },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    getRowClassName={(params) =>
                      params.indexRelativeToCurrentPage % 2 === 0
                        ? "table-row-even-status-history"
                        : "table-row-odd-status-history"
                    }
                  />
                </Box>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal open={open} onClose={toggleModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            overflow: "auto",
          }}
        >
          <DataGridPro
            autoPageSize={false}
            autoHeight={true}
            rowSpacingType={"border"}
            rows={getProjectsRows()}
            sx={{
              "& .MuiDataGrid-cell": {
                padding: 0,
              },
              "& .custom-header": {
                backgroundColor: "#374c72",
                color: "#fff",
                fontWeight: "bold",
                borderBottom: "2px solid #d3d3d3",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                color: "#fff !important",
              },
              "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
              {
                outline: "none",
              },
              "& .MuiDataGrid-sortIcon, & .MuiDataGrid-menuIconButton": {
                color: "#fff !important",
              },
            }}
            disableDensitySelector
            disableColumnSelector
            columns={getColumns()}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            pagination
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50]}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? "table-row-even-status-history"
                : "table-row-odd-status-history"
            }
          />
        </Box>
      </Modal>
    </>
  );
};
