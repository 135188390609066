import React, {FC, forwardRef, useImperativeHandle} from "react";
import WithDropdown, {ReflowDropdownRef, ReflowDropdownState} from "./withDropdown";
interface ReflowDropdownProps {
    handleChange:()=> void;
    value: string;
}


const ReflowDropdown:FC<ReflowDropdownProps> = forwardRef<ReflowDropdownRef,ReflowDropdownProps>(({value: VALUE, handleChange}, ref) => {
    useImperativeHandle(ref, ()=>({
        setData: ()=> VALUE
    }),[VALUE]);
    return(
        <select value={VALUE} onChange={handleChange} style={{padding:"5px 0"}} >
            {Object.entries(ReflowDropdownState).map(([key, value])=> (
                <option key={key} value={value}>{value}</option>
            ))}
        </select>
    )
})
export default WithDropdown(ReflowDropdown)