import React, { useContext, useState } from "react";
import { Menu, MenuItem, TextField } from "@mui/material";
import { projectFolderAction } from "../constants";

interface FileActionsProps {
  open: boolean;
  anchorEl: any;
  //   node: {
  //     id: string;
  //     type: string;
  //     name: string;
  //   };
  setAnchorElId: Function;
}

const ITEM_HEIGHT = 52;

const options = [
  { label: "Rename", value: projectFolderAction.RENAME_FILE },
  { label: "Delete", value: projectFolderAction.DELETE_FILE },
  { label: "Copy to project", value: projectFolderAction.COPY_FILE },
];

function FileActions(props: FileActionsProps) {
  const { anchorEl, open, setAnchorElId } = props;

  const [openModalName, setOpenModalName] = useState("");

  const handleSelectOption = (option: any) => {
    setOpenModalName(option.value);
    setAnchorElId("");
  };
  return (
    <>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorElId("")}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "30ch",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => handleSelectOption(option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default FileActions;
