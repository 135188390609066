import React from 'react';
import { Box, Typography } from '@mui/material';

type CiDescriptionItemProps = {
  label: string;
  content?: string | undefined | null;
  render?: () => React.ReactNode;
};

const CiDescriptionItem: React.FC<CiDescriptionItemProps> = ({ label, content, render }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', gap: 1 }}>
      <Box sx={{ minWidth: 150 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
          {label}
        </Typography>
      </Box>

      {content && (
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="body2" sx={{fontSize:'18px'}}>{content}</Typography>
        </Box>
      )}

      {render && typeof render === 'function' && (
        <Box sx={{ flexGrow: 1 }}>
          {render()}
        </Box>
      )}
    </Box>
  );
};

export default CiDescriptionItem;
