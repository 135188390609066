import React, { useContext, useState } from "react";

import Box from "@mui/material/Box";
import CheckIcon from "@mui/icons-material/Check";
import { BlockOutlined } from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import { acceptedFileTypes, projectFolderAction } from "../../constants";
import { Node } from "../../services/folderDragAndDrop";
import Divider from "@mui/material/Divider";
import FolderAndFilesSelect, {
  FileNode,
  FolderItem,
  schemaNode,
} from "./FolderAndFilesSelect";
import { ProjectFileManagementContext } from "../../contexts/ProjectFolderManagementContextProvider";
import { useAppDispatch } from "../../hooks/redux-hook";
import { hideProgressLine, showProgressLine } from "../../redux/progress-line";
import { postApiCreateOrGetFolder } from "../../services/folder";
import { useParams } from "react-router-dom";
import { startUploadingToS3 } from "../file-management";
import { useAuthService } from "../../contexts/auth-context";
import { showSnackbar } from "../../redux/snackbar";
import {
  FileScanPayload,
  FileScanResponse,
  FileScanTagInfo,
  filesScanning,
  updateFileForVirusScanStatus,
} from "../../services/files";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"; // Down arrow
import ArrowRightIcon from "@mui/icons-material/ArrowRight"; // Right arrow
import { LinearProgress } from "@mui/material";
import { StyledCloseIconUploader } from "../close-button";
import { refreshProjectData } from "../../redux/file-management";

const uploadKey = {
  UPLOADING: "UPLOADING",
  VIRUS_SCANNING: "VIRUS_SCANNING",
  COMPLETED: "COMPLETED",
};

interface UploadFileOrFolderProps {
  node: Node;
  onCancel: () => void;
  isFolderSelectedForUploading: boolean;
}
interface UploadFileModalProps {
  node: Node;
  openModalName: string;
  setOpenModalName: Function;
}

function FolderAndFileUploadStatusNode({
  node,
  tags = [],
}: {
  node: schemaNode;
  tags: FileScanTagInfo[];
}) {
  const [isOpen, setIsOpen] = useState(true);

  const checkFileIsClean = () => {
    const tagItem = tags?.find((tag) => tag.fileName === node.name);

    if (!tagItem) {
      return false;
    }
    if (tagItem?.tag === "clean") {
      return true;
    }
    return false;
  };

  return (
    <>
      <div
        style={{
          cursor: "grab",
          display: "flex",
          alignItems: "center",
        }}
      >
        {node.type === "folder" ? (
          <>
            <span
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </span>
            <span>📁 {node.name}</span>
          </>
        ) : acceptedFileTypes.includes(node.type) ? (
          <>
            <span
              style={{
                marginLeft: 34,
                width: "100%",
              }}
            >
              <span> 📄 {node.name}</span>
              <span
                style={{
                  verticalAlign: "middle",
                  marginLeft: 16,
                }}
              >
                {checkFileIsClean() ? <CheckIcon /> : <BlockOutlined />}
              </span>
            </span>
          </>
        ) : null}
      </div>

      {isOpen && "children" in node && node?.children.length > 0 && (
        <div style={{ marginLeft: "20px" }}>
          {node?.children.map((child, index) => (
            <FolderAndFileUploadStatusNode
              key={`${child.name}_${index}`}
              node={child}
              tags={tags}
            />
          ))}
        </div>
      )}
    </>
  );
}

const UploadFileOrFolder: React.FC<UploadFileOrFolderProps> = ({
  node,
  isFolderSelectedForUploading = false,
  onCancel = () => {},
}) => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams();

  const auth = useAuthService();

  const [selectedSchemaForUpload, setSelectedSchemaForUpload] = useState<
    schemaNode[]
  >([]);
  const [uploadingStatus, setUploadingStatus] = useState("");

  /** virus scanning tag */
  const [tags, setTags] = useState<FileScanResponse["tags"]>([]);

  /* File Management */
  const { allFileObjectList } = useContext(ProjectFileManagementContext);

  const updateFileStatus = async (tags: FileScanTagInfo[]) => {
    const controller = new AbortController();
    const infectedFiles = tags.filter((tag) => tag.tag === "infected");
    if (infectedFiles.length > 0) {
      const promises: any = [];
      infectedFiles.forEach((file: any) =>
        promises.push(
          updateFileForVirusScanStatus(
            { virusScanStatus: "infected", id: file.id },
            controller.signal
          )
        )
      );
      await Promise.all(promises);
    }
  };

  const stopScanning = async (response: FileScanResponse, payload: any) => {
    setTags(response.tags);
    await updateFileStatus(response.tags);
  };

  const handleSubmitSchema = async (schema: schemaNode[]) => {
    try {
      const targetFolderId = node.folderId!;
      const targetProjectId = projectId!;
      setSelectedSchemaForUpload(schema);
      dispatch(showProgressLine());
      setUploadingStatus(uploadKey.UPLOADING);
      const controller = new AbortController();

      const fileDataListToUpload: Array<FileNode> = [];
      /** create new folder & file in db & store file data with targetParentFolderId in fileDataToCopy array */
      const recursivelyCreateFolderAndFileObjectData = async (
        node: schemaNode,
        parentFolderId: number
      ) => {
        if (node.type === "folder") {
          /** create new folder in db */
          const payload = { name: node.name, parentFolderId: parentFolderId };
          const newFolder = await postApiCreateOrGetFolder(payload);
          const targetParentFolderId = newFolder.id;

          if ("children" in node && node.children?.length) {
            await Promise.all(
              node.children.map(async (childNode: schemaNode) =>
                recursivelyCreateFolderAndFileObjectData(
                  childNode,
                  targetParentFolderId!
                )
              )
            );
          }
        } else if (acceptedFileTypes.includes(node.type) && "size" in node) {
          /** push new file in fileDataListToUpload to upload */
          node.projectId = Number(targetProjectId);
          node.folderId = parentFolderId;
          fileDataListToUpload.push(node);
        }
      };
      await Promise.all(
        schema.map(async (node: schemaNode) =>
          recursivelyCreateFolderAndFileObjectData(node, targetFolderId)
        )
      );

      const uploadResponse = await Promise.all(
        fileDataListToUpload.map(async (fileItem: FileNode) => {
          const isFileExistsInProject = !!fileItem.isFileExistsInProject;
          return startUploadingToS3(
            fileItem,
            controller.signal,
            Number(projectId!),
            auth.loginInfo?.tenant?.user?.id,
            isFileExistsInProject,
            false
          );
        })
      );

      dispatch(
        showSnackbar({
          message:
            "Files successfully uploaded. Virus scanning is processing...",
          type: "info",
        })
      );

      const runScanVirus = async (
        files: { id: number; name: string }[] = []
      ) => {
        setUploadingStatus(uploadKey.VIRUS_SCANNING);
        const payload = {
          projectId: projectId!,
          files: files.filter((file) => file !== null),
        } as FileScanPayload;
        const response = await filesScanning(payload, controller.signal);

        if (response.isCompleted) {
          await stopScanning(response, payload);
        } else {
          await runScanVirus(files);
        }
      };

      const filesForScanning = uploadResponse
        .filter((file) => file !== null)
        .map((file) => ({ id: Number(file!.id), name: file!.name })) as {
        id: number;
        name: string;
      }[];

      if (filesForScanning.length > 0) {
        await runScanVirus(filesForScanning);
      }
      dispatch(hideProgressLine());
      setUploadingStatus("");
      setUploadingStatus(uploadKey.COMPLETED);
      dispatch(refreshProjectData());
    } catch (error) {
      dispatch(refreshProjectData());
      setUploadingStatus("");
      dispatch(hideProgressLine());
      dispatch(
        showSnackbar({ message: "Error uploading files", type: "error" })
      );
    }
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        minHeight: 300,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 1,
      }}
    >
      <div className="wizard-title">
        <StyledCloseIconUploader onClick={onCancel} />
        <span>
          {uploadingStatus === uploadKey.COMPLETED &&
          selectedSchemaForUpload.length > 0
            ? "Virus Scanning Summary"
            : "RLS File Uploader"}
        </span>
      </div>
      <Divider component="li" />

      {!uploadingStatus && selectedSchemaForUpload.length === 0 && (
        <FolderAndFilesSelect
          projectFileList={allFileObjectList}
          isFolderSelectedForUploading={isFolderSelectedForUploading}
          handleSubmitSchema={handleSubmitSchema}
          handleCancel={() => {
            onCancel();
          }}
        />
      )}

      {uploadingStatus &&
        (uploadingStatus === uploadKey.UPLOADING ||
          uploadingStatus === uploadKey.VIRUS_SCANNING) && (
          <div className="custom">
            <p>
              {uploadingStatus === uploadKey.VIRUS_SCANNING
                ? "Virus Scanning"
                : "Uploading"}
            </p>
            <LinearProgress className="linear-progress" />
            <small>please wait...</small>
          </div>
        )}

      {uploadingStatus === uploadKey.COMPLETED &&
        selectedSchemaForUpload.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              maxHeight: 400,
              overflow: "auto",
            }}
          >
            {selectedSchemaForUpload.map((node, index) => (
              <FolderAndFileUploadStatusNode
                key={`${node.name}_${index}`}
                node={node}
                tags={tags}
              />
            ))}
          </div>
        )}
    </Box>
  );
};

const FileOrFolderUploadModal: React.FC<UploadFileModalProps> = ({
  node,
  openModalName,
  setOpenModalName,
}) => {
  return (
    <Modal
      open={
        openModalName === projectFolderAction.FILE_UPLOAD ||
        openModalName === projectFolderAction.FOLDER_UPLOAD
      }
      onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <UploadFileOrFolder
        node={node}
        onCancel={() => setOpenModalName("")}
        isFolderSelectedForUploading={openModalName === projectFolderAction.FOLDER_UPLOAD}
      />
    </Modal>
  );
};

export default FileOrFolderUploadModal;
