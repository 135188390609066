import { IconButton } from "@mui/material";
import { LocalizationProvider, DesktopDatePicker, PickersDayProps, PickersDay, pickersDayClasses } from "@mui/x-date-pickers";
import MomentUtils from '@date-io/moment'
import moment from "moment";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../hooks/redux-hook";
import { setShowToolbarAfterDeletion } from "../redux/action-bar";
import { useAppSelector } from "../hooks/redux-hook";
import { userSettingsSelector } from "../redux/userSettings";

export function CustomDatePicker(p: {
    value: Date | null, setValue: (value: Date | null) => void, name: string, icon: JSX.Element, shortView?: boolean,
    minDate?: Date | undefined, dontShowValue?: boolean, readonly?: boolean, setIsCustomOpen?: (open: boolean) => void
}) {
    const customInputRef = useRef<HTMLDivElement | null>(null);
    const [open, setOpen] = useState(false);
    const [hoverStartDate, setHoverStartDate] = useState(false);
    const dispatch = useAppDispatch();
    const userSettings = useAppSelector((state) => userSettingsSelector(state));
    const dateFormat = userSettings?.regional?.dateFormat || "YYYY-MM-DD";

    const setModalOpen = (value: boolean) => {
        setOpen(value);
        if (p.setIsCustomOpen) {
            p.setIsCustomOpen(value);
        }
    }

    const windowClickHandler = useRef((ev: MouseEvent) => {
        const calendars = Array.from(document.getElementsByClassName("MuiCalendarPicker-root"));
        for (const calendar of calendars) {
            const dialogElement = calendar.parentElement?.parentElement?.parentElement?.parentElement;
            if (dialogElement) {
                const el = ev.target as HTMLElement;
                if (!dialogElement.contains(el)) {
                    setModalOpen(false);
                }
            }
        }
    })

    useEffect(() => {
        fixDatePickerPaddings(open, windowClickHandler);
    }, [open]);

    useEffect(() => {
        if (p.value) {
            setModalOpen(false);
        }
    }, [p.value])

    const monthWidth = (month: number) => {
        switch (month) {
            case 0: return 110;
            case 1: return 115;
            case 8: return 120;
            case 9: return 110;
            case 10: return 120;
            case 11: return 120;
            default: return 100;
        }
    }

    return <LocalizationProvider dateAdapter={MomentUtils}>
        <DesktopDatePicker
            value={p.value}
            PopperProps={{ anchorEl: customInputRef.current, sx: { width: 256 } }}
            renderDay={renderWeekPickerDay}
            open={open}
            // minDate={p.minDate}
            onChange={(newValue) => {
                p.setValue(newValue);
                setModalOpen(false);
            }}
            renderInput={() => <div ref={customInputRef}
                style={{
                    width: p.shortView ? "" : 225, height: p.shortView ? "" : 38,
                    border: p.shortView ? "" : "1px solid #E6E7E8",
                    display: "flex", alignItems: "center", justifyContent: "space-around"
                }}>
                {!p.shortView && <span style={{ fontSize: 13 }}>{p.name}</span>}
                {p.value && !p.dontShowValue && <div onMouseEnter={p.value ? () => setHoverStartDate(true) : () => { }}
                    onMouseLeave={p.value ? () => setHoverStartDate(false) : () => { }}
                    style={{
                        display: "flex", alignItems: "center", justifyContent: "center", position: "relative", right: 7,
                        width: monthWidth(moment(p.value).month())
                    }}>
                    {hoverStartDate && !p.readonly ? <div onClick={() => p.setValue(null)}
                        style={{
                            width: 12, height: 12, borderRadius: 6, fontSize: 8, marginTop: 2,
                            marginRight: 6, backgroundColor: "#939598", color: "white",
                            cursor: "pointer", display: "flex", justifyContent: "center"
                        }}>
                        X
                    </div> :
                        <div style={{
                            width: 12, height: 12, borderRadius: 6, marginTop: 2,
                            marginRight: 6, backgroundColor: "white", color: "white"
                        }} />}
                    <span style={{ fontSize: 13, letterSpacing: 0.4, color: "#223250", whiteSpace: "nowrap" }}
                        onClick={() => setModalOpen(p.readonly ? false : !open)}>
                        {moment(p.value).format(dateFormat)}
                    </span>
                </div>}
                {(!p.value || (p.value && p.dontShowValue)) && <IconButton sx={{ width: 24, height: 24 }}
                    onClick={() => {
                        dispatch(setShowToolbarAfterDeletion(false));
                        return (!p.value || (p.value && p.dontShowValue)) && !p.readonly ? setModalOpen(!open) : {};
                    }} >
                    {p.icon}
                </IconButton>
                }
            </div>}
        />
    </LocalizationProvider>
}

export const renderWeekPickerDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
) => {

    return (
        <PickersDay
            {...pickersDayProps}
            sx={{
                [`&&.${pickersDayClasses.today}`]: {
                    borderColor: "#217DA2"
                },
                [`&&.${pickersDayClasses.selected}`]: {
                    backgroundColor: "#217DA2"
                }
            }}
        />
    );
};

export function fixDatePickerPaddings(open: boolean, windowClickHandler: MutableRefObject<(ev: MouseEvent) => void>) {
    if (open) {
        setTimeout(() => {
            const calendars = Array.from(document.getElementsByClassName("MuiCalendarPicker-root"));
            for (let calendar of calendars) {
                calendar.parentElement!.className = "date-picker";
                (calendar.firstElementChild! as HTMLElement).style!.paddingLeft = "24px";
                (calendar.firstElementChild! as HTMLElement).style!.paddingRight = "";
                for (let span of Array.from(calendar.lastElementChild!.getElementsByTagName("span"))) {
                    span.style.width = "32px";
                    span.style.height = "32px";
                    span.style.margin = "0";
                }
            }
            window.addEventListener('click', windowClickHandler.current);
        }, 100);
    } else {
        window.removeEventListener('click', windowClickHandler.current);
    }
}
