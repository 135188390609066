// ciWorkFlowstatus.ts
export const ciWorkFlowActionObj = {
    "create":"Create",
    "submit_review":"Submit for Review",
    "reviewed": "Reviewed",
    "rejected_review": "Rejected (From Review)",
    "rejected_approval": "Rejected (From Approval)",
    "approved": "Approved",
    "hold": "On Hold",
    "resubmit_review": "Resubmited for Review",
    "edit":"Edit by Manager"
  } as const; 

  export type CiWorkFlowActionKeys = keyof typeof ciWorkFlowActionObj;

