import React from 'react';
import { Box, Typography } from '@mui/material';
import { assestStatus } from '../../../../models/assetStatus';



type AssetStatusProps = {
  status?: keyof typeof assestStatus;
};

const AssetStatus: React.FC<AssetStatusProps> = ({ status }) => {
  const statusDetails = status ? assestStatus[status] : { label: 'Unknown', color: '#d1d1d1' };

  return (
    <Box
      sx={{
        paddingLeft: '.5rem',
        paddingRight: '.5rem',
        backgroundColor: statusDetails.color,
        color: 'white',
        borderRadius: '12px',
        fontWeight: 600,
        display: 'flex',
        width: 'fit-content',
        paddingTop: '.2rem',
        paddingBottom: '.2rem',
      }}
    >
      <Typography variant="body2" sx={{ margin: 0, color: '#000' }}>
        {statusDetails.label}
      </Typography>
    </Box>
  );
};

export default AssetStatus;
