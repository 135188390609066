import { Box, Card, Tooltip, Typography } from "@mui/material";
import React from "react";
import {
  AutoSizer,
  List as VirtualizedList
} from "react-virtualized";
import { CI } from "../../../../models/CI";
import { WorkFlowLabel } from "../component/workFlowLabel";

type CategoryListProps = {
  items: CI[];
  onItemClick: (item: CI) => void;
};

const Chip = (p: { label?: string }) => (
  <Tooltip title={p?.label || ""} arrow><div
    style={{
      backgroundColor: "#e9eef9",
      paddingLeft: ".25rem",
      paddingRight: ".25rem",
      width: "fit-content",
      borderRadius: 3,
    }}
  >
    <Typography style={{
      fontSize: 18, overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "120px",
    }}>{p?.label || ""}</Typography>
  </div>
  </Tooltip>
);


const Row = ({
  index,
  style,
  data,
  onItemClick,
}: {
  index: number;
  style: React.CSSProperties;
  data: CI[];
  onItemClick: (item: CI) => void;
}) => {
  const item = data[index];
  return (
    <Box style={style}>
      <Card
        sx={{
          padding: "16px 24px",
          display: "flex",
          flexDirection: "column",
          gap: '15px',
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          border: "1px solid #e0e0e0",
          cursor: "pointer",
          marginBottom: "20px",
        }}
        onClick={() => onItemClick(item)}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "150px 150px 1fr",
            gap: 2,
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            <Typography sx={{ fontSize: 15, opacity: 0.7 }}>Asset</Typography>
            <Chip label={item.asset.name} />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            <Typography sx={{ fontSize: 15, opacity: 0.7 }}>Category</Typography>
            <Chip label={item?.category?.name} />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            <Typography sx={{ fontSize: 15, opacity: 0.7 }}>Known CI</Typography>
            <Tooltip title={item.knownCI || ""} arrow>
              <Typography
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  fontSize: 18,
                  maxWidth: "500px",
                }}
              >
                {item.knownCI}
              </Typography>
            </Tooltip>
          </Box>
        </Box>
        <Box sx={{ marginTop: "8px" }}>
          <WorkFlowLabel
            currentStatus={item.status}
            wrapperStyle={{ width:item.status===1?850: 600 }}
            labelFontSize={15}
            chevronHeight={50}
          />
        </Box>
      </Card>
    </Box>
  );
};

export const CategoryList: React.FC<CategoryListProps> = ({ items, onItemClick }) => {
  if (!items.length) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "16px",
          borderRadius: "8px",
          border: "1px dashed #e0e0e0",
          backgroundColor: "#f9f9f9",
          height: "100%",
        }}
      >
        <Typography sx={{ fontSize: "1rem", color: "#9e9e9e" }}>No CI</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ flex: 1, overflow: "hidden" }}>
      <AutoSizer>
        {({ height, width }) => {
          return (
            <VirtualizedList
              width={width}
              height={height}
              rowCount={items.length}
              rowHeight={181}
              rowRenderer={({ index, style }) => (
                <Row
                  index={index}
                  style={style}
                  data={items}
                  onItemClick={onItemClick}
                />
              )}
            />
          );
        }}
      </AutoSizer>
    </Box>
  );
};