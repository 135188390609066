import { Box, Button, CircularProgress, Modal, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useAppDispatch } from '../../../../../hooks/redux-hook';
import { showSnackbar } from '../../../../../redux/snackbar';
import { putApiReSubmitCI } from '../../../../../services/ciWorkFlow';

type ReSubmitCIModalProps = {
  open: boolean;
  onClose: () => void;
  ciId: number;
  onSuccess: () => void;
};

export const ReSubmitCIModal: React.FC<ReSubmitCIModalProps> = ({
  open,
  onClose,
  ciId,
  onSuccess,
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');

  const handleSubmitReview = () => {

    setLoading(true);
    putApiReSubmitCI(ciId, { actionReason: comment })
      .then(() => {
        dispatch(showSnackbar({
          message: "Successfully submitted for review",
          type: "info",
        }));
        onSuccess();
      })
      .catch(() => {
        dispatch(showSnackbar({
          message: "Error submitting for review",
          type: "error",
        }));
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '400px',
          bgcolor: 'background.paper',
          padding: 4,
          borderRadius: 2,
          boxShadow: 24,
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Re Submit for Review
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: 2, backgroundColor: '#f5f5f5', padding: 1.5, borderRadius: 1 }}
        >
          Please note: You have done suffecient changes before resubmitting.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Add a Comment (Optional)
        </Typography>
        <TextField
          label="Enter your comment"
          multiline
          rows={4}
          fullWidth
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Provide a comment or context to help"
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: 2,
            },
          }}
        />
        <Box
          sx={{
            marginTop: 3,
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            fullWidth
            sx={{
              textTransform: 'none',
              borderRadius: 2,
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitReview}
            disabled={loading}
            fullWidth
            sx={{
              textTransform: 'none',
              borderRadius: 2,
            }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Re Submit'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
