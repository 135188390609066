import React, { useCallback, useContext } from 'react';
import RedactionSearchResult from './RedactionSearchResult';
import WebViewerContext from "../../../contexts/webviewer-context";
import {getStyleAndDisplayCategory} from "./RedactionSearchResultsContainer";

const RedactionSearchResultContainer = (props) => {
  const {
    searchResult,
    checked,
    checkResult,
    isActive
  } = props;

  const { index } = searchResult;
  const resultStr = searchResult.getCustomData('trn-annot-preview');
  const ambientStr = searchResult.getCustomData('ambientStr');
  const resultStrStart = searchResult.getCustomData('resultStrStart');
  const resultStrEnd = searchResult.getCustomData('resultStrEnd');
  const { instance } = useContext(WebViewerContext);

  const onChange = useCallback((event) => {
    checkResult(event, index);
  }, [index, checkResult]);

  const onClickResult = useCallback(() => {
    instance.Core.annotationManager.deselectAllAnnotations();
    instance.Core.annotationManager.selectAnnotation(searchResult);
    instance.Core.annotationManager.jumpToAnnotation(searchResult);
    props.setActiveSearchResult(searchResult);
  }, [searchResult]);

  return (
    <RedactionSearchResult
      ambientStr={ambientStr}
      resultStrStart={resultStrStart}
      resultStrEnd={resultStrEnd}
      resultStr={resultStr}
      type={searchResult.type}
      isChecked={checked ?? false}
      onChange={onChange}
      onClickResult={onClickResult}
      isActive={isActive}
      index={index}
    />
  );
};

export default RedactionSearchResultContainer;
