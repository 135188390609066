import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Asset } from '../models/Asset';
import { RootState } from './store';

export const defaultAssets: Asset[] = [];

const assetsInitialState = {
  assets: defaultAssets,
  loaded: false,
  selected: null as number | null,
  loading: false,
};

const assetsSlice = createSlice({
  name: 'assets',
  initialState: assetsInitialState,
  reducers: {
    loadAssets: (state, action: PayloadAction<Asset[]>) => {
      state.assets = action.payload;
      state.loaded = true;
    },
    loadAsset: (state, action: PayloadAction<Asset>) => {
      const index = state.assets.findIndex((asset) => asset.id === action.payload.id);
      if (index !== -1) {
        state.assets[index] = action.payload;
      } else {
        state.assets.unshift(action.payload);
      }
    },
    selectAsset: (state, action: PayloadAction<number>) => {
      state.selected = action.payload;
    },
    removeAsset: (state, action: PayloadAction<number>) => {
      state.assets = state.assets.filter((asset) => asset.id !== action.payload);
    },
    setAssetsLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
    setAssetsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const {
  loadAssets,
  loadAsset,
  selectAsset,
  removeAsset,
  setAssetsLoaded,
  setAssetsLoading,
} = assetsSlice.actions;

export const assetsSelector = (state: RootState) => state.assets.assets;
export const loadedAssetsSelector = (state: RootState) => state.assets.loaded;
export const selectedAssetSelector = (state: RootState) =>
  state.assets.assets.find((asset: { id: any; }) => asset.id === state.assets.selected);

export default assetsSlice.reducer;
