

import AddLinkIcon from "@mui/icons-material/Link";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Autocomplete, Card, CardContent, CircularProgress, Fade, IconButton, List, Skeleton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { StyledBackArrow, StyledCloseIcon } from "../../../components/close-button";
import { useAppDispatch } from "../../../hooks/redux-hook";
import { Asset } from "../../../models/Asset";
import { Project } from "../../../models/Project";
import { getApiAssets, postApiAssetLinkProject, postApiAssetUnLinkProject } from "../../../services/assets";
import { useCustomModal } from "../custom-message-modal";
import './remove-entity-modal.scss';

export function LinkAssetModel(props: { project: Project, fetchProject: () => void }) {
    const { hideModal } = useCustomModal();
    
      const [availableAssets, setAvailableAssets] = useState<Asset[]>([]);
      const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
      const [loading, setLoading] = useState<boolean>(true);
      const [linkingLoading, setLinkingLoading] = useState<boolean>(false);

      const [linkedAssets, setLinkedAssets] = useState<Asset[]>(props.project?.linkedAssets || []);
      const dispatch = useAppDispatch();
    
      useEffect(() => {
        const fetchAssets = async () => {
          try {
            const assets = await getApiAssets();
            const filteredAssets = assets.filter(asset => !linkedAssets.some(linkedAsset => linkedAsset.id === asset.id));
            setAvailableAssets(filteredAssets);
          } catch (error) {
            console.error("Error fetching projects:", error);
          } finally {
            setLoading(false);
          }
        };
    
        fetchAssets();
      }, [linkedAssets]);
    
      const handleLinkProject = async () => {
        if (selectedAsset) {
          try {
            setLinkingLoading(true)
            await postApiAssetLinkProject({ assetId: selectedAsset.id, projectId: props.project.id });
            props.fetchProject()
            setLinkedAssets([...linkedAssets, selectedAsset]);
            setAvailableAssets(availableAssets.filter(asset => asset.id !== selectedAsset.id));
            setSelectedAsset(null);
          } catch (error) {
            console.error("Error linking asset:", error);
          }finally{
            setLinkingLoading(false)

          }
        }
      };
      const handleUnlinkAsset = async (assetId: number) => {
        const assetToLink = linkedAssets.find((asset) => asset.id === assetId);
        if (assetToLink) {
          try {
            setLinkingLoading(true)
            await postApiAssetUnLinkProject({ assetId, projectId:props.project.id });
            props.fetchProject()
            setLinkedAssets(linkedAssets.filter((asset) => asset.id !== assetId));
            setAvailableAssets([...availableAssets, assetToLink]);
          } catch (error) {
            console.error("Error unlinking asset:", error);
          }finally{
            setLinkingLoading(false)
          }
        }
      };

    return <div style={{ width:500, height: 414, display: "flex", marginTop: 36 }}>
        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <StyledBackArrow onClick={() => hideModal()} />
            <StyledCloseIcon onClick={() => hideModal()} />
        <p style={{ textAlign: "center", marginTop: 10, fontSize: 25, }}>Link Asset</p>
            <div 
            style={{ padding:25,paddingTop:0 }}
            >
            {loading ? (
          <Stack spacing={2}>
            <Skeleton width="100%" height={50} />
            <Skeleton width="100%" height={40} />
            <Skeleton width="100%" height={80} />
          </Stack>
        ) : (
          <div style={{ marginTop: "20px" }}>
          {linkedAssets.length===0 &&  <><Autocomplete
              options={availableAssets}
              getOptionLabel={(option) => option.name}
              value={selectedAsset}
              onChange={(_, newValue) => setSelectedAsset(newValue)}
              renderInput={(params) => <TextField {...params} label="Select an asset to link" variant="outlined" fullWidth />}
            />
            
            <Button 
              variant="contained" 
              color="primary" 
              startIcon={linkingLoading?null: <AddLinkIcon />} 
              onClick={handleLinkProject} 
              sx={{ mt: 2,height:39,width:144 }} 
              disabled={!selectedAsset || linkingLoading}
            >
              {linkingLoading?<CircularProgress size={10}/>: `Link Asset`}
            </Button>
            </>}

            <Typography variant="h6" sx={{ mt: 3, fontWeight: "bold" }}>
              {`Linked Asset`}
            </Typography>

            {linkedAssets.length === 0 ? (
              <Typography variant="body2" sx={{ mt: 2, color: "gray", textAlign: "center" }}>
                No asset linked yet. Select a asset above to link.
              </Typography>
            ) : (
              <List sx={{ mt: 2 }}>
                {linkedAssets.map((asset, index) => (
                  <Fade in timeout={300} key={asset.id}>
                    <Card sx={{ mb: 2, display: "flex", alignItems: "center", p: 2, borderRadius: 2 }}>
                      <CardContent sx={{ flexGrow: 1,padding:0 }}>
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          {asset.name}
                        </Typography>
                        <Typography variant="body2">
                          Generic Name: {asset.genericName}
                        </Typography>
                        <Typography variant="body2">
                          Created By: {asset?.createdByName || 'N/A'}
                        </Typography>
                      </CardContent>

                      <Tooltip title="Unlink Asset" arrow>
                        <IconButton 
                          size="small" 
                          onClick={() => handleUnlinkAsset(asset.id)}
                          disabled={linkingLoading}
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </Card>
                  </Fade>
                ))}
              </List>
            )}
          </div>
        )}
            </div>
        </div>
    </div>
}
