import { useState } from "react";
import { Node } from "../../../services/folderDragAndDrop";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"; // Down arrow
import ArrowRightIcon from "@mui/icons-material/ArrowRight"; // Right arrow

interface FolderTreeToSaveFileProps {
  node: Node;
  fileFolderId: number;
  handleSelectTargetFolder?: Function;
}

function FolderTreeToSaveFile(props: FolderTreeToSaveFileProps) {
  const { node, fileFolderId, handleSelectTargetFolder = () => {} } = props;

  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <>
        {node.type === "folder" && (
          <>
            <div
              style={{
                cursor: "grab",
                display: "flex",
                alignItems: "center",
                backgroundColor: fileFolderId == node.folderId ? "#eaf5ff" : "",
                borderRadius: 8,
                paddingLeft: 2
              }}
            >
              {node.type === "folder" && (
                <span
                  style={{ marginRight: "10px", cursor: "pointer" }}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {isOpen ? (
                    <ArrowDropDownIcon
                      sx={{
                        color:
                          fileFolderId == node.folderId ? "blue" : "#223250",
                      }}
                    />
                  ) : (
                    <ArrowRightIcon
                      sx={{
                        color:
                          fileFolderId == node.folderId ? "blue" : "#223250",
                      }}
                    />
                  )}
                </span>
              )}

              <span
                onClick={() => {
                  handleSelectTargetFolder(node);
                }}
                style={{
                  cursor: "pointer",
                  color: fileFolderId == node.folderId ? "blue" : "#223250",
                  fontWeight: fileFolderId == node.folderId ? "bold" : "normal",
                }}
              >
                {node.type === "folder" ? "📁" : "📄"} {node.name}
              </span>
            </div>

            {isOpen && node.children && node.children.length > 0 && (
              <div style={{ marginLeft: "20px" }}>
                {node.children.map((child) => (
                  <FolderTreeToSaveFile
                    key={child.id}
                    node={child}
                    fileFolderId={fileFolderId}
                    handleSelectTargetFolder={handleSelectTargetFolder}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </>
    </>
  );
}

export default FolderTreeToSaveFile;
