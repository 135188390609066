import { ArrowBack } from '@mui/icons-material';
import EditIcon from "@mui/icons-material/Edit";
import { Autocomplete, Box, Button, IconButton } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hook';

import { TextField } from '@mui/material';
import {
  DataGridPro,
  GridColDef,
  GridRowsProp,
  GridToolbar
} from "@mui/x-data-grid-pro";
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Asset } from '../../../../models/Asset';
import { CI as CISchema } from '../../../../models/CI';
import { ciWorkFlowStatus } from '../../../../models/ciWorkFlowStatus';
import { actionBarIsVisibleSelector } from '../../../../redux/action-bar';
import { loadAssets } from '../../../../redux/assets';
import { loadCIs, loadedCISelector, loadFilteredCIs } from '../../../../redux/ci';
import { hideProgressLine, showProgressLine } from '../../../../redux/progress-line';
import { userSettingsSelector } from '../../../../redux/userSettings';
import { getApiAssets } from '../../../../services/assets';
import { getApiCIs } from '../../../../services/ci';
import { fetchUsers } from '../../../../services/init';
import { useCustomModal } from '../../../modals/custom-message-modal';
import ViewCIDetailDrawer from '../approveReviewCI/viewCI/viewCIDetailDrawer';
import { mockEditCategoryData } from '../cimHelper';
import CIStatusChip from '../component/ciStatusChip';
import AddCIForm from './mapCategoryForm';
import { convertDateTimeFromUTCToTimezone } from '../../../../services/utils';


const ciTableHeaders = [
  "Assets Name",
  "Category Name",
  "Known CI",
  "CI Effective Date",
  "Project",
  "Status",
  "Created By",
  "Edit",
];

export const CI = () => {
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const { showModal, hideModal } = useCustomModal();
  const userSettings = useAppSelector((state) => userSettingsSelector(state));
  const actionBarIsVisible = useAppSelector((state) =>
    actionBarIsVisibleSelector(state)
  );
  const [selectedCI, setSelectedCI] = useState<CISchema | null>(null);

  const dateFormat = userSettings?.regional?.dateFormat || "MM/DD/YYYY";
  const timezone  = userSettings?.regional?.timezone || "UTC";

  const ciObj = useAppSelector((state) => state.CI);
  const ci = ciObj.CI;
  const ciFiltered = ciObj.filteredCI
  const loading = ciObj.loading;
  const assetsObj = useAppSelector((state) => state.assets);
  const assets = assetsObj.assets;
  const [selectedAsset, setSelectedAsset] = useState<Asset | null>();

  const loaded = useAppSelector((state) => loadedCISelector(state));
  const loadAssetsData = useCallback(async () => {
    const assetsList = await getApiAssets();
    dispatch(loadAssets(assetsList));
  }, [dispatch]);

  useEffect(() => {
    loadAssetsData();
  }, [loadAssetsData])


  useEffect(() => {
    const controller = new AbortController();
    fetchUsers(controller.signal, dispatch);
    return () => controller.abort();
  }, []);

  const loadCIData = useCallback(async () => {
    dispatch(showProgressLine());
    try {
      const allCIs = await getApiCIs();
      dispatch(loadCIs(allCIs));
      dispatch(hideProgressLine());

    } catch (err) {
      dispatch(hideProgressLine());

    }


  }, [dispatch])

  useEffect(() => {
    if (selectedAsset) {
      const filteredCatList = ci.filter(item => item.assetId === selectedAsset.id)
      dispatch(loadFilteredCIs(filteredCatList))
    } else {
      dispatch(loadFilteredCIs(ci))
    }
  }, [ci, selectedAsset])

  useEffect(() => {
    loadCIData()
  }, [dispatch, loadCIData])


  const handleAssetChange = (event: any, newValue: Asset | null) => {
    if (newValue) {
      setSelectedAsset(newValue);
    } else {
      setSelectedAsset(null);
    }
  };

  function getColumns() {
    let columnsObject: GridColDef[] = [];
    ciTableHeaders.forEach((header, index) => {
      if (index === 0) {
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 80,
          flex: 1.5,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
        });
      }
      else if (index === 1) {
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 80,
          flex: 2,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
        });
      }
      else if (index === 2) {
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 100,
          flex: 2,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
        });
      }
      else if (index === 3) {
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 70,
          flex: 1.5,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
        });
      }
      else if (index === 4) {
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 100,
          flex: 1,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
        });
      }
      else if (index === 5) {
        columnsObject.push({
          field: header,
          headerName: header,
          flex: 1.5,
          minWidth: 180,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
          renderCell: (params) => {
            return <Box sx={{ display: 'flex', justifyContent: 'center', flex: 1 }}> <CIStatusChip status={params.row?.ci?.status} onClick={() => { setSelectedCI(params.row?.ci) }} /></Box>
          },
        });
      }
            else if (index === 6) {
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 130,
          flex: 1.4,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
        });
      }
      else if (index === 7) {
        columnsObject.push({
          field: header,
          headerName: header,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
          renderCell: (params) => {
            return (
              <IconButton onClick={() => onEditCategory(params)}>
                <EditIcon />
              </IconButton>
            );
          },
        });
      } else {
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 120,
          width: index === 6 ? 160 : 120,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border projects-table-header",
        });
      }
    });
    return columnsObject;
  }

  function onEditCategory(params: any) {
    showModal(AddCIForm, {
      hideModal,
      onRefersh: loadCIData,
      defaultData: mockEditCategoryData(params.value,timezone),
    });
  }

  function getProjectsRows() {
    let rows: GridRowsProp = [];
    for (let ci of ciFiltered) {
      rows = rows.concat({
        id: ci.id,
        [ciTableHeaders[0]]: ci.asset.name,
        [ciTableHeaders[1]]: ci?.category?.name,
        [ciTableHeaders[2]]: ci.knownCI,
        [ciTableHeaders[3]]: convertDateTimeFromUTCToTimezone(ci.ciEffectiveDate, timezone, dateFormat),
        [ciTableHeaders[4]]: ci?.project?.name || '',
        [ciTableHeaders[5]]: ciWorkFlowStatus[ci.status]?.label || "N/A",
        [ciTableHeaders[6]]: ci?.createdByName,
        [ciTableHeaders[7]]: ci,
        ci,
      });
    }

    return rows;
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="body-container">
        <div className="page-wrapper" style={{ padding: 0, flexGrow: 1, width: "96%" }}>
          <div>
            <div
              className="page-header-small"
              style={{ padding: "12px 16px", justifyContent: "space-between" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <ArrowBack className="arrow-back" onClick={() => nav(-1)} />
                {"CI"}
              </div>
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    showModal(AddCIForm, {
                      hideModal,
                      onRefersh: loadCIData,
                      defaultData: null,
                    });
                  }}
                >
                  ADD CI
                </Button>
              </div>
            </div>
            <div style={{ display: 'flex', padding: " 0px 16px 12px 16px", }}>
              <div style={{ width: '30%' }}>
                <Autocomplete value={selectedAsset} onChange={handleAssetChange} options={assets} getOptionLabel={(option) => option.name} renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Asset"
                    variant="outlined"
                  />
                )} />
              </div>
            </div>
          </div>
          <div
            style={{
              height: `calc(100vh - var(--headerHeight) - var(--footerHeight) - 176px${actionBarIsVisible ? " - 60px" : ""
                }`,
              overflowY: "auto",
            }}
            id="assets-data-0"
          >

            {loaded ? (
              <>
                {(ciFiltered.length === 0 && ci.length === 0) ? (
                  <div
                    style={{
                      margin: 32,
                      backgroundColor: "var(--lightGrayishBlue)",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: 205,
                    }}
                  >
                    <div
                      style={{
                        marginTop: 32,
                        marginBottom: 40,
                        textAlign: "center",
                        fontSize: 21,
                        fontWeight: 600,
                        letterSpacing: 0.15,
                      }}
                    >
                      There is no CI added.
                    </div>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          showModal(AddCIForm, {
                            hideModal,
                            onRefersh: loadCIData,
                            defaultData: null,
                          });
                        }}
                      >
                        ADD CI
                      </Button>
                  </div>
                ) : (
                  <div style={{ paddingTop: 16, overflow: "hidden" }}>
                    <Box>
                      <DataGridPro
                        loading={loading}
                        autoPageSize={false}
                        autoHeight={true}
                        rowSpacingType={"border"}
                        rows={getProjectsRows()}
                        sx={{
                          "& .MuiDataGrid-cell": {
                            padding: 0,
                          },
                        }}
                        disableDensitySelector
                        disableColumnSelector
                        columns={getColumns()}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                        pagination
                        initialState={{
                          pagination: { paginationModel: { pageSize: 5 } },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        keepColumnPositionIfDraggedOutside={true}
                      />
                    </Box>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}

          </div>
        </div>
      </div>
      {selectedCI && <ViewCIDetailDrawer onRefresh={loadCIData} onClose={() => { setSelectedCI(null); }} open ciId={selectedCI.id} />}

    </div>
  );
}
