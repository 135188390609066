import React from 'react';
import { Box, Skeleton, Grid, Card, CardContent, Typography } from '@mui/material';

const ListSkeleton: React.FC = () => {
  return (
    <Box >
      {Array.from({ length: 4 }).map((_, index) => (
        <Card key={index} sx={{ mb: 3, boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            border: "1px solid #e0e0e0",height:'153px' }}>
          <CardContent>
            {/* Top Row */}
            <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
              <Grid item xs={2}>
                <Skeleton variant="text"  height={40} width="60%" />
              </Grid>
              <Grid item xs={2}>
                <Skeleton variant="text"  height={40} width="60%" />
              </Grid>
              <Grid item xs={4}>
                <Skeleton variant="text"  height={40} width="70%" />
              </Grid>
            </Grid>

            {/* Progress Bar */}
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              {Array.from({ length: 4 }).map((_, i) => (
                <Skeleton key={i} variant="rectangular" width={70} height={40} sx={{ borderRadius: 1 }} />
              ))}
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default ListSkeleton;
