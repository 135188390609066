import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';

import "../pages.scss";
import './doc-viewer.scss';
import {Box, Button} from "@mui/material";
import {CSVLink} from "react-csv";
import {utils, write, writeFile} from 'xlsx'
import {DataGridPro, GridColDef, GridRowsProp} from "@mui/x-data-grid-pro";
import {uploadFileUsingS3} from "../../components/file-management";
import {useAuthService} from "../../contexts/auth-context";
import {useDispatch} from "react-redux";
import {showSnackbar} from "../../redux/snackbar";

export function BatchReportViewer() {
  return ReportViewer({header: "Batch Report", headerStorageItem: "batchReportHeaderRow", rowsStorageItem: "batchReportRows"})
}

export function MultiDocumentBatchReportViewer() {
  return ReportViewer({header: "Multi-Document Batch Report", headerStorageItem: "MultiDocumentBatchReportHeaderRow", rowsStorageItem: "MultiDocumentBatchReportRows"})
}

export function PatientReportViewer() {
  return ReportViewer({header: "Patient Report", headerStorageItem: "patientReportHeaderRow", rowsStorageItem: "patientReportRows"})
}

export function MarksReportViewer() {
  return ReportViewer({header: "Marks Report", headerStorageItem: "marksReportHeaderRow", rowsStorageItem: "marksReportRows"})
}

export function TransformsReportViewer() {
  return ReportViewer({header: "Transforms Report", headerStorageItem: "transformsReportHeaderRow", rowsStorageItem: "transformsReportRows"})
}

export function SearchReportViewer() {
  return ReportViewer({header: "Search Results Report", headerStorageItem: "searchReportHeaderRow", rowsStorageItem: "searchReportRows"})
}

function ReportViewer (props : {header: string, headerStorageItem: string, rowsStorageItem: string}) {
  const params = useParams();
  const dispatch = useDispatch();
  const { projectId } = params;
  const location = useLocation();
  const [currentHeaders, setCurrentHeaders] = useState<string[]>([])
  const [tableRows, setTableRows] = useState<string[][]>([])
  const [docName, setDocName] = useState<string>("");
  const auth = useAuthService();
  const projectName =  props.header==='Multi-Document Batch Report' ? localStorage.getItem('MultiDocumentBatchReportProjectName')! : undefined

  useEffect(() => {
    const headerRow: string[] = JSON.parse(localStorage.getItem(props.headerStorageItem)!);
    const reportRows: string[][] = JSON.parse(localStorage.getItem(props.rowsStorageItem)!);
    setCurrentHeaders(headerRow);
    setTableRows(reportRows)
    try {
      let dname = reportRows[1][0];
      if (dname.endsWith('.pdf')) {
        dname = dname.substring(0, dname.length - 4);
      }
      setDocName(dname);
    } catch (e) {
      console.log(e);
    }
  }, [location.state]);

  function getRows() {
    let rows: GridRowsProp = [];
    if (tableRows.length === 0) {
      return rows;
    }
    let i = 0;
    for(let row of tableRows) {
      rows = rows.concat(constructRowObject(i, row))
      ++i
    }

    return rows;
  }

  function constructRowObject(i: number, row: any) {
    let object = {id: i};
    let j=0;
    currentHeaders.map(header => {
      object = {
        ...object,
        [header]: [row[j]]
      }
      ++j
    })
    return object
  }

  if (currentHeaders.length===0) {
    return <p>No headers</p>
  }

  const columns: GridColDef[] = currentHeaders.map(header => {
    return {field: header, headerName: header, minWidth: 230, width: 230}
  })

  const description = () => {
    switch (props.header) {
      case "Batch Report":
        return "The Batch report details every annotation contained in the document."
      case "Patient Report":
        return "A report of all the patients in a document."
      case "Marks Report":
        return "The marks report details every redaction annotation contained in the document."
      case "Search Results Report":
        return "The Search Results report details the current search results in the document."
      case "Multi-Document Batch Report":
        return "The Combined Batch Reports of multiple documents."
    }
  }

  const parseCsvArray = (headers: string[], rows: string[][]) => {
    let csvText: string = `"${headers.join('","')}"\n`;
    rows.forEach((row) => {
      csvText += `"${row.join('","')}"\n`
    })
    //Delete trailing new line
    if (csvText.endsWith("\n")) {
      csvText = csvText.substring(0, csvText.length - 1)
    }
    return csvText
  }

  const saveToFileManagement = () => {
    let wb = getOutputWorkbook();
    const fileData = write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([fileData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const file = new File([blob], getFileName(), {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    let category = undefined
    if (props.header === 'Multi-Document Batch Report') {
      category='Multi-Document Batch Report'
    } else if (props.header === 'Batch Report') {
      category='Batch Report'
    }
    uploadFileUsingS3(file, projectId ? +projectId : -1, auth.loginInfo?.tenant?.user?.id, true, false, category).then((r) => {
      console.log('uploaded report')
      window.open('/app/user/workflow/projects/' + projectId + '/file-management');
    }).catch((e) => {
      dispatch(showSnackbar({ message: "Error saving report", type: "error" }));
      console.log(e);
      console.log('error uploading report')
    })
  }

  const getOutputWorkbook = () => {
    let wb = utils.book_new()
    let outputRows = [...tableRows]
    outputRows.unshift(currentHeaders)
    let outputSheet = utils.aoa_to_sheet(outputRows)

    utils.book_append_sheet(wb, outputSheet, "Sheet0")
    return wb;
  }

  const exportXLSX = () => {
    let wb = getOutputWorkbook();
    writeFile(wb, getFileName())
  }

  const getMDBRFileName = () => {
    const date = new Date().toLocaleString().replaceAll("/", "-").replaceAll(",", "").replaceAll(" ", "_");
    const extension = '.xlsx';
    return projectName?.replaceAll(' ', '_')+'-'+date+'-MDBR'+extension
  }

  const getFileName = () => {
    if (props.header==='Multi-Document Batch Report') return getMDBRFileName()
    const reportType = props.header.toLowerCase().replaceAll(" ", "-");
    const date = new Date().toLocaleString().replaceAll("/", "-").replaceAll(",", "").replaceAll(" ", "_");
    const extension = '.xlsx';
    return docName + '-' + reportType + '-' + date + extension;
  }

  return <div>
    <header style={{ fontSize: 30 }}>{props.header}</header>
    <p style={{ fontSize: 17, marginBottom: 20 }}>{description()}</p>
    <Button variant="contained" color="secondary" style={{marginRight: 10, marginBottom: 0}} onClick={exportXLSX} >EXPORT RAW DATA</Button>
    <Button variant="contained" color="secondary" style={{marginRight: 10, marginBottom: 0}} onClick={saveToFileManagement} >
      SAVE TO FILE MANAGEMENT
    </Button>
    <p style={{ fontSize: 17 }}>Raw data will be exported/saved without respect to any filtering or sorting selected on the table below.</p>
    <Box>
      <DataGridPro autoPageSize={false}
                   autoHeight={true}
                   rows={getRows()}
                   columns={columns}
                   keepColumnPositionIfDraggedOutside={true}
      />
    </Box>
  </div>

};


