import React, {useContext, useEffect, useRef, useState} from "react";
import "./project/project.scss"
import Button from "@mui/material/Button/Button";
import {
    DataGridPro,
    GridColDef,
    GridRenderCellParams,
    GridRow,
    GridRowId,
    GridRowsProp,
} from "@mui/x-data-grid-pro";
import {Box, MenuItem, Paper, Stack, Tab, Tabs, Tooltip, Typography} from "@mui/material";
import {Done, Error, HourglassEmpty} from '@mui/icons-material'; 
import {
    getEncodedFileName,
    getFileVersionUrl,
    listFileVersions,
    putApiFileById,
    updateFileTagByVersionId
} from "../services/files";
import {Select} from '@mui/material';
import {filesSelector} from "../redux/file-management";
import {useDispatch} from "react-redux";
import {updateFile} from "../redux/file-management";
import {useAppSelector} from "../hooks/redux-hook";
import {FileState} from "../models/FileState";
import {saveAs} from "file-saver";
import {showSnackbar} from "../redux/snackbar";
import DownloadIcon from "@mui/icons-material/Download";
import {Refresh, RestorePage} from "@mui/icons-material";
import {useCustomModal} from "../pages/modals/custom-message-modal";
import {RestoreFileVersionModal} from "../pages/modals/modal-content/restore-file-version-modal";
import { getApiNlpJobDetailListByFileId } from "../services/nlp";
import { useAuthService } from "../contexts/auth-context";
import DraggableElement from "./dndKit/DraggableElement";
import { ProjectFileManagementContext } from "../contexts/ProjectFolderManagementContextProvider";
import FileActions from "./FileActions";

export const fileCategories = [
    {label: "Unassigned", value: "Unassigned"},
    {label: "Comparison", value: "Comparison"},
    {label: "Unassigned", value: "Unassigned"},
    {label: "3rd Country Inspections (Reporting Summary)", value: "3rd Country Inspections (Reporting Summary)"},
    {label: "Agreement from another sponsor", value: "Agreement from another sponsor"},
    {label: "AMPD - Full", value: "AMPD - Full"},
    {label: "ASR-DSUR", value: "ASR-DSUR"},
    {
        label: "Attachment of justification of low interventional clinical trial",
        value: "Attachment of justification of low interventional clinical trial"
    },
    {label: "Authorisation of manufacturing and import", value: "Authorisation of manufacturing and import"},
    {label: "Clinical Overview", value: "Clinical Overview"},
    {
        label: "Compliance with national requirements on Data Protection",
        value: "Compliance with national requirements on Data Protection"
    },
    {label: "Compliance with Regulation (EU) 2016/679", value: "Compliance with Regulation (EU) 2016/679"},
    {label: "Compliance with use of Biological samples", value: "Compliance with use of Biological samples"},
    {label: "Content labelling of the IMPs", value: "Content labelling of the IMPs"},
    {label: "Cover letter", value: "Cover letter"},
    {label: "CSR Synopsis", value: "CSR Synopsis "},
    {label: "Data Safety Monitoring Committee Charter", value: "Data Safety Monitoring Committee Charter"},
    {
        label: "End of Trial, Non-safety Temporary Halt & Early Termination",
        value: "End of Trial, Non-safety Temporary Halt & Early Termination"
    },
    {label: "Financial arrangements", value: "Financial arrangements"},
    {label: "Full CSR ", value: "Full CSR"},
    {label: "IMPD-Q", value: "IMPD-Q"},
    {label: "Informed Consent", value: "Informed Consent"},
    {label: "IMPD-Q Placebo", value: "IMPD-Q Placebo"},
    {label: "IMPD-Safety and Efficacy", value: "IMPD-Safety and Efficacy"},
    {label: "Investigator Brochure", value: "Investigator Brochure"},
    {label: "Investigator CV", value: "Investigator CV"},
    {label: "Member State Communication", value: "Member State Communication"},
    {label: "Member State Conclusions", value: "Member State Conclusions"},
    {label: "Member State Inspections", value: "Member State Inspections"},
    {label: "PIP opinion", value: "PIP opinion"},
    {label: "Plain Language Summary", value: "Plain Language Summary "},
    {label: "Proof of insurance", value: "Proof of insurance"},
    {label: "Proof of payment", value: "Proof of payment"},
    {label: "Protocol", value: "Protocol"},
    {label: "QP GMP certification", value: "QP GMP certification"},
    {label: "Recruitment arrangements", value: "Recruitment arrangements"},
    {label: "Responses from sponsor", value: "Responses from sponsor"},
    {label: "Safety Temporary Halt or Early Termination ", value: "Safety Temporary Halt or Early Termination "},
    {label: "Scientific advice - Quality", value: "Scientific advice - Quality"},
    {label: "Serious breaches", value: "Serious breaches"},
    {label: "Simplified IMPD-Q", value: "Simplified IMPD-Q"},
    {label: "Simplified IMPD-Safety and Efficacy", value: "Simplified IMPD-Safety and Efficacy"},
    {label: "Study design", value: "Study design"},
    {label: "Subject information and informed consent form", value: "Subject information and informed consent form"},
    {label: "Substantial Modifications ", value: "Substantial Modifications "},
    {label: "Suitability of the facilities", value: "Suitability of the facilities"},
    {label: "Suitability of the investigator", value: "Suitability of the investigator"},
    {label: "Summary of Interim Analysis", value: "Summary of Interim Analysis "},
    {label: "Summary of Product Characteristics (SmPC)", value: "Summary of Product Characteristics (SmPC)"},
    {label: "Summary of scientific advice", value: "Summary of scientific advice"},
    {label: "SUSARs", value: "SUSARs"},
    {label: "Synopsis of the Protocol", value: "Synopsis of the Protocol"},
    {label: "Unexpected events (Art 53)", value: "Unexpected events (Art 53)"},
    {label: "Union Controls", value: "Union Controls"},
    {label: "Urgent Safety measures", value: "Urgent Safety measures"},
    {label: "Other", value: "Other"},
    {label: "RLS Protect Risk", value: "RLS Protect Risk"},
    {label: "RLS Protect Risk", value: "RLS Protect Risk"},
    {label: "Batch Report", value: "Batch Report"},
    {label: "Multi-Document Batch Report", value: "Multi-Document Batch Report"}
];

function DetailPanelContent({fileVersionList, row: rowProp,p,data}: 
  {fileVersionList:any[], row: any,p: any,data: any }) {
    const dispatch = useDispatch();
    const { showModal } = useCustomModal();

    // . : + = @ _ / -
    const tagPattern = /^[a-zA-Z0-9.:=@_\/\-\s]+$/
    
    async function downloadFileVersion(projectId: string | undefined, fileName: string, versionId: string) {
        try {
            const url = await getFileVersionUrl(projectId, decodeURIComponent(fileName), versionId);
            const response = await fetch(url.url);
            const blob = await response.blob();
            saveAs(blob, decodeURIComponent(fileName));
            dispatch(showSnackbar({message: "Download Successful!", type: "info"}));
            console.log('All files downloaded successfully.');
        } catch (error) {
            console.error('An error occurred while downloading files:', error);
            dispatch(showSnackbar({message: "Error Downloading Files!", type: "error"}));
        }
    }

    async function updateVersionTag(projectId: string, fileName: string, versionId: string, newTag: string) {
      await updateFileTagByVersionId(projectId, fileName, versionId, newTag);
      dispatch(showSnackbar({message: "Updated file version tag!", type: "info"}));
  }

    const versionColumns: GridColDef[] = [
        {
            field: 'col1', headerName: 'Version Number', flex: 2
        },
        {
            field: 'col2', headerName: 'File Size', flex: 2
        },
        {
            field: 'col6', headerName: 'Last Modified', flex: 8
        },
        {
            field: 'col7', headerName: 'Tags', flex: 15, editable: true
        },
        {
            field: 'col8', headerName: 'Download', flex: 2, minWidth: 100, maxWidth: 100, renderCell: params => {
                if (params.row.col1!=="CURRENT") {
                    return <DownloadIcon onClick={() => {
                        downloadFileVersion(p.projectId, params.row.col10, params.row.col11)
                    }} style={{alignSelf: "center", textAlign: 'center'}} fontSize="small"/>
                }
            }
        },
        {
            field: 'col9', headerName: 'Restore', flex: 2, minWidth: 100, maxWidth: 100, renderCell: params => {
                if (params.row.col1!=="CURRENT") {
                    return <RestorePage onClick={async () => {
                        showModal(RestoreFileVersionModal, {projectId: p.projectId, fileName: params.row.col10, versionId: params.row.col11, fileId: params.row.col12, lastModifiedDate: params.row.col6.toString(), tag: params.row.col7})
                    }} style={{alignSelf: "center", textAlign: 'center'}} fontSize="small"/>
                }
            }
        },
        {
            field: 'col10', headerName: '', maxWidth: 0,
        },
        {
            field: 'col11', headerName: '', maxWidth: 0,
        },
    ];

    function getVersionRows() {
    
      return fileVersionList.map((version, index) => ({
        id: index,
        col1: version.IsLatest ? "CURRENT" : fileVersionList.length - index,
        col2: getReadableFileSizeString(version.Size),
        col6: new Date(version.LastModified),
        col7: version.ETag,
        col10: decodeURI(rowProp.col1),
        col11: version.VersionId,
        col12: rowProp.col7
      }));
    }
    
    const fileVersions = getVersionRows();


    return (
        <Stack sx={{py: 2, height: 1, boxSizing: 'border-box'}} direction="column">
            <Paper sx={{flex: 1, mx: 'auto', width: '90%', p: 1}}>
                <Stack direction="column" spacing={1} sx={{height: 1}}>
                    <DataGridPro
                        columnVisibilityModel={{
                            // Hide columns filename, version id, and inherited row file id, used in the requests
                            col10: false,
                            col11: false,
                            col12: false
                        }}
                        {...data}
                        initialState={{
                            ...data.initialState,
                            pagination: {paginationModel: {pageSize: 5}},
                        }}
                        density="compact"
                        autoHeight
                        columns={versionColumns}
                        rows={fileVersions}
                        checkboxSelection
                        onRowSelectionModelChange={async (ids) => {
                            const selectedIDs = new Set(ids);
                            const selectedRows = fileVersions.filter((row) =>
                                selectedIDs.has(row.id),
                            );
                            localStorage.setItem("versionFileNames", JSON.stringify(selectedRows.map((row) => `v${row.col1} * ${new Date(row.col6).toLocaleDateString()} * ${row.col10}`)));
                            const promises: any = []
                            for (const row of selectedRows) {
                                promises.push(await getFileVersionUrl(p.projectId, decodeURIComponent(row.col10), row.col11))
                            }
                            const response = await Promise.all(promises);
                            localStorage.setItem("versionUrls", JSON.stringify(response.map((row) => row.url)));
                            p.setIsDisabledCompareButton(selectedRows.length < 2);
                        }}
                        processRowUpdate={async (updatedRow, originalRow) => {
                            if(updatedRow.col7 !== originalRow.col7) {
                                const error = updatedRow.col7.match(tagPattern) === null;
                                if(error) {
                                    dispatch(showSnackbar({message: "Only alphanumeric and . : + = @ _ / - allowed!", type: "error"}))
                                }
                                else {
                                    // @ts-ignore
                                    await updateVersionTag(p.projectId, updatedRow.col10, updatedRow.col11, updatedRow.col7);
                                    originalRow.col7 = updatedRow.col7;
                                }
                            }
                        }}
                        disableRowSelectionOnClick={true}
                        pagination
                        sx={{flex: 1}}
                    />
                </Stack>
            </Paper>
        </Stack>
    );
}

function NlpDetailPanelContent({
  fileVersionList,
  nlpJobDetailList,
  data,
}: {
  fileVersionList: any[];
  nlpJobDetailList: any[];
  data: any;
}) {

  const versionColumns: GridColDef[] = [
    {
      field: "requestId",
      headerName: "Request Id",
      flex: 2,
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      flex: 6,
    },
    {
        field: "personalData",
        headerName: "Personal Data",
        flex: 8,
      },
    {
      field: "confidentialData",
      headerName: "Confidential Information",
      flex: 8,
    },
    {
      field: "pages",
      headerName: "Pages",
      flex: 3,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 3,
    },
    {
      field: "nlpAnnotations",
      headerName: "Annotations",
      flex: 3,
    },
    {
      field: "fileVersionId",
      headerName: "File Version",
      flex: 3,
    }
  ];


  const totalFileVersion = fileVersionList.length;
  const totalRequest = nlpJobDetailList.length;
  const versionRowDataList = nlpJobDetailList.map((version, index) => {
    const fileIndex = fileVersionList.findIndex(
      (fileVersion) => fileVersion.VersionId === version.fileVersionId
    );

    const fileVersion =
      fileIndex === -1
        ? "N/A"
        : fileVersionList?.[fileIndex]?.IsLatest
        ? "CURRENT"
        : totalFileVersion - fileIndex;

    const personalDataDescription =
      version.personalData?.map((personalData: { description: string }) => {
        return personalData.description;
      }) || [];

    const confidentialInformationDescription =
      version.confidentialData?.map(
        (confidentialData: { description: string }) => {
          return confidentialData.description;
        }
      ) || [];

    return {
      id: version.id,
      requestId: totalRequest - index,
      createdAt: new Date(version.createdAt),
      personalData: personalDataDescription.join(", "),
      confidentialData: confidentialInformationDescription.join(", "),
      pages: `${version.startPageToProcess} - ${
        version.numberOfPagesToProcess !== 0
          ? version.startPageToProcess + version.numberOfPagesToProcess - 1
          : "All"
      }`,
      status: version.status,
      nlpAnnotations: version?.nlpTotalAnnotations
        ? `${version?.nlpSelectedAnnotations} / ${version?.nlpTotalAnnotations}`
        : "N/A",
      fileVersionId: fileVersion,
    };
  });

  return (
    <Stack
      sx={{ py: 2, height: 1, boxSizing: "border-box" }}
      direction="column"
    >
      <Paper sx={{ flex: 1, mx: "auto", width: "90%", p: 1 }}>
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <DataGridPro
            {...data}
            initialState={{
              ...data.initialState,
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            rowHeight={null}
            density="compact"
            autoHeight
            columns={versionColumns}
            rows={versionRowDataList}
            disableRowSelectionOnClick={true}
            pagination
            sx={{ flex: 1 }}
            getRowHeight={(params) => 'auto'}
          />
        </Stack>
      </Paper>
    </Stack>
  );
}

type TabName = "fileVersion" | "nlpHistory";

const FilesSubTabRender = ({
  row: rowProp,
  p,
  data,
}: {
  row: any;
  p: any;
  data: any;
}) => {
  const auth = useAuthService();
  const accessToNlp = auth.hasNlpAccess() 
  
  const [selectedTab, setSelectedTab] = useState<TabName>("fileVersion");

  const [nlpJobDetailList, setNlpJobDetailList] = useState<any[]>([]);
  const [fileVersionList, setFileVersionList] = useState<any[]>([]);

  useEffect(() => {
    getFileVersionList();
    if (accessToNlp) {
      getNlpJobDetailList();
    }
  }, []);

  const getNlpJobDetailList = async () => {
    try {
      const list: any[] = await getApiNlpJobDetailListByFileId(
        rowProp?.file?.fileId
      );

      if (list?.length > 0) {
        setNlpJobDetailList(list);
      }
    } catch (error) {
      console.error("Error fetching NLP job details", error);
    }
  };

  const getFileVersionList = async () => {
    localStorage.removeItem("versionUrls");
    localStorage.removeItem("versionFileNames");
    const fileVersionName = decodeURIComponent(rowProp.col1);
    const fileVersionData = await listFileVersions(
      p.projectId,
      fileVersionName
    );
    setFileVersionList(fileVersionData);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: TabName) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        TabIndicatorProps={{
          style: { backgroundColor: "rgb(33, 125, 162)" },
        }}
        sx={{ marginLeft: 6 }}
      >
        <Tab
          label="File Versions"
          value="fileVersion"
          sx={{
            fontSize: "15px",
            fontWeight: 600,
            textTransform: "none",
            color: "rgb(65, 64, 66)",
            "&.Mui-selected": {
              color: "rgb(33, 125, 162)",
            },
          }}
        />
        {accessToNlp && (
          <Tab
            label="NLP History"
            value="nlpHistory"
            sx={{
              fontSize: "15px",
              fontWeight: 600,
              textTransform: "none",
              color: "rgb(65, 64, 66)",
              "&.Mui-selected": {
                color: "rgb(33, 125, 162)",
              },
            }}
          />
        )}
      </Tabs>

      {selectedTab === "fileVersion" ? (
        <DetailPanelContent
          fileVersionList={fileVersionList}
          row={rowProp}
          p={p}
          data={data}
        />
      ) : selectedTab === "nlpHistory" ? (
        <NlpDetailPanelContent
          fileVersionList={fileVersionList}
          nlpJobDetailList={nlpJobDetailList}
          data={data}
        />
      ) : null}
    </Box>
  );
};

export function FilesComponent(p: {
  projectId: string | undefined;
  files: any[];
  selectedFiles: any[];
  handleUpdateSelectedFiles: (files: any[]) => void;
  onDoubleClickRow: Function;
  setIsDisabledCompareButton: Function;
  setIsDisableCombineReportsButton: Function;
  refreshTable: Function;
}) {
  const auth = useAuthService();
  const accessToNlp = auth.hasNlpAccess() 
  
  const projectFiles: FileState[] = useAppSelector(filesSelector);
  const dispatch = useDispatch();

    const [anchorElId, setAnchorElId] = React.useState("")
    const anchorElObjectRef = useRef<{ [key: string]: HTMLElement | null }>({})
  

    const {
      selectedFiles,
      handleMultiSelectNode
    } = useContext(ProjectFileManagementContext);

  function getRows() {
    let rows: GridRowsProp = [];
    if (p.files.length === 0) {
      return rows;
    }
    let i = 0;
    for (let file of p.files) {
      const projectFile = projectFiles.find(
        (projectFile: FileState) => projectFile.name === file.name
      );
      if (projectFile) {
        const userName = projectFile.createdByName || "";
        rows = rows.concat({
          id: i,
          col1: file.name,
          col2: getReadableFileSizeString(file.size),
          col3: "-",
          col4: userName,
          col5: new Date(file.createdAt),
          col6: new Date(file.lastModifiedAt),
          col7: projectFile.id,
          file: file,
        });
      }
      i++;
    }

    return rows;
  }
  const data = getRows();

  function handleChange(event: any, fileNameOfCategoryChange: string) {
    if (fileNameOfCategoryChange && p.projectId) {
      const file = projectFiles.find(
        (file: any) => file.name === fileNameOfCategoryChange
      );
      if (file) {
        const id = file.id;
        putApiFileById(JSON.stringify(id), {
          id: id,
          name: file.name,
          size: file.size,
          category: event.target.value as string,
          projectId: parseInt(p.projectId),
          isDocOpen: file.isDocOpen ? file.isDocOpen : false,
          createdByName: file.createdByName,
        });

        dispatch(
          updateFile({
            ...file,
            id: id,
            name: fileNameOfCategoryChange,
            category: event.target.value as string,
            projectId: parseInt(p.projectId),
          })
        );
        p.refreshTable()
      }
    }
  }

  function handleDisableCombineReports(selectedRows: any[]) {
    let csvFilesSelected = 0
    for (const row of selectedRows) {
        const filename = row.col1
        if (filename.endsWith('.xlsx') || filename.endsWith('.csv')) {
            csvFilesSelected++
        } else {
            p.setIsDisableCombineReportsButton(true)
            return
        }
    }
    if (csvFilesSelected>1) {
        p.setIsDisableCombineReportsButton(false)
    } else {
        p.setIsDisableCombineReportsButton(true)
    }
  }

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] =
    React.useState<GridRowId[]>([]);

  const handleDetailPanelExpandedRowIdsChange = React.useCallback(
    async (newIds: GridRowId[]) => {
      //setFileVersions([]);
      if (newIds.length > 1) {
        setDetailPanelExpandedRowIds([newIds[newIds.length - 1]]);
      } else {
        setDetailPanelExpandedRowIds(newIds);
      }
    },
    []
  );

  const columns: GridColDef[] = [
    ...(accessToNlp
      ? [
          {
            field: "nlpStatus",
            headerName: "NLP Status",
            flex: 3,
            renderCell: (params: GridRenderCellParams) => {
              const nlpJobStatus = params.row.file.nlpJobDetail?.status;

              const toolTipTitle =
                nlpJobStatus === "PENDING"
                  ? "NLP job is still in progress"
                  : nlpJobStatus === "FAILED"
                  ? "NLP job failed"
                  : nlpJobStatus === "COMPLETED"
                  ? "NLP job completed"
                  : "";

              return (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  style={{ width: "100%" }}
                >
                  {params.row.file.nlpJobDetail?.isNlpJobResponseRelevant && (
                    <Tooltip title={toolTipTitle}>
                      {nlpJobStatus === "PENDING" ? (
                        <HourglassEmpty style={{ color: "orange" }} />
                      ) : nlpJobStatus === "FAILED" ? (
                        <Error style={{ color: "red" }} />
                      ) : nlpJobStatus === "COMPLETED" ? (
                        <Done style={{ color: "green" }} />
                      ) : (
                        <></>
                      )}
                    </Tooltip>
                  )}
                </Box>
              );
            },
          },
        ]
      : []),
    {
      field: "col1",
      headerName: "File Name",
      flex: 10,
    },
    {
      field: "col2",
      headerName: "File Size",
      flex: 2,
    },
    {
      field: "col4",
      headerName: "Owner",
      flex: 4,
    },
    {
      field: "col5",
      headerName: "Created At",
      flex: 5,
    },
    {
      field: "col6",
      headerName: "Last Modified",
      flex: 5,
    },
    {
      field: "col9",
      headerName: "File Category",
      flex: 5,
      renderCell: (params) => {
        const currentCategory = projectFiles.find(
          (file: FileState) => file.name === params.row.col1
        )?.category;
        return (
          <Select
            onChange={(event) => handleChange(event, params.row.col1)}
            value={currentCategory || "Unassigned"}
            style={{ minWidth: 160, width: "100%" }}
          >
            {fileCategories.map((category, index) => {
              return (
                <MenuItem key={index} value={category.value}>
                  {" "}
                  {category.value}{" "}
                </MenuItem>
              );
            })}
          </Select>
        );
      },
    },
    {
      field: "col8",
      headerName: "File Logs",
      flex: 3,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              window.open(
                "/app/user/docs/" +
                  getEncodedFileName(filterPDFExtension(params.row.col1)) +
                  "/" +
                  p.projectId +
                  "/logsFile"
              );
            }}
            color="secondary"
            variant="outlined"
            sx={{fontSize: "12px !important"}}
          >
            Go to Logs
          </Button>
        );
      },
    },
  ];

  return (
    <Box
      style={{
        minHeight: 350,
        height: 500,
        maxHeight: 600,
        width: "100%",
      }}
    >
      <DataGridPro
        columnVisibilityModel={{
          // Hide columns status and traderName, the other columns will remain visible
          col7: false,
        }}
        sx={{
          // disable cell selection style
          ".MuiDataGrid-cell:focus": {
            outline: "none",
          },
          // pointer cursor on ALL rows
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        rowHeight={30}
        style={{ height: "calc(100vh - 550px)" }}
        rows={data}
        columns={columns}
        pagination
        pageSizeOptions={[25, 50, 100]}
        checkboxSelection
        rowSelectionModel={selectedFiles.map((file) => file.id)}
        onRowDoubleClick={({ id, row }) => {
          if (
            row.file.nlpJobDetail?.isNlpJobResponseRelevant &&
            row.file.nlpJobDetail?.status === "PENDING"
          ) {
            const response = window.confirm(
              "NLP job is still in progress. Do you want to proceed?"
            );

            if (response) {
              p.handleUpdateSelectedFiles([row]);
              p.onDoubleClickRow(row);
            }
          } else {
            p.handleUpdateSelectedFiles([row]);
              p.onDoubleClickRow(row);
          }
        }}
        onRowSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = data.filter((row) => {
            return selectedIDs.has(row.id);
          });
          const nodeList = selectedRows.map((row) => {
            const file = {
              ...row.file,
              dndType: "dndTypeProjectFolderManagement",
            };
            return file;
          });
          handleMultiSelectNode(nodeList);
          p.handleUpdateSelectedFiles(selectedRows);
          p.setIsDisabledCompareButton(selectedRows.length < 2);
          handleDisableCombineReports(selectedRows)
        }}
        getDetailPanelHeight={() => "auto"} // Height based on the content.
        getDetailPanelContent={({ row }) => (
          <FilesSubTabRender row={row} p={p} data={data} />
        )}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        onDetailPanelExpandedRowIdsChange={
          handleDetailPanelExpandedRowIdsChange
        }
        disableRowSelectionOnClick={true}
        slots={{
          row: (props) => {
            const id = props.row?.file?.id;
            const file = props.row?.file;
            return (
              <DraggableElement
                id={`table!^|${id}`}
                elementData={file}
                hasDragOverlay={true}
              >
                <GridRow
                  id={`table-row!^|${id}`}
                  {...props}
                  data-test-id={id}
                  sx={{ backgroundColor: true ? "#c2e7ff" : "inherit" }}
                  onContextMenu={(event: any) => {
                    event.preventDefault();
                    setAnchorElId(`table-row!^|${id}`);
                  }}
                  onMouseDown={(event: any) => {
                    if (event.ctrlKey) {
                      setAnchorElId(`table-row!^|${id}`);
                    }}}
                  ref={(element) => {
                    if (element) {
                      anchorElObjectRef.current[id] = element;
                    }
                  }}
                />
                <FileActions
                  open={anchorElId === `table-row!^|${id}`}
                  anchorEl={anchorElObjectRef.current[id]}
                  setAnchorElId={setAnchorElId}
                />
              </DraggableElement>
            );
          },
        }}
        {...data}
      />
      
    </Box>
  );
}

function getReadableFileSizeString(fileSizeInBytes: number) {
    var i = -1;
    var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        fileSizeInBytes /= 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

const pdfExtenstion = '.pdf'
export function filterPDFExtension(name: string): string {
    return name.endsWith(pdfExtenstion) ? name.substring(0, name.length-4) : name
}
