import { Box, Typography } from "@mui/material";
import React from "react";
import { ciWorkFlowStatus, ciWorkFlowStatusObj } from "../../../../models/ciWorkFlowStatus";

type WorkFlowLabelProps = {
  currentStatus: number;
  pathStyle?: any;
  wrapperStyle?: any;
  labelStyle?: any;
  chevronHeight?: number;
  labelFontSize?: number;
};

export const WorkFlowLabel: React.FC<WorkFlowLabelProps> = ({
  currentStatus,
  pathStyle = {},
  wrapperStyle = {},
  labelStyle = {},
  chevronHeight = 33,
  labelFontSize = 8,
}) => {
  const getVisibleStatuses = (currentStatus: number): ({ step: number, color: string }[]) => {
    if (currentStatus === ciWorkFlowStatusObj.DRAFT) {
      return [{ step: ciWorkFlowStatusObj.DRAFT, color: '#d5e0f8' }, { step: ciWorkFlowStatusObj.SUBMITTED_FOR_REVIEW, color: '#eeedef' }, { step: ciWorkFlowStatusObj.IN_APPROVAL, color: '#eeedef' }, { step: ciWorkFlowStatusObj.APPROVED, color: '#eeedef' }];
    }
    if (currentStatus === ciWorkFlowStatusObj.SUBMITTED_FOR_REVIEW) {
      return [{ step: ciWorkFlowStatusObj.SUBMITTED_FOR_REVIEW, color: '#d5e0f8' }, { step: ciWorkFlowStatusObj.IN_APPROVAL, color: '#eeedef' }, { step: ciWorkFlowStatusObj.APPROVED, color: '#eeedef' }];
    }
    if (currentStatus === ciWorkFlowStatusObj.REJECTED_FROM_REVIEW) {
      return [{ step: ciWorkFlowStatusObj.SUBMITTED_FOR_REVIEW, color: '#b7d0ae' }, { step: ciWorkFlowStatusObj.REJECTED_FROM_REVIEW, color: '#E57373' }];
    }
    if (currentStatus === ciWorkFlowStatusObj.IN_APPROVAL) {
      return [{ step: ciWorkFlowStatusObj.SUBMITTED_FOR_REVIEW, color: '#b7d0ae' }, { step: ciWorkFlowStatusObj.IN_APPROVAL, color: '#d5e0f8' }, { step: ciWorkFlowStatusObj.APPROVED, color: '#eeedef' }];
    }
    if (currentStatus === ciWorkFlowStatusObj.REJECTED_FROM_APPROVAL) {
      return [{ step: ciWorkFlowStatusObj.SUBMITTED_FOR_REVIEW, color: '#b7d0ae' }, { step: ciWorkFlowStatusObj.IN_APPROVAL, color: '#b7d0ae' }, { step: ciWorkFlowStatusObj.REJECTED_FROM_APPROVAL, color: '#E57373' }];
    }
    if (currentStatus === ciWorkFlowStatusObj.APPROVED) {
      return [{ step: ciWorkFlowStatusObj.SUBMITTED_FOR_REVIEW, color: '#b7d0ae' }, { step: ciWorkFlowStatusObj.IN_APPROVAL, color: '#b7d0ae' }, { step: ciWorkFlowStatusObj.APPROVED, color: '#b7d0ae' }];
    }
    if (currentStatus === ciWorkFlowStatusObj.ON_HOLD) {
      return [{ step: ciWorkFlowStatusObj.ON_HOLD, color: '#eeedef' }];
    }
    return [];
  };

  const visibleStatuses = getVisibleStatuses(currentStatus);
  const totalSteps = visibleStatuses.length;
  const chevronWidth = 100 / Math.max(3, totalSteps);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
      style={{ ...wrapperStyle }}
    >
      {visibleStatuses.map((visibleStatus, index) => {
        const status = ciWorkFlowStatus[visibleStatus.step];

        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              width: `${chevronWidth}%`,
              ...pathStyle,
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox={`0 0 ${chevronHeight * 2.5} ${chevronHeight}`} // Maintain aspect ratio
              width="100%"
              height={chevronHeight}
              preserveAspectRatio="none"
            >
              <path
                fill={visibleStatus.color}
                d={`
      M 0 0
      L ${chevronHeight * 2} 0
      L ${chevronHeight * 2.5} ${chevronHeight / 2}
      L ${chevronHeight * 2} ${chevronHeight}
      L 0 ${chevronHeight}
      L ${chevronHeight / 2.5} ${chevronHeight / 2}
      Z
    `}
              />
            </svg>

            <Typography
              variant="body2"
              sx={{
                fontWeight: 700,
                color: "#000",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                fontSize: `${labelFontSize}px`,
                lineHeight: 1.2
              }}
              style={{ ...labelStyle }}
            >
              {status.label}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
