import React, {FC, forwardRef} from 'react';
import {SelectChangeEvent} from "@mui/material";
export enum ReflowDropdownState {
    TEXT_REFLOW_DEFAULT = "Text Reflow (default)",
    REPLACE_TO_FIT = "Replace to Fit"
}
export interface ReflowDropdownRef {
    setData:()=>string;
}
function WithDropdown<T>(ChildComponent:React.ComponentType<T>){
    const ReturnedComponent = forwardRef<ReflowDropdownRef, Omit<T, "value" | "handleChange">>((props, ref) => {
        const [value, setValue] = React.useState<ReflowDropdownState>(ReflowDropdownState.TEXT_REFLOW_DEFAULT);
        const handleDropdown = (event: SelectChangeEvent) => {
            setValue(event.target.value as ReflowDropdownState)
        }
        return(
            <ChildComponent ref={ref} value={value} handleChange={handleDropdown} {...props as T}/>
        )
    })
    return ReturnedComponent;
}

export default WithDropdown;