import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthService } from '../contexts/auth-context';
import { useCustomModal } from '../pages/modals/custom-message-modal';
import './appLayout.scss';
import '../pages/pages.scss';
import { Menu } from './menu';
import { NavBar } from './navBar';
import { Footer } from "./footer";
import { ActionBar } from "./project/action-bar";
import { NotificationsService } from "./notifications-service";
import { UserSettings } from "../models/UserSettings";
import { useAppSelector } from "../hooks/redux-hook";
import { userSettingsSelector } from "../redux/userSettings";
import Button from '@mui/material/Button';
import { Collapse } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export function AppLayout() {

  const auth = useAuthService();
  const userSettings: UserSettings = useAppSelector((state) => userSettingsSelector(state));
  const { isOpen } = useCustomModal();
  const [open, setOpen] = React.useState(true);
  const nav = useNavigate();
  const [showBanner, setShowBanner] = useState(sessionStorage.getItem("passwordChangeRequired"));
  const remainingDays = 90 - auth.loginInfo?.user?.passwordResetAge!; // maximum period without pass change is 90 days
  if (auth.loadingLoginInfo) return <div>Loading</div>
  if (!auth.loginInfo) auth.logout();

  return <div className='layout' style={isOpen ? { filter: "blur(2px)" } : {}} >
    <NavBar />
    <div className='content body-container'>
      <Collapse in={open} collapsedSize='15px' orientation={"horizontal"}>
      <Menu />
      </Collapse>
      <ArrowForwardIosIcon className="collapse-button" style={{transform: open ? "rotate(180deg)" : "initial"}} onClick={() => setOpen(!open)}/>
      <div className='outlet'>
        {showBanner ? <div className = "app-layout-change-pass-banner">
          <span className = "app-layout-pass-banner-text-wrapper">
            <span className = "app-layout-pass-banner-text-alert">
              Your RLS password will expire {remainingDays >= 2 ? `in ${remainingDays} days` : `tomorrow`}.
            </span> <br />
            For security reasons, we require you to change your password every 90 days. <br />
            Please change your password in your account settings or by clicking the “Change password” button.
          </span>
          <div className = "app-layout-pass-banner-buttons-wrapper">
            <Button color="secondary" style={{ marginRight: 15 }} onClick={() => {
              setShowBanner(null);
              sessionStorage.removeItem("passwordChangeRequired");
              nav("/app/user/my-account");
            }}>CHANGE PASSWORD</Button>
            <Button color="secondary" onClick={() => {
              setShowBanner(null);
              sessionStorage.removeItem("passwordChangeRequired");
            }}>DISMISS</Button>
          </div>
        </div> : auth.inactiveTime > 600 ? <div style={{
          display: "flex", alignItems: "center",
          minHeight: 80, marginLeft: 32, marginRight: 32, backgroundColor: "#FFEFD0"
        }}>
          <div style={{
            margin: "16px 24px", padding: "8px 16px", backgroundColor: "white",
            fontSize: 21, fontWeight: 600, letterSpacing: 1.89, textAlign: "center", verticalAlign: "middle"
          }}>
            {(15 - Math.ceil(auth.inactiveTime / 60)).toString().padStart(2, "0")}:
            {(auth.inactiveTime % 60 === 0 ? 0 : 60 - auth.inactiveTime % 60).toString().padStart(2, "0")}
          </div>
          <div style={{ margin: 16, fontWeight: 600, letterSpacing: 0.24 }}>
            <span style={{ fontSize: 17, color: "#C13826", letterSpacing: 1.53 }}>WARNING:</span> You have been inactive for the past 10 minutes.<br />
            When the timer expires, you will be logged out for security reasons due to inactivity.
          </div>
        </div> : <></>}
        <ActionBar />
        <Outlet />
      </div>
    </div>
    <Footer />
    {(auth.hasUserRoleAccess() || auth.hasCIMAccess()) && userSettings.notifications && userSettings.notifications.inApp && auth.loginInfo &&
      <NotificationsService key={0} />}
  </div>
}
