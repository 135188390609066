import { Account } from "../models/Account";
import { BaseAccount } from "../models/BaseAccount";
import { baseUrl, headers } from "./config";
import { fetchAndParse, addQueryParameter } from "./utils";
import { DocsAccessRequest } from "../models/DocsAccessRequest";
import { NLPAccessRequest } from "../models/NLPAccessRequest";
import { NLPPersonalDataAccessRequest } from "../models/NLPPersonalDataAccessRequest";
import { CIMAccessRequest } from "../models/CIMAccessRequest";
export function getApiRlsAccounts(signal?: AbortSignal): Promise<Account[]> {
    const url = new URL(`/api/rls/accounts`, baseUrl);
    return fetchAndParse<Account[]>(url.toString(), { method: "GET", headers, signal });
}
export function getApiRlsAccountsById(id: string, signal?: AbortSignal): Promise<Account> {
    const url = new URL(`/api/rls/accounts/${id}`, baseUrl);
    return fetchAndParse<Account>(url.toString(), { method: "GET", headers, signal });
}
export function putApiRlsAccountsById(id: string, body?: BaseAccount, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/rls/accounts/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function putApiRlsAccountsDocsAccess(id: string, body?: DocsAccessRequest, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/rls/accounts/${id}/accessToDocs`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function putApiRlsAccountsNlpAccess(id: string, body?: NLPAccessRequest, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/rls/accounts/${id}/nlp-access`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function putApiRlsAccountsNlpPersonalDataAccess(id: string, body?: NLPPersonalDataAccessRequest, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/rls/accounts/${id}/nlp-personal-data-access`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function deleteApiRlsAccountsById(id: string, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/rls/accounts/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "DELETE", headers, signal });
}
export function postApiRlsAccountsCreate(body?: BaseAccount, signal?: AbortSignal): Promise<Account> {
    const url = new URL(`/api/rls/accounts/create`, baseUrl);
    return fetchAndParse<Account>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}

export function putApiRlsAccountsCIMAccess(id: string, body?: CIMAccessRequest, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/rls/accounts/${id}/accessToCIM`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}