import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JustificationCategory } from '../models/JustificationCategory';
import { RootState } from './store';

export const defaultJustificationCategories: JustificationCategory[] = [];

const justificationCategoriesInitialState = {
  justificationCategories: defaultJustificationCategories,
  loaded: false,
  selected: null as number | null,
  loading: false,
};

const justificationCategoriesSlice = createSlice({
  name: 'justificationCategories',
  initialState: justificationCategoriesInitialState,
  reducers: {
    loadJustificatiobCategories: (state, action: PayloadAction<JustificationCategory[]>) => {
      state.justificationCategories = action.payload;
      state.loaded = true;
    },
    loadJustificatiobCategory: (state, action: PayloadAction<JustificationCategory>) => {
      const index = state.justificationCategories.findIndex((justificationCategory) => justificationCategory.id === action.payload.id);
      if (index !== -1) {
        state.justificationCategories[index] = action.payload;
      } else {
        state.justificationCategories.push(action.payload);
      }
    },
    selectJustificationCategory: (state, action: PayloadAction<number>) => {
      state.selected = action.payload;
    },
    removeJustificatiobCategory: (state, action: PayloadAction<number>) => {
      state.justificationCategories = state.justificationCategories.filter((justificationCategory) => justificationCategory.id !== action.payload);
    },
    setJustificationCategoriesLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
    setJustificationCategoriesLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const {
  loadJustificatiobCategories,
  loadJustificatiobCategory,
  selectJustificationCategory,
  removeJustificatiobCategory,
  setJustificationCategoriesLoaded,
  setJustificationCategoriesLoading,
} = justificationCategoriesSlice.actions;

export const justificationCategoriesSelector = (state: RootState) => state.justificationCategories.justificationCategories;
export const loadedJustificationCategoriesSelector = (state: RootState) => state.justificationCategories.loaded;
export const selectedJustificationCategorySelector = (state: RootState) =>
  state.justificationCategories.justificationCategories.find((justificationCategory) => justificationCategory.id === state.justificationCategories.selected);

export default justificationCategoriesSlice.reducer;
