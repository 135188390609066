import { baseUrl, headers } from "./config";
import { fetchAndParse } from "./utils";
import {Account} from "../models/Account";

export function getApiRlsIsUseWebviewerDownloader(signal?: AbortSignal): Promise<boolean> {
    const url = new URL(`/api/rls/settings/useWebviewerDownloader`, baseUrl);
    return fetchAndParse<boolean>(url.toString(), { method: "GET", headers, signal });
}

export function putApiRlsUseWebviewerDownloader(body?: { isUseWebviewerDownloader: boolean }, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/rls/settings/useWebviewerDownloader`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
