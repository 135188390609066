import baseline_close from "../../../provisional_icons/baseline-close.png";
import { useCustomModal } from "../custom-message-modal";
import RLS_Protect_Icon from "../../../provisional_icons/RLS_Protect_Icon.png";

export function RlsCIMUnavailableModal() {
    const { hideModal } = useCustomModal();

    return <div className="remove-modal-container">
        <div className="content-remove-modal">
            <img alt="close" src={baseline_close} className="remove-entity-close-button" style = {{marginRight: -16}} onClick={hideModal} />
            <button className="CIM-menu-button">
            <img alt="logo" src={RLS_Protect_Icon} height={32}/>
            <p className={"CIM-menu-button-text"}><b>RLS</b> CIM</p>
        </button>
            <span style={{ fontSize: 18, fontWeight: "600", letterSpacing: 0.15, marginTop: 15 }}>
            CIM (Confidential Information Management) is not currently enabled for your organization. To learn more about CIM features and availability, please contact your customer success representative.
            </span>
        </div>
    </div>
}
