import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CI } from '../models/CI';
import { RootState } from './store';

export const defaultCIs: CI[] = [];

const workflowCIInitialState = {
  CI: defaultCIs,
  loaded: false,
  selected: null as number | null,
  loading: false,
  filteredCI: defaultCIs,
  currentCI: null as CI | null,
};

const workflowCISlice = createSlice({
  name: 'WorkflowCI',
  initialState: workflowCIInitialState,
  reducers: {
    loadWorkflowCIs: (state, action: PayloadAction<CI[]>) => {
      state.CI = action.payload;
      state.loaded = true;
    },
    loadWorkflowFilteredCIs: (state, action: PayloadAction<CI[]>) => {
      state.filteredCI = action.payload;
    },
    loadWorkflowCI: (state, action: PayloadAction<CI>) => {
      const index = state.CI.findIndex((CIItem) => CIItem.id === action.payload.id);
      if (index !== -1) {
        state.CI[index] = action.payload;
      } else {
        state.CI.unshift(action.payload);
      }
    },
    selectWorkflowCI: (state, action: PayloadAction<number>) => {
      state.selected = action.payload;
    },
    removeWorkflowCI: (state, action: PayloadAction<number>) => {
      state.CI = state.CI.filter((CIItem) => CIItem.id !== action.payload);
    },
    setWorkflowCILoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
    setWorkflowCILoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setWorkflowCurrentCI: (state, action: PayloadAction<CI | null>) => {
      state.currentCI = action.payload;
    },
    updateWorkflowCurrentCI: (state, action: PayloadAction<CI>) => {
      if(state.currentCI && state.currentCI.id===action.payload.id){
        state.currentCI = action.payload; 
      }
    },
    refreshWorkflowCI: (state, action: PayloadAction<CI>) => {
      const index = state.CI.findIndex((CIItem) => CIItem.id === action.payload.id);
      if (index !== -1) {
        state.CI[index] = action.payload;
      }
    },
  },
});

export const {
  loadWorkflowCIs,
  loadWorkflowCI,
  selectWorkflowCI,
  removeWorkflowCI,
  setWorkflowCILoaded,
  loadWorkflowFilteredCIs,
  setWorkflowCILoading,
  setWorkflowCurrentCI,
  updateWorkflowCurrentCI,
  refreshWorkflowCI,
} = workflowCISlice.actions;

export const workflowCISelector = (state: RootState) => state.WorkflowCI.CI;
export const loadedWorkflowCISelector = (state: RootState) => state.WorkflowCI.loaded;
export const selectedWorkflowCISelector = (state: RootState) =>
  state.WorkflowCI.CI.find((CIItem) => CIItem.id === state.WorkflowCI.selected);

export default workflowCISlice.reducer;
