import { BaseCI } from "../../../models/BaseCI";
import { CI } from "../../../models/CI";
import { convertDateTimeFromUTCToTimezone } from "../../../services/utils";

export const mockEditCategoryData = (data: CI,timezone:string):BaseCI => {
    const editData = {
      id:data.id,
      assetId:data.asset, 
      knownCI : data.knownCI,
      categoryId:data.category ,
      justificationCategoryId:data.justificationCategory,
      justificationText:data.justificationText,
      ciEffectiveDate:convertDateTimeFromUTCToTimezone(data.ciEffectiveDate,timezone,"YYYY-MM-DD"),
      publicDate:data.publicDate ?convertDateTimeFromUTCToTimezone(data.publicDate,timezone,"YYYY-MM-DD"):undefined,
    approvers: data?.approvers?.map((approver) => approver.id),
    reviewers: data?.reviewers?.map((reviewer) => reviewer.id)
     };
  // @ts-ignore
    return editData;
  };