import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Divider,
  Drawer,
  Typography
} from "@mui/material";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import { UsersAvatarList } from "../../../../../components/users-avatar-list";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux-hook";
import { useCimUserRoles } from "../../../../../hooks/useCimUserRoles";
import { ciWorkFlowStatusObj } from "../../../../../models/ciWorkFlowStatus";
import { InfoTenantUser } from "../../../../../models/InfoTenantUser";
import { refreshCI } from "../../../../../redux/ci";
import { refreshWorkflowCI, setWorkflowCurrentCI } from "../../../../../redux/ciWorkflow";
import { hideProgressLine, showProgressLine } from "../../../../../redux/progress-line";
import { showSnackbar } from "../../../../../redux/snackbar";
import { usersSelector } from "../../../../../redux/users";
import { userSettingsSelector } from "../../../../../redux/userSettings";
import { getApiCIById } from "../../../../../services/ci";
import { ApproveCIAction } from "../../component/approveCIAction copy/approveCIAction";
import { ReSubmitCIAction } from "../../component/reSubmitCIAction/reSubmitCIAction";
import { ReviewCIAction } from "../../component/reviewCIAction/reviewCIAction";
import { StatusHistory } from "../../component/statusHistory";
import { SubmitCIAction } from "../../component/submitCIAction/submitCIAction";
import { WorkFlowLabel } from "../../component/workFlowLabel";
import CiDescriptionItem from "../ciDescriptionItem";
import ViewCISkeleton from "./viewSkeleton";
import { convertDateTimeFromUTCToTimezone, convertDateTimeFromUTCToTimeZoneIncludeTime } from "../../../../../services/utils";

type ViewCIDetailDrawerProps = {
  open: boolean;
  onClose: () => void;
  ciId: number;
  onRefresh?: () => void;
};

const ViewCIDetailDrawer: React.FC<ViewCIDetailDrawerProps> = ({ open, onClose, ciId, onRefresh }) => {

  const { tabName } = useParams();
  const location = useLocation()
  
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const userSettings = useAppSelector(userSettingsSelector);
  const users = useAppSelector((state) => usersSelector(state, false))
  const workFlowCIObj = useAppSelector((state) => state.WorkflowCI);
  const currentCI = workFlowCIObj.currentCI;
  const { isManager, isApprover, isReviewer } = useCimUserRoles();
  const dateFormat = userSettings?.regional?.dateFormat || "MM/DD/YYYY";
  const timeFormat = userSettings?.regional?.timeFormat || 24;
  const timezone  = userSettings?.regional?.timezone || "UTC";

  const getCiData = useCallback((ciId) => {
    dispatch(showProgressLine());
    setLoading(true);
    getApiCIById(ciId).then((data) => {
      dispatch(setWorkflowCurrentCI(data))
      dispatch(refreshWorkflowCI(data))
      dispatch(refreshCI(data));
      dispatch(hideProgressLine());
    }).catch((e) => {
      dispatch(showSnackbar({ message: "Error on getting CI Info", type: "info" }));
      dispatch(hideProgressLine());
    }).finally(() => {
      setLoading(false)
      dispatch(hideProgressLine());
    })
  }, [])

  useEffect(() => {
    return () => {
      dispatch(setWorkflowCurrentCI(null))
    }
  }, [])

  const filteredUsers = (selectedUsers: InfoTenantUser[], users: InfoTenantUser[]) => {
    const filteredUsers = users?.filter(u => selectedUsers?.some(su => su.id === u.id));
    return filteredUsers
  }

  useEffect(() => {
    getCiData(ciId)
  }, [getCiData, ciId]);

  const onReviewSuccess = () => {
    getCiData(ciId)
  }


  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "40%",
          padding: 3,
          overflowY: "auto",
        },
      }}
    >
      {(loading || !currentCI) ? (
        <Box>
          <ViewCISkeleton />
        </Box>
      ) : <Box>
        {/* Header */}
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 3 }}>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            View CI
          </Typography>
          {((isManager || isReviewer) && (tabName === "review-ci" || (tabName === "manage-ci" || location.pathname === "/app/user/cim/ci")) && currentCI.status === ciWorkFlowStatusObj.SUBMITTED_FOR_REVIEW) && <ReviewCIAction ciData={currentCI} onSuccess={onReviewSuccess} />}
          {((isManager || isApprover) && (tabName === "approve-ci" || (tabName === "manage-ci" || location.pathname === "/app/user/cim/ci")) && currentCI.status === ciWorkFlowStatusObj.IN_APPROVAL) && <ApproveCIAction ciData={currentCI} onSuccess={onReviewSuccess} />}
          {(isManager && (currentCI.status === ciWorkFlowStatusObj.DRAFT || currentCI.status === ciWorkFlowStatusObj.ON_HOLD)) && <SubmitCIAction ciData={currentCI} onSuccess={onReviewSuccess} />}
          {(isManager && (currentCI.status === ciWorkFlowStatusObj.REJECTED_FROM_REVIEW || currentCI.status === ciWorkFlowStatusObj.REJECTED_FROM_APPROVAL)) && <ReSubmitCIAction ciData={currentCI} onSuccess={onReviewSuccess} />}

        </Box>

        {/* Workflow Progress */}
          <Box sx={{ marginBottom: 3, display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 600, fontSize: '21px' }}>
              Workflow Progress
            </Typography>
            <WorkFlowLabel
              currentStatus={currentCI.status}
              chevronHeight={70}
              labelFontSize={15}
            />
        </Box>

        <Divider sx={{ marginBottom: 3 }} />

        {/* CI Details */}
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: 2 }}>
            CI Details
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <CiDescriptionItem label="Asset" content={currentCI.asset?.name || "-"} />
            <CiDescriptionItem label="Category Name" content={currentCI.category?.name || "-"} />
            {/* <CiDescriptionItem label="Justification Category" content={currentCI?.justificationCategory?.name || "-"} /> */}
            <CiDescriptionItem label="Justification Text" content={currentCI.justificationText || "-"} />
            <CiDescriptionItem label="Known CI" content={currentCI.knownCI || "-"} />
            <CiDescriptionItem label="CI Effective Date" content={convertDateTimeFromUTCToTimezone(currentCI.ciEffectiveDate, timezone, dateFormat)} />
            <CiDescriptionItem label="Project" content={currentCI?.project?.name || "-"} />
            <CiDescriptionItem label="Created At" content={ convertDateTimeFromUTCToTimeZoneIncludeTime(currentCI.createdAt, timezone, dateFormat, timeFormat)} />
            <CiDescriptionItem label="Public Date" content={currentCI.publicDate ? convertDateTimeFromUTCToTimezone(currentCI.publicDate, timezone, dateFormat):"-"} />
            <CiDescriptionItem label="Last Modified" content={ convertDateTimeFromUTCToTimeZoneIncludeTime(currentCI.lastModifiedAt,timezone, dateFormat, timeFormat)} />
            <CiDescriptionItem label="Owner" content={currentCI.createdByName || "-"} />
            <CiDescriptionItem label="Reviewers" render={() => <UsersAvatarList users={filteredUsers(currentCI.reviewers || [], users || [])} shortView={false} />} />
            <CiDescriptionItem label="Approvers" render={() => <UsersAvatarList users={filteredUsers(currentCI.approvers || [], users || [])} shortView={false} />} />
          </Box>
        </Box>

        {/* Status History */}
        <Box sx={{ marginBottom: 8 }}>
          <StatusHistory history={currentCI.statusHistory || []} />
        </Box>
      </Box>}
      <Box>
      </Box>
    </Drawer>
  );
};

export default ViewCIDetailDrawer;
