import React, { useEffect, useRef, useState } from "react";
import {Switch} from "@mui/material";
import {useAdminService} from "../../contexts/super-admin-context";
import {getApiRlsIsUseWebviewerDownloader} from "../../services/rls.setting";

export function GeneralPage() {
    const [isUseDownlaoder, setIsUseDownlaoder] = useState(false);
    const { updateUseWebviewerDownloader } = useAdminService();

    useEffect(() => {
        //make api call for current state
        getApiRlsIsUseWebviewerDownloader().then((value) => {
            setIsUseDownlaoder(value)
        })
    }, [])

    const handleUseDownloaderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateUseWebviewerDownloader(event.target.checked).then(() => {
            setTimeout(() => setIsUseDownlaoder(!isUseDownlaoder), 300);
        })
    }

    return <div className='page-wrapper' style={{ paddingTop: 24 }}>
        <div className="page-header">General Settings</div>
        <div style={{display: "flex", paddingTop: 30}}>
            <p style={{marginTop: 5}}>Use Webviewer Downloader</p>
            <Switch checked={isUseDownlaoder} onChange={handleUseDownloaderChange}
                    color="secondary" id="email-notification" />
        </div>
    </div>
}
