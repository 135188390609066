import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RejectionCategory } from '../models/RejectionCategory';
import { RootState } from './store';

export const defaultRejectionCategories: RejectionCategory[] = [];

const rejectionCategoriesInitialState = {
  rejectionCategories: defaultRejectionCategories,
  loaded: false,
  selected: null as number | null,
  loading: false,
};

const categoriesSlice = createSlice({
  name: 'rejectionCategories',
  initialState: rejectionCategoriesInitialState,
  reducers: {
    loadRejectionCategories: (state, action: PayloadAction<RejectionCategory[]>) => {
      state.rejectionCategories = action.payload;
      state.loaded = true;
    },
    setRejectionCategoriesLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
    setRejectionCategoriesLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const {
  loadRejectionCategories,
  setRejectionCategoriesLoaded,
  setRejectionCategoriesLoading,
} = categoriesSlice.actions;

export const rejectionCategoriesSelector = (state: RootState) => state.rejectionCategories.rejectionCategories;
export const loadedRejectionCategoriesSelector = (state: RootState) => state.rejectionCategories.loaded;
export default categoriesSlice.reducer;
