import MoreVertIcon from '@mui/icons-material/MoreVert';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useCimUserRoles } from '../../../../../hooks/useCimUserRoles';
import { CI } from '../../../../../models/CI';
import { EditMenuItem } from '../actionMenuItem/editMenuItem';
import { PutOnHoldMenuItem } from '../actionMenuItem/putOnHoldMenuItem';
import { OnHoldCIModal } from '../onHoldCIModel';
import { ReSubmitCIModal } from './reSubmitCIModal';

type ReSubmitCIActionProps = {
  ciData: CI;
  onSuccess?: () => void;
};


export const ReSubmitCIAction: React.FC<ReSubmitCIActionProps> = ({ ciData, onSuccess = () => { } }) => {
  const [loading] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [actionState, setActionState] = useState<string | null>(null);
  const { isManager } = useCimUserRoles();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const onHoldCI = () => {
    setActionState('onHold');
    handleMenuClose();
  };

  const onReSubmitApproval = () => {
    setActionState('resubmit');
    handleMenuClose();
  };

  if (!isManager) {
    return null
  }

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Box>
        <Button
          onClick={handleMenuOpen}
          disabled={loading}
          sx={{
            minWidth: 0,
            padding: 1,
            borderRadius: '50%',
            backgroundColor: 'action.hover',
            '&:hover': { backgroundColor: 'action.selected' },
          }}
        >
          <MoreVertIcon />
        </Button>
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            elevation: 3,
            sx: {
              backgroundColor: '#1a1a1a',
              color: '#fff',
              borderRadius: '8px',
              minWidth: '200px',
              boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
              overflow: 'hidden',
            },
          }}
        >
          <EditMenuItem onSuccess={onSuccess} ciData={ciData} handleMenuClose={handleMenuClose} loading={loading} />

          <MenuItem
            onClick={onReSubmitApproval}
            disabled={loading}
            sx={{
              '&:hover': {
                backgroundColor: '#555',
                color: '#fff',
                transition: 'all 0.3s ease',
              },
            }}
          >
            <SettingsBackupRestoreIcon sx={{ mr: 1, }} />
            Re Submit CI
          </MenuItem>

          <PutOnHoldMenuItem onClick={onHoldCI} ciData={ciData} loading={loading} />

        </Menu>
      </Box>

      {actionState === 'resubmit' && (
        <ReSubmitCIModal
          open={true}
          onClose={() => setActionState(null)}
          ciId={ciData.id}
          onSuccess={onSuccess}
        />
      )}

      {actionState === 'onHold' && (
        <OnHoldCIModal
          open={true}
          onClose={() => setActionState(null)}
          ciId={ciData.id}
          onSuccess={onSuccess}
        />
      )}
    </Box>
  );
};
