import React, {useEffect, useRef, useState} from "react";
import { useForm } from "react-hook-form";
import { useCustomModal } from "../custom-message-modal";
import './remove-entity-modal.scss';
import {useAppDispatch} from "../../../hooks/redux-hook";
import {getExcelFileNoCache} from "../../../components/file-management";
import {LinearProgress} from "@mui/material";
import {getFileNameFromURL} from "../../user/projects/single-project";
import {Row} from "read-excel-file";
import {DESCRIPTION_ROW} from "../../../constants";
import {useImportExcel} from "../../../hooks/import-excel";
import {useParams} from "react-router-dom";
import ModalIconContent from "./modal-icon-content";
import {StyledCloseIconUploader} from "../../../components/close-button";
import Button from "@mui/material/Button";

export function CombineBatchReportsModal(urls: string[], project: any) {

    const { hideModal } = useCustomModal();
    const [failedFiles, setFailedFiles] = useState<string[]>([]);
    const {acceptedExcelExtensions, importExcel} = useImportExcel();
    const [processingCompleted, setProcessingCompleted] = useState<boolean>(false);
    const cancelled = useRef(false);

    const [progress, setProgress] = useState(0);
    const headerRow = Array.from(DESCRIPTION_ROW.keys())
    const descriptionRow = Array.from(DESCRIPTION_ROW.values())
    let filesProcessed = 0
    let currentRows: any[] = []
    useEffect(() => {
        processFiles()
    }, [])

    const onImport = async (rows: Row[]) => {
        currentRows = currentRows.concat(rows.slice(2)) // the first two rows are the headers and description
        filesProcessed++
        if (filesProcessed===urls.length) {
            localStorage.setItem("MultiDocumentBatchReportHeaderRow",  JSON.stringify(headerRow));
            localStorage.setItem("MultiDocumentBatchReportRows", JSON.stringify([descriptionRow].concat(currentRows)));
            localStorage.setItem("MultiDocumentBatchReportProjectName", project.name);
            window.open(`/app/user/docs/${project.id}/multi-document-batch-report`);
            setProcessingCompleted(true)
        }
        setProgress(filesProcessed*100/urls.length)
    }

    function handleFailedFiles(fileName: string) {
        setFailedFiles([...failedFiles, fileName])
    }

    async function processFiles() {
        for (let i = 0; i < urls.length; i++) {
            const url = urls[i]
            const data = await getExcelFileNoCache(url)
            const fileName = getFileNameFromURL(url) || 'Project'
            const file = await new File([data], fileName)
            if (cancelled.current) return
            await importExcel(file, onImport, () => handleFailedFiles(fileName))
        }
    }

    function onClose() {
        cancelled.current=true
        hideModal()
    }

    return (
        <div className="upload-file-modal-container">
            <div className="wizard-title">
                <StyledCloseIconUploader onClick={onClose}/>
                <span>{"Multi-document Batch Report"}</span>
            </div>
            {processingCompleted
                    ?
                    failedFiles.length > 0
                        ?
                        <div style={{padding: 20}}>
                            There was an error combining the following files
                            {failedFiles.map((file) =>
                                <div style={{color: "red", margin: 20}}>{file}</div>
                            )}
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <Button variant='contained' style={{justifyContent: "center"}} onClick={hideModal}>Ok</Button></div>
                        </div>
                        :
                        <div  style={{position: "relative",
                            width: 350, display: "flex", alignItems: 'center',
                            flexDirection: "column", justifyContent: "center", padding: "20px 30px", marginTop: 80
                        }}>
                            <ModalIconContent isDone/>
                            <h4 style={{fontWeight:"bold"}}>Successfully Combined {urls.length} Reports</h4>
                            <Button variant='contained' onClick={hideModal}>Ok</Button>
                        </div>
                    :
                    <div className={'custom'}>
                        <p>{'Combining Reports'}</p>
                        <LinearProgress variant={"determinate"} className={'linear-progress'} value={progress}/>
                        <small>please wait...</small>
                        <button className="rounded-button cancel-button" onClick={onClose}>Cancel
                        </button>
                    </div>
                    }
        </div>)
}
