import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FileState } from "../models/FileState";
import { RootState } from "./store";

const defaultFiles: FileState[] = [];

const initialState = {
    files: defaultFiles,
    refreshCount: 0,
}

const files = createSlice({
    name: "files",
    initialState: initialState,
    reducers: {
        loadFiles: (state, action) => {
            state.files = action.payload;
        },
        updateFile: (state, action) => {
            const index = state.files.findIndex((file: FileState) => file.id === action.payload.id);
            state.files[index] = action.payload; 
        },
        refreshProjectData: (state, action: PayloadAction<undefined>) => {
           state.refreshCount++;
         },
    }
});

export const { loadFiles, updateFile, refreshProjectData } = files.actions;
export const filesSelector = (state: RootState) => state.files.files;

export const fileRefreshCountSelector = (state: RootState) =>
    state.files.refreshCount;

export default files.reducer;