import { Box, Button, CircularProgress, Divider, Modal, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useAppDispatch } from '../../../../hooks/redux-hook';
import { showSnackbar } from '../../../../redux/snackbar';
import { putApIOnHoldCI } from '../../../../services/ciWorkFlow';

type OnHoldCIModalProps = {
  open: boolean;
  onClose: () => void;
  ciId: number;
  onSuccess: () => void;
};

export const OnHoldCIModal: React.FC<OnHoldCIModalProps> = ({
  open,
  onClose,
  ciId,
  onSuccess,
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');

  const handleSubmitApproval = () => {
    setLoading(true);
    putApIOnHoldCI(ciId, { actionReason: comment })
      .then(() => {
        dispatch(showSnackbar({ message: 'Successfully added on hold.', type: 'info' }));
        onSuccess();
      })
      .catch(() => {
        dispatch(showSnackbar({ message: 'Error adding CI on hold.', type: 'error' }));
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '450px',
          bgcolor: 'background.paper',
          padding: 4,
          borderRadius: 2,
          boxShadow: 24,
        }}
      >
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Put CI On Hold
        </Typography>

        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ marginBottom: 2, backgroundColor: '#f5f5f5', padding: 1.5, borderRadius: 1 }}
        >
          Note:Placing CI on means Reviewrs and Approvers will have no logner access to this CI. You can Re Submit it any time
        </Typography>

        <Divider sx={{ marginBottom: 2 }} />
        <Typography variant="body1" gutterBottom>
          Add a Comment (Optional)
        </Typography>
        <TextField
          label="Enter your comment"
          multiline
          rows={4}
          fullWidth
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Provide a reason or context for putting the CI on hold (optional)"
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: 2,
            },
          }}
        />

        <Box
          sx={{
            marginTop: 3,
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            fullWidth
            sx={{
              textTransform: 'none',
              borderRadius: 2,
            }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitApproval}
            disabled={loading}
            fullWidth
            sx={{
              textTransform: 'none',
              borderRadius: 2,
            }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Hold CI'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
