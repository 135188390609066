import React, { useContext, useState } from "react";
import { Menu, MenuItem, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { useParams } from "react-router-dom";
import { ProjectFileManagementContext } from "../../contexts/ProjectFolderManagementContextProvider";
import { Node } from "../../services/folderDragAndDrop";
import { useAppSelector } from "../../hooks/redux-hook";
import { projectsSelector } from "../../redux/projects";
import { projectFolderAction } from "../../constants";
import {
  copyFolderToTargetProjectFolder,
  getApiFolderSchemaByProjectId,
  getFolderCopyInformation,
} from "../../services/folder";
import {
  DataGridPro,
  GridCallbackDetails,
  GridRow,
  GridRowParams,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { Project } from "../../models/Project";
import FolderTreeTargetForCopyFolderAndFile from "./FolderTreeTargetForCopyFolderAndFile";
import FolderTreeSourceForCopyFolderAndFile from "./FolderTreeSourceForCopyFolderAndFile";

function CopyFolderToProjectModal({
  node,
  openModalName,
  setOpenModalName,
}: {
  node: Node; // sourceNode
  openModalName: string;
  setOpenModalName: Function;
}) {
  const { projectId } = useParams();
  const projects = useAppSelector((state) => projectsSelector(state));

  const [searchProject, setSearchProject] = useState("");

  const [selectedProjectSchema, setSelectedProjectSchema] = useState<Node[]>(
    []
  );

  const [sourceFolderSchema, setSourceFolderSchema] = useState<Node[] | null>(
    null
  );

  const [targetFolderNode, setTargetFolderNode] = useState<Node | null>(null);

  const handleSelectProjectToGetFolder = async (project: Project) => {
    try {
      setOpenModalName(projectFolderAction.FOLDER_LIST_TO_COPY_FOLDER);

      const folderSchema = await getApiFolderSchemaByProjectId(project.id);

      setSelectedProjectSchema(folderSchema);
    } catch (error) {}
  };

  const handleSelectTargetFolderToCopySourceFolder = async (
    targetNode: Node
  ) => {
    try {
      const sourceFolderSchema = await getFolderCopyInformation(
        node.folderId!,
        targetNode.folderId!
      );
      if (sourceFolderSchema) {
        setTargetFolderNode(targetNode);
        setSourceFolderSchema(sourceFolderSchema);
        setOpenModalName(projectFolderAction.CONFIRM_COPY_FOLDER_FILE);
      }
    } catch (error) {}
  };

  const handleConfirmCopyFolder = async () => {
    try {
      const response = await copyFolderToTargetProjectFolder(
        node.folderId!,
        targetFolderNode?.folderId!
      );
      if (response) {
        setOpenModalName("");
      }
    } catch (error) {}
  };

  const getColumns = (() => {
    const column = [
      {
        field: "name",
        headerName: "Project Name",
        flex: 4,
        renderCell: (params: any) => {
          return (
            <Button
              title={params.row.projectName}
              style={{
                width: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                display: "block",
                height: "100%",
                backgroundColor: params.row.color,
                color: "white",
                font: "inherit",
              }}
              onClick={() => {}}
            >
              <span>{params.row.name}</span>
            </Button>
          );
        },
      },
      {
        field: "owner",
        headerName: "Owner",
        flex: 2,
        valueGetter: (params: any) => params.row.createdByName,
      },
      {
        field: "type",
        headerName: "Type of Submission",
        flex: 2,
        valueGetter: (params: any) => params.row.submissionType?.name,
      },
    ];
    return column;
  })();

  const searchProjects = (() => {
    const filteredProject = projects.filter(
      (project) =>
        project.name.toLowerCase().includes(searchProject.toLowerCase()) &&
        project.id !== Number(projectId)
    );
    return filteredProject;
  })();

  return (
    <>
      <Modal
        open={openModalName === projectFolderAction.PROJECT_LIST_TO_COPY_FOLDER}
        onClose={() => setOpenModalName("")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box sx={{ margin: 1 }}>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h3"
              sx={{ textAlign: "center" }}
            >
              Select a project to which you want to copy the folder.
            </Typography>

            <TextField
              name="searchProject"
              label="Search Project"
              size="small"
              sx={{ width: 400, marginTop: 2 }}
              value={searchProject}
              onChange={(e) => {
                setSearchProject(e.target.value);
              }}
            />
          </Box>

          <Box
            sx={{
              width: "100%",
              height: "calc(100vh - 200px)",
              overflow: "auto",
              scrollbarWidth: "none",
            }}
          >
            <DataGridPro
              rows={searchProjects}
              columns={getColumns}
              pageSizeOptions={[5, 10, 25]}
              onRowClick={(
                params: GridRowParams,
                event: MuiEvent,
                details: GridCallbackDetails
              ) => {
                handleSelectProjectToGetFolder(params.row);
              }}
            />
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openModalName === projectFolderAction.FOLDER_LIST_TO_COPY_FOLDER}
        onClose={() => setOpenModalName("")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box sx={{ margin: 1 }}>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h3"
              sx={{ textAlign: "center" }}
            >
              Select a destination folder to copy the source folder.
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              height: "calc(100vh - 400px)",
              overflow: "auto",
              scrollbarWidth: "none",
              marginTop: 2,
            }}
          >
            {selectedProjectSchema?.map((node: Node) => (
              <FolderTreeTargetForCopyFolderAndFile
                key={node.id}
                node={node}
                handleSelectTargetFolderToCopySourceFolder={
                  handleSelectTargetFolderToCopySourceFolder
                }
              />
            ))}
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openModalName === projectFolderAction.CONFIRM_COPY_FOLDER_FILE}
        onClose={() => setOpenModalName("")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box sx={{ margin: 1 }}>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h3"
              sx={{ textAlign: "center" }}
            >
              Confirm that the following source folder tree will be copied into
              <em> "{targetFolderNode?.name}"</em>.
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              height: "calc(100vh - 400px)",
              overflow: "auto",
              scrollbarWidth: "none",
              marginTop: 2,
            }}
          >
            {sourceFolderSchema?.map((node: Node) => (
              <FolderTreeSourceForCopyFolderAndFile key={node.id} node={node} />
            ))}
          </Box>

          <div style={{ textAlign: "center" }}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                setOpenModalName("");
              }}
              style={{ marginRight: 40 }}
            >
              CANCEL
            </Button>
            <Button
              type="button"
              color="secondary"
              variant="contained"
              onClick={() => {
                handleConfirmCopyFolder();
              }}
            >
              COPY
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default CopyFolderToProjectModal;
