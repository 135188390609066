import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

const initialState = {
  nlpTotalAnnotations: 0,
  nlpSelectedAnnotations: 0,
};

const document = createSlice({
  name: "document",
  initialState: initialState,
  reducers: {
    updateNlpTotalAnnotations: (state, action) => {
      state.nlpTotalAnnotations = action.payload;
    },
    addNlpSelectedAnnotations: (state, action) => {
      state.nlpSelectedAnnotations += action.payload;
    },
    resetNlpAnnotationCount: (state) => {
      state.nlpTotalAnnotations = 0;
      state.nlpSelectedAnnotations = 0;
    },
  },
});

export const {
  updateNlpTotalAnnotations,
  addNlpSelectedAnnotations,
  resetNlpAnnotationCount,
} = document.actions;

export const nlpTotalAnnotationSelector = (state: RootState) =>
  state.document.nlpTotalAnnotations;

export const nlpSelectedAnnotationSelector = (state: RootState) =>
  state.document.nlpSelectedAnnotations;

export default document.reducer;
