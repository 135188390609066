import { Autocomplete, Box, Chip, CircularProgress, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CIMRoleKey } from '../../../../../constants';
import { useAuthService } from '../../../../../contexts/auth-context';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hook';
import { Asset } from '../../../../../models/Asset';
import { CI } from '../../../../../models/CI';
import { ciWorkFlowStatus, CIWorkFlowStatusType } from '../../../../../models/ciWorkFlowStatus';
import { loadAssets } from '../../../../../redux/assets';
import { loadWorkflowCIs, loadWorkflowFilteredCIs, setWorkflowCILoading } from '../../../../../redux/ciWorkflow';
import { hideProgressLine, showProgressLine } from '../../../../../redux/progress-line';
import { getApiAssets } from '../../../../../services/assets';
import { getApiWorkflowCIsAllViewer, getApiWorkflowCIsCimApprover, getApiWorkflowCIsCimManager, getApiWorkflowCIsCimReviewer } from '../../../../../services/ciWorkFlow';
import { fetchUsers } from '../../../../../services/init';
import CIStatusChip from '../../component/ciStatusChip';
import { CategoryList } from '../categoryItem';
import ListSkeleton from './listSkeleton';
import ViewCIDetailDrawer from './viewCIDetailDrawer';

interface ViewCIListProps {
    activeRole: "VIEW" | CIMRoleKey;
}
export const ViewCIList = ({ activeRole }: ViewCIListProps) => {
    const basePath = "/app/user/cim/ci-workflow/:tabName";
    const { tabName = "" } = useParams();
    const [searchParams] = useSearchParams();
    const nav = useNavigate();
    const dispatch = useAppDispatch();
    const auth = useAuthService();
    const ciIdFromQuery = searchParams.get("ciId");

    const workflowCIObj = useAppSelector((state) => state.WorkflowCI);
    const ci = workflowCIObj.CI;
    const ciFiltered = workflowCIObj.filteredCI;
    const ciLoading = workflowCIObj.loading;
    const assetsObj = useAppSelector((state) => state.assets);
    const assets = assetsObj.assets;
    const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
    const [selectedStatus, setSelectedStatus] = useState<keyof typeof ciWorkFlowStatus | null>(null);

    const roles = auth?.loginInfo?.tenant?.user?.roles;

    const loadAssetsData = useCallback(async () => {
        const assetsList = await getApiAssets();
        dispatch(loadAssets(assetsList));
    }, [dispatch]);

    useEffect(() => {
        loadAssetsData();
    }, [loadAssetsData]);

    useEffect(() => {
        const controller = new AbortController();
        fetchUsers(controller.signal, dispatch);
        return () => controller.abort();
    }, []);

    const loadCIData = useCallback(async () => {
        dispatch(showProgressLine());
        dispatch(setWorkflowCILoading(true))
        try {
            let allCIs = [];
            if (activeRole === "CIM_MANAGER") {
                allCIs = await getApiWorkflowCIsCimManager();
            } else if (activeRole === "CIM_APPROVER") {
                allCIs = await getApiWorkflowCIsCimApprover();

            } else if (activeRole === "CIM_REVIEWER") {
                allCIs = await getApiWorkflowCIsCimReviewer();
            } else {
                allCIs = await getApiWorkflowCIsAllViewer();
            }
            dispatch(loadWorkflowCIs(allCIs));
            dispatch(hideProgressLine());
        } catch (err) {
            dispatch(hideProgressLine());
        } finally {
            dispatch(setWorkflowCILoading(false))

        }
    }, [dispatch]);

    useEffect(() => {
        loadCIData();
    }, [dispatch, loadCIData]);

    useEffect(() => {
        let filteredCatList = ci;
        if (selectedAsset) {
            filteredCatList = ci.filter((item) => item.assetId === selectedAsset.id);
        }
        if (selectedStatus) {
            filteredCatList = filteredCatList.filter(item => item.status === selectedStatus)
        }

        dispatch(loadWorkflowFilteredCIs(filteredCatList));
    }, [ci, selectedAsset, selectedStatus]);

    const handleAssetChange = (event: any, newValue: Asset | null) => {
        if (newValue) {
            setSelectedAsset(newValue);
        } else {
            setSelectedAsset(null);
        }
    };

    const handleStatusChange = (event: any, newValue: keyof typeof ciWorkFlowStatus | null) => {
        setSelectedStatus(newValue);
    };

    const filteredStatuses = Object.keys(ciWorkFlowStatus)
        .map(Number)
        .filter((key: CIWorkFlowStatusType) => {
            const status = ciWorkFlowStatus[key];
            return roles?.some(role => status.roles?.includes(role) && status.visibleOnTab.includes(tabName));
        });

    const handleItemClick = (clickedCI: CI) => {
        const newPath = generatePath(basePath, { tabName }) + `?ciId=${clickedCI.id}`;

        nav(newPath, { replace: true });
    };

    const handleDrawerClose = () => {
        const newPath = generatePath(basePath, { tabName });
        nav(newPath, { replace: true });
    };

    return (
        <Box style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <Box
                sx={{
                    flex: 1,
                    overflowY: "auto",
                    padding: "16px",
                    backgroundColor: "#fff",
                    overFlow: 'hidden',
                    display: 'flex',
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, flex: 1 }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 2,
                        }}
                    >
                        {ciLoading ? <Box style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}><Typography variant="h6" sx={{ fontWeight: 600 }}>
                            CI List
                        </Typography><CircularProgress size={20} /></Box> : <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            CI List ({ciFiltered.length || 0})
                        </Typography>}
                        <Box style={{ display: "flex", gap: 16 }}>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end", gap: 1 }}>
                                {!selectedAsset ? <Autocomplete
                                    size="small"
                                    value={selectedAsset}
                                    onChange={handleAssetChange}
                                    options={assets}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Asset"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props}>{option.name}</li>
                                    )}
                                    sx={{
                                        width: '250px',
                                        '& .MuiAutocomplete-inputRoot': {
                                            padding: '8px 12px',
                                            backgroundColor: 'transparent',
                                        },
                                    }}
                                /> : (
                                    <Chip
                                        label={selectedAsset.name}
                                        color="primary"
                                        onDelete={() => setSelectedAsset(null)}
                                        sx={{
                                            borderRadius: '16px',
                                            fontWeight: 'bold',
                                            minHeight: '36px',
                                            height: '36px',
                                        }}
                                    />
                                )}
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end", gap: 1 }}>
                                {!selectedStatus ? (
                                    <Autocomplete
                                        size="small"
                                        value={selectedStatus}
                                        onChange={handleStatusChange}
                                        options={filteredStatuses}
                                        getOptionLabel={(status) => ciWorkFlowStatus[status].label}
                                        sx={{ width: 250 }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select Status"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                        // renderOption={(props, status) => (
                                        //     <li {...props}>
                                        //         <Box sx={{ display: "flex", alignItems: "center" }}>
                                        //             <CIStatusChip status={status} />
                                        //         </Box>
                                        //     </li>
                                        // )}
                                    />
                                ) : (<CIStatusChip onDelete={() => setSelectedStatus(null)} status={selectedStatus} />

                                )}
                            </Box>
                        </Box>
                    </Box>
                    {ciLoading ? <ListSkeleton /> : <CategoryList items={ciFiltered} onItemClick={handleItemClick} />}
                </Box>
                {ciIdFromQuery && <ViewCIDetailDrawer onRefresh={loadCIData} onClose={handleDrawerClose} open ciId={Number(ciIdFromQuery)} />}
            </Box>
        </Box>
    );
};
