import {
  Box,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hook';
import { hideProgressLine, showProgressLine } from '../../../../redux/progress-line';
import { loadRejectionCategories } from '../../../../redux/rejectionCategory';
import { showSnackbar } from '../../../../redux/snackbar';
import { putApiRejectInApprovalCI } from '../../../../services/ciWorkFlow';
import { getApiRejectionCategories } from '../../../../services/rejectionCategory';

type RejectApprovalCIModalProps = {
  open: boolean;
  onClose: () => void;
  ciId: number;
  onSuccess: () => void;
};

type FormData = {
  rejectionCategoryId: number;
  comment: string;
};

export const RejectApprovalCIModal: React.FC<RejectApprovalCIModalProps> = ({
  open,
  onClose,
  ciId,
  onSuccess,
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const rejectionCategoriesObj = useAppSelector((state) => state.rejectionCategories);
  const rejectionCategories = rejectionCategoriesObj.rejectionCategories;

  const { control, handleSubmit, reset, formState: { errors } } = useForm<FormData>({ mode: 'onChange' });

  const loadRejectionCategoryData = useCallback(async () => {
    dispatch(showProgressLine());
    const rejectionCategoriesList = await getApiRejectionCategories();
    dispatch(loadRejectionCategories(rejectionCategoriesList));
    dispatch(hideProgressLine());
  }, [dispatch]);

  useEffect(() => {
    if (open) {
      loadRejectionCategoryData();
      reset();
    }
  }, [open, loadRejectionCategoryData, reset]);

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    putApiRejectInApprovalCI(ciId, {
      rejectionCategoryId: data.rejectionCategoryId,
      actionReason: data.comment,
    })
      .then(() => {
        dispatch(showSnackbar({ message: 'CI Rejected!', type: 'info' }));
        onSuccess();
      })
      .catch(() => {
        dispatch(showSnackbar({ message: 'Error on rejecting CI', type: 'error' }));
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '450px',
          bgcolor: 'background.paper',
          padding: 4,
          borderRadius: 2,
          boxShadow: 24,
        }}
      >
        {/* Modal Header */}
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Reject Change Request
        </Typography>

        {/* Informational Note */}
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ marginBottom: 2, backgroundColor: '#f5f5f5', padding: 1.5, borderRadius: 1 }}
        >
          Note: Rejecting this CI will notify the owner and all reviewers and approvers about the rejection.
          Comments are optional but recommended for clarity.
        </Typography>

        <Divider sx={{ marginBottom: 2 }} />

        <Typography variant="body1" gutterBottom>
          Select a Rejection Category *
        </Typography>
        <Controller
          name="rejectionCategoryId"
          control={control}
          rules={{ required: 'Rejection category is required' }}
          render={({ field }) => (
            <Select
              {...field}
              fullWidth
              displayEmpty
              error={!!errors.rejectionCategoryId}
              sx={{
                borderRadius: 2,
              }}
            >
              <MenuItem value="" disabled>
                Select a category
              </MenuItem>
              {rejectionCategories.map((category: any) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {errors.rejectionCategoryId && (
          <Typography variant="caption" color="error">
            {errors.rejectionCategoryId.message}
          </Typography>
        )}

        <Typography variant="body1" gutterBottom sx={{ marginTop: 2 }}>
          Add a Comment (Optional)
        </Typography>
        <Controller
          name="comment"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Enter your comment"
              multiline
              rows={4}
              fullWidth
              placeholder="Provide feedback or reasons for rejection (optional)"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                },
              }}
            />
          )}
        />

        <Box
          sx={{
            marginTop: 3,
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            fullWidth
            sx={{
              textTransform: 'none',
              borderRadius: 2,
            }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={loading}
            fullWidth
            sx={{
              textTransform: 'none',
              borderRadius: 2,
            }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Reject CI'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
