import { Button, styled } from "@mui/material";
import { useState } from "react";
import { ButtonSpinner } from "../../../components/button-spinner";
import { StyledCloseIcon } from "../../../components/close-button";
import { useAppDispatch } from "../../../hooks/redux-hook";
import { removeAsset } from "../../../redux/assets";
import { showSnackbar } from "../../../redux/snackbar";
import { deleteApiAssetsById } from "../../../services/assets";
import { useCustomModal } from "../../modals/custom-message-modal";

const StyledCancelButton = styled(Button)({
    color: '#217DA2',
    borderColor: '#217DA2'
});

export function CimAssetDelete(props : { id: number, name: string }) {
    const { hideModal } = useCustomModal();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const deleteAsset = () => {
        const controller = new AbortController();
        setLoading(true);
        deleteApiAssetsById(props.id, controller.signal)
            .then(() =>{
                dispatch(removeAsset(props.id))
                hideModal()
                dispatch(showSnackbar({
                    message: `Asset ${props.name} has been removed.`,
                    type: "info"
                }))
            })
            .catch((error) => {
                setLoading(false);
                dispatch(showSnackbar({
                    message: error.message,
                    type: "error"
                }));
            });
    }

    return <div className="wizard-dialog" style={{ padding: 32, display: 'flex', flexDirection: 'column' }}>
        <StyledCloseIcon onClick={() => loading ? {} : hideModal()} />
        <span style={{ color: '#C13826', fontSize: 24, fontWeight: 600, marginBottom: 16 }}>
            Delete {props.name}?
        </span>
        <span style={{ marginBottom: 24 }}>Are you sure you want to permanently delete this asset?</span>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <StyledCancelButton disabled={loading} variant="outlined" onClick={hideModal}>CANCEL</StyledCancelButton>
            <Button disabled={loading} variant="contained" color="secondary" onClick={deleteAsset}>
                DELETE {loading && <ButtonSpinner />}
            </Button>
        </div>
    </div>
}
