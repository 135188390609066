import _ from "lodash";
export interface NlpJobDetail {
  id: number | null;
  status: string | null;
  createdAt: string | null;
  modifiedAt: string | null;
  sqsResultFileName: string | null;
  isNlpJobResponseRelevant: boolean | null;
}

export interface Node {
  id: string;
  name: string;
  type: "folder" | "file" | string;
  children?: Node[];
  folderId?: number;
  isProjectRootFolder?: boolean;
  fileId?: number;
  size?: number;
  category?: string;
  openedBy?: string;
  createdAt?: string;
  isDocOpen?: boolean;
  projectId?: number;
  nlpJobDetail?: NlpJobDetail;
  createdByName?: string;
  lastModifiedAt?: string;
  // for copy folder/files
  isFileAlreadyExistsInTarget?: boolean;
}

export function findNodePathById(schema: Node[], targetId: string): Node[] {
  const path: Node[] = [];

  function traverse(node: Node, currentPath: Node[]): boolean {
    // Add the current node to the path
    currentPath.push({ id: node.id, type: node.type, name: node.name });

    // Check if the current node is the target
    if (node.id === targetId) {
      path.push(...currentPath); // Store the path when target is found
      return true;
    }

    // If the node has children, traverse them
    if (node.children && Array.isArray(node.children)) {
      for (const child of node.children) {
        if (traverse(child, [...currentPath])) {
          return true;
        }
      }
    }

    return false; // Return false if target not found in this branch
  }

  // Iterate over the top-level nodes in the JSON array
  for (const item of schema) {
    if (traverse(item, [])) {
      break;
    }
  }

  return path;
}

export function findNodeDetailsById(
  schema: Node[],
  targetId: string
): Node | null {
  let result: Node | null = null;

  function traverse(node: Node): boolean {
    if (node.id === targetId) {
      result = node; // Store the node details when target is found
      return true;
    }

    if (node.children && Array.isArray(node.children)) {
      for (const child of node.children) {
        if (traverse(child)) {
          return true;
        }
      }
    }

    return false;
  }

  for (const item of schema) {
    if (traverse(item)) {
      break;
    }
  }

  return result; // Return the found node or null if not found
}

export function getRealNodeId(nodeId: string): string {
  return nodeId.includes("!^|")
    ? (nodeId.split("!^|").pop() as string)
    : nodeId;
}

export const updateNodeById = (
  schema: Node[],
  updatedNode: Node
): { updatedSchema: Node[]; isNodeUpdated: boolean } => {
  const updatedSchema = JSON.parse(JSON.stringify(schema)); // Deep clone the JSON data

  let isNodeUpdated = false;

  const updateNodeRecursion = (nodes: Node[]): boolean => {
    for (let node of nodes) {
      if (node.id === updatedNode.id) {
        Object.assign(node, updatedNode);
        isNodeUpdated = true;
        return true;
      }

      if (node.children && updateNodeRecursion(node.children)) {
        return true;
      }
    }

    return false;
  };

  updateNodeRecursion(updatedSchema);

  return { updatedSchema, isNodeUpdated };
};

export const removeNodeById = (
  schema: Node[],
  targetId: string
): { updatedSchema: Node[]; removedNode: Node | null } => {
  const updatedSchema = JSON.parse(JSON.stringify(schema)); // Deep clone the JSON data

  let removedNode: Node | null = null;

  const removeNodeRecursion = (nodes: Node[]) => {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].id === targetId) {
        removedNode = nodes.splice(i, 1)[0];
        return true;
      }
      if (
        nodes[i].children &&
        removeNodeRecursion(nodes[i].children as Node[])
      ) {
        return true;
      }
    }
  };

  removeNodeRecursion(updatedSchema);

  return { updatedSchema, removedNode };
};

export const addNodeById = (
  schema: Node[],
  targetId: string,
  nodeToAdd: Node
): { updatedSchema: Node[]; isNodeAdded: boolean; errorMessage: string } => {
  const updatedSchema = JSON.parse(JSON.stringify(schema)); // Deep clone the JSON data
  let errorMessage = "";

  const addNodeRecursion = (nodes: Node[]): boolean => {
    for (let node of nodes) {
      if (node.id === targetId && node.type === "folder") {
        node.children = node.children || [];

        const isDuplicate = node.children.some(
          (child) =>
            (child.type === nodeToAdd.type && child.name === nodeToAdd.name) ||
            child.id === nodeToAdd.id
        );

        if (isDuplicate) {
          errorMessage = `${
            nodeToAdd.type === "folder" ? "Folder" : "File"
          } already exists with the name "${nodeToAdd.name}"`;
          return false;
        }

        node.children.push(nodeToAdd);
        return true;
      }

      if (node.children && addNodeRecursion(node.children)) {
        return true;
      }
    }
    return false;
  };

  const isNodeAdded = addNodeRecursion(updatedSchema);

  return { updatedSchema, isNodeAdded, errorMessage };
};

export function moveNodeByIds(
  schema: Node[],
  overId: string,
  activeIds: string[] = []
): { updatedSchema: Node[]; isNodeMoved: boolean } {
  let isNodeMoved = false;
  if (!schema || !overId || !activeIds?.[0]) {
    return { updatedSchema: schema, isNodeMoved: false };
  } // Check if overId is falsy
  const realOverId = getRealNodeId(overId); // Extract last segment if structured

  let updatedSchema = _.cloneDeep(schema);

  for (const activeId of activeIds) {
    const realActiveId = getRealNodeId(activeId); // Extract last segment if structured

    if (realActiveId === realOverId) {
      continue;
    }

    // remove the node from the original position
    const { updatedSchema: updatedSchemaAfterNodeRemoved, removedNode } =
      removeNodeById(updatedSchema, realActiveId);

    if (!removedNode) {
      continue;
    }

    // add the node to the new position
    const { updatedSchema: updatedSchemaAfterNodeAdded, isNodeAdded } =
      addNodeById(updatedSchemaAfterNodeRemoved, realOverId, removedNode);

    // Update the schema if the node was successfully moved
    if (isNodeAdded) {
      isNodeMoved = true;
      updatedSchema = updatedSchemaAfterNodeAdded;
    }
  }

  return { updatedSchema, isNodeMoved };
}

export const getAllFileListFromSchema = (
  activeNode: Node | null,
  includeNestedChildFile = false
): Node[] => {
  const schema = activeNode?.children || [];
  const files: Node[] = [];
  if (includeNestedChildFile) {
    const traverse = (nodes: Node[]) => {
      for (const node of nodes) {
        if (node.type === "file") {
          files.push(node);
        }
        if (node.children && node.children.length > 0) {
          traverse(node.children);
        }
      }
    };

    traverse(schema);
  } else {
    const activeNodeChildren = activeNode?.children || [];
    for (const node of activeNodeChildren) {
      if (node.type === "file") {
        files.push(node);
      }
    }
  }

  return files;
};
