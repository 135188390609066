import CancelIcon from '@mui/icons-material/Cancel';
import CommentIcon from '@mui/icons-material/Comment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Button, CircularProgress, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useAppDispatch } from '../../../../../hooks/redux-hook';
import { CI } from '../../../../../models/CI';
import { hideProgressLine, showProgressLine } from '../../../../../redux/progress-line';
import { showSnackbar } from '../../../../../redux/snackbar';
import { putApiReviewedCI } from '../../../../../services/ciWorkFlow';
import { EditMenuItem } from '../actionMenuItem/editMenuItem';
import { PutOnHoldMenuItem } from '../actionMenuItem/putOnHoldMenuItem';
import { OnHoldCIModal } from '../onHoldCIModel';
import { RejectInReviewCIModal } from '../rejectInReviewCIModel';
import { ReviewApprovalModal } from './reviewApprovalModal';


type ReviewCIActionProps = {
  ciData: CI;
  onSuccess: () => void;
};

export const ReviewCIAction: React.FC<ReviewCIActionProps> = ({ ciData, onSuccess }) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [actionState, setActionState] = useState<string | null>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const onSubmitCI = () => {
    setLoading(true);
    dispatch(showProgressLine());
    putApiReviewedCI(ciData.id, { actionReason: '' })
      .then(() => {
        dispatch(showSnackbar({ message: 'Successfully submitted CI for approval', type: 'info' }));
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(() => {
        dispatch(showSnackbar({ message: 'Error submitting CI for approval', type: 'error' }));
      })
      .finally(() => {
        setLoading(false);
        dispatch(hideProgressLine());
      });
  };

  const onHoldCI = () => {
    setActionState('onHold');
    handleMenuClose();
  };

  const onReviewApproval = () => {
    setActionState('ReviewApprovalComment');
    handleMenuClose();
  };
  const rejectCI = () => {
    setActionState('rejectCI');
    handleMenuClose();
  };

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Button
        variant="contained"
        color="primary"
        onClick={onSubmitCI}
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} color="inherit" />}
        sx={{
          textTransform: 'none',
          borderRadius: 2,
        }}
      >
        Submit For Approval
      </Button>
      <Box>
        <Button
          onClick={handleMenuOpen}
          disabled={loading}
          sx={{
            minWidth: 0,
            padding: 1,
            borderRadius: '50%',
            backgroundColor: 'action.hover',
            '&:hover': { backgroundColor: 'action.selected' },
          }}
        >
          <MoreVertIcon />
        </Button>
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            elevation: 3,
            sx: {
              backgroundColor: '#1a1a1a',
              color: '#fff',
              borderRadius: '8px',
              minWidth: '200px',
              boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
              overflow: 'hidden',
            },
          }}
        >

          <MenuItem
            onClick={onReviewApproval}
            disabled={loading}
            sx={{
              '&:hover': {
                backgroundColor: '#555',
                color: '#fff',
                transition: 'all 0.3s ease',
              },
            }}
          >
            <CommentIcon sx={{ mr: 1 }} />
            Submit for Approval with Comment
          </MenuItem>

          <MenuItem
            onClick={rejectCI}
            disabled={loading}
            sx={{
              '&:hover': {
                backgroundColor: '#ff4d4d',
                color: '#fff',
                transition: 'all 0.3s ease',
              },
            }}
          >
            <CancelIcon sx={{ mr: 1 }} />
            Reject
          </MenuItem>
          <EditMenuItem onSuccess={onSuccess} ciData={ciData} handleMenuClose={handleMenuClose} loading={loading} />
          <PutOnHoldMenuItem onClick={onHoldCI} ciData={ciData} loading={loading} />
        </Menu>
      </Box>

      {actionState === 'ReviewApprovalComment' && (
        <ReviewApprovalModal
          open={true}
          onClose={() => setActionState(null)}
          ciId={ciData.id}
          onSuccess={onSuccess}
        />
      )}

      {actionState === 'onHold' && (
        <OnHoldCIModal
          open={true}
          onClose={() => setActionState(null)}
          ciId={ciData.id}
          onSuccess={onSuccess}
        />
      )}
      {actionState === 'rejectCI' && (
        <RejectInReviewCIModal
          open={true}
          onClose={() => setActionState(null)}
          ciId={ciData.id}
          onSuccess={onSuccess}
        />
      )}
    </Box>
  );
};
