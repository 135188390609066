import { CATEGORY_SPLITTER } from "../constants";
import { CI } from "../models/CI";
import { getApiApprovedCIs } from "../services/ci";

export const getAllCimApproverAnnotationsJson = async (pageCount: number) => {
    const approvedCIs = await getApiApprovedCIs().catch(() => [] as CI[]);
    const pages = Array(pageCount).fill("Sample");
    const annotations = [];    
    for (let page = 1; page <= pageCount; page++) {
        for (const ci of approvedCIs) {
            annotations.push({
                original: ci.knownCI,
                match_category: `${'cci'}${CATEGORY_SPLITTER}${ci.category?.name}` || "pd",
                page_no: page,
                start_word: Math.floor(Math.random() * 100),
                end_word: Math.floor(Math.random() * 100),
                start_char: Math.floor(Math.random() * 500),
                end_char: Math.floor(Math.random() * 500) + 10,
                frequency_seen: Math.floor(Math.random() * 20),
                frequency_redacted: Math.floor(Math.random() * 5),
                redaction_ratio: Math.random().toFixed(2),
                annotation_type: `${'cci'}${CATEGORY_SPLITTER}${ci.category?.name}` || "pd",
            });
        }
    }

    return { pages, annotations };
};

export const getAllConfidentialPatterns = (categories:{label: string, type: string}[]) => {
    return categories.reduce((result:string[], { label, type }) => {
      const [prefix] = type.split(CATEGORY_SPLITTER, 1);
      if (['cci', 'cbi'].includes(prefix?.toLowerCase())) {
        result.push(label);
      }
      return result;
    }, []);
  };