import { Box, LinearProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import { useCustomModal } from "../../pages/modals/custom-message-modal";
import { Project } from "../../models/Project";
import { useAppDispatch } from "../../hooks/redux-hook";
import {
  hideProgressLine,
  showProgressLine,
} from "../../redux/progress-line";
import { showSnackbar } from "../../redux/snackbar";
import Button from "@mui/material/Button";
import {
  startUploadingToS3,
  uploadFileUsingS3,
} from "../file-management";
import { StyledCloseIconUploader } from "../close-button";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import FolderIcon from "@mui/icons-material/Folder";
import {
  FileScanPayload,
  FileScanResponse,
  FileScanTagInfo,
  filesScanning,
  updateFileForVirusScanStatus,
} from "../../services/files";
import { FileState } from "../../models/FileState";
import List from "../common/List";
import { useAuthService } from "../../contexts/auth-context";

// ShowUpload
const SingleProjectShowUpload = ({
  project,
  refreshTable = () => {},
  projectFiles,
}: {
  project: Project;
  refreshTable: () => void;
  projectFiles: FileState[];
}) => {
    const auth = useAuthService();

  const dispatch = useAppDispatch();

  const { hideModal } = useCustomModal();
  const [failedFiles, setFailedFiles] = useState<any[]>([]);
  const [invalidCharacterFiles, setInvalidCharacterFiles] = useState<any[]>([]);
  const [containsFailedFiles, setContainsFailedFiles] = useState(false);
  const [containsInvalidCharacterFiles, setContainsInvalidCharacterFiles] =
    useState(false);
  const [waiting, setWaiting] = useState(false);
  const [isScanning, setIsScanning] = useState<boolean | undefined>(undefined);
  const [tags, setTags] = useState<FileScanResponse["tags"]>([]);
  
  const handleChangeStatus = ({ meta }: any, status: any) => {
    if (
      status === "removed" &&
      failedFiles.some(
        (failedFileName) =>
          filterFilename(failedFileName) === filterFilename(meta.name)
      )
    ) {
      failedFiles.splice(failedFiles.indexOf(meta.name), 1);
    }
    
    if (failedFiles.length > 0) {
      setContainsFailedFiles(true);
    } else {
      setContainsFailedFiles(false);
    }
    if (
      status === "removed" &&
      invalidCharacterFiles.some(
        (invalidFileName) =>
          filterFilename(invalidFileName) === filterFilename(meta.name)
      )
    ) {
      invalidCharacterFiles.splice(invalidCharacterFiles.indexOf(meta.name), 1);
    }
    if (invalidCharacterFiles.length > 0) {
      setContainsInvalidCharacterFiles(true);
    } else {
      setContainsInvalidCharacterFiles(false);
    }
  };
  function filterFilename(filename: string) {
    const fileParts = filename.split("/");
    return fileParts[fileParts.length - 1];
  }

  const handleSubmit = async (files: { file: any }[], allFiles: any[]) => {
    const controller = new AbortController();
    dispatch(showProgressLine());
    setWaiting(true);
    setIsScanning(undefined);
    const promises: any = [];
    //type === 1  && files.forEach((file: any) => promises.push(uploadFileUsingS3(file.file, project.id, auth.loginInfo?.tenant?.user?.id, false, false)));
    files.forEach((file: any) =>
      promises.push(
        startUploadingToS3(
          file.file,
          controller.signal,
          project.id,
          auth.loginInfo?.tenant?.user?.id,
          false,
          false
        )
      )
    );
    let payload = {
      files: [],
      projectId: project.id.toString(),
    } as FileScanPayload;
    const startScanning = async () => {
      setIsScanning(true);
      const response = await filesScanning(payload, controller.signal);
      if (response.isCompleted) {
        await stopScanning(response, payload);
        await refreshTable();
      } else {
        await startScanning();
      }
    };
    try {
      const response = await Promise.all(promises);
      payload = {
        ...payload,
        files: response.filter((file) => file !== null),
      };
      allFiles.forEach((f) => f.remove());
      dispatch(
        showSnackbar({
          message:
            "Files successfully uploaded. Virus scanning is processing...",
          type: "info",
        })
      );
      if (payload.files.length > 0) {
        await startScanning();
      }
      dispatch(hideProgressLine());
      setWaiting(false);
      await refreshTable();
    } catch (e) {
      setIsScanning(false);
      setWaiting(false);
      await refreshTable();
      dispatch(hideProgressLine());
      hideModal();
      dispatch(
        showSnackbar({ message: "Error uploading files", type: "error" })
      );
    }
  };

  const stopScanning = async (response: FileScanResponse, payload: any) => {
    setTags(response.tags);
    await updateFileStatus(response.tags);
    setIsScanning(false);
  };

  const updateFileStatus = async (tags: FileScanTagInfo[]) => {
    const controller = new AbortController();
    const infectedFiles = tags.filter((tag) => tag.tag === "infected");
    if (infectedFiles.length > 0) {
      const promises: any = [];
      infectedFiles.forEach((file: any) =>
        promises.push(
          updateFileForVirusScanStatus(
            { virusScanStatus: "infected", id: file.id },
            controller.signal
          )
        )
      );
      await Promise.all(promises);
    }
  };

  return (
    <div className="upload-file-modal-container">
      <div className="wizard-title">
        <StyledCloseIconUploader onClick={hideModal} />
        <span>{"RLS File Uploader"}</span>
      </div>
      {!isScanning && !waiting && (
        <Dropzone
          validate={(file) => {
            const isInCurrentProject = projectFiles.find(
              (projectFile: FileState) => projectFile.name === file.file.name
            );
            if (isInCurrentProject) {
              setFailedFiles([...failedFiles, file.file.name]);
              dispatch(
                showSnackbar({
                  message: "Error uploading files!",
                  type: "error",
                })
              );
              return "Error";
            } else if (file.file.name.includes("%")) {
              setInvalidCharacterFiles([
                ...invalidCharacterFiles,
                file.file.name,
              ]);
              dispatch(
                showSnackbar({
                  message: "Error uploading files!",
                  type: "error",
                })
              );
              return "Error";
            }
            return false;
          }}
          onChangeStatus={handleChangeStatus}
          onSubmit={handleSubmit}
          maxFiles={300}
          // .xlxs,, .xls .pdf, SAS, .xpt, Database (JDBC), and .deid (RLS Risk)
          accept={
            "application/pdf,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/x-sas-data,application/x-sas-xport,application/arx,.deid"
          }
          inputContent="Click here or drag and drop"
          submitButtonDisabled={(files) =>
            files.length < 1 ||
            failedFiles.length > 0 ||
            invalidCharacterFiles.length > 0 ||
            waiting
          }
          styles={{ dropzone: { minHeight: 450, minWidth: 400, padding: 8 } }}
        />
      )}
      {waiting && (
        <div className="custom">
          <p>{isScanning ? "Virus Scanning" : "Uploading"}</p>
          <LinearProgress className="linear-progress" />
          <small>please wait...</small>
        </div>
      )}
      {containsFailedFiles && (
        <p style={{ color: "#FF0000", marginLeft: 10 }}>
          Attention! The file you are attempting to upload({failedFiles[0]})
          already exists within this project in the file management system. To
          prevent redundancy and maintain an organized structure, we kindly
          request that you review the existing file before proceeding. You may
          consider renaming your file or making any necessary modifications to
          the existing one.
        </p>
      )}
      {containsInvalidCharacterFiles && (
        <p style={{ color: "#FF0000", marginLeft: 10 }}>
          Attention! The file you are attempting to upload (
          {invalidCharacterFiles[0]}) has invalid Characters (%) in its name.
          Please remove the invalid characters from the file name.
        </p>
      )}
      {isScanning === false && (
        <div className="custom">
          <Box sx={{ textAlign: "left", width: "100%" }}>
            <Typography variant="h6" paddingLeft="20px">
              Virus Scanning Summary:
            </Typography>
          </Box>
          <List
            lists={tags.map((tag) => ({
              text: tag.fileName,
              rightIconValue: tag.tag === "clean" ? "valid" : "invalid",
            }))}
            iconBefore={<FolderIcon />}
          />
          <Button variant="contained" onClick={hideModal}>
            Ok
          </Button>
        </div>
      )}
    </div>
  );
};

export default SingleProjectShowUpload;
