import React, { useEffect, useState } from "react";
import { ArrowBack, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import "../pages.scss";
import "./my-account.scss";
import { Avatar, Button, Checkbox, MenuItem, Radio, Select, SelectChangeEvent, Switch, TextField } from "@mui/material";
import { useCustomModal } from "../modals/custom-message-modal";
import { UserColorWizard } from "./wizards/user-color-wizard";
import { getUserInitials, updateLocalStorageUser, userSettingsToDb } from "../../services/utils";
import { useAuthService } from "../../contexts/auth-context";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hook";
import { loadSettings, userSettingsLoadedSelector, userSettingsSelector } from "../../redux/userSettings";
import TimezoneSelect from 'react-timezone-select';
import { ChangeNameUserModal } from "../modals/modal-content/change-name-user-modal";
import { ChangeUserPassword } from "../modals/modal-content/change-user-password";
import { putApiUsers } from "../../services/rls.user";
import { UserSettings } from "../../models/UserSettings";
import { hideProgressLine, showProgressLine } from "../../redux/progress-line";
// @ts-ignore
import DOMPurify from 'dompurify';

export function MyAccount() {

    const auth = useAuthService();
    const userSettings = useAppSelector((state) => userSettingsSelector(state));
    const userSettingsLoaded = useAppSelector((state) => userSettingsLoadedSelector(state));
    const dispatch = useAppDispatch();
    const user = auth.loginInfo?.user;
    let navigate = useNavigate();
    const { showModal, hideModal } = useCustomModal();

    const [userColor, setUserColor] = useState<string>();
    const [timezone, setTimezone] = useState<string>();
    const [timeFormat, setTimeFormat] = useState<number>();
    const [dateFormat, setDateFormat] = useState<string>();
    const [emailNotification, setEmailNotification] = useState<boolean>();
    const [inAppNotification, setInAppNotification] = useState<boolean>();
    const [notificationStartTime, setNotificationStartTime] = useState<string>();
    const [notificationStartTimeAmPm, setNotificationStartTimeAmPm] = useState<string>();
    const [notificationEndTime, setNotificationEndTime] = useState<string>();
    const [notificationEndTimeAmPm, setNotificationEndTimeAmPm] = useState<string>();
    const [emailTasksNotification, setEmailTasksNotification] = useState<boolean>();
    const [emailMentionsNotification, setEmailMentionsNotification] = useState<boolean>();
    const [inAppTasksNotification, setInAppTasksNotification] = useState<boolean>();
    const [inAppMentionsNotification, setInAppMentionsNotification] = useState<boolean>();

    useEffect(() => {
        if (userSettingsLoaded && userColor === undefined) {
            setUserColor(userSettings.color);
            setTimezone(userSettings.regional.timezone);
            setTimeFormat(userSettings.regional.timeFormat);
            setDateFormat(userSettings.regional.dateFormat);
            setEmailNotification(userSettings.notifications.email.enabled);
            setInAppNotification(userSettings.notifications.inApp.enabled);
            setNotificationStartTime(userSettings.notifications.email.startTime);
            setNotificationStartTimeAmPm(userSettings.notifications.email.startTimeAmPm);
            setNotificationEndTime(userSettings.notifications.email.endTime);
            setNotificationEndTimeAmPm(userSettings.notifications.email.endTimeAmPm);
            setEmailTasksNotification(userSettings.notifications.email.taskUpdates);
            setEmailMentionsNotification(userSettings.notifications.email.mentions);
            setInAppTasksNotification(userSettings.notifications.inApp.taskUpdates);
            setInAppMentionsNotification(userSettings.notifications.inApp.mentions);
        }
        if (userSettingsLoaded && userColor !== undefined && timezone !== undefined && timeFormat !== undefined
            && dateFormat !== undefined && emailNotification !== undefined && notificationStartTimeAmPm !== undefined
            && notificationEndTimeAmPm !== undefined && emailTasksNotification !== undefined
            && emailMentionsNotification !== undefined && inAppNotification !== undefined
            && inAppTasksNotification !== undefined && inAppMentionsNotification !== undefined) {
            const settings: UserSettings = {
                ...userSettings,
                color: userColor,
                regional: {
                    timezone,
                    timeFormat,
                    dateFormat
                },
                notifications: {
                    email: {
                        enabled: emailNotification,
                        startTime: userSettings.notifications.email.startTime,
                        startTimeAmPm: notificationStartTimeAmPm,
                        endTime: userSettings.notifications.email.endTime,
                        endTimeAmPm: notificationEndTimeAmPm,
                        taskUpdates: emailTasksNotification,
                        mentions: emailMentionsNotification
                    },
                    inApp: {
                        enabled: inAppNotification,
                        taskUpdates: inAppTasksNotification,
                        mentions: inAppMentionsNotification
                    }
                }
            }
            updateSettings(userSettings, settings);
        }
    });

    const updateSettings = (userSettings: UserSettings, settings: UserSettings) => {
        if (JSON.stringify(userSettings) !== JSON.stringify(settings)) {
            const controller = new AbortController();
            dispatch(showProgressLine());
            putApiUsers(userSettingsToDb(settings, auth.loginInfo?.tenant?.user), controller.signal)
                .then(() => dispatch(loadSettings(settings)))
                .then(() => updateLocalStorageUser(settings, auth.loginInfo!))
                .then(() => dispatch(hideProgressLine()))
                .catch(() => {
                    dispatch(loadSettings(userSettings));
                    dispatch(hideProgressLine());
                });
        }
    }

    const updateEmailNotificationInterval = () => {
        const settings: UserSettings = {
            ...userSettings,
            notifications: {
                ...userSettings.notifications,
                email: {
                    ...userSettings.notifications.email,
                    startTime: notificationStartTime!,
                    endTime: notificationEndTime!,
                }
            }
        }
        updateSettings(userSettings, settings);
    }


    const updateUserColor = (color: string) => {
        setUserColor(color || userColor);
        hideModal();
    }

    const changeUserColor = () => showModal(UserColorWizard,
        { initials: getUserInitials(user?.name), color: userColor!, setColor: updateUserColor });

    const changePassword = () => showModal(ChangeUserPassword, {isChangeScheduled: false});

    const updateName = (name: string) => {
        //Remove anything from the new username that could be an XSS attack. In general, React handles sanitizing user
        //input so we don't need to worry about it. The risk here is that usernames can be directly added to the inner html
        //as tags in comments (see comments.tsx). We sanitize the string there too, but I'll keep this here to be doubly safe.
        const sanitizedName: string = DOMPurify.sanitize(name)
        const prevUser = auth.loginInfo?.tenant?.user;
        const user = {
            ...prevUser,
            name: sanitizedName,
            email: prevUser!.email,
            color: prevUser!.color,
            timezone: prevUser!.timezone,
            dateFormat: prevUser!.dateFormat,
            timeFormat: prevUser!.timeFormat,
            emailNotifications: prevUser!.emailNotifications,
            inAppNotifications: prevUser!.inAppNotifications,
            emailTaskUpdates: prevUser!.emailTaskUpdates,
            emailMentions: prevUser!.emailMentions,
            inAppTaskUpdates: prevUser!.inAppTaskUpdates,
            inAppMentions: prevUser!.inAppMentions,
            emailNotificationsStartTime: prevUser!.emailNotificationsStartTime,
            emailNotificationsEndTime: prevUser!.emailNotificationsEndTime,
            id: prevUser!.id
        }
        const loginInfo = {
            ...auth.loginInfo,
            tenant: {
                ...auth.loginInfo?.tenant,
                user
            }
        }
        const controller = new AbortController();
        putApiUsers(userSettingsToDb(userSettings, user), controller.signal)
            .then(() => updateLocalStorageUser(userSettings, loginInfo))
            .then(() => auth.changeUserName(sanitizedName));
    }

    const editName = () => showModal(ChangeNameUserModal, { updateName });

    const changeTimeFormat = (event: React.ChangeEvent<HTMLInputElement>) => setTimeFormat(+event.target.value);

    const changeDateFormat = (event: React.ChangeEvent<HTMLInputElement>) => setDateFormat(event.target.value);

    const handleEmailNotification = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!emailNotification && event.target.checked) {
            setEmailMentionsNotification(true);
            setEmailTasksNotification(true);
        }
        if (emailNotification && !event.target.checked) {
            setEmailMentionsNotification(false);
            setEmailTasksNotification(false);
        }
        setEmailNotification(event.target.checked);
    }

    const handleInAppNotification = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!inAppNotification && event.target.checked) {
            setInAppMentionsNotification(true);
            setInAppTasksNotification(true);
        }
        if (inAppNotification && !event.target.checked) {
            setInAppMentionsNotification(false);
            setInAppTasksNotification(false);
        }
        setInAppNotification(event.target.checked);
    }

    const constructTime = (time: string, key: string) => {
        if (time.length === 2) {
            time = time + ":";
        }
        time += key;
        if (time.length === 2) {
            time = time + ":";
        }
        return time;
    }

    const handleNotificationStartTime = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const key = event.key;
        if (key === "Backspace") {
            setNotificationStartTime(notificationStartTime!.substring(0, notificationStartTime!.length -
                (notificationStartTime!.length === 4 ? 2 : 1)));
        } else {
            if (notificationStartTime!.length === 5 || isNaN(+key) || (notificationStartTime!.length === 0 && +key > 1)
                || ((notificationStartTime!.length === 2 || notificationStartTime!.length === 3) && +key > 5)) {
                return;
            }
            const time = constructTime(notificationStartTime!, key);
            if (time.length > 2 && (+time.substring(0, 1) < 0 || +time.substring(0, 1) > 12)) {
                return;
            }
            setNotificationStartTime(time);
        }
    }

    const updateNotificationStartTime = () => {
        if (notificationStartTime!.length !== 5) {
            setNotificationStartTime(userSettings.notifications.email.startTime)
        } else {
            updateEmailNotificationInterval();
        }
    }

    const updateNotificationEndTime = () => {
        if (notificationEndTime!.length !== 5) {
            setNotificationEndTime(userSettings.notifications.email.endTime)
        } else {
            updateEmailNotificationInterval();
        }
    }

    const handleNotificationStartTimeAmPm = (event: SelectChangeEvent) => setNotificationStartTimeAmPm(event.target.value as string);

    const handleNotificationEndTime = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const key = event.key;
        if (key === "Backspace") {
            setNotificationEndTime(notificationEndTime!.substring(0, notificationEndTime!.length -
                (notificationEndTime!.length === 4 ? 2 : 1)));
        } else {
            if (notificationEndTime!.length === 5 || isNaN(+key) || (notificationEndTime!.length === 0 && +key > 1)
                || (notificationEndTime!.length === 3 && +key > 5)) {
                return;
            }
            const time = constructTime(notificationEndTime!, key);
            if (time.length > 2 && (+time.substring(0, 1) < 0 || +time.substring(0, 1) > 12)) {
                return;
            }
            setNotificationEndTime(time);
        }
    }

    const handleNotificationEndTimeAmPm = (event: SelectChangeEvent) => setNotificationEndTimeAmPm(event.target.value as string);

    return !userSettingsLoaded || userColor === undefined ? <div className='page'></div> :
        <div className='page-wrapper' style={{ paddingTop: 24 }}>
            <div className='page-header'>
                <ArrowBack className='arrow-back' onClick={() => navigate(-1)} /> My Account
            </div>
            <div style={{ display: 'flex' }}>
                <div className='widget'>
                    <span className='widget-title'>User settings</span>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 32 }}>
                        <Avatar sx={{ bgcolor: userColor }}>{getUserInitials(auth.loginInfo?.tenant?.user?.name)}</Avatar>
                        <span className='user-color' onClick={changeUserColor}>CHANGE USER COLOR</span>
                    </div>
                    <div className='settings-row'>
                        <div className='left-column'>Full name:</div><div>{auth.loginInfo?.tenant?.user?.name}</div>
                        <Edit className='edit-icon' onClick={editName} />
                    </div>
                    <div className='settings-row'>
                        <div className='left-column'>Company:</div><div>{userSettings.company}</div>
                    </div>
                    <div className='settings-row'>
                        <div className='left-column'>Role:</div><div>{user?.role}</div>
                    </div>
                    <div className='settings-row'>
                        <div className='left-column'>Email:</div><div>{user?.email}</div>
                    </div>
                    <Button variant='contained' color='secondary' className='change-password'
                            onClick={changePassword}>
                        CHANGE PASSWORD
                    </Button>
                </div>
                <div style={{ width: 32 }} />
                <div className='widget'>
                    <span className='widget-title'>Regional settings</span>
                    <div className='regional-row'>Timezone</div>
                    <TimezoneSelect
                        value={timezone!}
                        onChange={(tz) => setTimezone(tz.value)}
                        className='timezone-select'
                    />
                    <div className='regional-row'>Time format</div>
                    <div className="radio-row">
                        <Radio
                            checked={timeFormat === 24}
                            onChange={changeTimeFormat}
                            value="24"
                            name="radio-time-format"
                        />
                        <div style={{ flex: 1 }}>24 hour</div>
                    </div>
                    <div className="radio-row">
                        <Radio
                            checked={timeFormat === 12}
                            onChange={changeTimeFormat}
                            value="12"
                            name="radio-time-format"
                        />
                        <div style={{ flex: 1 }}>12 hour</div>
                    </div>
                    <div className='regional-row mt-12'>Date format</div>
                    <div className="radio-row">
                        <Radio
                            checked={dateFormat === "MM/DD/YYYY"}
                            onChange={changeDateFormat}
                            value="MM/DD/YYYY"
                            name="radio-date-format"
                        />
                        <div style={{ flex: 1 }}>MM/DD/YYYY</div>
                    </div>
                    <div className="radio-row">
                        <Radio
                            checked={dateFormat === "DD/MM/YYYY"}
                            onChange={changeDateFormat}
                            value="DD/MM/YYYY"
                            name="radio-date-format"
                        />
                        <div style={{ flex: 1 }}>DD/MM/YYYY</div>
                    </div>
                    <div className="radio-row">
                        <Radio
                            checked={dateFormat === "YYYY/MM/DD"}
                            onChange={changeDateFormat}
                            value="YYYY/MM/DD"
                            name="radio-date-format"
                        />
                        <div style={{ flex: 1 }}>YYYY/MM/DD</div>
                    </div>
                </div>
            </div>
            {(auth.hasUserRoleAccess() || auth.hasCIMRoleAccess()) && <div className='widget-large'>
                <span className='widget-title'>Notification settings</span>
                <div style={{ display: "flex" }}>
                    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <h6>Email notifications</h6>
                        <div className='settings-row' style={{ alignItems: 'center' }}>
                            <div className='notification-left-column'>Receive email notifications</div>
                            <Switch checked={emailNotification} onChange={handleEmailNotification}
                                    color="secondary" id="email-notification" />
                        </div>
                        <div style={{ marginBottom: 16 }}>Notify me from</div>
                        <div style={{ display: "flex" }}>
                            <TextField value={notificationStartTime} onKeyUp={handleNotificationStartTime} onBlur={updateNotificationStartTime}
                                       className="time-input" inputProps={{ style: { height: 11 } }} />
                            <Select id='start-time-am-pm' className='am-pm-select'
                                    value={notificationStartTimeAmPm} onChange={handleNotificationStartTimeAmPm}>
                                <MenuItem value='am'>AM</MenuItem>
                                <MenuItem value='pm'>PM</MenuItem>
                            </Select>
                            <div className='time-to-text'>to</div>
                            <TextField value={notificationEndTime} onKeyUp={handleNotificationEndTime} onBlur={updateNotificationEndTime}
                                       className="time-input" inputProps={{ style: { height: 11 } }} />
                            <Select id='end-time-am-pm' className='am-pm-select'
                                    value={notificationEndTimeAmPm} onChange={handleNotificationEndTimeAmPm}>
                                <MenuItem value='am'>AM</MenuItem>
                                <MenuItem value='pm'>PM</MenuItem>
                            </Select>
                        </div>
                        <div style={{ marginBottom: 16, marginTop: 32 }}>Send me email notifications for:</div>
                        <div className='regional-row'>
                            <Checkbox color='secondary' className='notification-checkbox'
                                      checked={emailTasksNotification}
                                      onChange={() => {
                                          if (emailTasksNotification && !emailMentionsNotification) {
                                              setEmailNotification(false);
                                          }
                                          else if (!emailTasksNotification) setEmailNotification(true);
                                          setEmailTasksNotification(!emailTasksNotification);
                                      }}
                            />
                            <div>Task updates<br />
                                <span style={{ fontSize: 15 }}>
                                You’ll be notified when there’s a comment on a task <br />
                                you’re assigned, you’re assigned a task, or you’re <br />
                                added as a collaborator to a task
                            </span>
                            </div>
                        </div>
                        <div className='regional-row'>
                            <Checkbox color='secondary' className='notification-checkbox'
                                      checked={emailMentionsNotification}
                                      onChange={() => {
                                          if (!emailTasksNotification && emailMentionsNotification) {
                                              setEmailNotification(false);
                                          } else if (!emailMentionsNotification) setEmailNotification(true);
                                          setEmailMentionsNotification(!emailMentionsNotification)
                                      }}
                            />
                            <div>Mentions only<br />
                                <span style={{ fontSize: 15 }}>You’ll be notified when someone mentions you</span>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: 32 }} />
                    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <h6>In-app notifications</h6>
                        <div className='regional-row' style={{ alignItems: 'center' }}>
                            <div className='notification-left-column'>Receive in-app notifications</div>
                            <Switch checked={inAppNotification} onChange={handleInAppNotification}
                                    color="secondary" id="inApp-notification" />
                        </div>
                        <div style={{ marginBottom: 16, marginTop: 16 }}>Send me in-app notifications for:</div>
                        <div className='regional-row'>
                            <Checkbox color='secondary' className='notification-checkbox'
                                      checked={inAppTasksNotification}
                                      onChange={() => {
                                          if (inAppTasksNotification && !inAppMentionsNotification) {
                                              setInAppNotification(false);
                                          }
                                          else if (!inAppTasksNotification) setInAppNotification(true);
                                          setInAppTasksNotification(!inAppTasksNotification)
                                      }}
                            />
                            <div>Task updates<br />
                                <span style={{ fontSize: 15 }}>
                                You’ll be notified when there’s a comment on a task <br />
                                you’re assigned, you’re assigned a task, or you’re <br />
                                added as a collaborator to a task
                            </span>
                            </div>
                        </div>
                        <div className='regional-row'>
                            <Checkbox color='secondary' className='notification-checkbox'
                                      checked={inAppMentionsNotification}
                                      onChange={() => {
                                          if (inAppMentionsNotification && !inAppTasksNotification) {
                                              setInAppNotification(false);
                                          }
                                          else if (!inAppMentionsNotification) setInAppNotification(true);
                                          setInAppMentionsNotification(!inAppMentionsNotification)
                                      }}
                            />
                            <div>Mentions only<br />
                                <span style={{ fontSize: 15 }}>You’ll be notified when someone mentions you</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
}
