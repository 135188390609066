import React from 'react';
import { Choice } from '@pdftron/webviewer-react-toolkit';
import './RedactionSearchResult.scss';
import classNames from 'classnames';
import {redactionTypeMap} from "../../../constants";

const displayRedactionSearchResult = (props) => {
    const { ambientStr, resultStrStart, resultStrEnd, resultStr, type } = props;

    if (type === redactionTypeMap['TEXT'] || !(type in redactionTypeMap) ) { // //If the type is a user added pattern we also want to show the before and after text

        let textBeforeSearchValue = ''
        let textAfterSearchValue = ''

        if (ambientStr?.slice(resultStrStart, resultStrEnd) === resultStr) {
            textBeforeSearchValue = ambientStr.slice(0, resultStrStart);
            textAfterSearchValue = ambientStr.slice(resultStrEnd);
        } else {
            if (ambientStr.includes(resultStr)) { //After the user manually modifies the redaction, the resultStr will be correct but the resultStrStart and resultStrEnd will be incorrect.
                const adjustedStart = ambientStr.indexOf(resultStr);
                const adjustedEnd = adjustedStart + resultStr.length;
                textBeforeSearchValue = ambientStr.slice(0, adjustedStart);
                textAfterSearchValue = ambientStr.slice(adjustedEnd);
            }
        }

        return (
            <>
                {textBeforeSearchValue}
                <span className="search-value">{resultStr}</span>
                {textAfterSearchValue}
            </>
        );
    }
    return resultStr;
};

const RedactionSearchResult = (props) => {
    const {
        isChecked,
        onChange,
        onClickResult,
        isActive,
        type,
        index
    } = props;

    const displayResult = displayRedactionSearchResult(props);
    const searchResultClassname = classNames('redaction-search-result', { 'active': isActive });

    return (
        <div className={searchResultClassname} role="listitem" onClick={onClickResult} id={`search-result-${index}`}>
            <div style={{paddingRight: '14px'}}>
                <input type={"checkbox"}
                       checked={isChecked ?? false}
                       onChange={onChange}
                />
            </div>
            {/*<div style={{ paddingRight: '14px' }}>*/}
            {/*    <Icon glyph={icon} />*/}
            {/*</div>*/}
            <div className="redaction-search-result-info">
                {displayResult}
            </div>
            <div className="redaction-item-date-author">
                {type}
            </div>
        </div>
    );
};

export default React.memo(RedactionSearchResult);
