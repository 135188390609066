import React, { useState } from 'react';
import { Modal, Box, TextField, Button, CircularProgress, Typography } from '@mui/material';
import { useAppDispatch } from '../../../../../hooks/redux-hook';
import { showSnackbar } from '../../../../../redux/snackbar';
import { putApiReviewedCI } from '../../../../../services/ciWorkFlow';

type ReviewApprovalModalProps = {
  open: boolean;
  onClose: () => void;
  ciId: number;
  onSuccess: () => void;
};

export const ReviewApprovalModal: React.FC<ReviewApprovalModalProps> = ({
  open,
  onClose,
  ciId,
  onSuccess,
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');

  const handleSubmitApproval = () => {
    if (!comment.trim()) {
      dispatch(showSnackbar({ message: "Comment is required", type: "warning" }));
      return;
    }

    setLoading(true);
    putApiReviewedCI(ciId, { actionReason: comment })
      .then(() => {
        dispatch(showSnackbar({
          message: "Successfully submitted for approval with comment",
          type: "info",
        }));
        onSuccess();
      })
      .catch(() => {
        dispatch(showSnackbar({
          message: "Error submitting for approval with comment",
          type: "error",
        }));
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '400px',
          bgcolor: 'background.paper',
          padding: 4,
          borderRadius: 2,
          boxShadow: 24,
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Submit for Approval with Comment
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: 2, color: 'text.secondary' }}>
          Please note: Your comment will be visible to the approver to provide additional context.
        </Typography>
        <TextField
          label="Comment *"
          multiline
          rows={4}
          fullWidth
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <Box sx={{ marginTop: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitApproval}
            disabled={loading}
            fullWidth
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Submit for Approval"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
