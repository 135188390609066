import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Button, CircularProgress, Menu, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useAppDispatch } from '../../../../../hooks/redux-hook';
import { CI } from '../../../../../models/CI';
import { hideProgressLine, showProgressLine } from '../../../../../redux/progress-line';
import { showSnackbar } from '../../../../../redux/snackbar';
import { putApiSubmitReviewCI } from '../../../../../services/ciWorkFlow';
import { EditMenuItem } from '../actionMenuItem/editMenuItem';
import { PutOnHoldMenuItem } from '../actionMenuItem/putOnHoldMenuItem';
import { OnHoldCIModal } from '../onHoldCIModel';

type SubmitCIActionProps = {
  ciData: CI;
  onSuccess: () => void;
};

export const SubmitCIAction: React.FC<SubmitCIActionProps> = ({ ciData, onSuccess }) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [actionState, setActionState] = useState<string | null>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const onSubmitCI = () => {
    setLoading(true);
    dispatch(showProgressLine());
    putApiSubmitReviewCI(ciData.id, { actionReason: '' })
      .then(() => {
        dispatch(showSnackbar({ message: 'Successfully submitted CI for review', type: 'info' }));
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(showSnackbar({ message: error.message, type: 'error' }));
      })
      .finally(() => {
        setLoading(false);
        dispatch(hideProgressLine());
      });
  };

  const onHoldCI = () => {
    setActionState('onHold');
    handleMenuClose();
  };

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Button
        variant="contained"
        color="primary"
        onClick={onSubmitCI}
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} color="inherit" />}
      >
        Submit For Review
      </Button>

      <Box>
        <Tooltip title="More actions" arrow>
          <Button onClick={handleMenuOpen} disabled={loading} sx={{
            minWidth: 0,
            padding: 1,
            borderRadius: '50%',
            backgroundColor: 'action.hover',
            '&:hover': { backgroundColor: 'action.selected' },
          }}>
            <MoreVertIcon />
          </Button>
        </Tooltip>
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            elevation: 3,
            sx: {
              backgroundColor: '#1a1a1a',
              color: '#fff',
              borderRadius: '8px',
              minWidth: '200px',
              boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
              overflow: 'hidden',
            },
          }}
        >
          <EditMenuItem onSuccess={onSuccess} ciData={ciData} handleMenuClose={handleMenuClose} loading={loading} />

          <PutOnHoldMenuItem onClick={onHoldCI} ciData={ciData} loading={loading} />
        </Menu>
      </Box>

      {actionState === 'onHold' && (
        <OnHoldCIModal
          open={true}
          onClose={() => setActionState(null)}
          ciId={ciData.id}
          onSuccess={onSuccess}
        />
      )}

    </Box>
  );
};
