import { FileState } from "../models/FileState";
import {baseUrl, getHeadersWithoutJSON, headers} from "./config";
import { fetchAndParse } from "./utils";
import {PreSignedS3UploadUrl} from "../models/PreSignedS3UploadUrl";
import {LargeFileUpload} from "../models/LargeFileUpload";
import {FontUrlResponse} from "../models/FontUrlResponse";
export interface FileScanPayload{
    files: Array<{name: string, id: number}> | [];
    file: string;
    projectId: string
}
export interface FileScanTagInfo{
    fileName:string;
    tag:string;
    id: number
}

export interface FileScanResponse{
    tags: FileScanTagInfo[];
    isCompleted: boolean
}

export function getApiFilesByProjectId(projectId: number, signal?: AbortSignal): Promise<FileState[]> {
    const url = new URL(`/api/files/project/${projectId}`, baseUrl);
    return fetchAndParse<FileState[]>(url.toString(), { method: "GET", headers, signal });
}

export function getApiFilesWithURLByTaskId(taskId: number, signal?: AbortSignal): Promise<FileState[]> {
    const url = new URL(`/api/files/task/${taskId}`, baseUrl);
    return fetchAndParse<FileState[]>(url.toString(), { method: "GET", headers, signal });
}

export function postApiFile(body?: FormData, signal?: AbortSignal): Promise<FileState> {
    const url = new URL(`/api/files/create`, baseUrl);
    return fetchAndParse(url.toString(), { method: "POST", body: body, headers: getHeadersWithoutJSON(), signal })
}

export function requestUploadUrlForFile(): Promise<PreSignedS3UploadUrl>{
    //https://aws.amazon.com/blogs/developer/generate-presigned-url-modular-aws-sdk-javascript/
    const url = new URL(`/api/files/new-upload-url`, baseUrl)
    return fetchAndParse<PreSignedS3UploadUrl>(url.toString(), {method: "GET", headers})
}

export function putFileToS3PreSignedUrl(url:string, body: any, signal?: AbortSignal): Promise<FileState>{
    return fetchAndParse(url, {method: "PUT", body, signal})
}

export function commitApiFile(params: LargeFileUpload): Promise<FileState>{
    const url = new URL(`/api/files/commit`, baseUrl)
    return fetchAndParse(url.toString(), {method: "POST", body: JSON.stringify(params), headers})
}

export function putApiSaveFile(projectId: string, body?: FormData, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/files/project/${projectId}/save`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: body, headers: getHeadersWithoutJSON(), signal });
}

export function getFileTagByProjectIdAndFileName(projectId: number | undefined, fileName: string, signal?: AbortSignal): Promise<any> {
    const url = new URL(`/api/files/project/${projectId}/${getEncodedFileName(fileName)}/getTag`, baseUrl);
    return fetchAndParse<any>(url.toString(), { method: "GET", headers: getHeadersWithoutJSON(), signal });
}

export function listFileVersions(projectId: string | undefined, fileName: string, body?: FormData, signal?: AbortSignal): Promise<any[]> {
    const url = new URL(`/api/files/listVersions/${projectId}/${getEncodedFileName(fileName)}`, baseUrl);
    return fetchAndParse<any>(url.toString(), { method: "GET", body: body, headers: getHeadersWithoutJSON(), signal });
}

export function getFileVersionUrl(projectId: string | undefined, fileName: string, versionId: string, body?: FormData, signal?: AbortSignal): Promise<PreSignedS3UploadUrl> {
    const url = new URL(`/api/files/project/${projectId}/${getEncodedFileName(fileName)}/${versionId}`, baseUrl);
    return fetchAndParse<PreSignedS3UploadUrl>(url.toString(), { method: "GET", body: body, headers: getHeadersWithoutJSON(), signal });
}

export function getFilesByIds(ids: number[], signal?: AbortSignal): Promise<FileState[]> {
    const url = new URL(`/api/files/by-ids`, baseUrl);
    return fetchAndParse<FileState[]>(url.toString(), { method: "POST", body: JSON.stringify(ids), headers, signal });
}

export function getFileById(fileId: number, body?: FormData, signal?: AbortSignal): Promise<FileState> {
    const url = new URL(`/api/files/${fileId}`, baseUrl);
    return fetchAndParse<FileState>(url.toString(), { method: "GET", body: body, headers: getHeadersWithoutJSON(), signal });
}

export function getFontUrl(name: string, signal?: AbortSignal): Promise<FontUrlResponse> {
    const url = new URL(`/api/fonts/${name}/url`, baseUrl);
    return fetchAndParse<FontUrlResponse>(url.toString(), { method: "GET", headers: getHeadersWithoutJSON(), signal });
}

export function putApiFileById(id: string, body?: any, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/files/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}

export function createLogFileByProjectId(id: string, body?: FormData, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/logs/project/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: body, headers: getHeadersWithoutJSON(), signal });
}
export function getLogFilesByProjectIdAndFilename(id: string, filename: string, signal?: AbortSignal): Promise<any[]> {
    const url = new URL(`/api/logs/project/${id}/${getEncodedFileName(filename)}`, baseUrl);
    return fetchAndParse<any>(url.toString(), { method: "GET", headers, signal });
}
export function updateFileTagByVersionId(id: string, filename: string, versionId: string | undefined, body: string, signal?: AbortSignal): Promise<any[]> {
    const url = new URL(`/api/files/project/${id}/${getEncodedFileName(filename)}/${versionId}`, baseUrl);
    return fetchAndParse<any>(url.toString(), { method: "PUT",headers: getHeadersWithoutJSON(), body: body, signal });
}
export function deleteApiFileById(id: number, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/files/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "DELETE", headers, signal });
}
//TEXT REFLOW
export async function onTextReflow(data: any, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/reflow`, baseUrl);
    return fetchAndParse(url.toString(), {method: "POST", headers, body: JSON.stringify(data), signal});
}
export async function reflowCompletenessStatusCheck(data: Record<string, string | number>, signal?: AbortSignal): Promise<Record<string, any>> {
    const url = new URL(`/api/reflow-finished`, baseUrl);
    return fetchAndParse(url.toString(), {method: "POST", headers, body: JSON.stringify(data), signal});
}
export async function destroyTemporaryReflowAsset(data: Pick<Record<string, string | boolean>, string>, signal?: AbortSignal): Promise<Record<string, string>> {
    const url = new URL(`/api/reflow-finalize`, baseUrl);
    return fetchAndParse(url.toString(), {method: "PUT", headers, body: JSON.stringify(data), signal});
}
//sanitization
export async function sanitizePDFFIle(data: any, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/sanitize`, baseUrl);
    return fetchAndParse(url.toString(), {method: "POST", headers, body: JSON.stringify(data), signal});
}
export async function sanitizeCompletenessStatusCheck(data: Record<string, string | number>, signal?: AbortSignal): Promise<Record<string, string>> {
    const url = new URL(`/api/sanitize-finished`, baseUrl);
    return fetchAndParse(url.toString(), {method: "POST", headers, body: JSON.stringify(data), signal});
}
export async function destroyTemporarySanitizeAsset(data: Pick<Record<string, string | boolean>, string>, signal?: AbortSignal): Promise<Record<string, string>> {
    const url = new URL(`/api/sanitize-finalize`, baseUrl);
    return fetchAndParse(url.toString(), {method: "PUT", headers, body: JSON.stringify(data), signal});
}


export async function ocrPDFFIle(data: {filename: string, projectId: string}, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/ocr-file`, baseUrl);
    return fetchAndParse(url.toString(), {method: "POST", headers, body: JSON.stringify(data), signal});
}
export async function initiateUploading(data: any, signal?: AbortSignal): Promise<Record<string, string | number>> {
    const url  = new URL(`/api/files/start-upload`, baseUrl);
    return fetchAndParse(url.toString(), {method: "POST", headers, body: JSON.stringify(data), signal});
}

export async function generatePreSignedURL(data: any, signal?: AbortSignal): Promise<Record<string, string | number>> {
    const url  = new URL(`/api/files/generate-pre-signed-url`, baseUrl);
    return fetchAndParse(url.toString(), {method: "POST", headers, body: JSON.stringify(data), signal});
}
export async function finishUploadingFile(data:Record<string, any>, signal?: AbortSignal): Promise<FileState> {
    const url = new URL(`/api/files/finish-uploading`, baseUrl);
    return fetchAndParse(url.toString(), {method: "POST", headers, body: JSON.stringify(data), signal});
}

//This returns 202 when the job is successfully started. The job status can be checked using saveCompletenessStatusCheck.
export async function saveAnnotationsToFile(data: {projectId: number, fileName: string, fileId: number, isTemporaryLocalChanges: boolean}, signal?: AbortSignal): Promise<string> {
    const url = new URL(`/api/files/save-annotations`, baseUrl);
    return fetchAndParse(url.toString(), {method: "POST", headers, body: JSON.stringify(data), signal});
}

export async function saveCompletenessStatusCheck(data: {jobId: string}, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/savefinished`, baseUrl);
    return fetchAndParse(url.toString(), {method: "POST", headers, body: JSON.stringify(data), signal});
}

export async function filesScanning(fileScanPayload: FileScanPayload, signal?: AbortSignal): Promise<FileScanResponse> {
    const url = new URL(`/api/files/virusScanning`, baseUrl);
    return fetchAndParse(url.toString(), {method: "POST", headers, body:JSON.stringify(fileScanPayload), signal});
}
export async function filesOptimisation(optimisePayload: FileScanPayload, signal?: AbortSignal): Promise<{status: string}> {
    const url = new URL(`/api/files/optimisation`, baseUrl);
    return fetchAndParse(url.toString(), {method: "POST", headers, body:JSON.stringify(optimisePayload), signal});
}
export async function filesOptimisationStatus(optimisePayload: FileScanPayload, signal?: AbortSignal): Promise<{status: string}> {
    const url = new URL(`/api/files/optimisation/status`, baseUrl);
    return fetchAndParse(url.toString(), {method: "POST", headers, body:JSON.stringify(optimisePayload), signal});
}
export async function updateFileForVirusScanStatus(data: {virusScanStatus: string, id: number}, signal?: AbortSignal): Promise<any> {
    const url = new URL(`/api/files/${data.id}/virusstatus`, baseUrl);
    return fetchAndParse(url.toString(), {method: "PUT", headers, body:JSON.stringify(data), signal});
}
export async function ocrCompletenessStatusCheck(data: {filename: string, projectId: string}, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/ocrfinished`, baseUrl);
    return fetchAndParse(url.toString(), {method: "POST", headers, body: JSON.stringify(data), signal});
}

export function putApiFileOpenStatusById(id: number, body?: any, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/files/${id}/open`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify({isDocOpen: false}), headers, signal });
}

export function deleteTemporaryLocalChangesFileById(id: number, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/files/${id}/temporaryLocal`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "DELETE", body: JSON.stringify({isDocOpen: false}), headers, signal });
}

// File names that are included in a URL need to be encoded (by replaing spaces and other special characters). Encoding
// and already encoded string will result in a different string, so this decodes first and then encodes.
export function getEncodedFileName(filename: string): string {
    return encodeURIComponent(decodeURIComponent(filename));
}