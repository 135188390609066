import React, { useState } from "react";
import { Node } from "../../services/folderDragAndDrop";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"; // Down arrow
import ArrowRightIcon from "@mui/icons-material/ArrowRight"; // Right arrow

interface FolderTreeProps {
  node: Node;
  handleSelectTargetFolderToCopySourceFolder?: Function;
}

function FolderTreeTargetForCopyFolderAndFile(props: FolderTreeProps) {
  const { node, handleSelectTargetFolderToCopySourceFolder = () => {} } = props;

  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <>
        {node.type === "folder" && (
          <>
            <div
              style={{
                cursor: "grab",
                display: "flex",
                alignItems: "center",
              }}
            >
              {node.type === "folder" && (
                <span
                  style={{ marginRight: "10px", cursor: "pointer" }}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                </span>
              )}

              <span
                onClick={() => {
                  handleSelectTargetFolderToCopySourceFolder(node);
                }}
              >
                {node.type === "folder" ? "📁" : "📄"} {node.name}
              </span>
            </div>

            {isOpen && node.children && node.children.length > 0 && (
              <div style={{ marginLeft: "20px" }}>
                {node.children.map((child) => (
                  <FolderTreeTargetForCopyFolderAndFile
                    key={child.id}
                    node={child}
                    handleSelectTargetFolderToCopySourceFolder={
                      handleSelectTargetFolderToCopySourceFolder
                    }
                  />
                ))}
              </div>
            )}
          </>
        )}
      </>
    </>
  );
}

export default FolderTreeTargetForCopyFolderAndFile;
