import React, { forwardRef, ReactNode, Ref } from "react";
import { useDraggable, useDroppable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";

interface DraggableAndDroppableElementProps {
  id: string;
  dndType?: string;
  allowedDndTypes?: string[];
  elementData?: object;
  children: ReactNode;
}

const DraggableAndDroppableElement = (
  {
    id,
    dndType = "dndTypeProjectFolderManagement",
    allowedDndTypes = ["dndTypeProjectFolderManagement"],
    elementData= {},
    children,
  }: DraggableAndDroppableElementProps,
  ref: Ref<HTMLDivElement>
) => {
  const {
    attributes,
    listeners,
    setNodeRef: setDraggableNodeRef,
    transform,
    // transition,
  } = useDraggable({
    id,
    data: {
      dndType: dndType,
      ...elementData
    },
  });

  const { setNodeRef: setDroppableNodeRef, isOver } = useDroppable({
    id,
    data: {
      allowedDndTypes: allowedDndTypes,
      ...elementData
    },
  });

  const combinedRef = (node: HTMLDivElement | null) => {
    setDraggableNodeRef(node);
    setDroppableNodeRef(node);
    if (typeof ref === "function") {
      ref(node);
    } else if (ref) {
      (ref as React.MutableRefObject<HTMLDivElement | null>).current = node;
    }
  };

  const itemStyle: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    // transition,
    padding: "1px",
    border: isOver ? "2px dashed #007bff" : "1px solid transparent",
    borderRadius: "4px",
    backgroundColor: isOver ? "#e7f5ff" : "transparent",
    cursor: "grab",
    display: "flex",
    alignItems: "center",
  };

  return (
    <>
      {React.isValidElement(children) ? (
        React.cloneElement(children, {
          // @ts-ignore: Unreachable code error
          ref: combinedRef,
          style: { ...itemStyle, ...children.props.style },
          ...attributes,
          ...listeners,
        })
      ) : (
        <div ref={combinedRef} style={itemStyle} {...attributes} {...listeners}>
          {children}
        </div>
      )}
    </>
  );
};

export default forwardRef(DraggableAndDroppableElement);
