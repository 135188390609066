import {REPLACEMENT_TEXT_COLUMN} from "../constants";
import {applyDefaultRules} from "./rules/defaultRules";
  import {createReportRow} from "./ReportGenerator";
import AnnotationFilterHelpers from "../components/webviewer-multipanel/AnnotationFilterHelpers";

export const ISSUE_COLUMN = "Issue";
export const FINAL_REPLACEMENT_TEXT_COLUMN = "Replacement Text in Final Document";
export const FINAL_ANNOTATION_TYPE_COLUMN = "Annotation Type in Final Document";
export const STATUS_INEXACT_COLUMN = "Status (inexact)";
export const STATUS_EXACT_COLUMN = "Status (exact)";
export const TRUE_POSITIVE_TEXT = "True Positive";
export const FALSE_POSITIVE_TEXT = "False Positive";
export const FALSE_NEGATIVE_TEXT = "False Negative";
export const FALSE_POSITIVE_ISSUE = "Annotation is not in the final version";
export const REPLACEMENT_TEXT_ISSUE = "Different replacement text";
export const ANNOTATION_TYPE_ISSUE = "Different annotation type";
export const FALSE_NEGATIVE_ISSUE = "Annotation is not in this file";

export const REPORT_FILE_ENDING = '-comparison-report.xlsx';

  export const INEXACT_METRICS_TITLE = "Metrics with Inexact Matching\n"
    + "(An annotation is a true positive if the final version has an annotation with the same ID or " +
    "an annotation in approximately the same place)\n\n";
export const EXACT_METRICS_TITLE = "Metrics with Exact Matching\n"
    + "(An annotation is a true positive if the final version has an annotation with the same ID, " +
    "annotation type, and replacement text)";
export const CATEGORY_COLUMN = "Category";
export const TRUE_POSITIVES_COLUMN = "True Positives";
export const FALSE_POSITIVES_COLUMN = "False Positives";
export const FALSE_NEGATIVES_COLUMN = "False Negatives";
export const DIFFERENT_REPLACEMENT_COLUMN = "Annotations with different replacement text";
export const DIFFERENT_TYPE_COLUMN = "Annotations with different type";
export const PRECISION_COLUMN = "Precision";
export const RECALL_COLUMN = "Recall";
export const F1_SCORE_COLUMN = "F1 Score";

export function comparisonReportGenerator(goldStandardAnnotations: any[], testFileAnnotations: any[], fileName: string) {
    applyDefaultRules(testFileAnnotations)
    let falsePositives = new Set();
    let falseNegatives = new Set();
    let truePositives = new Set();
    let annotationsWithWrongReplacementText = new Set();
    let annotationsWithWrongType = new Set();
    let annotationsWithWrongID = new Set();

    let goldStandardMap = new Map<string, any>()
    let goldAnnotationsByPage = new Map<number, any[]>()
    let outputFirst = true
    let firstPage = 1
    goldStandardAnnotations.forEach(annotation => {
        goldStandardMap.set(annotation.Id, annotation)
        const pageNumber = annotation.getPageNumber()
        if (outputFirst) {
            firstPage=pageNumber
            outputFirst = false
        }
        if (goldAnnotationsByPage.get(pageNumber)) {
            goldAnnotationsByPage.set(pageNumber, goldAnnotationsByPage.get(pageNumber)!.concat(annotation))
        } else {
            goldAnnotationsByPage.set(pageNumber, [annotation])
        }
    })

    let testAnnotationsMap = new Map<string, any>()
    let testAnnotationsByPage = new Map<number, any[]>()
    testFileAnnotations.forEach(annotation => {
        testAnnotationsMap.set(annotation.Id, annotation)
        const pageNumber = annotation.getPageNumber()
        if (testAnnotationsByPage.get(pageNumber)) {
            testAnnotationsByPage.set(pageNumber, testAnnotationsByPage.get(pageNumber)!.concat(annotation))
        } else {
            testAnnotationsByPage.set(pageNumber, [annotation])
        }
    })

    let matchedAnnotations = new Map<string, string>()

    testAnnotationsMap.forEach((annotation, key) => {
        let matchInGoldStandard = findMatchInOtherFile(annotation, goldStandardMap, goldAnnotationsByPage)
        if (matchInGoldStandard) {
            matchedAnnotations.set(annotation.Id, matchInGoldStandard.Id)
            truePositives.add(annotation);
            if (hasDifferentReplacementText(annotation, matchInGoldStandard)) {
                annotationsWithWrongReplacementText.add(annotation);
            }
            if (hasDifferentType(annotation, matchInGoldStandard)) {
                annotationsWithWrongType.add(annotation);
            }
            if (annotation.Id !== matchInGoldStandard.Id) {
                annotationsWithWrongID.add(annotation);
            }
        } else {
            falsePositives.add(annotation);
        }
    })
    goldStandardMap.forEach((annotation, key) => {
        if (findMatchInOtherFile(annotation, testAnnotationsMap, testAnnotationsByPage) === null) {
            falseNegatives.add(annotation);
        }
    })

    const comparisonReport = getComparisonReport(testFileAnnotations, falsePositives, falseNegatives,
        annotationsWithWrongReplacementText, annotationsWithWrongType, goldStandardMap, matchedAnnotations, fileName);

    const {inexactMetricsRows, exactMetricsRows} = calculateMetrics(falsePositives, falseNegatives, truePositives,
        annotationsWithWrongReplacementText, annotationsWithWrongType, annotationsWithWrongID,
        goldStandardMap, fileName);

    const comparisonReportArray = convertMapArrayToArray(comparisonReport)
    const inexactMetricsRowsArray = convertMapArrayToArray(inexactMetricsRows)
    const exactMetricsRowsArray = convertMapArrayToArray(exactMetricsRows)

    return {comparisonReportArray, inexactMetricsRowsArray, exactMetricsRowsArray}
}

function convertMapArrayToArray(mapArray: Map<string, string>[] ) {
    let result: string[][] = []
    if (mapArray.length>0) {
        const headers = Array.from(mapArray[0].keys())
        result.push(headers)
        mapArray.forEach(map => {
            result.push(convertMapToArray(map, headers))
        })
    }
    return result
}

function convertMapToArray(map: Map<string, string>, sortedHeaders: string[] ) {
    let result: string[] = []
    sortedHeaders.forEach(header => {
        if (map.has(header)) {
            result.push(map.get(header)!)
        }
    })
    return result
}

function hasDifferentReplacementText(testAnnotation: any, goldAnnotation: any) {
  return testAnnotation.elementName ==='highlight' &&
      testAnnotation.getCustomData(REPLACEMENT_TEXT_COLUMN) !==goldAnnotation.getCustomData(REPLACEMENT_TEXT_COLUMN)
}

function hasDifferentType(testAnnotation: any, goldAnnotation: any) {
    return testAnnotation.elementName !==goldAnnotation.elementName
}

function getPrecision(truePositives: number, falsePositives: number) {
  const value = ( truePositives) / (truePositives + falsePositives);
  return (!isNaN(value) && isFinite(value)) ? value : 0.0
}

function getRecall(truePositives: number, falseNegatives: number) {
  const value = (truePositives) / (truePositives + falseNegatives);
    return (!isNaN(value) && isFinite(value)) ? value : 0.0
}

function getF1Score(precision: number, recall: number) {
  const value = 2 * ((precision * recall) / (precision + recall));
    return (!isNaN(value) && isFinite(value)) ? value : 0.0
}

function findMatchInOtherFile(annotation: any, otherFileMap: Map<string, any>, otherFileByPage: Map<number, any[]>) {
    if (otherFileMap.has(annotation.Id)) {
        return otherFileMap.get(annotation.Id)
    } else {
        const annotationsOnPageInOtherFile = otherFileByPage.get(annotation.getPageNumber())

        let returnValue = null
        annotationsOnPageInOtherFile?.forEach(pageAnnotation => {
            if (isNearlyContained(pageAnnotation.getRect(), annotation.getRect())) {
                returnValue = pageAnnotation;
            }
        })
        return returnValue;
    }
}

export function isNearlyContained(rect1: any, rect2: any) {
    return withinHorizontalContainment(rect1, rect2) && withinVerticalContainment(rect1, rect2)
}

function withinHorizontalContainment(rect1: any, rect2: any) {
    let HORIZONTAL_MARGIN = 10
    return (((rect1.x1 >= (rect2.x1 - HORIZONTAL_MARGIN)) && (rect1.x2 <= (rect2.x2 +
        HORIZONTAL_MARGIN))) || ((rect2.x1 >= (rect1.x1 - HORIZONTAL_MARGIN)) && (rect2.x2 <= (rect1.x2 +
        HORIZONTAL_MARGIN))))
}
function withinVerticalContainment(rect1: any, rect2: any) {
    let VERTICAL_MARGIN = 10
    return (((rect1.y1 >= (rect2.y1 - VERTICAL_MARGIN)) && (rect1.y2 <= (rect2.y2 +
            VERTICAL_MARGIN))) || ((rect2.y1 >= (rect1.y1 - VERTICAL_MARGIN)) && (rect2.y2 <= (rect1.y2 +
            VERTICAL_MARGIN))));
}

function getComparisonReport(annotations: any[], falsePositives: Set<any>,
                             falseNegatives: Set<any>,
                             annotationsWithWrongReplacementText: Set<any>,
                             annotationsWithWrongType: Set<any>,
                             goldStandardMap: Map<string, any>,
                             matchedAnnotations: Map<string, string>, fileName: string) {
    let reportRows: Map<string, string>[] = []
    let occurrences: Map<number, Map<string, number>> = new Map()

    annotations.forEach((annotation, index) => {
        let reportRow: Map<string, string> = createReportRow(annotation, fileName, index, occurrences)
        reportRow.set(STATUS_INEXACT_COLUMN, TRUE_POSITIVE_TEXT);
        reportRow.set(STATUS_EXACT_COLUMN, TRUE_POSITIVE_TEXT);

        let goldStandardAnnot = null;
        const goldStandardID = matchedAnnotations.get(annotation.Id);
        if(goldStandardID !== null && goldStandardID !== undefined) {
            goldStandardAnnot = goldStandardMap.get(goldStandardID);
        }

        if (falsePositives.has(annotation)) {
            reportRow.set(ISSUE_COLUMN, FALSE_POSITIVE_ISSUE);
            reportRow.set(STATUS_INEXACT_COLUMN, FALSE_POSITIVE_TEXT);
            reportRow.set(STATUS_EXACT_COLUMN, FALSE_POSITIVE_TEXT);
        }
        if (annotationsWithWrongReplacementText.has(annotation)) {
            reportRow.set(ISSUE_COLUMN, REPLACEMENT_TEXT_ISSUE);
            reportRow.set(FINAL_REPLACEMENT_TEXT_COLUMN, goldStandardAnnot.getCustomData(REPLACEMENT_TEXT_COLUMN));
            reportRow.set(STATUS_EXACT_COLUMN, FALSE_POSITIVE_TEXT);
        }
        if (annotationsWithWrongType.has(annotation)) {
            reportRow.set(ISSUE_COLUMN, ANNOTATION_TYPE_ISSUE);
            const annotationType = goldStandardAnnot === null ? "" : AnnotationFilterHelpers.getAnnotationType(goldStandardAnnot) || ""
            reportRow.set(FINAL_ANNOTATION_TYPE_COLUMN, annotationType);
            reportRow.set(STATUS_INEXACT_COLUMN, TRUE_POSITIVE_TEXT);
            reportRow.set(STATUS_EXACT_COLUMN, FALSE_POSITIVE_TEXT);
        }
        reportRows.push(reportRow);
    })

    falseNegatives.forEach((annotation, index) => {
        let reportRow = createReportRow(annotation, fileName, index, occurrences)
        reportRow.set(ISSUE_COLUMN, FALSE_NEGATIVE_ISSUE);
        reportRow.set(STATUS_INEXACT_COLUMN, FALSE_NEGATIVE_TEXT);
        reportRow.set(STATUS_EXACT_COLUMN, FALSE_NEGATIVE_TEXT);
        reportRows.push(reportRow);
    })

    return reportRows
}

function calculateMetrics(falsePositives: Set<any>,
                          falseNegatives: Set<any>,
                          truePositives: Set<any>,
                          annotationsWithWrongReplacementText: Set<any>,
                          annotationsWithWrongType: Set<any>,
                          annotationsWithWrongID: Set<any>,
                          goldStandardMap: Map<string, any>,
                          filepath: string) {
    const inexactMetricsRows = getMetricRows(falsePositives, falseNegatives, truePositives,
        annotationsWithWrongReplacementText, annotationsWithWrongType, annotationsWithWrongID, goldStandardMap, filepath,
        INEXACT_METRICS_TITLE);


    const inexactMatches = new Set()
    annotationsWithWrongReplacementText.forEach(annotation => inexactMatches.add(annotation))
    annotationsWithWrongType.forEach(annotation => inexactMatches.add(annotation))
    annotationsWithWrongID.forEach(annotation => inexactMatches.add(annotation))

    //For the exact metrics, we don't consider inexact matches to be true positives
    truePositives.forEach(annotation => {
        if (inexactMatches.has(annotation)) {
            truePositives.delete(annotation)
        }
    })
    inexactMatches.forEach(annotation => {
        falsePositives.add(annotation)
        falseNegatives.add(annotation)
    })

    const exactMetricsRows = getMetricRows(falsePositives, falseNegatives, truePositives,
        annotationsWithWrongReplacementText, annotationsWithWrongType, annotationsWithWrongID, goldStandardMap, filepath, EXACT_METRICS_TITLE);

    return {inexactMetricsRows, exactMetricsRows}
  }

  //getMetricRows
function getMetricRows(falsePositives: Set<any>,
                            falseNegatives: Set<any>,
                            truePositives: Set<any>,
                            annotationsWithWrongReplacementText: Set<any>,
                            annotationsWithWrongType: Set<any>,
                            annotationsWithWrongID: Set<any>,
                            goldStandardMap: Map<string, any>,
                            filepath: string,
                            title: string) {

    let annotationCategories = new Set(Array.from(goldStandardMap.values()).map(annotation => AnnotationFilterHelpers.getCategory(annotation)))
    falsePositives.forEach(annotation => annotationCategories.add(AnnotationFilterHelpers.getCategory(annotation)))

    let outputRows: Map<string, string>[] = []
    outputRows.push(getMetricString(truePositives.size, falsePositives.size, falseNegatives.size,
        annotationsWithWrongReplacementText.size, annotationsWithWrongType.size, "Overall"));

    annotationCategories.forEach(category => {
        outputRows.push(getMetricStringByCategory(truePositives, falsePositives, falseNegatives,
            annotationsWithWrongReplacementText, annotationsWithWrongType, category));
    })

    return outputRows;
}

function getMetricStringByCategory(truePositives: Set<any>, falsePositives: Set<any>, falseNegatives: Set<any>,
   annotationsWithWrongReplacementText: Set<any>, annotationsWithWrongType: Set<any>, category: string) {

  return getMetricString(getNumWithCategory(truePositives, category),
      getNumWithCategory(falsePositives, category),
      getNumWithCategory(falseNegatives, category),
      getNumWithCategory(annotationsWithWrongReplacementText, category),
      getNumWithCategory(annotationsWithWrongType, category), category);
}

function getNumWithCategory(annotations: Set<any>, category: string) {
    let size = 0
    annotations.forEach(annotation => (AnnotationFilterHelpers.getCategory(annotation) === category) && size++ )
    return size
}

function getMetricString(truePositives: number, falsePositives: number, falseNegatives: number,
                         annotationsWithWrongReplacementText: number, annotationsWithWrongType: number, category: string) {
    let rowMap: Map<string, string> = new Map<string, string>()

    rowMap.set(CATEGORY_COLUMN, category)
    rowMap.set(TRUE_POSITIVES_COLUMN, truePositives.toString())
    rowMap.set(FALSE_POSITIVES_COLUMN, falsePositives.toString())
    rowMap.set(FALSE_NEGATIVES_COLUMN, falseNegatives.toString())
    rowMap.set(DIFFERENT_REPLACEMENT_COLUMN, annotationsWithWrongReplacementText.toString())
    rowMap.set(DIFFERENT_TYPE_COLUMN, annotationsWithWrongType.toString())

    const precision = getPrecision(truePositives, falsePositives);
    const recall = getRecall(truePositives, falseNegatives);
    const f1Score = getF1Score(precision, recall);

    rowMap.set(PRECISION_COLUMN,  precision.toFixed(2));
    rowMap.set(RECALL_COLUMN, recall.toFixed(2));
    rowMap.set(F1_SCORE_COLUMN, f1Score.toFixed(2));

    return rowMap;
}
