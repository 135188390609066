import React, { useContext, useState } from "react";
import { Menu, MenuItem, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { useParams } from "react-router-dom";
import { ProjectFileManagementContext } from "../../contexts/ProjectFolderManagementContextProvider";
import { Node } from "../../services/folderDragAndDrop";
import CopyFolderToProjectModal from "./CopyFolderToProjectModal";
import { projectFolderAction } from "../../constants";
import FileOrFolderUploadModal from "./FileOrFolderUploadModal";

import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FolderOpenIcon from "@mui/icons-material/FolderOpen"; // or FolderCopyIcon
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // or FileCopyIcon
import { deleteApiFolderById } from "../../services/folder";
import { useAppDispatch } from "../../hooks/redux-hook";
import { refreshProjectData } from "../../redux/file-management";

const ITEM_HEIGHT = 52;

const options = [
  {
    label: "Rename folder",
    value: projectFolderAction.RENAME_FOLDER,
    icon: () => <DriveFileRenameOutlineIcon />,
  },
  {
    label: "Archive folder",
    value: projectFolderAction.ARCHIVE_FOLDER,
    icon: () => <DeleteOutlineIcon />,
  },
  {
    label: "New folder",
    value: projectFolderAction.NEW_FOLDER,
    icon: () => <CreateNewFolderIcon />,
  },
  {
    label: "File upload",
    value: projectFolderAction.FILE_UPLOAD,
    icon: () => <UploadFileIcon />,
  },
  {
    label: "Folder upload",
    value: projectFolderAction.FOLDER_UPLOAD,
    icon: () => <FolderOpenIcon />,
  },

  {
    label: "Copy to project",
    value: projectFolderAction.PROJECT_LIST_TO_COPY_FOLDER,
    icon: () => <ContentCopyIcon />,
  },
];

interface FolderActionsProps {
  open: boolean;
  anchorEl: any;
  node: {
    id: string;
    type: string;
    name: string;
  };
  setAnchorEl: Function;
}

function FolderOperations(props: FolderActionsProps) {
  const { node } = props as { node: Node };
  const { anchorEl, open, setAnchorEl } = props;
  const dispatch = useAppDispatch();
  const { projectId } = useParams();

  const { handleCreateNewFolder, handleRenameFolder } = useContext(
    ProjectFileManagementContext
  );

  const [openModalName, setOpenModalName] = useState("");

  const [folderName, setFolderName] = useState("");

  const handleSelectOption = (option: any) => {
    if (option.value === projectFolderAction.RENAME_FOLDER && node?.name) {
      setFolderName(node.name);
    }

    setOpenModalName(option.value);
    setAnchorEl(null);
  };

  const handleReset = () => {
    setOpenModalName("");
    setFolderName("");
  };

  const onClickCreateNewFolder = async () => {
    try {
      if (projectId && node?.folderId) {
        await handleCreateNewFolder({
          name: folderName,
          projectId: parseInt(projectId),
          parentFolderId: node.folderId,
        });
        setAnchorEl(null);
        setOpenModalName("");
        setFolderName("");
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const onClickRenameFolder = async () => {
    try {
      await handleRenameFolder(node, folderName);
      setAnchorEl(null);
      setOpenModalName("");
      setFolderName("");
    } catch (error) {}
  };

  const onClickArchiveFolder = async () => {
    try {
      if (node.type === "folder") {
        await deleteApiFolderById(node.folderId!);
        dispatch(refreshProjectData());
      }
      setAnchorEl(null);
      setOpenModalName("");
      setFolderName("");
    } catch (error) {}
  };

  const updatedOptions = (() => {
    if (node?.isProjectRootFolder) {
      return options.filter(
        (option) => option.value !== projectFolderAction.ARCHIVE_FOLDER
      );
    }
    return options;
  })();

  return (
    <>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 6,
            width: "30ch",
          },
        }}
      >
        {updatedOptions.map((option) => (
          <MenuItem
            key={option.value}
            // selected={option === "Pyxis"}
            onClick={() => handleSelectOption(option)}
          >
            <span>
              <span style={{ verticalAlign: "middle" }}> {option.icon()}</span>
              <span style={{ marginLeft: 10 }}>{option.label}</span>
            </span>
          </MenuItem>
        ))}
      </Menu>
      <Modal
        open={openModalName === projectFolderAction.NEW_FOLDER}
        onClose={() => setOpenModalName("")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h5" component="h2">
            New folder
          </Typography>

          <TextField
            name="folderName"
            label="Folder Name"
            size="small"
            fullWidth
            sx={{ marginTop: 2 }}
            value={folderName}
            onChange={(e) => {
              setFolderName(e.target.value);
            }}
          />

          <Box sx={{ textAlign: "center", marginTop: 2 }}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={handleReset}
              style={{ marginRight: 40 }}
            >
              CANCEL
            </Button>
            <Button
              type="button"
              color="secondary"
              variant="contained"
              disabled={folderName.length < 3}
              onClick={() => {
                onClickCreateNewFolder();
              }}
            >
              CREATE
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openModalName === projectFolderAction.RENAME_FOLDER}
        onClose={() => setOpenModalName("")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {`Rename folder - ${node.name}`}
          </Typography>

          <TextField
            name="folderName"
            label="Folder Name"
            size="small"
            fullWidth
            sx={{ marginTop: 2 }}
            value={folderName}
            onChange={(e) => {
              setFolderName(e.target.value);
            }}
          />

          <Box sx={{ textAlign: "center", marginTop: 2 }}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={handleReset}
              style={{ marginRight: 40 }}
            >
              CANCEL
            </Button>
            <Button
              type="button"
              color="secondary"
              variant="contained"
              disabled={folderName.length < 3}
              onClick={() => {
                onClickRenameFolder();
              }}
            >
              RENAME
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openModalName === projectFolderAction.ARCHIVE_FOLDER}
        onClose={() => setOpenModalName("")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h3">
            Are you sure you want to archive{` "${node.name}" `}folder?
          </Typography>

          <Box sx={{ textAlign: "center", marginTop: 2 }}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={handleReset}
              style={{ marginRight: 40 }}
            >
              CANCEL
            </Button>
            <Button
              type="button"
              color="secondary"
              variant="contained"
              onClick={() => {
                onClickArchiveFolder();
              }}
            >
              ARCHIVE
            </Button>
          </Box>
        </Box>
      </Modal>
      <CopyFolderToProjectModal
        node={node}
        openModalName={openModalName}
        setOpenModalName={setOpenModalName}
      />
      <FileOrFolderUploadModal
        node={node}
        openModalName={openModalName}
        setOpenModalName={setOpenModalName}
      />
    </>
  );
}

export default FolderOperations;
