import { BaseFolder } from "../models/BaseFolder";
import { baseUrl, headers } from "./config";
import { fetchAndParse, addQueryParameter } from "./utils";
import { Node } from "./folderDragAndDrop";

export function getApiFolderWithoutFileSchemaByProjectId(
  projectId: number,
  signal?: AbortSignal
): Promise<[]> {
  const url = new URL(`api/folder/project/${projectId}/folder-schema`, baseUrl);
  return fetchAndParse<[]>(url.toString(), {
    method: "GET",
    headers,
    signal,
  });
}

export function getApiFolderSchemaByProjectId(
  projectId: number,
  signal?: AbortSignal
): Promise<[]> {
  const url = new URL(`api/folder/project/${projectId}/schema`, baseUrl);
  return fetchAndParse<[]>(url.toString(), {
    method: "GET",
    headers,
    signal,
  });
}

export function putApiUpdateParentFolderById(
  parentFolderId: number,
  body: { fileIdList?: number[]; folderIdList: number[] } = {
    fileIdList: [],
    folderIdList: [],
  },
  signal?: AbortSignal
): Promise<[]> {
  const url = new URL(`/api/folder/parent/${parentFolderId}`, baseUrl);
  return fetchAndParse<[]>(url.toString(), {
    method: "PUT",
    headers,
    signal,
    body: JSON.stringify(body),
  });
}

export function postApiCreateNewFolder(
  body: BaseFolder,
  signal?: AbortSignal
): Promise<BaseFolder> {
  const url = new URL(`api/folder/new`, baseUrl);
  return fetchAndParse<BaseFolder>(url.toString(), {
    method: "POST",
    headers,
    signal,
    body: JSON.stringify(body),
  });
}

export function postApiCreateOrGetFolder(
  body: BaseFolder,
  signal?: AbortSignal
): Promise<BaseFolder> {
  const url = new URL(`api/folders/create-or-get`, baseUrl);
  return fetchAndParse<BaseFolder>(url.toString(), {
    method: "POST",
    headers,
    signal,
    body: JSON.stringify(body),
  });
}

export function patchApiRenameFolder(
  folderId: number,
  body: { name: string },
  signal?: AbortSignal
): Promise<BaseFolder> {
  const url = new URL(`api/folder/${folderId}/name`, baseUrl);
  return fetchAndParse<BaseFolder>(url.toString(), {
    method: "PATCH",
    headers,
    signal,
    body: JSON.stringify(body),
  });
}

export function getFolderCopyInformation(
  sourceId: number,
  targetId: number,
  signal?: AbortSignal
): Promise<Node[]> {
  const url = new URL(
    `api/folder/${sourceId}/targets/${targetId}/copy-information`,
    baseUrl
  );
  return fetchAndParse<[]>(url.toString(), {
    method: "GET",
    headers,
    signal,
  });
}

export function copyFolderToTargetProjectFolder(
  sourceId: number,
  targetId: number,
  signal?: AbortSignal
): Promise<[]> {
  const url = new URL(`api/folder/${sourceId}/copy`, baseUrl);
  return fetchAndParse<[]>(url.toString(), {
    method: "POST",
    headers,
    signal,
    body: JSON.stringify({
      targetFolderId: targetId,
    }),
  });
}

export function deleteApiFolderById(
  id: number,
  signal?: AbortSignal
): Promise<void> {
  const url = new URL(`/api/folder/${id}`, baseUrl);
  return fetchAndParse<void>(url.toString(), {
    method: "DELETE",
    headers,
    signal,
  });
}
