import React, { forwardRef, ReactNode, Ref } from "react";
import { CSS } from "@dnd-kit/utilities";
import { useDraggable } from "@dnd-kit/core";

interface DraggableElementProps {
  id: string;
  dndType?: string;
  elementData?: object;
  children: ReactNode;
  hasDragOverlay?: boolean;
}

const DraggableElement = (
  { id, dndType = "dndTypeProjectFolderManagement", elementData={}, children, hasDragOverlay }: DraggableElementProps,
  ref: Ref<HTMLDivElement>
) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    //  transition
  } = useDraggable({ id, data: { dndType: dndType, ...elementData} });

  const itemStyle: React.CSSProperties = {
    transform: hasDragOverlay ? "unset" : CSS.Transform.toString(transform),
    // transition,
    cursor: "grab",
  };

  return (
    <>
      {React.isValidElement(children) ? (
        React.cloneElement(children, {
          // @ts-ignore: Unreachable code error
          ref: (node: HTMLDivElement | null) => {
            setNodeRef(node);
            if (typeof ref === "function") {
              ref(node);
            } else if (ref) {
              (ref as React.MutableRefObject<HTMLDivElement | null>).current =
                node;
            }
          },
          style: { ...itemStyle, ...children.props.style },
          ...attributes,
          ...listeners,
        })
      ) : !!children ? (
        <div ref={setNodeRef} style={itemStyle} {...attributes} {...listeners}>
          {children}
        </div>
      ) : null}
    </>
  );
};

export default forwardRef(DraggableElement);
