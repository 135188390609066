import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, Button, List, ListItem, IconButton, Typography, CircularProgress, Stack, Fade, CardContent, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Asset } from "../../../../models/Asset";
import { Project } from "../../../../models/Project";
import { getApiProjects } from "../../../../services/project";
import { postApiAssetLinkProject, postApiAssetUnLinkProject } from "../../../../services/assets";
import { Skeleton } from "@mui/material";
import AddLinkIcon from "@mui/icons-material/Link";
import { Card } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useAppDispatch } from "../../../../hooks/redux-hook";
import { loadAsset } from "../../../../redux/assets";

const LinkedProjectsModal = ({ onClose, asset }: { onClose: () => void; asset: Asset; }) => {
  const [availableProjects, setAvailableProjects] = useState<Project[]>([]);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [linkingLoading, setLinkingLoading] = useState<boolean>(false);
  const [linkedProjects, setLinkedProjects] = useState<Project[]>(asset.linkedProjects || []);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projects = await getApiProjects();
        const filteredProjects = projects.filter(project => !project.linkedAssets?.length);
        setAvailableProjects(filteredProjects);
      } catch (error) {
        console.error("Error fetching projects:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [linkedProjects]);

  const handleLinkProject = async () => {
    if (selectedProject) {
      try {
        setLinkingLoading(true)
        const newAsset= await postApiAssetLinkProject({ assetId: asset.id, projectId: selectedProject.id });
        dispatch(loadAsset(newAsset));
        setLinkedProjects([...linkedProjects, selectedProject]);
        setAvailableProjects(availableProjects.filter(proj => proj.id !== selectedProject.id));
        setSelectedProject(null);
      } catch (error) {
        console.error("Error linking project:", error);
      }finally{
        setLinkingLoading(false)
      }
    }
  };

  const handleUnlinkProject = async (projectId: number) => {
    const projectToUnlink = linkedProjects.find((project) => project.id === projectId);
    if (projectToUnlink) {
      try {
        setLinkingLoading(true)
        const newAsset = await postApiAssetUnLinkProject({ assetId: asset.id, projectId });
        dispatch(loadAsset(newAsset));
        setLinkedProjects(linkedProjects.filter((project) => project.id !== projectId));
        setAvailableProjects([...availableProjects, projectToUnlink]);
      } catch (error) {
        console.error("Error unlinking project:", error);
      }finally{
        setLinkingLoading(false)
      }
    }
  };

  const navigateToProject = (project: Project) => {
    const projectUrl = `/app/user/workflow/projects/${project.id}`;
    window.open(projectUrl, "_blank"); // Opens the URL in a new tab
  };
  
  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ background: "#223250", color: "#fff", textAlign: "center", fontWeight: "bold" }}>
        Link Projects
        <IconButton sx={{ position: "absolute", right: 16, top: 16, color: "#fff" }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        {loading ? (
          <Stack spacing={2}>
            <Skeleton width="100%" height={50} />
            <Skeleton width="100%" height={40} />
            <Skeleton width="100%" height={80} />
          </Stack>
        ) : (
          <div style={{ marginTop: "20px" }}>
            <Autocomplete
              options={availableProjects}
              getOptionLabel={(option) => option.name}
              value={selectedProject}
              onChange={(_, newValue) => setSelectedProject(newValue)}
              renderInput={(params) => <TextField {...params} label="Select a project to link" variant="outlined" fullWidth />}
            />
            
            <Button 
              variant="contained" 
              color="primary" 
              startIcon={<AddLinkIcon />} 
              onClick={handleLinkProject} 
              sx={{ mt: 2 }} 
              disabled={!selectedProject || linkingLoading}
            >
              Link Project
            </Button>

            <Typography variant="h6" sx={{ mt: 3, fontWeight: "bold" }}>
            {`Linked Projects (${linkedProjects.length|| 0})`}
            </Typography>

            {linkedProjects.length === 0 ? (
              <Typography variant="body2" sx={{ mt: 2, color: "gray", textAlign: "center" }}>
                No projects linked yet. Select a project above to link.
              </Typography>
            ) : (
              <List sx={{ mt: 2, maxHeight: 300, overflowY: "auto" }}>
                {linkedProjects.map((project, index) => (
                  <Fade in timeout={300} key={project.id}>
                    <Card sx={{ mb: 2, display: "flex", alignItems: "center", p: 2, background: project.color, borderRadius: 2 }}>
                      <CardContent sx={{ flexGrow: 1,padding:0 }}>
                        <Typography onClick={()=>navigateToProject(project)} variant="h6" sx={{ fontWeight: "bold", color: "#fff","&:hover": {
      textDecoration: "underline",
      cursor: "pointer"
    } }}>
                          {project.name}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#fff" }}>
                          Status: {project.status}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#fff" }}>
                          Created By: {project.createdByName}
                        </Typography>
                      </CardContent>

                      <Tooltip title="Unlink Project" arrow>
                        <IconButton 
                          size="small" 
                          sx={{ color: "#fff" }} 
                          onClick={() => handleUnlinkProject(project.id)}
                          disabled={linkingLoading}
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </Card>
                  </Fade>
                ))}
              </List>
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LinkedProjectsModal;
