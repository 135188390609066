import { useCustomModal } from "../custom-message-modal";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { ButtonSpinner } from "../../../components/button-spinner";
import TextField from "@mui/material/TextField";
import baseline_close from "../../../provisional_icons/baseline-close.png";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { NlpJobDetail } from "../../../models/NlpJobDetail";
import {
  getApiFolderWithoutFileSchemaByProjectId,
  putApiUpdateParentFolderById,
} from "../../../services/folder";
import { getFileById } from "../../../services/files";
import { putApiUpdateNlpAnnotationsCount } from "../../../services/nlp";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hook";
import {
  nlpSelectedAnnotationSelector,
  nlpTotalAnnotationSelector,
} from "../../../redux/document";
import { Node } from "../../../services/folderDragAndDrop";
import { showSnackbar } from "../../../redux/snackbar";
import FolderTreeToSaveFile from "./FolderTreeToSaveFile";
import { FileState } from "../../../models/FileState";

const viewTypeConstant = {
  FOLDER_SCHEMA: "FOLDER_SCHEMA",
  TAG: "TAG",
};

export function UpdateFileTagModal({
  projectId,
  fileName,
  fileId,
  tag: Tag,
  selectedSqsResultData = undefined,
  updateTag,
}: {
  projectId?: number;
  fileName: string;
  fileId?: string;
  tag?: string;
  selectedSqsResultData: NlpJobDetail | undefined;
  updateTag: (status: string | undefined) => void;
}) {
  const dispatch = useAppDispatch();
  const nlpTotalAnnotations = useAppSelector((state) =>
    nlpTotalAnnotationSelector(state)
  );
  const nlpSelectedAnnotations = useAppSelector((state) =>
    nlpSelectedAnnotationSelector(state)
  );

  const [folderSchema, setFolderSchema] = useState<Node[]>([]);
  const [fileData, setFileData] = useState<FileState | null>(null);
  const [radioValue, setRadioValue] = React.useState("retain");
  const [tagValue, setTagValue] = useState("     ");
  const { hideModal } = useCustomModal();
  const [loading, setLoading] = useState(false);
  const [modifyTagDisabled, setModifyTagDisabled] = React.useState(true);
  const [viewType, setViewType] = useState(viewTypeConstant.FOLDER_SCHEMA);

  let tag = Tag;
  if (tag === undefined) {
    tag = "";
  } else {
    tag = tag.toString();
  }

  useEffect(() => {
    handleGetProjectFolderSchema();
  }, []);

  const handleGetProjectFolderSchema = async () => {
    try {
      if (!projectId || !fileId) {
        return;
      }

      const [folderSchema, fileData] = await Promise.all([
        getApiFolderWithoutFileSchemaByProjectId(projectId),
        getFileById(Number(fileId)),
      ]);

      setFolderSchema(folderSchema);
      setFileData(fileData);
      console.log("folderSchema", folderSchema);
      console.log("fileData", fileData);
    } catch (error) {
      dispatch(
        showSnackbar({ message: "Error loading files!", type: "error" })
      );
    }
  };

  const handleSelectTargetFolder = (node: Node) => {
    setFileData((prevState) =>
      prevState ? { ...prevState, folderId: node.folderId } : null
    );
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setRadioValue(value);
    if (value === "retain") {
      // @ts-ignore
      setTagValue(tag);
      setModifyTagDisabled(true);
    } else if (value === "save") {
      setTagValue(" ");
      setModifyTagDisabled(true);
    } else if (value === "add") {
      // @ts-ignore
      setTagValue(tag);
      setModifyTagDisabled(false);
    }
  };

  const onSubmit = async () => {
    try {
      const parentFolderId = fileData?.folderId || 0;
      const payload = {
        fileIdList: [Number(fileId)],
        folderIdList: [],
      };

      await putApiUpdateParentFolderById(parentFolderId, payload);

      if (selectedSqsResultData?.id) {
        const requestPayload = {
          nlpTotalAnnotations: nlpTotalAnnotations,
          nlpSelectedAnnotations: nlpSelectedAnnotations,
        };

        await putApiUpdateNlpAnnotationsCount(
          selectedSqsResultData?.id,
          requestPayload
        );
      }
    } catch (error) {
      console.error("Error updating nlp annotation count", error);
    }

    if (radioValue === "save") {
      updateTag(" ");
    } else if (radioValue === "retain") {
      updateTag(tag);
    } else {
      updateTag(tagValue);
    }
    hideModal();
  };

  // . : + = @ _ / -
  const pattern = /^[a-zA-Z0-9.:=@_\/\-\s]+$/;

  const error = tagValue.match(pattern) === null && !modifyTagDisabled;

  return (
    <div className="remove-modal-container">
      <div className="content-remove-modal">
        <img
          alt="close"
          src={baseline_close}
          className="remove-entity-close-button"
          onClick={() => (loading ? {} : hideModal())}
        />
        {viewType === viewTypeConstant.FOLDER_SCHEMA ? (
          <>
            <span
              className="remove-entity-title"
              style={{ color: "#223250", textAlign: "center" }}
            >
              Select Folder to Save
            </span>

            {folderSchema?.[0] && (
              <div style={{ marginBottom: 20 }}>
                <FolderTreeToSaveFile
                  fileFolderId={fileData?.folderId || 0}
                  node={folderSchema?.[0]}
                  handleSelectTargetFolder={handleSelectTargetFolder}
                />
              </div>
            )}

            <div
              className="remove-entity-button-list"
              style={{ marginTop: 20 }}
            >
              <Button
                disabled={loading}
                color="secondary"
                variant="outlined"
                onClick={hideModal}
              >
                CANCEL
              </Button>
              <Button
                disabled={
                  loading || error || (tagValue === tag && radioValue === "add")
                }
                onClick={() => {
                  setViewType(viewTypeConstant.TAG);
                }}
                color="secondary"
                variant="contained"
              >
                Next {loading && <ButtonSpinner />}
              </Button>
            </div>
          </>
        ) : (
          <>
            <span
              className="remove-entity-title"
              style={{ color: "#223250", textAlign: "center" }}
            >
              Add or update a document tag and save
            </span>
            <br></br>
            <form onSubmit={onSubmit}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="retain"
                name="radio-buttons-group"
                value={radioValue}
                onChange={handleRadioChange}
              >
                <TextField
                  disabled={modifyTagDisabled}
                  onChange={(e) => setTagValue(e.target.value)}
                  value={tagValue === "     " ? tag : tagValue}
                  defaultValue={tag}
                  variant="outlined"
                  id="outlined-error-helper-text"
                  inputProps={{ style: { fontSize: 17 } }}
                  style={{ width: "100%" }}
                  helperText={
                    error
                      ? "Only alphanumeric and . : + = @ _ / - allowed!"
                      : ""
                  }
                  error={error}
                />
                <FormControlLabel
                  value="retain"
                  control={<Radio />}
                  label="Retain Current Tag"
                />
                <FormControlLabel
                  value="add"
                  control={<Radio />}
                  label="Add or Update Tag"
                />
                <FormControlLabel
                  value="save"
                  control={<Radio />}
                  label="Save Without Tag"
                />
              </RadioGroup>

              <div
                className="remove-entity-button-list"
                style={{ marginTop: 20 }}
              >
                <Button
                  disabled={loading}
                  color="secondary"
                  variant="outlined"
                  onClick={hideModal}
                >
                  CANCEL
                </Button>
                <Button
                  disabled={
                    loading ||
                    error ||
                    (tagValue === tag && radioValue === "add")
                  }
                  onClick={onSubmit}
                  color="secondary"
                  variant="contained"
                >
                  Save {loading && <ButtonSpinner />}
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
}
