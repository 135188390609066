import React, {useEffect, useState} from 'react';
import {useCustomModal} from "../../pages/modals/custom-message-modal";
import "./TransformSettingsModal.scss"
import CreatableSelect from "react-select/creatable";
import {categoricalTransformations, REPLACEMENT_TEXT_COLUMN} from "../../constants";
import {formatCategoricalTransform} from "./TransformsPanel/transform-panel";
import './load-from-category-modal.scss'


const LoadFromCategoryModal = (props: {annotations: any[], onDone: () => void}) => {
    const { hideModal } = useCustomModal();
    const [selections, setSelections] = useState(new Map())

    const categoriesInUse = Array.from(
        new Set(
            props.annotations
                .filter(annotation => annotation.getCustomData('trn-redaction-type'))
                .map(annotation => annotation.getCustomData('trn-redaction-type'))
        )
    );

    useEffect(() => {
        let initialSelections = new Map<string, string>();
        categoriesInUse.forEach((category) => {
            initialSelections.set(category, getCategoricalTransformOptions(category)[0])
        })
        setSelections(initialSelections)
    }, []);

    const onChangeSelection = (category: string, selection: string) => {
        setSelections(new Map(selections.set(category, selection)))
    }

    const onDone = () => {
        props.annotations.forEach((annotation) => {
            const category = annotation.getCustomData('trn-redaction-type');
            if (category && selections.has(category)) {
                annotation.setCustomData(REPLACEMENT_TEXT_COLUMN, selections.get(category));
            }
        })
        props.onDone();
        hideModal()
    }

    return (
        <div className={'transformSettingsModal'} style={{'padding': '5px'}}>
            <h1>Load Transforms By Category Names</h1>
            <div key={'headers'} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <div style={{ flex: 1 }}>
                    <h3>Category Name</h3>
                </div>
                <div style={{ flex: 1 }}>
                    <h3>Replacement Text</h3>
                </div>
            </div>
            <div>
                {categoriesInUse.map(category => (
                    <div key={category} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <div style={{ flex: 1 }}>
                            {category}
                        </div>
                        <div style={{ flex: 1 }}>
                            <CreatableSelect
                                options={getCategoricalTransformMenuOptions(category)}
                                value={{value: selections.get(category), label: selections.get(category)}}
                                onChange={(newSelection) => onChangeSelection(category, newSelection!.value)}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className={"button-container"}>
                <button className='change-status-btn' onClick={() => hideModal()}>Cancel</button>
                <button className='change-status-btn success' onClick={() => onDone()}>Done</button>
            </div>
        </div>
    );
};

export default LoadFromCategoryModal;

export const getCategoricalTransformOptions = (category: string): string[] => {
    let options: string[] = [];
    //First add the hard coded options from the constants file. These are the options that the CS team provided.
    if (categoricalTransformations.hasOwnProperty(category)) {
        //@ts-ignore
        const hardCodedOptions: string[] = categoricalTransformations[category];
        options = options.concat(hardCodedOptions);
    }
    //Next add the category name and * as options.
    options = options.concat([formatCategoricalTransform(category), '*']);
    return options;
}

export const getCategoricalTransformMenuOptions = (category: string): {value: string, label: string}[] => {
    return getCategoricalTransformOptions(category).map(option => ({value: option, label: option}));
}

