import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "../../hooks/utils";
import './signup.scss';
import { AuthentificationModal } from "../modals/auth-modal";
import { useValidation } from "../../hooks/validation";
import { ErrorHelper } from "../../components/error-helper";
import { NavBar } from "../../components/navBar";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAuthService } from "../../contexts/auth-context";

type InputInfo = {
    fullName: string,
    password: string
}

export function SignUpPage() {
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [isPasswordValidationError, setIsPasswordValidationError] = useState<boolean>();
    const { passwordRequirements, requirementsValidation, createPasswordValidation } = useValidation();
    const query = useQuery();
    const auth = useAuthService();
    const { register, handleSubmit, formState: { errors }, getValues, watch } = useForm<InputInfo>();

    const onSubmit: SubmitHandler<InputInfo> = (regInfo,event) => {
        event?.preventDefault();
        if (!!requirementsValidation.length) return;
        auth.signUpAccount({ ...regInfo, email: query.get("email")!, code: query.get("confirmationCode")! });
    }

    useEffect(() => {
        const password = getValues("password");
        createPasswordValidation(password);
        if (isPasswordValidationError !== undefined) {
            setIsPasswordValidationError(!!requirementsValidation.length);
        }
    }, [watch("password")])

    return <div>
        <NavBar />
        <div className='container'>
            <AuthentificationModal>
                <div className="contentBox">
                    <span className="title">Sign up to access RLS products</span>
                    <p className="detail">
                        We need a few details to create an account for <span style={{ textAlign: "left" }}><b>{query.get("email")}</b></span>
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }}>
                        <TextField
                            error={!!errors.fullName}
                            variant="outlined"
                            id="outlined-error-helper-text"
                            label="Full name"
                            {...register("fullName", { required: true })}
                            inputProps={{ style: { fontSize: 17 } }}
                            style={{ marginTop: 5 }}
                        />
                        {errors.fullName && errors.fullName.type === "required" && <ErrorHelper helperText="Full name is required." />}
                        <TextField
                            error={!!errors.password}
                            label='Create Password'
                            variant="outlined"
                            id="password"
                            type={passwordVisibility ? "text" : "password"}
                            {...register("password", {
                                required: true,
                            })}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setPasswordVisibility(!passwordVisibility)}
                                        >
                                            {passwordVisibility ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            inputProps={{ style: { fontSize: 17, fontFamily: !passwordVisibility ? "initial" : "" } }}
                            style={{ marginTop: 22 }}
                        />
                        {isPasswordValidationError && <ErrorHelper helperText="Password must be:" />}
                        <div className={isPasswordValidationError ? "password" : "error-password"}>
                            {!isPasswordValidationError && <div style={{ marginBottom: 4, marginTop: 16 }}>Password must be:</div>}
                            {passwordRequirements?.map(item => <div style={{ display: "flex", alignItems: "center" }}
                                key={item}
                                className={requirementsValidation?.includes(item) && !isPasswordValidationError ? "main-li li" :
                                    requirementsValidation.includes(item) && isPasswordValidationError ? "error-li li" : "gray-li li"
                                }
                                >
                                <div style={{ width: 8, height: 8, backgroundColor: "#217DA2", borderRadius: 4, marginRight: 12 }} /> {item}</div>
                            )}
                        </div>
                        <span className="policy">
                            {/* hrefs need to be change */}
                            By signing up, I accept the Real Life Sciences <a className="reference" href=""> Terms of Service </a>
                            and acknowledge the <a className="reference" href=""> Privacy Policy</a>.
                        </span>
                        <Button type="submit" variant="contained" color="secondary" onClick={() => setIsPasswordValidationError(!!requirementsValidation.length)}>SIGN UP</Button>
                    </form>
                </div>
            </AuthentificationModal>
        </div>
    </div>
}
