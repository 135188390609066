import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"; // Down arrow
import ArrowRightIcon from "@mui/icons-material/ArrowRight"; // Right arrow

import { Menu, MenuItem, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import DraggableAndDroppableItem from "../dndKit/DraggableAndDroppableElement";
import { Node } from "../../services/folderDragAndDrop";
import FolderOperations from "./FolderOperations";

interface FolderTreeProps {
  node: Node;
  expandedFolderIdList: string[];
  handleToggleFolder: (
    id: string,
    isOpenMandatory: boolean | undefined
  ) => void;
  handleSetActiveNode: (id: string) => void;
}

const FolderTree: React.FC<FolderTreeProps> = ({
  node,
  expandedFolderIdList,
  handleToggleFolder,
  handleSetActiveNode,
}) => {
  const isOpen = expandedFolderIdList.includes(node.id);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  return (
    <>
      {node.type === "folder" && (
        <>
          <div
            style={{
              cursor: "grab",
              display: "flex",
              alignItems: "center",
            }}
          >
            {node.type === "folder" && (
              <span
                style={{ marginRight: "10px", cursor: "pointer" }}
                onClick={() => handleToggleFolder(node.id, false)}
              >
                {isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
              </span>
            )}

            <DraggableAndDroppableItem id={node.id} elementData={node}>
              <span
                onMouseDown={(event) => {
                  if (event.ctrlKey) {
                    // check control click
                    setAnchorEl(event.currentTarget);
                  } else {
                    handleSetActiveNode(node.id);
                  }
                }}
                onContextMenu={(event) => {
                  event.preventDefault();
                  setAnchorEl(event.currentTarget);
                }}
              >
                {node.type === "folder" ? "📁" : "📄"} {node.name}
              </span>
            </DraggableAndDroppableItem>
            <FolderOperations
              node={node}
              open={open}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
            />
          </div>

          {isOpen && node.children && node.children.length > 0 && (
            <div style={{ marginLeft: "20px" }}>
              {node.children.map((child) => (
                <FolderTree
                  key={child.id}
                  node={child}
                  expandedFolderIdList={expandedFolderIdList}
                  handleToggleFolder={handleToggleFolder}
                  handleSetActiveNode={handleSetActiveNode}
                />
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default FolderTree;
