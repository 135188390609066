
import { Task } from "../../models/Task";
import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hook";
import { userSettingsSelector } from "../../redux/userSettings";
import { getApiTasksById, postApiTasksAssign, postApiTasksCreate, putApiTasksById, putApiTasksStatusById} from "../../services/task";
import TextField from "@mui/material/TextField/TextField";
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import assign_icon from "../../provisional_icons/assign-icon.png";
import baseline_event_available from "../../provisional_icons/baseline-event_available.png";
import baseline_event_due_date from "../../provisional_icons/baseline-event_due_date.png";
import { ProjectSubtask } from "./subtask";
import { UsersSelect } from "../users-select";
import { filteredUsersSelector, usersSelector } from "../../redux/users";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { statusList, StatusType } from "../../constants";
import { CustomDatePicker } from "../custom-date-picker";
import moment from "moment-timezone";
import { StatusMenuComponent } from "../status-menu-component";
import { hideProgressLine, showProgressLine } from "../../redux/progress-line";
import { showSnackbar } from "../../redux/snackbar";
import {
    actionBarProjectIdSelector,
    selectedTasksSelector, selectSubtasks,
    selectTasks, setIsTemplate,
    setProjectId,
    setProjectName,
    setShowToolbarAfterDeletion
} from "../../redux/action-bar";
import Checkbox from "@mui/material/Checkbox";
import { projectFiltersSelector } from "../../redux/project-filters";
import { areFiltersActive, filterTasks } from "../../services/filter-service";
import { useAuthService } from "../../contexts/auth-context";
import { loadProject, projectSelector, updateTask } from "../../redux/projects";
import { loadTemplate, projectTemplateSelector } from "../../redux/projectTemplates";
import { ProjectCustomFields } from "../../models/ProjectCustomFields";
import {
    cfvByTaskListSelector,
    createCustomFieldValue,
    customFieldsSelector,
    deleteCustomFieldValue, updateCustomFieldValue
} from "../../redux/custom-fields";
import {
    deleteApiCustomFieldsValuesById,
    postApiCustomFieldsValues,
    putApiCustomFieldsSorting,
    putApiCustomFieldsValues
} from "../../services/custom.fields";
import { TaskTimeTrackSummary } from "../../models/TaskTimeTrackSummary";
import { tasksTimeTrackingSelector, taskTimeTrackingSelector } from "../../redux/tasksTimeTracking";
import { convertDateTimeFromUTCToTimezone, convertMinutesToHours2 } from "../../services/utils";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import {LinkFilesToTaskModalGivenTask} from "../../pages/modals/modal-content/link-files-to-task-modal-given-task";
import {useCustomModal} from "../../pages/modals/custom-message-modal";
import {ShowAvailableTaskFilesModal} from "../../pages/modals/modal-content/show-available-task-files-modal";
import { DragIndicator as DragIndicatorIcon } from "@mui/icons-material";

export function ProjectTask(p: {
    task: Task, taskListId: number, last: boolean, isScroll: boolean, tableRef: HTMLElement | null,
    projectId: number, refreshList: (key?: string) => void, projectName: string, taskListName: string, isTemplate: boolean,
    projectCustomFields: ProjectCustomFields | undefined, newTask: boolean, setNewTask: (value: boolean) => void,
    index: number, displayedTasks: any, onTaskReorder: any, fetchTask: any, sortProgress: (value: boolean) => void
}) {
    const auth = useAuthService();
    const { showModal } = useCustomModal();
    const theme = useTheme();
    const nav = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const textFieldRef = useRef(null);
    const taskRef = useRef(null);
    const scrollBorderRef = useRef(null);
    const actionBarProjectId = useAppSelector((state) => actionBarProjectIdSelector(state));
    const selectedTasks: Task[] = useAppSelector((state) => selectedTasksSelector(state));
    const template = useAppSelector((state) => projectTemplateSelector(state, p.projectId))
    const project = useAppSelector((state) => projectSelector(state, p.projectId));
    const userSettings = useAppSelector((state) => userSettingsSelector(state));
    const users = useAppSelector((state) =>
        p.isTemplate || (project !== undefined && project.organizationShare) ?
            usersSelector(state, true) : filteredUsersSelector(state, project!.users?.map(u => u.id) || []));
    const filters = useAppSelector((state) => projectFiltersSelector(state, p.projectId));
    const customFields = useAppSelector((state) => customFieldsSelector(state));
    const customFieldsValues = useAppSelector((state) => cfvByTaskListSelector(state, p.taskListId)).customFieldsValues;
    const taskTimeTracking: TaskTimeTrackSummary | undefined = useAppSelector((state) => taskTimeTrackingSelector(state, p.task.id))
    const subtasksTimeTracked = useAppSelector((state) => tasksTimeTrackingSelector(state, p.task.subtasks?.map(s => s.id) || []));
    const [taskName, setTaskName] = useState<string>();
    const [toggleSubtasksView, setToggleSubtasksView] = useState(false);
    const [bgColor, setBgColor] = useState("");
    const isTaskSelected = selectedTasks.map(st => st.id).includes(p.task.id);

    const refreshListWithID = () => {
        p.refreshList(p.taskListId.toString());
    }


    useEffect(() => {
        if (location.state && (location.state as { taskId: number }).taskId === p.task.id && taskRef.current !== null) {
            if (location.pathname !== "/app/user/workflow/projects") {
                scrollToTask();
            } else {
                setTimeout(() => scrollToTask(), 1000);
            }
        }
        if (p.newTask) {
            setTimeout(() => window.addEventListener('click', newTaskListener), 500);
        }
        return () => {
            window.removeEventListener('click', newTaskListener);
        };
    }, []);


    useEffect(() => {
        if (p.isScroll && scrollBorderRef.current && taskRef.current) {
            (scrollBorderRef.current as HTMLElement).style.height =
                `${(taskRef.current as HTMLElement).getBoundingClientRect().height}px`;
        }
    }, [p.isScroll])


    const scrollToTask = () => {
        (taskRef.current! as HTMLElement).scrollIntoView({ behavior: "smooth", block: "center" });
        setBgColor("#5DABD3");
        setTimeout(() => {
            setBgColor("");
            location.state = null;
        }, 500);
    }


    const newTaskListener = (ev: MouseEvent) => {
        if (textFieldRef.current !== null && !p.last &&
            !(textFieldRef.current as HTMLElement).contains(ev.target as HTMLElement)) {
            p.setNewTask(false);
        }
    }


    const handleChange = () => {
        if (p.projectId !== actionBarProjectId) {
            dispatch(setProjectId(p.projectId));
            dispatch(setProjectName(p.projectName));
            dispatch(setIsTemplate(p.isTemplate));
            dispatch(selectSubtasks([]));
        }
        const tasks = p.projectId !== actionBarProjectId ? [] : [...selectedTasks];
        if (isTaskSelected) {
            const index = tasks.map(t => t.id).indexOf(p.task.id);
            tasks.splice(index, 1);
        } else {
            tasks?.push(p.task);
        }
        dispatch(selectTasks(tasks));
    };


    const createTask = () => {
        if (taskName && taskName !== "") {
            dispatch(showProgressLine());
            postApiTasksCreate({ taskListId: p.taskListId, name: taskName, status: "NOT_STARTED", timeTrackedByUserId: "" })
                .then(() => refreshListWithID())
                .then(() => dispatch(hideProgressLine()))
                .then(() => {
                    p.isTemplate ?
                        dispatch(loadTemplate({
                            ...template,
                            taskLists: template!.taskLists!.map(taskList => {
                                if (taskList.id === p.taskListId) {
                                    return {
                                        ...taskList,
                                        tasks: [
                                            ...taskList.tasks || [],
                                            {
                                                taskListId: p.taskListId,
                                                name: taskName,
                                                status: "NOT_STARTED"
                                            }
                                        ]
                                    }
                                }
                                return taskList;
                            })
                        }))
                        :
                        dispatch(loadProject({
                            ...project,
                            taskLists: project!.taskLists!.map(taskList => {
                                if (taskList.id === p.taskListId) {
                                    return {
                                        ...taskList,
                                        tasks: [
                                            ...taskList.tasks || [],
                                            {
                                                taskListId: p.taskListId,
                                                name: taskName,
                                                status: "NOT_STARTED"
                                            }
                                        ]
                                    }
                                }
                                return taskList;
                            })
                        }))
                    p.setNewTask(false);
                    dispatch(setShowToolbarAfterDeletion(false));
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(hideProgressLine());
                    dispatch(showSnackbar({ message: "Error creating task!", type: "error" }));
                    p.setNewTask(false);
                })
        }
    }


    const onKeyPress = async (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            createTask();
        }
    }


    const textFieldSX = {
        input: {
            WebkitTextFillColor: `${theme.palette.primary.main} !important`,
            color: `${theme.palette.primary.main} !important`,
            cursor: p.newTask ? "pointer" : "initial"
        },
    };


    const assignUserOnTask = (userIds: number | number[] | undefined) => {
        dispatch(showProgressLine());
        postApiTasksAssign({ userIds: Array.isArray(userIds) ? userIds.join(",") : userIds?.toString()!, taskId: p.task.id })
            .then(()=>refreshListWithID())
            .then(() => dispatch(hideProgressLine()))
            .catch(() => {
                dispatch(hideProgressLine());
                dispatch(showSnackbar({ message: "Error assigning user on task!", type: "error" }));
            });
    }


    const setDate = (value: Date | null, type: "start" | "due") => {
        dispatch(showProgressLine());
        const body = type === "start" ?
            {
                ...p.task,
                startDate: value ? moment(value).format("YYYY-MM-DD 00:00:00") : null
            }
            :
            {
                ...p.task,
                dueDate: value ? moment(value).format("YYYY-MM-DD 00:00:00") : null
            }
        putApiTasksById(p.task.id, body)
            .then(()=>refreshListWithID())
            .then(() => dispatch(hideProgressLine()))
            .catch(() => {
                dispatch(hideProgressLine());
                dispatch(showSnackbar({ message: "Error setting date!", type: "error" }));
            });
    }


    const setStatus = (status: StatusType) => {
        dispatch(showProgressLine());
        putApiTasksStatusById(p.task.id, { status })
            .then(()=>refreshListWithID())
            .then(() => dispatch(hideProgressLine()))
            .then(() => p.fetchTask(true,p.task.id))
            .catch(() => {
                dispatch(hideProgressLine());
                dispatch(showSnackbar({ message: "Error setting status!", type: "error" }));
            });
    }
    const displayedSubtasks = p.isTemplate || (!!filters.showSubtasks || toggleSubtasksView) ?
        (p.task.subtasks || []) : areFiltersActive(filters) ?
            filterTasks(p.task.subtasks || [], filters, auth.loginInfo?.tenant?.user!) : [];


    const lastRow = p.last && (p.task.subtasks === undefined || p.task.subtasks?.length === 0 ||
        (!p.isTemplate && filters.showSubtasks === undefined)) && displayedSubtasks?.length === 0;

    const saveTaskCustomField = (taskId: number, customFieldId: number, value: string) => {
        dispatch(showProgressLine());
        const customFieldValue = customFieldsValues.find(value => value.customFieldId === customFieldId
            && value.taskId === taskId);
        if (customFieldValue) {
            if (value === "") {
                deleteApiCustomFieldsValuesById(customFieldValue.id)
                    .then(() => dispatch(deleteCustomFieldValue({
                        taskListId: p.taskListId,
                        id: customFieldValue.id
                    })))
                    .then(() => dispatch(hideProgressLine()))
                    .catch(() => {
                        dispatch(hideProgressLine());
                        dispatch(showSnackbar({ message: "Error deleting custom field value!", type: "error" }));
                    })
            } else if (customFieldValue.valueString !== value) {
                const newValue = { ...customFieldValue, valueString: value };
                putApiCustomFieldsValues(newValue)
                    .then(() => dispatch(updateCustomFieldValue({
                        taskListId: p.taskListId,
                        customFieldValue: newValue
                    })))
                    .then(() => dispatch(hideProgressLine()))
                    .catch(() => {
                        dispatch(hideProgressLine());
                        dispatch(showSnackbar({ message: "Error updating custom field value!", type: "error" }));
                    })
            } else {
                dispatch(hideProgressLine());
            }
        } else if (value !== "") {
            const newValue = {
                id: 0,
                customFieldId: customFieldId,
                taskId,
                valueString: value
            };
            postApiCustomFieldsValues(newValue)
                .then((customFieldValue) =>
                    dispatch(createCustomFieldValue({ taskListId: p.taskListId, customFieldValue })))
                .then(() => dispatch(hideProgressLine()))
                .catch(() => {
                    dispatch(hideProgressLine());
                    dispatch(showSnackbar({ message: "Error adding custom field value!", type: "error" }));
                });
        } else {
            dispatch(hideProgressLine());
        }
    }


    const getCustomFieldValue = (customFieldId: number) => {
        const customFieldValue = customFieldsValues.find(cfv => cfv.customFieldId === customFieldId
            && cfv.taskId === p.task.id);
        if (customFieldValue) {
            return customFieldValue.valueString || "";
        }
        return "";
    }


    const filteredUsers = () => users.filter(u => p.task.assignedUserId &&
        p.task.assignedUserId.split(",").includes(`${u.id}`))
    // const handleDragIconClick = (event, task) => {
    //     // Implement your drag and drop functionality here based on the clicked task
    //     console.log("Drag icon clicked for task:", task);
    //   };

// on the basis on sort id
    // below function is used to handle the drag and drop functionality
    const onDragStart = (event: any, sortId: number, id: number) => {
        event.dataTransfer.setData("text/plain", sortId);
        event.dataTransfer.setData("id", id);
    };

    const onDragOver = (event: any) => {
        event.preventDefault();
    };
    const onDrop = async (event: any, targetSortId: number, targetId: number, parent?: number) => {
        event.preventDefault();
        const sourceSortId = event.dataTransfer.getData("text/plain");
        const id = event.dataTransfer.getData("id");
        if (+sourceSortId !== targetSortId) {
            // hit the api
            p.sortProgress(true);

            const sorted = await putApiCustomFieldsSorting(+id, +targetId)
            if(sorted) {

                if(parent) {
                    reorderSubtasks(parent, sourceSortId, targetSortId)
                }else {
                    reorderTasks(sourceSortId, targetSortId);
                }
            }
            p.sortProgress(false);
        }
    };

    const reorderTasks = (sourceId: string, targetId: number) => {
        let newTasks = p.displayedTasks.map((task: any) => ({ ...task }));
        const sourceIndex = newTasks.findIndex((task: any) => task.sortId === +sourceId);

        const targetIndex = newTasks.findIndex((task: any) => task.sortId === targetId);
        if (sourceIndex !== -1 && targetIndex !== -1) {
            const temp = newTasks[sourceIndex].sortId
            newTasks[sourceIndex].sortId = newTasks[targetIndex].sortId
            newTasks[targetIndex].sortId = temp;
            // local sorting
            newTasks = newTasks.sort((a: any, b: any) => { return a.sortId - b.sortId; });
        }
        // Call a function to update the tasks in the parent component
        p.onTaskReorder(newTasks);
    };
    const reorderSubtasks=(parent: number,sourceId: string, targetId: number)=>{
        const tasks = p.displayedTasks.map((task: any) => ({ ...task }));
        let taskItem = tasks.find((task: any) => task.id === parent);

        if (taskItem !== undefined && taskItem.subtasks.length > 0) {
            const sourceIndex = taskItem.subtasks.findIndex((task: Task) => task.sortId === +sourceId);
            const targetIndex = taskItem.subtasks.findIndex((task: Task) => task.sortId === targetId);

            if (sourceIndex !== -1 && targetIndex !== -1) {
                const updatedSubtasks = [...taskItem.subtasks]; // Create a copy of subtasks
                const temp = updatedSubtasks[sourceIndex].sortId;
                updatedSubtasks[sourceIndex] = { ...updatedSubtasks[sourceIndex], sortId: updatedSubtasks[targetIndex].sortId };
                updatedSubtasks[targetIndex] = { ...updatedSubtasks[targetIndex], sortId: temp };
                // Local sorting
                updatedSubtasks.sort((a: Task, b: Task) => {
                    return a.sortId - b.sortId;
                });
                taskItem = {
                    ...taskItem,
                    subtasks: updatedSubtasks,
                };
            }
            const newTasks = p.displayedTasks.map((task: any) => {
                if (task.id === parent) {
                    return taskItem;
                }
                return task;
            });
            p.onTaskReorder(newTasks);
        }
    }



    const arrangeInDescendingOrder = (list: any) => {
       if([...list].length > 0){
           const descendingTasks = [...list].sort((a: Task, b: Task)=>a.sortId - b.sortId);
           return descendingTasks
       }
       return []
    }


    return <>

        <tr key={p.task.id} className="single-template-task-row" ref={taskRef}
            onDragStart={(event) => onDragStart(event, p.task.sortId, p.task.id)}
            onDragOver={onDragOver}
            onDrop={(event) => onDrop(event, p.task.sortId, p.task.id)}
            draggable
        >
            <td width={30} className="sticky-column">
                {/* Add a drag icon and handle its onClick event */}
                <DragIndicatorIcon
                    style={{ cursor: "grab" }}

                />
            </td>
            <td width={30} className="sticky-column">
                <Checkbox
                    checked={isTaskSelected}
                    onClick={!p.newTask ? handleChange : () => { }}
                    disabled={!auth.hasPMRoleAccess()}
                    color="secondary"
                    size="small"
                    value={selectedTasks}
                    style={{
                        position: "relative", left: 6,
                        color: !auth.hasPMRoleAccess() ? "#e6e7e8" : (isTaskSelected ? theme.palette.secondary.main : "gray"),
                        marginLeft: -15
                    }}
                />
            </td>
            <td className={`single-template-task-name sticky-column-2 ${lastRow ? "last-row" : ""}
${isTaskSelected ? "selected-row-first" : ""}`}
                style={{
                    display: "flex", justifyContent: "space-between", alignItems: "center", minHeight: 48, paddingRight: 15,
                    backgroundColor: bgColor, transition: "background-color 0.5s ease"
                }}>
                {p.newTask ? <TextField
                    inputRef={textFieldRef}
                    variant="standard"
                    classes={{ root: "single-template-task-name" }}
                    placeholder="Enter a task name"
                    InputProps={{ disableUnderline: true }}
                    style={{ marginLeft: 16 }}
                    autoFocus
                    sx={textFieldSX}
                    onBlur={createTask}
                    onKeyPress={onKeyPress}
                    onChange={(e) => setTaskName(e.target.value)}
                    value={taskName}
                />
                    :
                    <div style={{
                        color: "#223250",
                        display: "flex", alignItems: "center", flexGrow: 1, marginLeft: 16, marginRight: 16
                    }}>
                        <span style={{ cursor: "pointer" }}
                            onClick={() => {
                                nav(`/app/user/workflow/task-details/${p.task.id}`,
                                    { state: { source: location.pathname } })
                            }}>
                            {p.task.name}
                        </span>
                        {!p.isTemplate && (p.task.subtasks?.length || 0 > 0) &&
                            <div style={{ marginLeft: 16, display: "flex", alignItems: "center", cursor: "pointer" }}
                                onClick={() => setToggleSubtasksView(!toggleSubtasksView)}>
                                <SubdirectoryArrowRightIcon sx={{ width: 16, height: 16, color: "#939598" }} />
                                <span style={{ color: filters.showSubtasks === undefined && !toggleSubtasksView ? "#217DA2" : "#939598" }}>
                                    {p.task.subtasks?.length}
                                </span>
                            </div>}
                    </div>
                }
                {p.isScroll && <div ref={scrollBorderRef} style={{
                    width: 10, backgroundImage: "linear-gradient(to right, rgba(0,0,0,.1), rgba(255,255,255,0.5))",
                    position: "absolute", height: 51, right: 0, top: 0
                }} />}
            </td>
            <td width={200} className={`${lastRow ? "last-row" : ""} ${isTaskSelected ? "selected-row-middle" : ""}`}>
                {p.isTemplate || p.newTask || (!auth.hasPMRoleAccess() && !p.task.assignedUserId) ?
                    <img alt="assign" className="single-template-task-icon" src={assign_icon} />
                    :
                    <UsersSelect allUsers={users} selectedUsers={filteredUsers()} maxAvatars={6} isTaskList={true}
                        taskRef={taskRef.current} multiple={false} onChange={assignUserOnTask} showAvatar={true} />
                }
            </td>
            <td width={100} className={`${lastRow ? "last-row" : ""} ${isTaskSelected ? "selected-row-middle" : ""}`}>
                {p.isTemplate || p.newTask || (!auth.hasPMRoleAccess() && !p.task.startDate) ?
                    <img alt="available" className="single-template-task-icon" src={baseline_event_available} />
                    :
                    auth.hasPMRoleAccess() ?
                        <CustomDatePicker value={p.task.startDate ? new Date(p.task.startDate) : null} key={p.task.startDate}
                            setValue={(date) => setDate(date, "start")} name="START DATE" shortView={true}
                            icon={<CalendarTodayIcon style={{ color: "#939598" }} />} />
                        :
                        <span style={{ fontSize: 13, fontWeight: 600, letterSpacing: 0.4 }}>
                            {moment(p.task.startDate).format("MMMM DD")}
                        </span>
                }
            </td>
            <td width={100} className={`${lastRow ? "last-row" : ""} ${isTaskSelected ? "selected-row-middle" : ""}`}>
                {p.isTemplate || p.newTask || (!auth.hasPMRoleAccess() && !p.task.dueDate) ?
                    <img alt="due date" className="single-template-task-icon" src={baseline_event_due_date} />
                    :
                    auth.hasPMRoleAccess() ?
                        <CustomDatePicker value={p.task.dueDate ? new Date(p.task.dueDate) : null} key={p.task.dueDate}
                            minDate={p.task.startDate ? new Date(p.task.startDate) : undefined}
                            setValue={(date) => setDate(date, "due")} name="DUE DATE" shortView={true}
                            icon={<EventAvailableIcon style={{ color: "#939598" }} />} />
                        :
                        <span style={{ fontSize: 13, fontWeight: 600, letterSpacing: 0.4 }}>
                            {moment(p.task.dueDate).format("MMMM DD")}
                        </span>
                }
            </td>
            <td width={100} align="center" className={`${lastRow ? "last-row" : ""} ${isTaskSelected ? "selected-row-middle" : ""}`}>
                {p.isTemplate || p.newTask ?
                    <span className="single-template-task-status">NOT STARTED</span>
                    :
                    auth.hasPMRoleAccess() || (auth.hasUserRoleAccess() && p.task.assignedUserId &&
                        p.task.assignedUserId.split(",").includes(auth.loginInfo?.tenant?.user?.id?.toString() || "")) ?
                        <StatusMenuComponent status={p.task.status as StatusType} onUpdate={setStatus} projectStatus={false} />
                        :
                        <div style={{
                            display: "flex", justifyContent: "center", alignItems: "center",
                            letterSpacing: 0.1, color: "white", fontSize: 11, fontWeight: 600,
                            backgroundColor: statusList[p.task.status as StatusType].color,
                            width: 86, height: 30, whiteSpace: "nowrap"
                        }}>
                            {statusList[p.task.status as StatusType].name}
                        </div>
                }
            </td>
            <td width={100} className={`${lastRow ? "last-row" : ""} ${isTaskSelected ? "selected-row-middle" : ""}`}>
                <span style={{ fontSize: 13, letterSpacing: 1.5, color: "#58595B" }}>
                    {p.isTemplate ? userSettings.regional.dateFormat : p.task.closeDate ?
                        convertDateTimeFromUTCToTimezone(p.task.closeDate, userSettings.regional.timezone,
                            userSettings.regional.dateFormat) : '-'}
                </span>
            </td>
            <td width={100} className={`${lastRow ? "last-row" : ""} ${isTaskSelected ? "selected-row-middle" : ""}`}>
                <span style={{ fontSize: 13, color: "#58595B", letterSpacing: 1.5 }}>
                    {convertMinutesToHours2((taskTimeTracking?.minutes || 0) +
                        subtasksTimeTracked.reduce((a, tts) => a + tts.minutes, 0))}
                </span>
            </td>
            <td width={150} align="center" className={`${lastRow ? "last-row" : ""} ${isTaskSelected ? "selected-row-middle" : ""}`}>
                {( p.task.files && p.task.files?.length!==0 ) ?
                    <Button className={'task-files-button'} style={{backgroundColor: "inherit", textDecoration: 'underline', color: "blue"}} sx={{fontSize: 1}} onClick={() => {showModal(() => ShowAvailableTaskFilesModal({projectId: p.projectId,taskId: p.task.id, files: p.task.files!, refreshProject: refreshListWithID}), {})}} >FILES AVAILABLE</Button>
                    : (auth.hasFMRoleAccess()) ?
                    <Button className={'task-files-button'} style={{backgroundColor: "inherit", textDecoration: 'underline', color: "blue"}} sx={{fontSize: 1}} onClick={() => {showModal(() => LinkFilesToTaskModalGivenTask({taskId: p.task.id, refreshProject: refreshListWithID}), {})}} >LINK FILES</Button>
                    : <p style={{ fontSize: 13, letterSpacing: 1.5 }}>NO FILES</p>
                }
            </td>
            {customFields
                .filter(cf => p.projectCustomFields && p.projectCustomFields[cf.shortName as CustomFieldKeyTypes])
                .map(customField =>
                    <td width={100} key={customField.id} className={`${lastRow ? "last-row" : ""} ${isTaskSelected ? "selected-row-middle" : ""}`}>
                        <TaskListCustomFieldInput customFieldId={customField.id}
                            taskId={p.task.id}
                            value={getCustomFieldValue(customField.id)}
                            saveValue={saveTaskCustomField} />
                    </td>)
            }
            {
                project.customInsertedCustomFields?.map(customField =>
                    <td width={100} key={customField.id} className={`${lastRow ? "last-row" : ""} ${isTaskSelected ? "selected-row-middle" : ""}`}>
                        <TaskListCustomFieldInput customFieldId={customField.id}
                                                  taskId={p.task.id}
                                                  value={getCustomFieldValue(customField.id)}
                                                  saveValue={saveTaskCustomField} />
                    </td>)
            }
            <td className={`${lastRow ? "last-row" : ""} ${isTaskSelected ? "selected-row-last" : ""}`}></td>
        </tr>
        {arrangeInDescendingOrder(displayedSubtasks).length > 0 && arrangeInDescendingOrder(displayedSubtasks).map((subtask, index) =>
            <ProjectSubtask
                onDragStartEvent={onDragStart}
                onDragOverEvent={onDragOver}
                onDropEvent={onDrop}
                key={subtask.id} subtask={subtask} parentId={p.task.id} projectName={p.projectName}
                last={p.last && index === displayedSubtasks?.length - 1} taskListName={p.taskListName}
                isTemplate={p.isTemplate} refreshList={refreshListWithID} isScroll={p.isScroll}
                projectCustomFields={p.projectCustomFields} projectId={p.projectId}
                saveTaskCustomField={saveTaskCustomField} fetchTask={p.fetchTask} />)}
    </>
}


export type CustomFieldKeyTypes = keyof ProjectCustomFields;


export function TaskListCustomFieldInput(p: {
    taskId: number, customFieldId: number, value: string,
    saveValue: (taskId: number, customFieldId: number, value: string) => void
}) {


    const textFieldRef = useRef(null);
    const [textFieldValue, setTextFieldValue] = useState("");
    const [edit, setEdit] = useState(false);
    const auth = useAuthService();


    useEffect(() => {
        setTextFieldValue(p.value);
    }, [p.value]);




    useEffect(() => {
        if (edit && textFieldRef.current !== null) {
            (textFieldRef.current as HTMLInputElement).focus();
        }
    }, [edit]);



    const keyUpHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            saveValue();
        }
    }


    const saveValue = () => {
        p.saveValue(p.taskId, p.customFieldId, textFieldValue);
        setEdit(false);
    }


    return !edit || !auth.hasPMRoleAccess() || p.taskId===-1 ?
        <div style={{
            width: "100%", textAlign: "center", cursor: auth.hasPMRoleAccess() ? "pointer" : "initial", color: "#58595B",
            fontSize: 13, letterSpacing: 1.5, textTransform: "uppercase"
        }}
            onClick={() => setEdit(true)}>
            {textFieldValue || "-"}
        </div>
        :
        <TextField
            inputRef={textFieldRef}
            size="small"
            sx={{ height: 32, width: 86 }}
            inputProps={{
                sx: {
                    height: 32, padding: "0 5px", fontSize: 13, color: "#58595B",
                    letterSpacing: 1.5, textTransform: "uppercase"
                }
            }}
            value={textFieldValue}
            onChange={(ev) => setTextFieldValue(ev.target.value)}
            onKeyUp={keyUpHandler}
            onBlur={saveValue}
        />
}
