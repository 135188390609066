import React, { useState } from "react";
import _ from "lodash";
import { Node } from "../../services/folderDragAndDrop";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"; // Down arrow
import ArrowRightIcon from "@mui/icons-material/ArrowRight"; // Right arrow

interface FolderTreeProps {
  node: Node;
  handleSelectTargetFolderToCopySourceFolder?: Function;
}

function FolderTreeSourceForCopyFolderAndFile(props: FolderTreeProps) {
  const { node } = props;

  const [isOpen, setIsOpen] = useState(true);

  const children = (() => {
    const child = _.cloneDeep(node?.children ?? []);
    const newChild = child?.reverse?.();
    return newChild || [];
  })();

  return (
    <>
      <div
        style={{
          cursor: "grab",
          display: "flex",
          alignItems: "center",
        }}
      >
        {node.type === "folder" ? (
          <>
            <span
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </span>
            <span>📁 {node.name}</span>
          </>
        ) : (
          <span style={{ marginLeft: 34, color: !!node?.isFileAlreadyExistsInTarget ? "darkred" : "inherit" }}>
            📄 {node.name}
            <span>
              {!!node?.isFileAlreadyExistsInTarget && (
                <span
                  style={{ color: "red", marginLeft: "10px", fontSize: "12px" }}
                >
                  - Duplicate file detected. Skipping copy for this file.
                </span>
              )}
            </span>
          </span>
        )}
      </div>

      {isOpen && node.children && node.children.length > 0 && (
        <div style={{ marginLeft: "20px" }}>
          {children.map((child) => (
            <FolderTreeSourceForCopyFolderAndFile key={child.id} node={child} />
          ))}
        </div>
      )}
    </>
  );
}

export default FolderTreeSourceForCopyFolderAndFile;
