import { ArrowBack } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, IconButton } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridRowsProp,
  GridToolbar
} from "@mui/x-data-grid-pro";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UsersAvatarList } from "../../../../components/users-avatar-list";
import { UsersSelect } from "../../../../components/users-select";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hook";
import { Asset } from "../../../../models/Asset";
import { BaseAsset } from "../../../../models/BaseAsset";
import { InfoTenantUser } from "../../../../models/InfoTenantUser";
import { actionBarIsVisibleSelector } from "../../../../redux/action-bar";
import { loadAssets, loadedAssetsSelector } from "../../../../redux/assets";
import { hideProgressLine, showProgressLine } from "../../../../redux/progress-line";
import { showSnackbar } from "../../../../redux/snackbar";
import { usersSelector } from "../../../../redux/users";
import { userSettingsSelector } from "../../../../redux/userSettings";
import { getApiAssets, postApiAssetsAssignUsers } from "../../../../services/assets";
import { fetchUsers } from "../../../../services/init";
import { convertDateTimeFromUTCToTimeZoneIncludeTime } from "../../../../services/utils";
import { useCustomModal } from "../../../modals/custom-message-modal";
import "../../../pages.scss";
import AddAssetForm from "./addAssets";
import "./assets.scss";
import AssetStatus from "./assetStatus";
import LinkedProjectsModal from "./linkedProjectsModal";
import { CimAssetDelete } from "../../wizards/cim-asset-delete";


const mockEditAssetData = (data: Asset): BaseAsset & { id: number } => {
  const editData = {
    id: data.id,
    name: data.name,
    genericName: data.genericName,
    description: data.description,
    moreInformationUrl: data.moreInformationUrl,
    status: data.status,
    assignees: data?.assignees?.map((assignee) => assignee.id)
  };
  return editData;
};

export function Assets() {
  const dispatch = useAppDispatch();
  const actionBarIsVisible = useAppSelector((state) =>
    actionBarIsVisibleSelector(state)
  );
  const nav = useNavigate();
  const { showModal, hideModal } = useCustomModal();
  const [assetToLinkProject,setAssetToLinkProject] = useState<Asset | null>(null);
  const loaded = useAppSelector((state) => loadedAssetsSelector(state));
  const assetsObj = useAppSelector((state) => state.assets);
  const assets = assetsObj.assets;

  const loadAssetsData = useCallback(async () => {
    dispatch(showProgressLine());
    const assetsList = await getApiAssets();
    dispatch(loadAssets(assetsList));
    dispatch(hideProgressLine());

  }, [dispatch]);

  useEffect(() => {
    loadAssetsData();
  }, [loadAssetsData])

  const users = useAppSelector((state) => usersSelector(state, false))
  const assetsTableHeaders = [
    "Asset Name",
    "Generic Name",
    "Description",
    "Status",
    "More Information (Url)",
    "Owner",
    "Linked Projects",
    "Created By",
    "Created At",
    "Action",
  ];
  const userSettings = useAppSelector((state) => userSettingsSelector(state));
  const dateFormat = userSettings?.regional.dateFormat || "MM/DD/YYYY";
  const timeFormat = userSettings?.regional.timeFormat || 24;
  const timezone  = userSettings?.regional.timezone || "UTC";


  useEffect(() => {
    const controller = new AbortController();
    fetchUsers(controller.signal, dispatch);
    return () => controller.abort();
  }, []);


  const addUser = (assetId: number, userIds: number | number[] | undefined) => {
    if (userIds && Array.isArray(userIds)) {
      dispatch(showProgressLine());
      postApiAssetsAssignUsers({ assignees: userIds || [], assetId }).then(() => {
        loadAssetsData().then(() => {
          dispatch(hideProgressLine());
        })

      }).catch((e) => {
        dispatch(showSnackbar({ message: "Error adding owner of asset!", type: "info" }));
      })
      dispatch(hideProgressLine());
    }
  }

  const removeUser = (assetId: number) => {

    dispatch(showProgressLine());
    postApiAssetsAssignUsers({ assignees: [], assetId }).then(() => {
      loadAssetsData().then(() => {
        dispatch(hideProgressLine());
      })

    }).catch((e) => {
      dispatch(showSnackbar({ message: "Error removing owner of asset!", type: "info" }));
      dispatch(hideProgressLine());
    })
    dispatch(hideProgressLine());

  }
  const filteredUsers = (selectedUsers: InfoTenantUser[], users: InfoTenantUser[]) => {
    const allUsers = users.filter(u => selectedUsers.some(su => su.id === u.id));
    return allUsers
  }

  const openLinkedProjectsModal = (asset:Asset) => {
    setAssetToLinkProject(asset);
  };

  const onCloseLinkProject = ()=>{
    setAssetToLinkProject(null);
  }
  function getColumns() {
    let columnsObject: GridColDef[] = [];
    assetsTableHeaders.forEach((header, index) => {
      if (index === 1) {
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 100,
          flex: 2,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
        });
      }
      else if (index === 2) {
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 150,
          flex: 2,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
        });
      }
      else if (index === 3) {
        columnsObject.push({
          field: header,
          headerName: header,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
          renderCell: (params) => <div><AssetStatus status={params.value} /></div>,
        });
      }
      else if (index === 4) {
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 100,
          flex: 2,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
        });
      }
      else if (index === 5) {
        columnsObject.push({
          field: header,
          headerName: header,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
          renderCell: (params) => {
            const selectedAssignees = params.value?.assignees || [];
            return (
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>

                {
                  selectedAssignees?.length ? <UsersAvatarList users={filteredUsers(selectedAssignees || [], users || [])} removeUser={() => removeUser(params.value.id)} shortView={false} /> : <UsersSelect
                    allUsers={users || []}
                    selectedUsers={filteredUsers(selectedAssignees || [], users || [])}
                    maxAvatars={1}
                    multiple={false}
                    onChange={(userIds) => addUser(params.value.id, userIds)}
                    showAvatar={true}
                  />
                }

              </div>
            );
          },
        });
      }else if (index === 6) {  // Assuming column index for linked projects
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 120,
          flex: 1,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
          renderCell: (params) => (
            <Box style={{display: "flex", justifyContent: "center",flex:1}}>
            <Button variant="text" onClick={() => openLinkedProjectsModal(params.value)}>
              {params.value?.linkedProjects?.length || 0 }
            </Button>
            </Box>
          ),
        });
      }
      else if (index === 9) {
        columnsObject.push({
          field: header,
          headerName: header,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
          renderCell: (params) => {
            return (
              <Box>
              <IconButton onClick={() => onEditAssets(params)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => onDeleteAsset(params)}>
                <DeleteIcon />
              </IconButton>
              </Box>
            );
          },
        });
      } else {
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 120,
          width: index === 6 ? 160 : 120,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
        });
      }
    });
    return columnsObject;
  }

  function onEditAssets(params: any) {
    showModal(AddAssetForm, {
      hideModal,
      onRefersh: loadAssetsData,
      defaultData: mockEditAssetData(params.value),
    });
  }

  function onDeleteAsset(params: any) {
    showModal(CimAssetDelete, {
      id:params.value.id,
      name:params.value.name
    });
  }

  function getProjectsRows() {
    let rows: GridRowsProp = [];
    for (let asset of assets) {
      rows = rows.concat({
        id: asset.id,
        [assetsTableHeaders[0]]: asset.name,
        [assetsTableHeaders[1]]: asset.genericName,
        [assetsTableHeaders[2]]: asset.description,
        [assetsTableHeaders[3]]: asset.status,
        [assetsTableHeaders[4]]: asset.moreInformationUrl,
        [assetsTableHeaders[5]]: asset,
        [assetsTableHeaders[6]]: asset,
        [assetsTableHeaders[7]]: asset.createdByName,
        [assetsTableHeaders[8]]: convertDateTimeFromUTCToTimeZoneIncludeTime(asset.createdAt, timezone, dateFormat, timeFormat),
        [assetsTableHeaders[9]]: asset,
      });
    }

    return rows;
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="body-container">
        <div className="page-wrapper" style={{ padding: 0, flexGrow: 1, width: "96%" }}>
          <div
            className="page-header-small"
            style={{ padding: "12px 16px", justifyContent: "space-between" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <ArrowBack className="arrow-back" onClick={() => nav(-1)} />
              {"Assets"}
            </div>
            <div>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  showModal(AddAssetForm, {
                    hideModal,
                    onRefersh: loadAssetsData,
                    defaultData: null,
                  });
                }}
              >
                ADD ASSET
              </Button>
            </div>
          </div>
          <div
            style={{
              height: `calc(100vh - var(--headerHeight) - var(--footerHeight) - 176px${actionBarIsVisible ? " - 60px" : ""
                }`,
              overflowY: "auto",
            }}
            id="assets-data-0"
          >

            {loaded ? (
              <>
                {assets.length === 0 ? (
                  <div
                    style={{
                      margin: 32,
                      backgroundColor: "var(--lightGrayishBlue)",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: 205,
                    }}
                  >
                    <div
                      style={{
                        marginTop: 32,
                        marginBottom: 40,
                        textAlign: "center",
                        fontSize: 21,
                        fontWeight: 600,
                        letterSpacing: 0.15,
                      }}
                    >
                      There is no Assets added.
                    </div>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          showModal(AddAssetForm, {
                            hideModal,
                            onRefersh: loadAssetsData,
                            defaultData: null,
                          });
                        }}
                      >
                        ADD ASSET
                      </Button>
                  </div>
                ) : (
                  <div style={{ paddingTop: 16, overflow: "hidden" }}>
                    <Box>
                      <DataGridPro
                        autoPageSize={false}
                        autoHeight={true}
                        rowSpacingType={"border"}
                        rows={getProjectsRows()}
                        sx={{
                          "& .MuiDataGrid-cell": {
                            padding: 0,
                          },
                        }}
                        disableDensitySelector
                        disableColumnSelector
                        columns={getColumns()}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                        pagination
                        initialState={{
                          pagination: { paginationModel: { pageSize: 5 } },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        keepColumnPositionIfDraggedOutside={true}
                      />
                    </Box>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}

          </div>
        </div>
      </div>
     {assetToLinkProject && <LinkedProjectsModal asset={assetToLinkProject} onClose={onCloseLinkProject}/>}
    </div>
  );
}
