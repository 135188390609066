import { useState, useRef, createContext, useEffect, useMemo } from "react";
import _ from "lodash";
import {
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  UniqueIdentifier,
  useDndMonitor,
} from "@dnd-kit/core";
import {
  addNodeById,
  findNodeDetailsById,
  findNodePathById,
  getAllFileListFromSchema,
  moveNodeByIds,
  updateNodeById,
} from "../services/folderDragAndDrop";
import { Node } from "../services/folderDragAndDrop";
import DraggableOverlay from "../components/dndKit/DraggableOverlay";
import {
  patchApiRenameFolder,
  postApiCreateNewFolder,
  putApiUpdateParentFolderById,
} from "../services/folder";
import { BaseFolder } from "../models/BaseFolder";

type ProjectFolderManagementContextProps = {
  folderStructure: Node[];
  allFileObjectList: Node[];
  fileObjectList: Node[];
  activeFolderDetails: Node | null;
  activeFolderPath: Node[];
  expandedFolderIdList: string[];
  selectedNodes: Node[];
  selectedFiles: any[];
  isDisableCompareButton: boolean;
  isShowFolderTreeView: boolean;
  setIsDisabledCompareButton: (isDisabled: boolean) => void;
  setSelectedFiles: (files: any[]) => void;
  handleInitialRendering: (schema?: Node[]) => void;
  handleToggleFolder: (
    id: string,
    isOpenMandatory: boolean | undefined
  ) => void;
  handleSetActiveNode: (id: string, folderStructureJson?: Node[]) => void;
  handleMultiSelectUnselectNode: (node: Node) => void;
  handleMultiSelectNode: (selectedNodes: Node[]) => void;
  handleCreateNewFolder: (params: BaseFolder) => Promise<boolean | undefined>;
  handleChangeFilesView: () => void;
  handleRenameFolder: (
    node: Node,
    newName: string
  ) => Promise<boolean | undefined>;
};

export const ProjectFileManagementContext =
  createContext<ProjectFolderManagementContextProps>({
    folderStructure: [],
    allFileObjectList: [],
    fileObjectList: [],
    activeFolderDetails: null,
    activeFolderPath: [],
    expandedFolderIdList: [],
    selectedNodes: [],
    selectedFiles: [],
    isDisableCompareButton: true,
    isShowFolderTreeView: true,
    setIsDisabledCompareButton: () => {},
    setSelectedFiles: () => {},
    handleInitialRendering: () => {},
    handleToggleFolder: () => {},
    handleSetActiveNode: () => {},
    handleMultiSelectUnselectNode: () => {},
    handleMultiSelectNode: () => {},
    handleCreateNewFolder: async ({ name, parentFolderId, projectId }) => {
      return false;
    },
    handleChangeFilesView: () => {},
    handleRenameFolder: async (node: Node, newName: string) => {
      return false;
    },
  });

function ProjectFileManagementContextProvider(props: {
  children:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | null
    | undefined;
}) {
  const holdTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const currentDroppableRef = useRef<UniqueIdentifier | null>(null);

  const [isDragging, setIsDragging] = useState(false);

  const [folderStructure, setFolderStructure] = useState<Node[]>([]);
  const [activeFolderId, setActiveFolderId] = useState("");

  const [expandedFolderIdList, setExpandedFolderIdList] = useState<string[]>(
    []
  ); // Tracks open folders by their IDs
  const [selectedNodes, setSelectedNodes] = useState<Node[]>([]);

  const [isShowFolderTreeView, setIsShowFolderTreeView] = useState(true);

  /** table selected files */
  const [selectedFiles, setSelectedFiles] = useState<Node[]>([]);

  /** compare button */
  const [isDisableCompareButton, setIsDisabledCompareButton] = useState(true);

  const activeFolderDetails = (() => {
    const details =
      findNodeDetailsById(folderStructure, activeFolderId) || null;
    return details;
  })();

  const activeFolderPath = (() => {
    const path = findNodePathById(folderStructure, activeFolderId);
    return path;
  })();

  useDndMonitor({
    onDragStart(event) {
      handleDragStart(event);
    },
    onDragOver(event) {
      const activeType = event.active?.data.current?.dndType;
      const allowedTypes = event.over?.data.current?.allowedDndTypes;
      if (allowedTypes?.includes(activeType)) {
        handleDragOver(event);
      }
    },
    onDragEnd(event) {
      const activeType = event.active?.data.current?.dndType;
      const allowedTypes = event.over?.data.current?.allowedDndTypes;
      if (allowedTypes?.includes(activeType)) {
        handleDragEnd(event);
      } else {
        handleNodeChangeCleanup();
      }
    },
  });

  const handleInitialRendering = (schema: Node[] = folderStructure) => {
    const initialId = schema?.[0]?.id;
    setFolderStructure(schema);
    handleSetActiveNode(initialId, schema);
  };

  const handleToggleFolder = (
    id: string,
    isOpenMandatory: boolean | undefined
  ) => {
    let newOpenFolderIdList = [];

    if (isOpenMandatory) {
      newOpenFolderIdList = expandedFolderIdList.includes(id)
        ? [...expandedFolderIdList]
        : [...expandedFolderIdList, id];
    } else {
      newOpenFolderIdList = expandedFolderIdList.includes(id)
        ? expandedFolderIdList.filter((folderId) => folderId !== id)
        : [...expandedFolderIdList, id];
    }

    setExpandedFolderIdList(newOpenFolderIdList);
  };

  const handleMultiSelectUnselectNode = (node: Node) => {
    const isNodeSelected = selectedNodes.some(
      (selectedNode) => selectedNode.id === node.id
    );
    if (isNodeSelected) {
      setSelectedNodes(
        selectedNodes.filter((selectedNode) => selectedNode.id !== node.id)
      );
    } else {
      setSelectedNodes([...selectedNodes, node]);
    }
  };

  const handleMultiSelectNode = (selectedNodes: Node[]) => {
    setSelectedNodes([...selectedNodes]);
  };

  const handleSetActiveNode = (
    id: string,
    folderStructureJson = folderStructure
  ) => {
    setActiveFolderId(id);
    handleToggleFolder(id, true);

    handleNodeChangeCleanup();
  };

  const handleDropHold = (event: DragOverEvent) => {
    if (event?.over?.id) {
      handleToggleFolder(`${event.over.id}`, true);
    }
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    if (over) {
      const newDroppableId = over.id;
      if (currentDroppableRef.current !== newDroppableId) {
        if (holdTimeoutRef.current) {
          clearTimeout(holdTimeoutRef.current);
        }

        currentDroppableRef.current = newDroppableId;
        holdTimeoutRef.current = setTimeout(() => {
          /** @description call handleDropHold function */
          handleDropHold(event);
        }, 500);
      }
    } else {
      /** Clear hold timer if no longer over a droppable area */
      if (holdTimeoutRef.current) {
        clearTimeout(holdTimeoutRef.current);
      }
      currentDroppableRef.current = null;
    }
  };

  const handleDragStart = (event: DragStartEvent) => {
    if (selectedNodes.length === 0 && event.active.data.current) {
      handleMultiSelectUnselectNode(event.active.data.current as Node);
    }
    setIsDragging(true);
    // Clear any existing hold timer
    if (holdTimeoutRef.current) {
      clearTimeout(holdTimeoutRef.current);
    }
  };

  const apiUpdateParentFolderById = async ({
    parentFolderId,
    fileIdList,
    folderIdList,

    originalSchema,
  }: {
    parentFolderId: number;
    folderIdList?: number[];
    fileIdList?: number[];

    originalSchema: Node[];
  }) => {
    try {
      const body = {
        fileIdList: fileIdList || [],
        folderIdList: folderIdList || [],
      };
      await putApiUpdateParentFolderById(parentFolderId, body);
    } catch (error) {
      setFolderStructure(originalSchema);
      if (activeFolderDetails) {
        handleSetActiveNode(activeFolderDetails.id, originalSchema);
      }
    }
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) {
      return;
    }

    const originalSchema = _.cloneDeep(folderStructure);
    const overId = over.id;

    const parentFolderId = over.data.current?.folderId;
    if (!parentFolderId) {
      return;
    }

    const folderIdList: number[] = [];
    const fileIdList: number[] = [];
    const selectedNodesIds = selectedNodes.map((nodeItem) => {
      if (nodeItem.type === "folder" && nodeItem.folderId) {
        folderIdList.push(nodeItem.folderId);
      } else if (nodeItem.type === "file" && nodeItem.fileId) {
        fileIdList.push(nodeItem.fileId);
      }
      return nodeItem.id;
    });

    if (fileIdList.length === 0 && folderIdList.length === 0) {
      return;
    }

    const { updatedSchema: updatedStructure, isNodeMoved } = moveNodeByIds(
      folderStructure,
      `${overId}`,
      selectedNodesIds
    );

    if (!isNodeMoved) {
      return;
    }

    setFolderStructure(updatedStructure);
    if (activeFolderDetails) {
      handleSetActiveNode(activeFolderDetails.id, updatedStructure);
    }

    setSelectedNodes([]);

    // Clear any existing hold timer
    setIsDragging(false);
    handleNodeChangeCleanup();

    apiUpdateParentFolderById({
      parentFolderId: parentFolderId,
      fileIdList,
      folderIdList,
      originalSchema: originalSchema,
    });
  };

  /**@description handleCreateFolder */
  const handleCreateNewFolder = async ({
    name,
    parentFolderId,
    projectId,
  }: BaseFolder): Promise<boolean | undefined> => {
    try {
      const folderData = await postApiCreateNewFolder({
        name: name,
        parentFolderId: Number(parentFolderId),
        projectId: projectId,
      });

      const newFolder = {
        id: `folder_${folderData.id}`,
        folderId: folderData.id,
        name: folderData.name,
        type: "folder",
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        children: [],
      };

      if (activeFolderDetails) {
        const { updatedSchema, isNodeAdded } = addNodeById(
          folderStructure,
          `folder_${folderData.parentFolderId}`,
          newFolder
        );

        if (isNodeAdded) {
          setFolderStructure(updatedSchema);
        }
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  /**@description handleRenameFolder */
  const handleRenameFolder = async (
    node: Node,
    newName: string
  ): Promise<boolean | undefined> => {
    try {
      if (!node.folderId) {
        return;
      }
      await patchApiRenameFolder(node.folderId, { name: newName });

      const newNode = { ...node, name: newName };

      const { updatedSchema, isNodeUpdated } = updateNodeById(
        folderStructure,
        newNode
      );

      if (isNodeUpdated) {
        setFolderStructure(updatedSchema);
        return true;
      }
    } catch (error) {
      return;
    }
  };

  /**@description handleDeleteFolder */
  const handleDeleteFolder = async (node: Node) => {};

  const handleNodeChangeCleanup = () => {
    setSelectedNodes([]);
    setSelectedFiles([]);
    setIsDisabledCompareButton(true);
  };

  /**@description show folder view or directly file view */
  const handleChangeFilesView = () => {
    handleInitialRendering();
    handleNodeChangeCleanup();
    setIsShowFolderTreeView((prevProps) => !prevProps);
  };

  const allFileObjectList = useMemo(() => {
    const node = folderStructure?.[0];
    const fileList = getAllFileListFromSchema(node, true);

    return fileList;
  }, [folderStructure]);

  const fileObjectList = useMemo(() => {
    const fileList = getAllFileListFromSchema(
      activeFolderDetails,
      !isShowFolderTreeView
    );

    return fileList;
  }, [activeFolderDetails, isShowFolderTreeView]);

  const contextValue = {
    folderStructure,
    allFileObjectList,
    fileObjectList,
    activeFolderDetails,
    activeFolderPath,
    expandedFolderIdList,
    selectedNodes,
    selectedFiles,
    isDisableCompareButton,
    isShowFolderTreeView,
    setIsDisabledCompareButton,
    setSelectedFiles,
    handleInitialRendering,
    handleToggleFolder,
    handleSetActiveNode,
    handleMultiSelectUnselectNode,
    handleMultiSelectNode,
    handleCreateNewFolder,
    handleChangeFilesView,
    handleRenameFolder,
  };

  const fileName = selectedNodes.map((nodeItem) => nodeItem.name).join(", ");
  const fileNumber = selectedNodes.length;

  return (
    <ProjectFileManagementContext.Provider value={contextValue}>
      {props.children}

      {isDragging && (
        <DragOverlay>
          <DraggableOverlay fileName={fileName} fileNumber={fileNumber} />
        </DragOverlay>
      )}
    </ProjectFileManagementContext.Provider>
  );
}

export default ProjectFileManagementContextProvider;
