import { useState, useEffect, useRef } from 'react';

type TimerState = {
  totalSeconds: number;
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
  isRunning: boolean;
};

type TimerHook = {
  totalSeconds: number;
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
  isRunning: boolean;
  start: () => void;
  pause: () => void;
  resume: () => void;
  restart: (expiryTimestamp: Date, autoStart?: boolean) => void;
};

const useTimer = ({
  expiryTimestamp,
  onExpire,
  autoStart = true,
}: {
  expiryTimestamp: Date;
  onExpire?: () => void;
  autoStart?: boolean;
}): TimerHook => {
    
  const calculateInitialTime = () => {
    const remainingSeconds = Math.max(0, Math.floor((expiryTimestamp.getTime() - new Date().getTime()) / 1000));
    const days = Math.floor(remainingSeconds / (3600 * 24));
    const hours = Math.floor((remainingSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((remainingSeconds % 3600) / 60);
    const seconds = remainingSeconds % 60;
    return { totalSeconds: remainingSeconds, days, hours, minutes, seconds };
  };

  const [timerState, setTimerState] = useState<TimerState>(() => {
    const initialTime = calculateInitialTime();
    return { ...initialTime, isRunning: autoStart };
  });

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const calculateTime = () => {
    const remainingSeconds = Math.max(0, Math.floor((expiryTimestamp.getTime() - new Date().getTime()) / 1000));
    const days = Math.floor(remainingSeconds / (3600 * 24));
    const hours = Math.floor((remainingSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((remainingSeconds % 3600) / 60);
    const seconds = remainingSeconds % 60;

    setTimerState({
      totalSeconds: remainingSeconds,
      seconds,
      minutes,
      hours,
      days,
      isRunning: remainingSeconds > 0,
    });

    if (remainingSeconds <=0 && timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  const start = () => {
    if (!timerRef.current) {
      timerRef.current = setInterval(calculateTime, 1000);
    }
  };

  const pause = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    setTimerState(prevState => ({
      ...prevState,
      isRunning: false,
    }));
  };

  const resume = () => {
    if (!timerRef.current) {
      timerRef.current = setInterval(calculateTime, 1000);
      setTimerState(prevState => ({
        ...prevState,
        isRunning: true,
      }));
    }
  };

  const restart = (newExpiryTimestamp: Date, autoStart: boolean = true) => {
    expiryTimestamp = newExpiryTimestamp;
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    const initialTime = calculateInitialTime();
    setTimerState({
      ...initialTime,
      isRunning: autoStart,
    });
    if (autoStart) {
      start();
    }
  };

  useEffect(() => {
    calculateTime(); // Set the initial time
    if (autoStart) {
      start();
    }
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [expiryTimestamp, autoStart]);

  useEffect(() => {
    if (timerState.totalSeconds <= 0 && onExpire) {
      onExpire();
    }
  }, [timerState.totalSeconds]);

  return {
    totalSeconds: timerState.totalSeconds,
    seconds: timerState.seconds,
    minutes: timerState.minutes,
    hours: timerState.hours,
    days: timerState.days,
    isRunning: timerState.isRunning,
    start,
    pause,
    resume,
    restart,
  };
};

export default useTimer;
