import { SubmissionType } from "../models/SubmissionType";
import { Project } from "../models/Project";
import { BaseProject } from "../models/BaseProject";
import { AssignUsersToProject } from "../models/AssignUsersToProject";
import { ProjectDetails } from "../models/ProjectDetails";
import { ProjectCustomFields } from "../models/ProjectCustomFields";
import { ProjectStatusDates } from "../models/ProjectStatusDates";
import { baseUrl, headers } from "./config";
import { fetchAndParse, addQueryParameter } from "./utils";
import {Pattern} from "../models/Pattern";
import { ProjectIsArchived } from "../models/ProjectIsArchived";

export function getApiProjectSubmissionTypes(signal?: AbortSignal): Promise<SubmissionType[]> {
    const url = new URL(`/api/project/submission-types`, baseUrl);
    return fetchAndParse<SubmissionType[]>(url.toString(), { method: "GET", headers, signal });
}
export function getApiProjects(signal?: AbortSignal): Promise<Project[]> {
    const url = new URL(`/api/projects`, baseUrl);
    return fetchAndParse<Project[]>(url.toString(), { method: "GET", headers, signal });
}
export function getApiProjectsById(id: number, signal?: AbortSignal): Promise<Project> {
    const url = new URL(`/api/projects/${id}`, baseUrl);
    return fetchAndParse<Project>(url.toString(), { method: "GET", headers, signal });
}
export function getApiProjectsWithoutFilesById(id: number, signal?: AbortSignal): Promise<Project> {
    const url = new URL(`/api/projects/${id}/getProjectWithoutFiles`, baseUrl);
    return fetchAndParse<Project>(url.toString(), { method: "GET", headers, signal });
}
export function deleteApiProjectsById(id: number, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/projects/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "DELETE", headers, signal });
}
export function postApiProjectsCreate(body?: BaseProject, signal?: AbortSignal): Promise<Project> {
    const url = new URL(`/api/projects/create`, baseUrl);
    return fetchAndParse<Project>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function postApiProjectsAssignUsers(body?: AssignUsersToProject, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/projects/assign-users`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function putApiProjectsProjectDetailsById(id: number, body?: ProjectDetails, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/projects/projectDetails/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function putApiProjectsProjectCustomFieldsById(id: number, body: ProjectCustomFields, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/projects/project-custom-fields/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function putApiProjectsProjectStatusDatesById(id: number, body?: ProjectStatusDates, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/projects/project-status-dates/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function getApiProjectsExistingCustomFields(signal?: AbortSignal): Promise<string[]> {
    const url = new URL(`/api/projects/existing-custom-fields`, baseUrl);
    return fetchAndParse<string[]>(url.toString(), { method: "GET", headers, signal });
}
export function putApiUpdateProject(id: number, body: Project, signal?: AbortSignal) {
    const url = new URL(`/api/projects/${id}`, baseUrl)
    const projectWithUsers = {...body, users: body.users? body.users.map(user => user.id) : []}
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(projectWithUsers), headers, signal });
}
export function putApiProjectIsArchived(id: number, body: ProjectIsArchived, signal?: AbortSignal) {
    const url = new URL(`/api/projects/isArchived/${id}`, baseUrl)
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
