import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

const initialState = {
 
};

const nlpSlice = createSlice({
  name: "nlp",
  initialState: initialState,
  reducers: {},
});



export default nlpSlice.reducer;
