import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Button,
    createTheme,
    Dialog,
    FormControl,
    IconButton,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    ThemeProvider,
    Typography
} from "@mui/material";
import React, { useState } from "react";
import { useAppSelector } from "../../../hooks/redux-hook";

interface CIMRedcationBulkEditModalProps {
  onClose: () => void;
  onBulkEdit: (action: string, data: { selectedCategory?: string; ciEffectiveDate?: string }) => void;
}

const CIMRedcationBulkEditModal: React.FC<CIMRedcationBulkEditModalProps> = ({ onClose, onBulkEdit }) => {
  const categoriesObj = useAppSelector((state) => state.categories);
  const categories = categoriesObj.categories;
  const [ciEffectiveDate, setCiEffectiveDate] = useState<string | undefined>(undefined);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [action, setAction] = useState("ciEffectiveDate");

  const handleCategoryChange = (e: SelectChangeEvent<string>) => {
    setSelectedCategory(e.target.value);
  };

  const handleActionChange = (e: SelectChangeEvent<string>) => { 
    setCiEffectiveDate("");
    setSelectedCategory("");
    setAction(e.target.value as string);
  }

  return (
      <ThemeProvider theme={(theme) =>
          createTheme({
            ...theme,
            components: {
              MuiPopover: {
                styleOverrides: {
                  root: {
                    zIndex: 13001,
                  },
                },
              },
              MuiMenu: {
                styleOverrides: {
                  paper: {
                    zIndex: 12000,
                  },
                },
              },
            },
            
          })
        }>
    <Dialog open sx={{zIndex:13000}}>
       <Paper
        elevation={4}
        sx={{
          backgroundColor: "#ffffff",
          padding: "10px",
          position: "relative",
          borderRadius: "12px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          maxWidth: "600px",
          margin: "auto",
        }}
      >
        <Box style={{display:'flex',justifyContent:'flex-end'}}>
        <IconButton
              onClick={onClose}
              sx={{
                backgroundColor: "#f5f5f5",
                "&:hover": { backgroundColor: "#e0e0e0" },
                height:'38px',
              }}
            >
              <CloseIcon />
            </IconButton>
        </Box>
      <Stack  spacing={2} sx={{ position: "relative",padding:'10px'}}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          <Typography variant="body2" sx={{ marginBottom: "4px", fontWeight: "bold" }}>
            Select Action:
          </Typography>
          <FormControl sx={{ width: 350 }}>
            <Select
              value={action}
              onChange={handleActionChange}
              size="small"
            >
              {/* <MenuItem value="category">Category</MenuItem> */}
              <MenuItem value="ciEffectiveDate">CI Effective Date</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Category Dropdown */}
        {action === "category" && (
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <Typography variant="body2" sx={{ marginBottom: "4px", fontWeight: "bold" }}>
              Set Category:
            </Typography>
            <FormControl>
              <Select
                value={selectedCategory}
                displayEmpty
                onChange={handleCategoryChange}
                size="small"
                sx={{ height: 40, width: 350 }}
              >
                <MenuItem value="" disabled>
                  Select a Category
                </MenuItem>
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        {/* CI Effective Date Picker */}
        {action === "ciEffectiveDate" && (
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <Typography variant="body2" sx={{ marginBottom: "4px", fontWeight: "bold" }}>
              Set CI Effective Date:
            </Typography>
            <TextField
              type="date"
              value={ciEffectiveDate || ""}
              onChange={(e) => setCiEffectiveDate(e.target.value)}
              size="small"
              sx={{ width: 350 }}
            />
          </Box>
        )}

        {/* Apply Button */}
        <Button
          variant="contained"
          onClick={() =>
            onBulkEdit(action, action === "category" ? { selectedCategory } : { ciEffectiveDate })
          }
          disabled={action === "category" ? !selectedCategory : !ciEffectiveDate}
          sx={{ height:"40px" }}
          style={{marginTop:'30px'}}
        >
          Apply Bulk Edit
        </Button>

      </Stack>
      </Paper>
    </Dialog>
    </ThemeProvider>
  );
};

export default CIMRedcationBulkEditModal;