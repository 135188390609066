import isEqual from "lodash/isEqual";
import moment from "moment";

function isObject(value: any): value is Record<string, any> {
  return value !== null && typeof value === "object" && !Array.isArray(value);
}

interface GenericObject {[key: string]: any;}

const getUpdatedFieldsValues = (newObj: GenericObject | null | undefined, preObj: GenericObject): GenericObject => {
  if (!newObj || Object.keys(newObj).length < 1) return {};

  return Object.keys(newObj).reduce((diff: GenericObject, key: string) => {
    const newValue = newObj[key];
    const preValue = preObj[key];

    if (moment.isMoment(newValue) && moment.isMoment(preValue)) {
      if (!newValue.isSame(preValue)) {
        return { ...diff, [key]: newValue };
      }
      return diff;
    }

    if (Array.isArray(newValue) && Array.isArray(preValue)) {
      if (!isEqual(newValue, preValue)) {
        return { ...diff, [key]: newValue };
      }
      return diff;
    }

    if (isObject(newValue) && isObject(preValue)) {
      if (!isEqual(newValue, preValue)) {
        return { ...diff, [key]: newValue };
      }
      return diff;
    }

    if (newValue !== preValue) {
      return { ...diff, [key]: newValue };
    }

    return diff;
  }, {});
};

export { getUpdatedFieldsValues };
