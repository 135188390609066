import { useAuthService } from "../contexts/auth-context";

export function useCimUserRoles() {
    const auth = useAuthService();
    const roles = auth?.loginInfo?.tenant?.user?.roles || [];
  
    const isManager = roles.some(role => role === "CIM_MANAGER" || role === "SUPER" || role === "ADMIN");
    const isApprover = roles.some(role => role === "CIM_APPROVER");
    const isReviewer = roles.some(role => role === "CIM_REVIEWER");

    return { roles, isManager, isApprover, isReviewer };
  }