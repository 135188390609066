import {Virtuoso} from "react-virtuoso";
import {getOriginalText} from "../../../services/ReportGenerator";
import React, {useContext, useState} from "react";
import {getTransformStyle} from "./transform-panel";
import AnnotationFilterHelpers from "../AnnotationFilterHelpers";
import {Menu, MenuItem} from "@mui/material";
import WebViewerContext from "../../../contexts/webviewer-context";

export const TransformTable = (props: {
    currentAnnotations: any[],
    selectedAnnotations: any[],
    checkedAnnotations: any,
    onClickCheckbox: (annotation: any) => void,
    selectAnnotation: (annotation: any) => void,
    onReplacementChange: (annotation: any, event: any) => void,
    onChangeComplete: (annotation: any) => void,
    allowEdit: boolean
}) => {

    const {
        currentAnnotations,
        checkedAnnotations,
        selectedAnnotations,
        onClickCheckbox,
        selectAnnotation,
        onReplacementChange,
        onChangeComplete,
        allowEdit
    } = props;

    const [anchorEl, setAnchorEl] = useState<boolean>(false);
    const [position, setPosition] = useState({ left: 0, top: 0 });
    const { instance } = useContext(WebViewerContext);

    const handleCloseMenu = () => {
        setAnchorEl(false);
    };

    function onClickEditMark() {
        setAnchorEl(false)
        instance.Core.annotationManager.trigger('editMark')
    }

    function getStatus(annotation: any) {
        if (annotation.getStatus()===""){
            return "None"
        }
        return annotation.getStatus()
    }

    function onContextMenu(e: any, annotation: any) {
        e.preventDefault()
        selectAnnotation(annotation)
        setAnchorEl(true)
        let left = e.pageX
        let top = e.pageY - document.body.offsetHeight
        setPosition({left, top})
    }

    const annotationToolTip = (annotation: any) => {
        let toolTipText = 'type: ' + AnnotationFilterHelpers.getCategory(annotation) + '\n';
        toolTipText+= 'page: ' + annotation.getPageNumber() + '\n';
        toolTipText+= 'status: ' + getStatus(annotation) + '\n';
        toolTipText+= 'patient Id: ' + annotation.getCustomData("Patient ID") + '\n';
        toolTipText+= 'start date: ' + annotation.getCustomData("Start Date") + '\n';
        toolTipText+= 'study ID: ' + annotation.getCustomData("Study ID") + '\n';
        toolTipText+= 'font type: \n';
        toolTipText+= 'font size: \n';
        toolTipText+= 'replaced with: ' + annotation.getCustomData("replacedWith") + '\n';
        return toolTipText;
    }

    return (
        <div
            className={'transform_table_container'}
            style={{
                width: '100%',         // Ensure full width of parent
                maxWidth: '100%',       // Prevent overflowing
                display: 'flex',        // Use flexbox for layout
                flexDirection: 'column' // Stack header and table vertically
            }}
        >
            <div
                className={'table_header'}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',      // Ensure full width
                    flexShrink: 0       // Prevent header from shrinking
                }}
            >
                <div className={'transform_column_header'} style={{width:'6%'}}/>
                <div className={'transform_column_header'} style={{width:'43%'}}>
                    Before
                </div>
                <div className={'transform_column_header'} style={{width:'47%'}}>
                    After
                </div>
            </div>
            {/*The menu where you right click and select edit. Only shows on the transform tab, not the replaced tab.*/}
            {
                allowEdit && <Menu
                    id="simple-menu"
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    style={{...position, zIndex: 99999}}>
                    <MenuItem onClick={onClickEditMark}>Edit</MenuItem>
                </Menu>
            }
            <Virtuoso
                style={{
                    width: '100%',      // Ensure full width
                    maxWidth: '100%',   // Prevent overflowing
                    overflowY: 'auto',       // Enable vertical scrolling
                }}
                totalCount={currentAnnotations.length}
                itemContent={(index) => {
                    const annotation = currentAnnotations[index];
                    const {
                        backgroundColor,
                        textColor,
                        displayReplacementText
                    } = getTransformStyle(annotation, 'transform');

                    return (
                        <div className={'table_row'} title={annotationToolTip(annotation)} style={{display: 'flex', backgroundColor: `${index %2 == 0 ? 'white' : 'lightblue'}`}}>
                            <div className={'transform_table_cell'} style={{width:'6%', display: 'flex', alignItems: 'center'}}>
                                <input
                                    type="checkbox"
                                    readOnly
                                    onClick={() => onClickCheckbox(annotation)}
                                    checked={checkedAnnotations.some((checkedAnnotation: any) => checkedAnnotation === annotation)}
                                />
                            </div>
                            <div
                                onClick={() => selectAnnotation(annotation)}
                                onContextMenu={(e) => onContextMenu(e, annotation)}
                                className={selectedAnnotations.some((selectedAnnotation: any) => selectedAnnotation.Id === annotation.Id) ? 'transform-item-selected' : 'transform_table_cell'}
                                style={{
                                    width:'45%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {getOriginalText(annotation)}
                            </div>
                            <div className={'transform_table_cell'} style={{width:'45%'}}>
                                <input
                                    key={`${annotation.Id}${displayReplacementText}`}
                                    defaultValue={displayReplacementText}
                                    style={{
                                        border: 'none',
                                        backgroundColor: backgroundColor,
                                        color: textColor,
                                    }}
                                    onChange={event => onReplacementChange(annotation, event)}
                                    onBlur={() => onChangeComplete(annotation)}
                                />
                            </div>
                        </div>
                    );
                }}
            />
        </div>
    );
}