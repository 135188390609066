import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  TextField,
  Autocomplete,
  FormControl,
  Box,
  Button,
  Typography,
  Divider,
  createFilterOptions,
} from "@mui/material";
import { useCustomModal } from "../custom-message-modal";
import "./remove-entity-modal.scss";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hook";
import { showSnackbar } from "../../../redux/snackbar";
import { Project } from "../../../models/Project";
import { postApiNewNlpRequest } from "../../../services/nlp";
import { StyledCloseIconUploader } from "../../../components/close-button";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { ErrorHelper } from "../../../components/error-helper";
import { userSettingsSelector } from "../../../redux/userSettings";
import { UserSettings } from "../../../models/UserSettings";
import { useAuthService } from "../../../contexts/auth-context";

const personalDataEntitiesToIdentify = [
  {
    id: "ae_mh_from_context",
    description: "Adverse Events and Medical History",
    type: "pd",
  },
  {
    id: "ae_mh_from_dictionary",
    description: "Adverse Events and Medical History (add dictionary)",
    type: "pd",
  },
  {
    id: "cm_from_dictionary",
    description: "Concomitant Medications from dictionary",
    type: "pd",
  },
  {
    id: "date",
    description: "Dates and times",
    type: "pd",
  },
  {
    id: "duration",
    description: "Durations",
    type: "pd",
  },
  {
    id: "id",
    description: "IDs",
    type: "pd",
  },
];

const confidentialInformationEntitiesToIdentify = [
  {
    id: "formulation",
    description: "Formulation",
    type: "pd",
  },
  {
    id: "manufacturing_method",
    description: "Manufacturing method",
    type: "pd",
  },
  {
    id: "dosing_regimen",
    description: "Dosing regimen",
    type: "pd",
  },
  // {
  //   id: "time_points",
  //   description: "Time points",
  //   type: "pd",
  // },
  {
    id: "sample_size",
    description: "Sample size",
    type: "pd",
  },
  {
    id: "exploratory_endpoint",
    description: "Exploratory endpoints",
    type: "pd",
  },
  // {
  //   id: "vendors_third_party_affiliations",
  //   description: "Vendors/Third party affiliations",
  //   type: "pd",
  // },
  {
    id: "non_clinical",
    description: "Non-clinical",
    type: "pd",
  },
  // {
  //   id: "statistical_methods",
  //   description: "Statistical methods",
  //   type: "pd",
  // },
  // {
  //   id: "product_characteristics",
  //   description: "Product characteristics",
  //   type: "pd",
  // },
  // {
  //   id: "schedule_of_events",
  //   description: "Schedule of events",
  //   type: "pd",
  // },
  {
    id: "biopharmaceutics",
    description: "Biopharmaceutics",
    type: "pd",
  },
  {
    id: "molecules",
    description: "Molecules",
    type: "pd",
  },
];

const personalDataSchema = z.object({
  id: z.string(),
  description: z.string(),
});

const confidentialDataSchema = z.object({
  id: z.string(),
  description: z.string(),
});

const createFormDataSchema = (
  accessToNlpPersonalData: boolean | undefined,
  accessToCIM: boolean | undefined
) => {
  const formDataSchema = z
    .object({
      personalData: z.array(personalDataSchema),
      confidentialData: z.array(confidentialDataSchema),
      startPageToProcess: z
        .number()
        .min(1, "Please enter a valid start page to process"),
      numberOfPagesToProcess: z
        .number()
        .min(0, "Please enter a valid number of pages to process"),
    })
    .refine(
      (data) =>
        !accessToNlpPersonalData ||
        data.personalData.length > 0 ||
        data.confidentialData.length > 0,
      {
        message:
          accessToNlpPersonalData && accessToCIM
            ? ""
            : "Please select Personal Data.",
        path: ["personalData"], // This will show the error for personalData
      }
    )
    .refine(
      (data) =>
        !accessToCIM ||
        data.personalData.length > 0 ||
        data.confidentialData.length > 0,
      {
        message:
          accessToNlpPersonalData && accessToCIM
            ? "Please select either Personal Data or Confidential Information"
            : "Please select Confidential Information.",
        path: ["confidentialData"], // This will show the error for confidentialData
      }
    );

  return formDataSchema;
};

type FormData = z.infer<ReturnType<typeof createFormDataSchema>>;

const defaultValues: FormData = {
  personalData: [],
  confidentialData: [],
  startPageToProcess: 1,
  numberOfPagesToProcess: 0,
};

export function NlpSuggestAnnotations(
  project: Project,
  selectedFileIds: number[],
  refreshProject: () => void,
  reset: () => void
) {
  const dispatch = useAppDispatch();
  const auth = useAuthService();
  const accessToNlpPersonalData = auth.hasNlpPersonalDataAccess();
  const accessToCIM = auth.hasCIMAccess();

  const userSettings: UserSettings = useAppSelector((state) =>
    userSettingsSelector(state)
  );

  const formDataSchema = createFormDataSchema(
    accessToNlpPersonalData,
    accessToCIM
  );

  const { control, handleSubmit, watch, setError, clearErrors, formState } =
    useForm<FormData>({
      defaultValues: {
        ...defaultValues,
      },
      resolver: zodResolver(formDataSchema),
    });

  const { errors, isSubmitted } = formState;

  console.log("errors", errors);

  const { hideModal } = useCustomModal();

  const onSubmitForm: SubmitHandler<FormData> = async (data) => {
    const {
      personalData,
      confidentialData,
      startPageToProcess,
      numberOfPagesToProcess,
    } = data;

    const requestPayload = {
      fileIds: selectedFileIds,
      personalData,
      confidentialData,
      startPageToProcess,
      numberOfPagesToProcess,
    };

    try {
      await postApiNewNlpRequest(requestPayload);
      dispatch(
        showSnackbar({
          message: `Successfully file added for annotation suggestions!`,
          type: "info",
        })
      );
      reset();
    } catch (error) {
      dispatch(
        showSnackbar({
          message: "Error file adding for annotation suggestions!",
          type: "error",
        })
      );
    } finally {
      hideModal();
      refreshProject();
    }
  };

  return (
    <div
      style={{ marginTop: 10, width: 500, maxHeight: "calc(100vh - 204px)" }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="wizard-title">
          <StyledCloseIconUploader onClick={hideModal} />
          <span>{"Select Annotation Categories"}</span>
        </div>
        <form style={{ marginBottom: 20 }}>
          <Box
            sx={{
              margin: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {/* form field */}
            <FormControl fullWidth size="small" sx={{ gap: 1 }}>
              <Typography>Personal Data Entities to identify</Typography>
              <Controller
                name="personalData"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    size="small"
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "#D3D3D3",
                      },
                    }}
                    multiple
                    options={personalDataEntitiesToIdentify}
                    fullWidth
                    disableCloseOnSelect
                    filterSelectedOptions
                    freeSolo={false}
                    getOptionLabel={(option) => option.description}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    value={field.value}
                    disabled={!accessToNlpPersonalData}
                    onChange={(_, value, reason) => {
                      clearErrors("confidentialData");
                      if (value.some((v) => v.id === "selectAll")) {
                        field.onChange(personalDataEntitiesToIdentify);
                      } else {
                        field.onChange(value);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filter = createFilterOptions<{
                        id: string;
                        description: string;
                      }>();
                      const filtered = filter(options, params);
                      if (
                        field.value.length ===
                        personalDataEntitiesToIdentify.length
                      ) {
                        return [];
                      } else {
                        return [
                          { id: "selectAll", description: "Select All" },
                          ...filtered,
                        ];
                      }
                    }}
                    renderOption={(props, option, state) => (
                      <>
                        {option.id === "selectAll" ? (
                          <div key={option.id}>
                            <li {...props}>
                              {option.description} <Divider />
                            </li>
                          </div>
                        ) : (
                          <li key={option.id} {...props}>
                            {option.description}
                          </li>
                        )}
                      </>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label={"Personal Data"}
                        error={isSubmitted && !!errors.personalData}
                      />
                    )}
                  />
                )}
              />
            </FormControl>
            {/*  @ts-ignore */}
            {isSubmitted && errors.personalData?.message && (
              <>
                {/*  @ts-ignore */}
                <ErrorHelper helperText={errors.personalData?.message} />
              </>
            )}

            <FormControl fullWidth size="small" sx={{ gap: 1 }}>
              <Typography>
                Confidential Information Categories to identify
              </Typography>
              <Controller
                name="confidentialData"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    size="small"
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "#D3D3D3",
                      },
                    }}
                    multiple
                    options={confidentialInformationEntitiesToIdentify}
                    fullWidth
                    disableCloseOnSelect
                    filterSelectedOptions
                    freeSolo={false}
                    getOptionLabel={(option) => option.description}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    disabled={!accessToCIM}
                    value={field.value}
                    onChange={(_, value, reason) => {
                      clearErrors("personalData");
                      if (value.some((v) => v.id === "selectAll")) {
                        field.onChange(
                          confidentialInformationEntitiesToIdentify
                        );
                      } else {
                        field.onChange(value);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filter = createFilterOptions<{
                        id: string;
                        description: string;
                      }>();
                      const filtered = filter(options, params);
                      if (
                        field.value.length ===
                        confidentialInformationEntitiesToIdentify.length
                      ) {
                        return [];
                      } else {
                        return [
                          { id: "selectAll", description: "Select All" },
                          ...filtered,
                        ];
                      }
                    }}
                    renderOption={(props, option, state) => (
                      <>
                        {option.id === "selectAll" ? (
                          <div key={option.id}>
                            <li {...props}>{option.description}</li> <Divider />
                          </div>
                        ) : (
                          <li key={option.id} {...props}>
                            {option.description}
                          </li>
                        )}
                      </>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label={"Confidential Information"}
                        error={isSubmitted && !!errors.confidentialData}
                      />
                    )}
                  />
                )}
              />
            </FormControl>

            {/*  @ts-ignore */}
            {isSubmitted && errors.confidentialData?.message && (
              <>
                {/*  @ts-ignore */}
                <ErrorHelper helperText={errors.confidentialData?.message} />
              </>
            )}

            <FormControl fullWidth size="small" sx={{ gap: 1 }}>
              <Typography>Start page to process</Typography>
              <Controller
                name="startPageToProcess"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    label="Start Page"
                    placeholder="e.g. 1"
                    size="small"
                    onChange={(e) => {
                      field.onChange(parseInt(e.target.value));
                    }}
                    error={isSubmitted && !!errors.startPageToProcess}
                    helperText={
                      isSubmitted && errors.startPageToProcess?.message
                    }
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth size="small" sx={{ gap: 1 }}>
              <Box>
                <Typography>Number of pages to process</Typography>
                <Typography fontSize={12}>
                  Set to 0 if you want to process the whole document.
                </Typography>
              </Box>
              <Controller
                name="numberOfPagesToProcess"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    label="Number of Pages"
                    placeholder="e.g. 5"
                    size="small"
                    onChange={(e) => {
                      field.onChange(parseInt(e.target.value));
                    }}
                    error={isSubmitted && !!errors.numberOfPagesToProcess}
                    helperText={
                      isSubmitted && errors.numberOfPagesToProcess?.message
                    }
                  />
                )}
              />
            </FormControl>

            {/* <FormControl fullWidth size="small" sx={{ gap: 1 }}>
              <Typography>
                Specify the time of day when you want the NLP processing
              </Typography>
              <Controller
                name="dateTime"
                control={control}
                render={({ field }) => (
                  <MobileDateTimePicker
                    {...field}
                    inputFormat="YYYY/MM/DD hh:mm a"
                    mask="____/__/__ __:__ _M"
                    minDateTime={dayjs().add(1, "hour")}
                    renderInput={(params) => (
                      <TextField {...params} size="small" label="Date & Time" />
                    )}
                  />
                )}
              />
            </FormControl> */}
          </Box>
          <div style={{ textAlign: "center" }}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={hideModal}
              style={{ marginRight: 40 }}
            >
              CANCEL
            </Button>
            <Button
              type="button"
              color="secondary"
              variant="contained"
              disabled={!accessToNlpPersonalData && !accessToCIM}
              onClick={handleSubmit(onSubmitForm)}
            >
              SUBMIT
            </Button>
          </div>
          <br />
        </form>
      </LocalizationProvider>
    </div>
  );
}
