import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CI } from '../models/CI';
import { RootState } from './store';

export const defaultCIs: CI[] = [];

const ciInitialState = {
  CI: defaultCIs,
  loaded: false,
  selected: null as number | null,
  loading: false,
  filteredCI: defaultCIs,
  currentCI: null as CI | null,
};

const ciSlice = createSlice({
  name: 'CI',
  initialState: ciInitialState,
  reducers: {
    loadCIs: (state, action: PayloadAction<CI[]>) => {
      state.CI = action.payload;
      state.loaded = true;
    },
    loadFilteredCIs: (state, action: PayloadAction<CI[]>) => {
      state.filteredCI = action.payload;
    },
    loadCI: (state, action: PayloadAction<CI>) => {
      const index = state.CI.findIndex((CIItem) => CIItem.id === action.payload.id);
      if (index !== -1) {
        state.CI[index] = action.payload;
      } else {
        state.CI.unshift(action.payload);
      }
    },
    selectCI: (state, action: PayloadAction<number>) => {
      state.selected = action.payload;
    },
    removeCI: (state, action: PayloadAction<number>) => {
      state.CI = state.CI.filter((CIItem) => CIItem.id !== action.payload);
    },
    setCILoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
    setCILoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    refreshCI: (state, action: PayloadAction<CI>) => {
      const index = state.CI.findIndex((CIItem) => CIItem.id === action.payload.id);
      if (index !== -1) {
        state.CI[index] = action.payload;
      }
    },
  },
});

export const {
  loadCIs,
  loadCI,
  selectCI,
  removeCI,
  setCILoaded,
  loadFilteredCIs,
  setCILoading,
  refreshCI,
} = ciSlice.actions;

export const ciSelector = (state: RootState) => state.CI.CI;
export const loadedCISelector = (state: RootState) => state.CI.loaded;
export const selectedCISelector = (state: RootState) =>
  state.CI.CI.find((CIItem) => CIItem.id === state.CI.selected);

export default ciSlice.reducer;
