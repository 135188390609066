import { CheckCircle, Warning } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { DataGridPro, GridColDef, GridRowsProp, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import { cloneDeep } from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hook";
import { Asset } from "../../../models/Asset";
import { BaseCategory } from "../../../models/BaseCategory";
import { Category } from "../../../models/Category";
import { CI } from "../../../models/CI";
import { ciWorkFlowStatus } from "../../../models/ciWorkFlowStatus";
import { Project } from "../../../models/Project";
import { useCustomModal } from "../../../pages/modals/custom-message-modal";
import OcrProcessingStatus from "../../../pages/modals/ocr-processing-status";
import { loadCategories } from "../../../redux/category";
import {
  projectSelector,
  selectedProjectSelector,
} from "../../../redux/projects";
import { showSnackbar } from "../../../redux/snackbar";
import {
  getApiCategories,
  postApiFetchOrCreateCategories,
} from "../../../services/category";
import {
  postApiCIFetchExistingCIs,
  postApiCIMultipleCreate,
} from "../../../services/ci";
import CIMRedcationBulkEditModal from "./cim-redcation-bulk-edit-modal";
import { BaseCI } from "../../../models/BaseCI";
import { userSettingsSelector } from "../../../redux/userSettings";
import { convertDateTimeFromUTCToTimezone } from "../../../services/utils";


interface RedactionRow {
  id: string;
  identifier: string;
  asset: Asset;
  knownCI: string;
  category: Category;
  ciEffectiveDate: string;
  existingCI: boolean;
  checkingIfExist: boolean;
  isEditable: boolean;
  error: boolean;
  ci?: CI;
}

const fetchExistingCIs = async ({
  assetIds,
  knownCIs,
  categoryIds,
}: {
  assetIds: number[];
  knownCIs: string[];
  categoryIds: number[];
}): Promise<Record<string, CI>> => {
  const existingCIObj: Record<string, CI> = {};
  if (assetIds.length === 0) return existingCIObj;
  const data = await postApiCIFetchExistingCIs({
    assetIds,
    knownCIs,
    categoryIds,
  });
  data.forEach((ci) => {
    const id = `${ci.asset.code}-${ci.knownCI.trim()}-${ci?.category?.code}`;
    const escapedId = sanitizeId(id);
    existingCIObj[escapedId] =
      ci;
  });
  return existingCIObj;
};

function sanitizeId(value: string): string {
  return value
    .replace(/\s+/g, '-') // Replace all whitespace characters (spaces, \n, \r, \t) with -
    .replace(/[^a-zA-Z0-9\-]/g, '-') // Replace all non-alphanumeric characters (except -) with -
    .replace(/-+/g, '-'); // Replace multiple consecutive dashes with a single dash
}
const createUniqueRedactions = (
  cciRedactions: any[],
  project: Project,
  {timezone}: { timezone: string },
): Record<string, RedactionRow> => {
  if (!project || !cciRedactions || !project?.linkedAssets?.length) return {};

  const uniqueSet = new Set<string>();
  const uniqueRedactions = cciRedactions.filter((redaction) => {
    const previewText = redaction.getCustomData("trn-annot-preview");
    if (uniqueSet.has(previewText) || !previewText) return false;
    uniqueSet.add(previewText);
    return true;
  });

  const finalRowsObj: { [key: string]: RedactionRow } = {};
  uniqueRedactions.forEach((redaction) => {
    const knownCI = redaction.getCustomData("trn-annot-preview");
    if (!knownCI) return;

    project.linkedAssets?.forEach((asset) => {
      const categoryName: string = redaction.type || "cci";
      const categoryCode: string = categoryName
        .trim()
        .toLowerCase()
        .replace(/\s+/g, "-");
      const id = `${asset.code}-${knownCI.trim()}-${categoryCode}`;
      const escapedId = sanitizeId(id);
      finalRowsObj[escapedId] = {
        id: escapedId,
        identifier: escapedId,
        asset,
        knownCI: knownCI.trim(),
        category: {
          id: 0,
          name: categoryName,
          code: categoryCode,
          createdAt:moment().tz(timezone).format("YYYY-MM-DD"),
        },
        ciEffectiveDate: moment().tz(timezone).format("YYYY-MM-DD"),
        existingCI: false,
        checkingIfExist: true,
        isEditable: true,
        error: false,
      };
    });
  });

  return finalRowsObj;
};

const fetchOrCreateCategories = async (uniqueRedactions: Record<string, RedactionRow>) => {
  const uniqueCategories: Record<string, BaseCategory> = {};
  for (const key in uniqueRedactions) {
    uniqueCategories[uniqueRedactions[key].category.code] = { name: uniqueRedactions[key].category.name, code: uniqueRedactions[key].category.code }
  }
  const { categories, newCategoryIds } = await postApiFetchOrCreateCategories(
    Object.values(uniqueCategories)
  );
  const updatedRedactions: Record<string, RedactionRow> = {};
  for (const row in uniqueRedactions) {
    const existingCategory = categories.find(
      (cat) => cat.code === uniqueRedactions[row].category.code
    )
    if (existingCategory) {
      updatedRedactions[uniqueRedactions[row].id] = {
        ...uniqueRedactions[row],
        category: existingCategory,
      };
    }
  }
  return {
    updatedRedactions,
    newCategoryIds,
  };
};

const checkForExistingCIs = async (updatedRedactions: Record<string, RedactionRow>, newCategoryIds: number[], { timezone }: { timezone: string }) => {
  const filteredRedactions = Object.values(updatedRedactions).filter(
    (row) => !newCategoryIds.includes(row.category.id)
  );
  const assetIds: number[] = [];
  const knownCIs: string[] = [];
  const categoryIds = [];
  for (const row of filteredRedactions) {
    if (row.category.id) {
      assetIds.push(row.asset.id);
      knownCIs.push(row.knownCI);
      categoryIds.push(row.category.id);
    }
  }
  const existingCIs = await fetchExistingCIs({
    assetIds,
    knownCIs,
    categoryIds,
  });
  const finalRowsObj: { [key: string]: RedactionRow } = {};
  for (const row in updatedRedactions) {
    const id = updatedRedactions[row].id;
    finalRowsObj[id] = {
      ...updatedRedactions[row],
      identifier: id,
      checkingIfExist: false,
      ...(existingCIs[id]
        ? {
          ci: existingCIs[id],
          existingCI: true,
          ciEffectiveDate: convertDateTimeFromUTCToTimezone(existingCIs[id].ciEffectiveDate,timezone,"YYYY-MM-DD"),
          isEditable: false,
        }
        : {}),
    };
  }
  return finalRowsObj;
};

const CIMRedactionModal: React.FC<{
  cciRedactions: any[];
  onDone: () => void;
  onCancel: () => void;
}> = ({ cciRedactions, onDone, onCancel }) => {
  const dispatch = useAppDispatch();
  const stepRef = useRef(1);
  const newCategoryIdsRef = useRef<number[]>([]);
  const categoryLoadingRef = useRef(false);
  const finalLoadingRef = useRef(true);
  const { showModal, hideModal } = useCustomModal();
  const apiRef = useGridApiRef();
  const [ciRedcations, setCiRedcations] = useState<Record<string, RedactionRow>>({});
  const [filterType, setFilterType] = useState("all");
  const [selectedCciRedactions, setSelectedCciRedactions] = useState([]);
  const [isBulkEditVisible, setIsBulkEditVisible] = useState(false);
  const projectId = useAppSelector((state) => selectedProjectSelector(state));
  const project = useAppSelector((state) => projectSelector(state, projectId));
  const categoriesObj = useAppSelector((state) => state.categories);
  const categories = categoriesObj.categories;

  const userSettings = useAppSelector((state) => userSettingsSelector(state));
  const timezone  = userSettings?.regional?.timezone || "UTC";

  useEffect(() => {
    if (stepRef.current === 1 && timezone) {
      const uniqueRedactions = createUniqueRedactions(cciRedactions, project, { timezone });
      stepRef.current = 2;
      setCiRedcations(uniqueRedactions);
    }
  }, [cciRedactions, project,timezone]);

  useEffect(() => {
    const fetchData = async () => {
      const { updatedRedactions, newCategoryIds } =
        await fetchOrCreateCategories(ciRedcations);
      // const categoriesList = await getApiCategories();
      // dispatch(loadCategories(categoriesList));
      stepRef.current = 3;
      newCategoryIdsRef.current = newCategoryIds;
      // categoryLoadingRef.current = false;
      setCiRedcations(updatedRedactions);
    };

    if (stepRef.current === 2 && Object.keys(ciRedcations).length) {
      fetchData();
    }
  }, [ciRedcations, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const finalRedactions = await checkForExistingCIs(
        ciRedcations,
        newCategoryIdsRef.current,
        { timezone },
      );
      finalLoadingRef.current = false;
      stepRef.current = 4;
      setCiRedcations(finalRedactions || {});
    };
    if (
      stepRef.current === 3 &&
      Object.values(ciRedcations)?.[0]?.category?.id &&
      newCategoryIdsRef.current && timezone
    ) {
      fetchData();
    }
  }, [ciRedcations,timezone]);

  const ocrProgressModalOpen = (title: string) => {
    showModal(OcrProcessingStatus, {
      onSave: async () => { },
      onRemoveSelected: () => { },
      title: title,
    });
  };

  const handleDateChange = (id: string, date: string) => {
    setCiRedcations((prevCiRedcations) => {
      const updatedCiRedcations = { ...prevCiRedcations };
      if (updatedCiRedcations[id]) {
        updatedCiRedcations[id].ciEffectiveDate = date;
        updatedCiRedcations[id].error = !moment(date).isValid();
      }
      return updatedCiRedcations;
    });
  };

  const handleCategoryChange = (id: string, categoryId: string) => {
    const selectedCategory = categories.find(
      (cat) => cat.id === parseInt(categoryId)
    );
    if (!selectedCategory) return;
    const newIdentifier = `${ciRedcations[id].asset.code}-${ciRedcations[
      id
    ].knownCI.trim()}-${selectedCategory.code || ''}`;
    const assetIds: number[] = [];
    const categoryIds = [];
    const knownCIs: string[] = [];
    const identifiers = [newIdentifier];
    assetIds.push(ciRedcations[id].asset.id);
    categoryIds.push(selectedCategory.id);
    knownCIs.push(ciRedcations[id].knownCI);
    setCiRedcations((prevCiRedcations) => {
      const updatedCiRedcations = cloneDeep(prevCiRedcations);
      if (updatedCiRedcations[id] && selectedCategory) {
        updatedCiRedcations[id].category = selectedCategory;
        updatedCiRedcations[id].identifier = newIdentifier;
        updatedCiRedcations[id].checkingIfExist = true;
        updatedCiRedcations[id].ci = undefined;

      }
      return updatedCiRedcations;
    });
    updateCIExistance({ assetIds, knownCIs, categoryIds, identifiers });
  };
  const redactCITableHeaders = [
    "Known CI",
    "Asset Name",
    "Category",
    "CI-Effective Date",
    "Status",
  ];

  function getRedcationCIRows() {
    let rows: GridRowsProp = [];
    for (let ciRedcation of Object.values(ciRedcations)) {
      rows = rows.concat({
        id: ciRedcation.id,
        [redactCITableHeaders[0]]: ciRedcation.knownCI,
        [redactCITableHeaders[1]]: ciRedcation.asset.name,
        [redactCITableHeaders[2]]: ciRedcation.category.name,
        [redactCITableHeaders[3]]: ciRedcation.ciEffectiveDate,
        [redactCITableHeaders[4]]: ciRedcation.existingCI ? "CI Found In CIM" : "New CI",
        existingCI: ciRedcation.existingCI,
        category: ciRedcation.category,
        checkingIfExist: ciRedcation.checkingIfExist,
        isEditable: ciRedcation.isEditable,
        identifier: ciRedcation.identifier,
        ci: ciRedcation.ci,
        error: ciRedcation.error,
      });
    }

    if (filterType === "existing") {
      rows = rows.filter((row) => row.existingCI);
    } else if (filterType === "non-existing") {
      rows = rows.filter((row) => !row.existingCI);
    }

    return rows;
  }

  function getColumns() {
    let columnsObject: GridColDef[] = [];
    redactCITableHeaders.forEach((header, index) => {
      if (index === 1 || index === 0) {
        // ci
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 250,
          flex: 1,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
        });
      } else if (index === 2) {
        // Category Dropdown
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 150,
          flex: 1,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
          // renderCell: (params) => {
          //   const isEditable = params.row.isEditable;
          //   const currentCategory = params.row.category;
          //   if (categoryLoadingRef.current) {
          //     return (
          //       <Box
          //         sx={{
          //           display: "flex",
          //           flex: 1,
          //           justifyContent: "center",
          //           paddingRight: "10px",
          //         }}
          //       >
          //         <Skeleton variant="rectangular" width={"100%"} height={30} />
          //       </Box>
          //     );
          //   }
          //   // if (isEditable) {
          //   //   return (
          //   //     <Box
          //   //       sx={{
          //   //         display: "flex",
          //   //         flex: 1,
          //   //         justifyContent: "center",
          //   //         paddingRight: "10px",
          //   //       }}
          //   //     >
          //   //       <FormControl fullWidth>
          //   //         <Select
          //   //           value={currentCategory?.id || ""}
          //   //           onChange={(e) =>
          //   //             handleCategoryChange(params.row.id, e.target.value)
          //   //           }
          //   //           size="small"
          //   //           fullWidth
          //   //         >
          //   //           {/* Populate dropdown options from Redux categories */}
          //   //           {categories.map((category) => (
          //   //             <MenuItem key={category.id} value={category.id}>
          //   //               {category.name}
          //   //             </MenuItem>
          //   //           ))}
          //   //         </Select>
          //   //       </FormControl>
          //   //     </Box>
          //   //   );
          //   // }

          //   return <Typography>{currentCategory?.name || "N/A"}</Typography>;
          // },
        });
      } else if (index === 3) {
        // Effective Date
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 200,
          flex: 2,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
          renderCell: (params) => {
            if (finalLoadingRef.current) {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    paddingRight: "10px",
                  }}
                >
                  <Skeleton variant="rectangular" width={"100%"} height={30} />
                </Box>
              );
            }
            return (
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  paddingRight: "10px",
                }}
              >
                <TextField
                  type="date"
                  value={params.value}
                  error={params.row.error}
                  onChange={(e) =>
                    handleDateChange(params.row.id, e.target.value)
                  }
                  size="small"
                  fullWidth
                  disabled={!params.row.isEditable} // Disable date change for existing CIs
                />
              </Box>
            );
          },
        });
      } else if (index === 4) {
        // Effective Date
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 100,
          flex: 2,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
          renderCell: (params) => {
            const isExistingCI = params.row.existingCI;
            const checkingIfExist = params.row.checkingIfExist;
            if (checkingIfExist || finalLoadingRef.current) {
              return (
                <Box sx={{ display: "flex", flex: 1 }}>
                  <Skeleton variant="rectangular" width={200} height={30} />
                </Box>
              );
            }
            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Tooltip
                  title={
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: "normal",
                        color: "#fff",
                        margin: 0,
                        padding: 0,
                        fontFamily: 'unset'
                      }}
                    >
                      {isExistingCI
                        ? `Current WorkFlow Status: ${params.row?.ci?.status ? ciWorkFlowStatus[params.row.ci.status]?.label || "N/A" : "N/A"}`
                        + (params.row?.ci?.project?.name ? `\nRelated Project: ${params.row.ci.project.name}` : "")
                        : "New CI"}
                    </p>
                  }
                  arrow={false}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: "#000",
                        color: "#fff",
                        borderRadius: "0px",
                        padding: "6px",
                        maxWidth: "300px",
                      },
                    },
                  }}
                >
                  <Box display="flex" alignItems="center">
                    {isExistingCI ? (
                      <>
                        <CheckCircle sx={{ color: "#4caf50" }} />
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#4caf50",
                            fontWeight: "bold",
                            marginLeft: 0.5,
                          }}
                        >
                          {"CI Found In CIM"}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Warning sx={{ color: "#ff9800" }} />
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#ff9800",
                            fontWeight: "bold",
                            marginLeft: 0.5,
                          }}
                        >
                          {"New CI"}
                        </Typography>
                      </>
                    )}
                  </Box>
                </Tooltip>
              </Stack>
            );
          },
        });
      } else {
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 120,
          width: index === 6 ? 160 : 120,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
        });
      }
    });
    return columnsObject;
  }

  const handleConfirm = async () => {
    if (selectedCciRedactions.length === 0) {
      dispatch(
        showSnackbar({
          message:
            "Please select at least one row to proceed with CIM.",
          type: "error",
        })
      );
      // alert("Please select at least one row to proceed with CIM.");
      return;
    }
    const selectedCci: BaseCI[] = [];
    for (const id of selectedCciRedactions) {
      if (ciRedcations[id] && !ciRedcations[id].existingCI) {
        if (ciRedcations[id]?.error) {
          dispatch(
            showSnackbar({
              message:
                "One or more selected Confidential Information (CI) entries have errors. Please resolve the errors before proceeding.",
              type: "error",
            })
          );
          // alert(
          //   "One or more selected Confidential Information (CI) entries have errors. Please resolve the errors before proceeding."
          // );
          return; // Exit the function early
        }
        if (ciRedcations[id].category?.id) {
          selectedCci.push({
            projectId: projectId,
            assetId: ciRedcations[id].asset.id,
            categoryId: ciRedcations[id].category.id,
            knownCI: ciRedcations[id].knownCI.trim(),
            ciEffectiveDate: moment.tz(ciRedcations[id].ciEffectiveDate, timezone).startOf("day").toISOString(),
          });
        }
      }
    }
    if (selectedCci.length === 0) {
      dispatch(
        showSnackbar({
          message:
            "No valid non-existing Confidential Information (CI) selected. Please select at least one non-existing CI to proceed.",
          type: "error",
        })
      );
      // alert("No valid non-existing Confidential Information (CI) selected. Please select at least one non-existing CI to proceed.");
      return;
    }
    if (selectedCci.length) {
      ocrProgressModalOpen("Creating CI into CIM");
      try {
        await postApiCIMultipleCreate(selectedCci);
        dispatch(
          showSnackbar({
            message:
              "Confidential Information (CI) successfully created in CIM, and redaction process has started.",
            type: "info",
          })
        );
      } catch (error) {
        dispatch(
          showSnackbar({
            message:
              "Failed to create CI in CIM. However, the redaction process has started.",
            type: "info",
          })
        );
      } finally {
        hideModal();
      }
    }
    onDone();
  };
  const onContinue = () => {
    hideModal();
    onDone();
  };
  const handleSelectionChange = (selectionModel: any) => {
    setSelectedCciRedactions(selectionModel);
    if (selectionModel.length < 2) {
      setIsBulkEditVisible(false);
    }
  };

  const onBulkEditClose = () => {
    setIsBulkEditVisible(false);
  };

  const updateCIExistance = async ({
    assetIds,
    knownCIs,
    categoryIds,
    identifiers,
  }: {
    assetIds: number[];
    knownCIs: string[];
    categoryIds: number[];
    identifiers: string[];
  }) => {
    const existingCIs = await fetchExistingCIs({
      assetIds,
      knownCIs,
      categoryIds,
    });
    setCiRedcations((prevCiRedcations) => {
      const updatedCiRedcations = cloneDeep(prevCiRedcations);
      for (const item of Object.values(updatedCiRedcations)) {
        if (identifiers.includes(item.identifier)) {
          if (existingCIs[item.identifier]) {
            item.existingCI = true;
            item.ci = existingCIs[item.identifier];
          } else {
            item.existingCI = false;
          }
          item.checkingIfExist = false;
        }
      }
      return updatedCiRedcations;
    });
  };

  const onBulkEditHandler = (action: string, data: { ciEffectiveDate?: string; selectedCategory?: string }) => {
    const selectedIds = selectedCciRedactions;

    if (action === "ciEffectiveDate" && data.ciEffectiveDate) {
      const ciEffectiveDate = data.ciEffectiveDate;
      const error = !moment(ciEffectiveDate).isValid();
      setCiRedcations((prevCiRedcations) => {
        const updatedCiRedcations = { ...prevCiRedcations };
        selectedIds.forEach((id) => {
          if (updatedCiRedcations[id]) {
            updatedCiRedcations[id].ciEffectiveDate = ciEffectiveDate;
            updatedCiRedcations[id].error = error;
          }
        });
        return updatedCiRedcations;
      });
      setIsBulkEditVisible(false);
    }

    if (action === "category" && data.selectedCategory) {
      const identifiers: string[] = [];
      const categoryId = data.selectedCategory;
      const ciEffectiveDate = data.ciEffectiveDate;
      const selectedCategory = categories.find(
        (cat) => cat.id === parseInt(categoryId)
      );
      if (!selectedCategory) {
        return;
      }
      const assetIds: number[] = [];
      const knownCIs: string[] = [];
      const categoryIds = [];
      setCiRedcations((prevCiRedcations) => {
        const updatedCiRedcations = { ...prevCiRedcations };
        selectedIds.forEach((id) => {
          if (updatedCiRedcations[id]) {
            const newIdentifier = `${updatedCiRedcations[id].asset.code
              }-${updatedCiRedcations[id].knownCI.trim()}-${selectedCategory.code
              }`;
            identifiers.push(newIdentifier);
            assetIds.push(updatedCiRedcations[id].asset.id);
            knownCIs.push(updatedCiRedcations[id].knownCI.trim());
            categoryIds.push(selectedCategory.id);
            updatedCiRedcations[id].checkingIfExist = true; // Show loading indicator
            updatedCiRedcations[id].ci = undefined;
            updatedCiRedcations[id].category = selectedCategory;
            updatedCiRedcations[id].ciEffectiveDate =
              ciEffectiveDate || updatedCiRedcations[id].ciEffectiveDate;
            updatedCiRedcations[id].identifier = newIdentifier;
          }
        });

        return updatedCiRedcations;
      });
      updateCIExistance({
        assetIds,
        knownCIs,
        categoryIds: [selectedCategory.id],
        identifiers,
      });
      setIsBulkEditVisible(false);
    }
  };
  return (
    <Dialog
      open
      onClose={(event, reason) => {
        if (reason !== "backdropClick") onCancel();
      }}
      disableEscapeKeyDown
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle
        sx={{ background: "#223250", color: "#fff", textAlign: "center" }}
      >
        Confidential Redactions Found
      </DialogTitle>

      <DialogContent sx={{ overflowX: "hidden", height: "100vh" }}>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
            height: 48,
            alignItems: "center",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: "#223250",
                fontWeight: "bold",
              }}
            >
              Project: {project?.name || "Loading..."} {/* Fallback for loading state */}
            </Typography>

          </Box>
          <Box style={{ display: 'flex', gap: '20px' }}>
            {selectedCciRedactions.length >= 2 && (
              <Button
                variant="contained"
                onClick={() => setIsBulkEditVisible(true)}
              >
                Bulk Edit
              </Button>
            )}
            {selectedCciRedactions.length >= 2 && isBulkEditVisible && (
              <CIMRedcationBulkEditModal
                onBulkEdit={onBulkEditHandler}
                onClose={onBulkEditClose}
              />
            )}
            <FormControl
              size="small"
              sx={{ minWidth: 150, background: "#fff", borderRadius: 1 }}
            >
              <Select
                value={filterType}
                onChange={(e) => setFilterType(e.target.value)}
                displayEmpty
                sx={{ height: 40 }}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="existing">CI Found In CIM</MenuItem>
                <MenuItem value="non-existing">New CI</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Stack>

        <Paper variant="outlined" sx={{ height: 400, width: "100%" }}>
          <DataGridPro
            apiRef={apiRef}
            className="ciRedctionsTable"
            rows={getRedcationCIRows()}
            columns={getColumns()}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={handleSelectionChange}
            getRowClassName={(params) =>
              !params.row.isEditable ? "existing-ci-row" : ""
            }
            autoPageSize={false}
            autoHeight={true}
            isRowSelectable={(params) => params.row.isEditable}
            rowSpacingType={"border"}
            sx={{
              "& .MuiDataGrid-cell": {
                padding: 0,
              },
              "& .MuiDataGrid-virtualScroller": {
                overflow: "hidden",
              },
            }}
            disableDensitySelector
            disableColumnSelector
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            pagination
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[5, 10, 25]}
            keepColumnPositionIfDraggedOutside={true}
          />
        </Paper>
      </DialogContent>

      <DialogActions sx={{ p: 2, justifyContent: "space-between" }}>
        <Stack direction="row" spacing={2}>
          <Button variant="contained" color="primary" onClick={handleConfirm}>
            Redact and Add to CIM
          </Button>
          <Button variant="outlined" color="secondary" onClick={onContinue}>
            Redact only
          </Button>
        </Stack>
        <Button variant="text" color="error" onClick={onCancel}>
          Cancel Redaction
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CIMRedactionModal;
