import React from "react";
import "./draggableOverlay.scss";
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';

function DraggableOverlay({ fileName = "", fileNumber = 1 }) {
  return (
    <div className="dnd-draggable-container">
      <DriveFileMoveIcon />
      <span className="dnd-draggable-file-name">{fileName}</span>
      {fileNumber > 1 && (
        <div className="dnd-draggable-file-number-badge">{fileNumber}</div>
      )}
    </div>
  );
}

export default DraggableOverlay;
