import { create_UUID, getUserInitials } from "../services/utils";
import { Avatar, AvatarGroup, Paper, SvgIcon } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { InfoTenantUser } from "../models/InfoTenantUser";
import { useAuthService } from "../contexts/auth-context";

export function UsersAvatarList(p: { users: InfoTenantUser[], removeUser?: (user: InfoTenantUser) => void,
    shortView: boolean, small?: boolean, maxAvatars?: number }) {

    const auth = useAuthService();
    const max = p.maxAvatars || 6;
    let displayUsers = p.shortView ? p.users.slice(0, max) : p.users;
    const showMoreUsersRef = useRef(null);
    const [showMoreUsers, setShowMoreUsers] = useState(false);

    const uuid = useRef(create_UUID());

    const isRemoveIcon = (value: any) => {
        const type = value.__proto__.constructor.name;
        return type === "SVGSVGElement" || type === "SVGPathElement" || type === "SVGCircleElement"
    }

    useEffect(() => {
        if (p.shortView && p.users.length > displayUsers.length) {
            const avatarGroupElement = document.getElementById(`avatarGroup-${uuid.current}`);
            if (avatarGroupElement?.children) {
                const plusAvatarElement = avatarGroupElement.firstElementChild as HTMLElement;
                plusAvatarElement.style.backgroundColor = "#FFF";
                plusAvatarElement.style.color = "var(--normalBlue)";
                plusAvatarElement.style.width = "30px";
                plusAvatarElement.style.height = "30px";
                plusAvatarElement.style.border = "2px solid var(--normalBlue)";
                plusAvatarElement.style.marginTop = "1px";
                plusAvatarElement.addEventListener('mouseenter', () => setShowMoreUsers(true));
                plusAvatarElement.addEventListener('mouseleave', () => setShowMoreUsers(false));
                const avatarGroupMoreUsersElement = showMoreUsersRef.current as HTMLElement | null;
                if (avatarGroupMoreUsersElement) {
                    avatarGroupMoreUsersElement.addEventListener('mouseenter', () => setShowMoreUsers(true));
                    let left = plusAvatarElement.getBoundingClientRect().left + plusAvatarElement.clientWidth - 83
                    if (left > window.outerWidth - 167) {
                        left = window.outerWidth - 180;
                    }
                    avatarGroupMoreUsersElement.style.left = `${left}px`;
                    avatarGroupMoreUsersElement.style.top = `${plusAvatarElement.getBoundingClientRect().top + plusAvatarElement.getBoundingClientRect().height}px`;
                }
            }
        }
        return () => {
            const avatarGroupElement = document.getElementById('avatarGroup');
            if (avatarGroupElement?.children) {
                const plusAvatarElement = avatarGroupElement.firstElementChild as HTMLElement;
                plusAvatarElement.removeEventListener('mouseenter', () => setShowMoreUsers(true));
                plusAvatarElement.removeEventListener('mouseleave', () => setShowMoreUsers(false));
                const avatarGroupMoreUsersElement = showMoreUsersRef.current as HTMLElement | null;
                if (avatarGroupMoreUsersElement) {
                    avatarGroupMoreUsersElement.removeEventListener('mouseenter', () => setShowMoreUsers(true));
                }
            }
        }
    }, [p.users]);

    if (p.shortView) {
        //TODO remove avatar group with custom display to load extra users list on hover
        return <>
            <AvatarGroup id={`avatarGroup-${uuid.current}`} max={max} total={p.users.length} sx={{ '& .MuiAvatar-root': {
                    bgcolor: "var(--normalBlue)",
                    width: 32,
                    height: 32,
                    fontSize: 17
                },
                '& .MuiAvatar-root:first-of-type': p.users.length > displayUsers.length ? {
                    bgcolor: "#FFF",
                    color: "var(--normalBlue)",
                    width: 30,
                    height: 30,
                    fontSize: 17,
                    border: "2px solid var(--normalBlue)",
                    marginTop: "1px!important"
                } : {}
            }}>
                {displayUsers.map(user => <Tooltip key={user.id} title={user.name!}>
                        <Avatar style = {{backgroundColor: user.color! ?? "var(--normalBlue)"}}>{getUserInitials(user.name)}</Avatar>
                    </Tooltip>
                )}
            </AvatarGroup>
            <div ref={showMoreUsersRef} style={{ padding: 4, display: showMoreUsers ? "flex" : "none", position: "fixed" }}
                onMouseEnter={() => setShowMoreUsers(true)}>
                <Paper style={{ display: "flex", width: 167, flexDirection: "column", borderRadius: 4, padding: 4,
                    backgroundColor: "#fff" }}>
                    {p.users.slice(max - 1).map(user => <MoreUser key={user.id} user={user} showMoreUsers={setShowMoreUsers} />)}
                </Paper>
            </div>
        </>
    }

    const avatarPerRow = 10;
    let rows = [];
    const totalRows = displayUsers.length % avatarPerRow === 0 ? displayUsers.length / avatarPerRow :
        (displayUsers.length - ( displayUsers.length % avatarPerRow)) / avatarPerRow + 1;
    for(let i = 0; i < totalRows; i++ ) {
        const rowUsers = displayUsers.slice(i * avatarPerRow, i * avatarPerRow + avatarPerRow);
        rows.push(<div style={{ display: "flex", maxWidth: rowUsers.length * (p.small ? 20 : 28) + 8 }}>
            {rowUsers.map((user, index) =>
                <Tooltip key={user.id} title={user.name!}>
                    <div style={{ maxHeight: p.small ? 28 : 36, position: "relative", left: ((index % avatarPerRow) * -8) }}>
                        <Avatar id={`avatar-${uuid.current}-${user.id}`}
                                onMouseEnter={() => {
                                    if (auth.hasPMRoleAccess() || auth.hasCIMRoleAccess()) {
                                        document.getElementById(`remove-user-${uuid.current}-${user.id}`)!.style.display = "";
                                    }
                                }}
                                onMouseLeave={(event) => {
                                    if (auth.hasPMRoleAccess()|| auth.hasCIMRoleAccess()) {
                                        const target = event.relatedTarget as HTMLDivElement | SVGElement | SVGPathElement | SVGCircleElement;
                                        if (target && isRemoveIcon(target)) {
                                            return;
                                        }
                                        document.getElementById(`remove-user-${uuid.current}-${user.id}`)!.style.display = "none";
                                    }
                                }}
                                sx={{ bgcolor: user.color ?? "var(--normalBlue)", width: p.small ? 24 : 32, height: p.small ? 24 : 32,
                                    fontSize: p.small ? 14 : 17, border: "2px solid white", zIndex: displayUsers.length - index,
                                     }}>
                            {getUserInitials(user.name)}
                        </Avatar>
                        {(auth.hasPMRoleAccess() || auth.hasCIMRoleAccess()) && <SvgIcon style={{position: "relative", top: p.small ? -30 : -36, left: p.small ? 16 : 24,
                            width: p.small ? 20 : 24, height: p.small ? 20 : 24,
                            cursor: "pointer", zIndex: displayUsers.length + 1, display: "none" }}
                                                            onClick={() => p.removeUser!(user)}
                                                            onMouseLeave={(event) => {
                                                                const target = event.relatedTarget as HTMLDivElement | SVGElement | SVGPathElement | SVGCircleElement;
                                                                if (target.id === `avatar-${uuid.current}-${user.id}`) {
                                                                    return;
                                                                }
                                                                document.getElementById(`remove-user-${uuid.current}-${user.id}`)!.style.display = "none";
                                                            }}
                                                            id={`remove-user-${uuid.current}-${user.id}`}
                        >
                            <circle id="Ellipse_291" data-name="Ellipse 291" cx="8" cy="8" r="8" fill="#fff"/>
                            <path id="Path_1990" data-name="Path 1990" d="M8,2a6,6,0,1,0,6,6A5.995,5.995,0,0,0,8,2Zm3,
                            8.154L10.154,11,8,8.846,5.846,11,5,10.154,7.154,8,5,5.846,5.846,5,8,7.154,10.154,5,11,
                            5.846,8.846,8Z" fill="#939598"/>
                            <path id="Path_1991" data-name="Path 1991" d="M0,0H16V16H0Z" fill="none"/>
                        </SvgIcon>}
                    </div>
                </Tooltip>
            )}
        </div>)
    }

    return <div style={{ display: "flex", flexDirection: "column", maxWidth: 300, alignItems: p.small ? "center" : "" }}>
        {rows.map((row, index) => <div key={`row-${index}`}>{row}</div>)}
    </div>
}

function MoreUser(p: { user: InfoTenantUser, showMoreUsers: (value: boolean) => void }) {

    const [hover, setHover] = useState(false);

    return <div style={{ display: "flex", padding: 8, backgroundColor: hover ? "#EDF5F8" : "#fff" }}
                onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <Avatar sx={{ width: 24, height: 24, bgcolor: p.user.color ?? "var(--normalBlue)", fontSize: 14 }}>
            {getUserInitials(p.user.name)}
        </Avatar>
        <span style={{ marginLeft: 12 }}>{p.user.name!}</span>
    </div>
}
