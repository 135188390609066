// Asseststatus.ts

export const assestStatus = {
    active: {
      label: "Active",
      color: "#ffeab6",
    },
    on_hold: {
      label: "On Hold",
      color: "#ffe0cc",
    },
    canceled: {
      label: "Canceled",
      color: "#cff5d1",
    },
    divested: {
      label: "Divested",
      color: "#d1e2ff",
    },
  } as const;
  
  export type AssetStatusType = keyof typeof assestStatus; // To type the status values
  