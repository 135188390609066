import { baseUrl, headers } from "./config";
import { fetchAndParse, addQueryParameter } from "./utils";
import { NlpRequest } from "../models/NlpRequest";
import { NlpCountRequest } from "../models/NlpCountRequest";

export function postApiNewNlpRequest(
  body?: NlpRequest,
  signal?: AbortSignal
): Promise<void> {
  const url = new URL(`/api/nlp/request`, baseUrl);
  return fetchAndParse<void>(url.toString(), {
    method: "POST",
    body: JSON.stringify(body),
    headers,
    signal,
  });
}

export function putApiUpdateNlpAnnotationsCount(
  nlpDetailId: number,
  body?: NlpCountRequest,
  signal?: AbortSignal
): Promise<void> {
  const url = new URL(
    `/api/nlp/job-detail/${nlpDetailId}/annotation/count`,
    baseUrl
  );
  return fetchAndParse<void>(url.toString(), {
    method: "PUT",
    body: JSON.stringify(body),
    headers,
    signal,
  });
}

export function getApiNlpJobDetailListByFileId(
  fileId: number,
  signal?: AbortSignal
): Promise<[]> {
  const url = new URL(`api/nlp/file/${fileId}/job-detail/list`, baseUrl);
  return fetchAndParse<[]>(url.toString(), {
    method: "GET",
    headers,
    signal,
  });
}
