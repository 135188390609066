import React, {useState, useEffect, useContext, useRef} from 'react';
import RedactionSearchResultGroup from './RedactionSearchResultGroup';
import './RedactionSearchResults.scss';
import classNames from 'classnames';
import {Virtuoso} from 'react-virtuoso';
import AnnotationFilterHelpers from '../AnnotationFilterHelpers';
import SearchProgressBar from './SearchProgressBar';
import SearchStatus from "./SearchStatus";
import WebViewerContext from "../../../contexts/webviewer-context";
import {BasicAnnotationFilter} from "../BasicAnnotationFilter";
import { useAppDispatch } from '../../../hooks/redux-hook';
import { addNlpSelectedAnnotations } from '../../../redux/document';


function RedactionSearchResults(props) {
    const {
        redactionSearchResults,
        searchStatus,
        onCancelSearch,
        isProcessingRedactionResults,
        markSelectedResultsForRedaction,
        deleteSelectedResults,
        selectedTab
    } = props;
    const dispatch = useAppDispatch();
    const { setInstance,instance } = useContext(WebViewerContext);
    const [filteredSearchResultPageMap, setFilteredSearchResultPageMap] = useState({});
    const [selectedSearchResultIndexesMap, setSelectedSearchResultIndexesMap] = useState({});
    const [selectedIndexes, setSelectedIndexes] = useState([]);
    const [filterPageRange, setFilterPageRange] = React.useState(undefined);
    const [searchPhrase, setSearchPhrase] = React.useState(undefined)
    const [activeSearchResult, setActiveSearchResult] = React.useState(undefined)
    const [category, setCategory] = React.useState(undefined)
    const searchTabIndex = 0
    const refid = useRef(null);
    const documentViewerRef = useRef(null);
    const annotationManagerRef = useRef(null);
    let pressedKeys=useRef([]);
    const C_CHAR_CODE = 67

    function filterPageRangeOnChange(newPageRange) {
        //We need to clone the value so that React recognizes it as a change and re-renders.
        const verifiedPageRange = newPageRange === undefined ? undefined : [...newPageRange];
        setFilterPageRange(verifiedPageRange);
    }

    function searchPhraseOnChange(newSearchPhrase) {
        setSearchPhrase(newSearchPhrase);
    }

    function categoryOnChange(newCategory) {
        setCategory(newCategory);
    }

    const scrollIntoViewByIndex = (index) => {
        if (index) {
            const el = document.querySelector('#search-result-' + index);
            if (el) {
                el.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    useEffect(() => {
        function onKeyUpHandler(event) {
            pressedKeys.current=pressedKeys.current.filter((key) => key!==event.key)
        }
        const onKeyDownHandler = (event) => {
            if (event.key==="Escape") {//Note: this way we don't need to add this to all panels
                annotationManagerRef.current.deselectAllAnnotations()
                return;
            }
            if (selectedTab.current!==searchTabIndex) {
                return
            }
            if (event.key === 'Shift' || event.key === 'Control' || event.key === 'Meta' || event.key === 'Alt') {
                pressedKeys.current.push(event.key)
            }

            //Scroll using the arrow keys
            if (event.key === 'ArrowLeft' || event.key === 'ArrowUp' || event.key === 'ArrowRight' || event.key === 'ArrowDown') {
                event.preventDefault();
                annotationManagerRef.current.deselectAllAnnotations();
                const goUp = event.key === 'ArrowLeft' || event.key === 'ArrowUp'
                const results = refid.current.redactionSearchResults
                const activeResultIndex = results.indexOf(refid.current.activeSearchResult)
                if (activeResultIndex !== undefined && activeResultIndex !== -1) {
                    if (goUp && activeResultIndex !== 0) {
                        annotationManagerRef.current.selectAnnotation(results[activeResultIndex - 1]);
                        annotationManagerRef.current.jumpToAnnotation(results[activeResultIndex - 1]);
                        scrollIntoViewByIndex(activeResultIndex - 1)
                    } else if (!goUp && activeResultIndex !== results.length - 1) {
                        annotationManagerRef.current.selectAnnotation(results[activeResultIndex + 1]);
                        annotationManagerRef.current.jumpToAnnotation(results[activeResultIndex + 1]);
                        scrollIntoViewByIndex(activeResultIndex + 1)
                    }
                }
            } else if (pressedKeys.current.includes('Meta') || pressedKeys.current.includes('Control')) {
                if (event.keyCode === C_CHAR_CODE ) {//We use the keycode instead of the character here because command+alt+c brings a latin character
                    if (pressedKeys.current.includes('Alt')) {
                        event.preventDefault()
                        documentViewerRef.current.trigger('searchResultsCleared')
                    } else {
                        selectAllResults()
                    }
                } else if (event.key === 'u') {
                    event.preventDefault()
                    unselectAllResults()
                }
            }
            console.log(event.key)
        };

        // add listener
        window.addEventListener('keydown', onKeyDownHandler)
        window.addEventListener('keyup', onKeyUpHandler)
        window.oncontextmenu = () => { pressedKeys.current=[] } // when the user right click, the onKeyUpHandler doesn't get triggered
        window.onblur = () => { pressedKeys.current=[] }
        // prevent memory leak
        return () => {
            window.removeEventListener('keydown', onKeyDownHandler);
            window.removeEventListener('keyup', onKeyUpHandler);
            window.oncontextmenu=null
            window.onblur=null
        }
    }, []);

    useEffect(() => {
        refid.current = { redactionSearchResults: redactionSearchResults, activeSearchResult: activeSearchResult };
    }, [redactionSearchResults])


    useEffect(() => {
        AnnotationFilterHelpers.applyFilters(redactionSearchResults, searchPhrase, filterPageRange, category)
        const redactionSearchResultPageMap = {};
        redactionSearchResults.forEach((result, index) => {
            const pageNumber = result.PageNumber;
            result.index = index;
            if (result.shouldHide) {
                return;
            }
            if (redactionSearchResultPageMap[pageNumber] === undefined) {
                redactionSearchResultPageMap[pageNumber] = [result];
            } else {
                redactionSearchResultPageMap[pageNumber] = [...redactionSearchResultPageMap[pageNumber], result];
            }
        });

        setFilteredSearchResultPageMap(redactionSearchResultPageMap);
    }, [redactionSearchResults, searchPhrase, filterPageRange, category]);

    useEffect(() => {
        const selectedIndexesMap = {};
        redactionSearchResults.forEach((index) => {
            selectedIndexesMap[index] = false;
        });
        setSelectedSearchResultIndexesMap(selectedIndexesMap);
    }, [redactionSearchResults]);


    useEffect(() => {
        const selectedIndexes = redactionSearchResults.filter((value, index) => {
            return selectedSearchResultIndexesMap[index];
        });

        setSelectedIndexes(selectedIndexes);
    }, [selectedSearchResultIndexesMap]);

    useEffect(() => {
        const onAnnotationSelected = (annotations) => {
            if (annotations[0].getCustomData('isSearchResult')) {
                setActiveSearchResult(annotations[0]);
                refid.current.activeSearchResult = annotations[0];
            }
        }

        if (instance) {
            documentViewerRef.current = instance.Core.documentViewer
            annotationManagerRef.current = instance.Core.annotationManager
            instance.Core.annotationManager.addEventListener('annotationSelected', onAnnotationSelected);
        }
        return () => {
            if (instance) {
                instance.Core.annotationManager.removeEventListener('annotationSelected', onAnnotationSelected);
            }
        }
    }, [instance]);


    const renderSearchResults = () => {
        const resultGroupPageNumbers = Object.keys(filteredSearchResultPageMap);

        if (resultGroupPageNumbers.length > 0) {
            return (
                <Virtuoso
                    data={resultGroupPageNumbers}
                    itemContent={(index, pageNumber) => {
                        return (
                            <RedactionSearchResultGroup
                                key={index}
                                pageNumber={pageNumber}
                                searchResults={filteredSearchResultPageMap[pageNumber]}
                                selectedSearchResultIndexes={selectedSearchResultIndexesMap}
                                setSelectedSearchResultIndexes={setSelectedSearchResultIndexesMap}
                                activeSearchResult={activeSearchResult}
                                setActiveSearchResult={setActiveSearchResult}
                            />);
                    }}
                />);
        }
    };

    const renderStartSearch = () => (
        <div aria-label='start search'>
        </div>
    );

    const noResults = (
        <div aria-label='no results'>
            no results
        </div>
    );

    const renderSearchInProgress = () => (
        <div>
            //TODO spinner
            {/*<Spinner height="25px" width="25px" />*/}
        </div>
    );

    const onCancelHandler = () => {
        setFilteredSearchResultPageMap({});
        onCancelSearch();
    };

    const selectAllResults = () => {
        const searchResultIndexMap = {};
        refid.current.redactionSearchResults.forEach((value, index) => {
            searchResultIndexMap[index] = selectedSearchResultIndexesMap[index]
            if (!value.shouldHide) {
                searchResultIndexMap[index] = true;
            }
        });
        setSelectedSearchResultIndexesMap(searchResultIndexMap);
    };

    const unselectAllResults = () => {
        const searchResultIndexMap = {};
        redactionSearchResults.forEach((value, index) => {
            searchResultIndexMap[index] = selectedSearchResultIndexesMap[index]
            if (!value.shouldHide) {
                searchResultIndexMap[index] = false;
            }
        });
        setSelectedSearchResultIndexesMap(searchResultIndexMap);
    };

    const onMarkAllForRedaction = () => {
        instance.Core.documentViewer.trigger('processStarted')
        //Set a timeout to get the loading modal to show up.
        setTimeout(() => {
            handleMarkForRedaction();
        }, 10);
    };

    const handleMarkForRedaction = () => {
        const markPromise = new Promise((resolve) => {

            let nlpSelectedAnnotations = 0
            selectedIndexes.forEach((item) => {
                if (item.AIGenerated) {
                    nlpSelectedAnnotations++
                }
            })

            dispatch(addNlpSelectedAnnotations(nlpSelectedAnnotations))

            markSelectedResultsForRedaction(selectedIndexes, instance);
            instance.Core.documentViewer.trigger('processEnded', 'mark')
            if (redactionSearchResults.length===selectedIndexes.length) {
                onCancelHandler()
                setTimeout(() => {//we want the successful clear message to come after the successful mark message
                    documentViewerRef.current.trigger('processEnded', 'searchCleared')
                }, 1000)
            }
            resolve();
        });

        markPromise.then(() => {
            instance.Core.documentViewer.trigger('processEnded', 'mark')
        }).catch((reason) => {
            console.log(reason);
            instance.Core.documentViewer.trigger('processEnded', 'markError')
        });
    }

    const onDeleteSelectedResults = () => {
        deleteSelectedResults(selectedIndexes);
    }

    const isEmptyList = redactionSearchResults.length === 0;

    const resultsContainerClass = classNames('redaction-search-results-container', {emptyList: isEmptyList});
    const deleteAllSelectedClass = classNames('delete-all-selected', {disabled: selectedIndexes.length === 0});
    const markAllForRedactionButtonClass = classNames('mark-all-selected', {disabled: selectedIndexes.length === 0});
    const shouldShowResultsCounterOptions = (searchStatus === SearchStatus['SEARCH_DONE'] && !isProcessingRedactionResults) || searchStatus === SearchStatus['SEARCH_NOT_INITIATED'];

    return (
        <>
            <div className="redaction-search-panel-controls-top">
                <button
                    onClick={onCancelHandler}
                    aria-label='cancel'
                    className="cancel"
                >
                    Cancel
                </button>
                <button
                    disabled={selectedIndexes.length === 0}
                    aria-label='delete'
                    className={deleteAllSelectedClass}
                    onClick={onDeleteSelectedResults}
                >
                    Delete
                </button>
                <button
                    disabled={selectedIndexes.length === 0}
                    aria-label='add mark'
                    className={markAllForRedactionButtonClass}
                    onClick={onMarkAllForRedaction}
                >
                    Add Mark
                </button>
            </div>
            <div className="redaction-search-counter-controls">
                {(searchStatus === SearchStatus['SEARCH_IN_PROGRESS'] || searchStatus === SearchStatus['NLP_LOAD_IN_PROGRESS']) && (
                    <div style={{width: '100%'}}>
                        <SearchProgressBar/>
                    </div>)}
                {shouldShowResultsCounterOptions && (
                    <>
                        <div className="redaction-search-results-counter">
                            <span>Search Results</span> ({AnnotationFilterHelpers.getCounter(redactionSearchResults)})
                        </div>
                        <button
                            onClick={selectAllResults}
                            disabled={isEmptyList}
                            aria-label='Select all'
                        >
                            Select All
                        </button>
                        <button
                            disabled={isEmptyList}
                            onClick={unselectAllResults}
                            aria-label='Unselect all'
                        >
                            Unselect all
                        </button>
                    </>)}
            </div>
            <BasicAnnotationFilter pageRangeChangeCallBack={filterPageRangeOnChange}
                              searchPhraseChangeCallBack={searchPhraseOnChange}
                              categoryChangeCallBack={categoryOnChange}
                              categories={AnnotationFilterHelpers.getCategories(redactionSearchResults)}/>
            <div className={resultsContainerClass} role="list">
                {searchStatus === SearchStatus['SEARCH_NOT_INITIATED'] && renderStartSearch()}
                {(searchStatus === SearchStatus['SEARCH_IN_PROGRESS'] && isEmptyList && isProcessingRedactionResults) && renderSearchInProgress()}
                {searchStatus === SearchStatus['SEARCH_DONE'] && isEmptyList && !isProcessingRedactionResults && noResults}
                {(searchStatus === SearchStatus['SEARCH_IN_PROGRESS'] || searchStatus === SearchStatus['SEARCH_DONE']) && renderSearchResults()}
            </div>
            <div className="redaction-search-panel-controls">
                <button
                    onClick={onCancelHandler}
                    aria-label='cancel'
                    className="cancel"
                >
                    Cancel
                </button>
                <button
                    disabled={selectedIndexes.length === 0}
                    aria-label='delete'
                    className={deleteAllSelectedClass}
                    onClick={onDeleteSelectedResults}
                >
                    Delete
                </button>
                <button
                    disabled={selectedIndexes.length === 0}
                    aria-label='add mark'
                    className={markAllForRedactionButtonClass}
                    onClick={onMarkAllForRedaction}
                >
                    Add Mark
                </button>
            </div>
        </>
    );
}

export default RedactionSearchResults;
