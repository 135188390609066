import { Chip } from '@mui/material';
import React from 'react';
import { ciWorkFlowStatus } from '../../../../models/ciWorkFlowStatus';


type CIStatusChipProps = {
  status: keyof typeof ciWorkFlowStatus;
  onClick?: () => void;
  onDelete?: () => void;
};

const CIStatusChip: React.FC<CIStatusChipProps> = ({ status, onClick, onDelete }) => {

  return (
    <Chip
      label={ciWorkFlowStatus[status].label}
      style={{
        backgroundColor: ciWorkFlowStatus[status].color,
        color: ciWorkFlowStatus[status].textColor,
        borderRadius: '16px',
        fontWeight: 'bold',
        minHeight: '36px',
        height: '36px',
      }}
      onDelete={onDelete}
      onClick={onClick}
    />
  );
};

export default CIStatusChip;
