import { ArrowBack } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, IconButton } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridRowsProp,
  GridToolbar
} from "@mui/x-data-grid-pro";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthService } from "../../../../contexts/auth-context";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hook";
import { actionBarIsVisibleSelector } from "../../../../redux/action-bar";
import { loadCategories, loadedCategoriesSelector } from "../../../../redux/category";
import { hideProgressLine, showProgressLine } from "../../../../redux/progress-line";
import { userSettingsSelector } from "../../../../redux/userSettings";
import { getApiCategories } from "../../../../services/category";
import { fetchUsers } from "../../../../services/init";
import { convertDateTimeFromUTCToTimeZoneIncludeTime } from "../../../../services/utils";
import { useCustomModal } from "../../../modals/custom-message-modal";
import "../../../pages.scss";
import { CimCategoryDelete } from "../../wizards/cim-category-delete";
import AddCategoryForm from "./addCategory";
import "./category.scss";


export function Categories() {
  const auth = useAuthService();
  const dispatch = useAppDispatch();
  const actionBarIsVisible = useAppSelector((state) =>
    actionBarIsVisibleSelector(state)
  );
  const nav = useNavigate();
  const { showModal, hideModal } = useCustomModal();

  const loaded = useAppSelector((state) => loadedCategoriesSelector(state));
  const categoriesObj = useAppSelector((state) => state.categories);
  const categories = categoriesObj.categories;

  const loadCategoryData = useCallback(async () => {
    dispatch(showProgressLine());
    const categoriesList = await getApiCategories();
    dispatch(loadCategories(categoriesList));
    dispatch(hideProgressLine());

  }, [dispatch]);

  useEffect(() => {
    loadCategoryData();
  }, [loadCategoryData])

  const categoriesTableHeaders = [
    "Name",
    "Description",
    "Created By",
    "Created At",
    "Action",
  ];
  const userSettings = useAppSelector((state) => userSettingsSelector(state));
  const dateFormat = userSettings?.regional?.dateFormat || "MM/DD/YYYY";
  const timeFormat = userSettings?.regional?.timeFormat || 24;
  const timezone  = userSettings?.regional?.timezone || "UTC";

  useEffect(() => {
    const controller = new AbortController();
    fetchUsers(controller.signal, dispatch);
    return () => controller.abort();
  }, []);


  function getColumns() {
    let columnsObject: GridColDef[] = [];
    categoriesTableHeaders.forEach((header, index) => {      
      if (index === 0) {
      columnsObject.push({
        field: header,
        headerName: header,
        minWidth: 130,
        flex:2,
        cellClassName: "cell-left-border table-left-padding",
        headerClassName: "cell-left-border categories-table-header",
      });
    }
     else if (index === 1) {
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 180,
          flex:2,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
        });
      }
      else if (index === 2) {
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 160,
          flex:1,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
        });
      }
      else if (index === 3) {
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 50,
          flex:1,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
        });
      }
      else if (index === 4) {
        columnsObject.push({
          field: header,
          headerName: header,
          width:120,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
          renderCell: (params) => {
            return (
              <Box>
              <IconButton onClick={() => onEditCategory(params)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => onDeleteCategory(params)}>
                <DeleteIcon />
              </IconButton>
              </Box>
            );
          },
        });
      } else {
        columnsObject.push({
          field: header,
          headerName: header,
          minWidth: 140,
          cellClassName: "cell-left-border table-left-padding",
          headerClassName: "cell-left-border categories-table-header",
        });
      }
    });
    return columnsObject;
  }

  function onEditCategory(params: any) {
    showModal(AddCategoryForm, {
      hideModal,
      onRefersh: loadCategoryData,
      defaultData: params.value,
    });
  }

  function onDeleteCategory(params: any) {
    showModal(CimCategoryDelete, {
      id:params.value.id,
      name:params.value.name
    });
  }

  function getProjectsRows() {
    let rows: GridRowsProp = [];
    for (let category of categories) {
      rows = rows.concat({
        id: category.id,
        [categoriesTableHeaders[0]]: category.name,
        [categoriesTableHeaders[1]]: category.description || 'N/A',
        [categoriesTableHeaders[2]]: category.createdByName,
        [categoriesTableHeaders[3]]: convertDateTimeFromUTCToTimeZoneIncludeTime(category.createdAt,timezone,dateFormat,timeFormat),
        [categoriesTableHeaders[4]]: category,

      });
    }

    return rows;
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="body-container">
        <div className="page-wrapper" style={{ padding: 0, flexGrow: 1, width: "96%" }}>
          <div
            className="page-header-small"
            style={{ padding: "12px 16px", justifyContent: "space-between" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <ArrowBack className="arrow-back" onClick={() => nav(-1)} />
              {"Category"}
            </div>
            <div>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  showModal(AddCategoryForm, {
                    hideModal,
                    onRefersh: loadCategoryData,
                    defaultData: null,
                  });
                }}
              >
                ADD CATEGORY
              </Button>
            </div>
          </div>
          <div
            style={{
              height: `calc(100vh - var(--headerHeight) - var(--footerHeight) - 176px${actionBarIsVisible ? " - 60px" : ""
                }`,
              overflowY: "auto",
            }}
            id="assets-data-0"
          >
            {loaded ? (
              <>
                {categories.length === 0 ? (
                  <div
                    style={{
                      margin: 32,
                      backgroundColor: "var(--lightGrayishBlue)",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: 205,
                    }}
                  >
                    <div
                      style={{
                        marginTop: 32,
                        marginBottom: 40,
                        textAlign: "center",
                        fontSize: 21,
                        fontWeight: 600,
                        letterSpacing: 0.15,
                      }}
                    >
                      There is no Category added.
                    </div>
                    {auth.hasPMRoleAccess() && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          showModal(AddCategoryForm, {
                            hideModal,
                            onRefersh: loadCategoryData,
                            defaultData: null,
                          });
                        }}
                      >
                        ADD CATEGORY
                      </Button>
                    )}
                  </div>
                ) : (
                  <div style={{ paddingTop: 16, overflow: "hidden" }}>
                    <Box>
                      <DataGridPro
                        autoPageSize={false}
                        autoHeight={true}
                        rowSpacingType={"border"}
                        rows={getProjectsRows()}
                        sx={{
                          "& .MuiDataGrid-cell": {
                            padding: 0,
                          },
                        }}
                        disableDensitySelector
                        disableColumnSelector
                        columns={getColumns()}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                        pagination
                        initialState={{
                          pagination: { paginationModel: { pageSize: 5 } },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        keepColumnPositionIfDraggedOutside={true}
                      />
                    </Box>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}

          </div>
        </div>
      </div>
    </div>
  );
}
