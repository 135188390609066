import { WebViewerInstance} from "@pdftron/webviewer";
import {Buttons} from "./webviewer-modal-button-utils";


class WebViewerCompareView {
    static domDoc:ShadowRoot | null = null
    static webviewerInstance: WebViewerInstance | null = null;
    static instance: null | WebViewerCompareView = null
    static files:Array<string>;
    static button: Buttons | null = null;
    static rows:Record<string, string | number>[] = [];
    static doc1: any = undefined
    static doc2: any = undefined
    constructor() {
        if(WebViewerCompareView.instance){
            return WebViewerCompareView.instance;
        }
        WebViewerCompareView.instance = this;
    }

    setFiles(files: Array<string>) {
        WebViewerCompareView.files =  files
    }
    setInstance(instance: WebViewerInstance) {
        WebViewerCompareView.webviewerInstance = instance
    }

    static processFiles(button: Buttons) {
        this.button = button;
        this.domDoc = document.getElementsByTagName('apryse-webviewer')![0].shadowRoot!;
        const [documentViewer1, documentViewer2] = WebViewerCompareView.webviewerInstance!.Core.getDocumentViewers();
        let documentsLoaded = 0;
        const compareOnceBothDocumentsLoaded = async () => {
            documentsLoaded++;
            if (documentsLoaded === 2) {
                const cb = WebViewerCompareView.compareDocuments();
                await cb(documentViewer1, documentViewer2);
                documentViewer1.removeEventListener('documentLoaded', onDocumentLoaded);
                documentViewer2.removeEventListener('documentLoaded', onDocumentLoaded);
            }
        };
        const onDocumentLoaded = async () => {
            await compareOnceBothDocumentsLoaded();
        };
        const names = JSON.parse(localStorage.getItem("versionFileNames")!);
        documentViewer1.addEventListener('documentLoaded', onDocumentLoaded);
        documentViewer2.addEventListener('documentLoaded', onDocumentLoaded);
        documentViewer1.loadDocument(this.files[0], {filename: names?.length > 0 ? decodeURIComponent(names[0]) : this.getFileNameFromURL(this.files[0])});
        documentViewer2.loadDocument(this.files[1],{filename: names?.length > 0 ? decodeURIComponent(names[1]) : this.getFileNameFromURL(this.files[1])});
    }
    static appendButton(){
        const comparePanel = WebViewerCompareView.domDoc!.querySelector('[data-element="comparePanel"]');
        const title = comparePanel!.querySelector('.changeListTitle');
        const button = document.createElement("button");
        button.textContent = "Report";
        button.addEventListener("click", async () => this.openReportView());
        button.style.float = "right";
        title!.appendChild(button);
    }
    static async getDifferenceList(doc1: any, doc2: any){
        this.doc1 = doc1;
        this.doc2 = doc2;
        this.appendButton();
        const rows: Record<string, string | number>[] =[];
        const comparePanel = WebViewerCompareView.domDoc!.querySelector('[data-element="comparePanel"]');
        setTimeout(()=>{
            const names = JSON.parse(localStorage.getItem("versionFileNames")!);
            if(comparePanel && comparePanel.querySelector('.changeList')!.querySelectorAll(".ChangeListItem").length > 0) {
                let page = Number(comparePanel.querySelector('.changeList')!.querySelectorAll(".page-number")[0]!.textContent!.split(" ")[1]);
                comparePanel.querySelector('.changeList')!.querySelectorAll(".ChangeListItem").forEach((item, index) => {

                    let oldText = item!.querySelector('.value-container.old .text')!.textContent!.trim() || "" ;
                    let newText = item!.querySelector('.value-container.new .text')!.textContent!.trim() || "";
                        rows.push({
                            [decodeURIComponent(doc1.getDocument().getFilename())]: oldText,
                            [decodeURIComponent(doc2.getDocument().getFilename())]: newText,
                            ['Page Number']: page
                        });

                    if(index > 0 && item.previousElementSibling!.classList.contains("page-number")) {
                        page = Number(item.previousElementSibling!.textContent!.split(" ")[1]);
                    }
                });
            }
            this.rows = rows;
        },500);
    }
    static async openReportView(){
        WebViewerCompareView.webviewerInstance?.Core.documentViewer.trigger("open",{columns: [decodeURIComponent(this.doc1.getDocument().getFilename()), decodeURIComponent(this.doc2.getDocument().getFilename()), 'Page Number'],rows:this.rows})
    }
    static compareDocuments() {
        return async (documentViewer1: any, documentViewer2: any) => {
            //document.querySelector(".multipanel")!.classList.add("close")
            const {Annotations} = WebViewerCompareView.webviewerInstance!.Core
            const shouldCompare = documentViewer1.getDocument() && documentViewer2.getDocument();
            if (shouldCompare) {
                const beforeColor = new Annotations.Color(21, 205, 131, 0.4);
                const afterColor = new Annotations.Color(255, 73, 73, 0.4);
                const options = {beforeColor, afterColor};
                await documentViewer1.startSemanticDiff(documentViewer2, options);
                WebViewerCompareView.webviewerInstance?.UI.closeElements(["process-modal"])
                //document.querySelector(".multipanel")!.classList.add("close")
                WebViewerCompareView.webviewerInstance?.UI.enableFeatures([WebViewerCompareView.webviewerInstance?.UI.Feature.ComparePages]);
                if(!WebViewerCompareView.webviewerInstance?.UI.isElementOpen(('comparePanel'))){
                    WebViewerCompareView?.webviewerInstance?.UI.openElements(['comparePanel']);
                    this.button?.makeActive(this.domDoc);
                    await this.getDifferenceList(documentViewer1, documentViewer2);
                }
            }
        }
    }

    static async createNewFileWithDifferences(){
        await WebViewerCompareView.webviewerInstance!.Core.PDFNet.initialize();

        const newDoc = await WebViewerCompareView.webviewerInstance!.Core.PDFNet.PDFDoc.create();
        await newDoc.lock();

        const doc1 = await WebViewerCompareView.webviewerInstance!.Core.PDFNet.PDFDoc.createFromURL(this.files[0]);
        const doc2 = await WebViewerCompareView.webviewerInstance!.Core.PDFNet.PDFDoc.createFromURL(this.files[1]);

        await newDoc.appendTextDiffDoc(doc1, doc2);

        await newDoc.unlock();

        WebViewerCompareView.webviewerInstance!.UI.loadDocument(newDoc);

        // wait until the document has been loaded
        WebViewerCompareView.webviewerInstance!.Core.documentViewer.addEventListener('documentLoaded', () => {
            WebViewerCompareView.webviewerInstance!.UI.setLayoutMode(WebViewerCompareView.webviewerInstance!.UI.LayoutMode.FacingContinuous);
        });
    }

    static getFileNameFromURL(url: string) {
        try {
            const fileNameWithAccessKey = url.split("/").pop();
            const fileName = fileNameWithAccessKey?.split("?")[0];
            return decodeURIComponent(fileName!);
        } catch (e) {
            return undefined;
        }
    }

}

export default WebViewerCompareView;